import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import ListaPedidosAgendados from './components/ListaPedidosAgendados';
import ReactGA from "react-ga4";

const PREFIX = 'PedidosAgendados'

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`,
    scrollBar: `${PREFIX}-scrollBar`,
    headerStyle: `${PREFIX}-headerStyle`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${classes.main}`]: {
        marginTop: theme.spacing(5),
        //marginBottom: theme.spacing(2),
    },

    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.headerStyle}`]: {
        backgroundColor: theme.palette.personal.boardCard
    }
}))

export default function PedidosAgendados() {


    useEffect(() => {//informa ao analytics que o usuário caiu na pagina de agendados
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "agendados" });
    }, [])

    return (
        <Root>
            <Container component="main" className={classes.main} maxWidth={false}>

                <Typography variant="h2" component="h1">
                    Pedidos Agendados
            </Typography>

                <Typography variant="h5" component="h2">
                    Listagem de pedidos agendados
            </Typography>

                <ListaPedidosAgendados />

            </Container>
        </Root>
    );
}
