import { createUUID } from "../../../../util/order/orderUtil"

export class SubItemObj {
	referenceCode
	discount
	name
	product
	price
	quantity
	observations
	subItemsPrice
	externalCode
	sumInTotal = true
	createdAt = new Date()
	notSaved = true
	tipoImportacao

	// totalPrice = () => this.quantity * this.price
}

export class ItemObj {
	referenceCode
	category
	discount
	product
	externalCode
	name
	price
	quantity
	observations
	subItems = []
	sumInTotal = true
	localNotification
	status = 'PENDENTE'
	createdAt = new Date()
	notSaved = true
	tipoImportacao

	// totalPrice = () => this.quantity * this.price
}

export class PaymentObj {
	id
	externalCode
	code
	name
	value
	changeFor
}

export class OrderObj {
	descricao = 'SISCHEF_DELIVERY_ONLINE'
	codigoIntegracao = createUUID() // Date.parse(new Date()) + '' + parseInt( Math.random() * 1000)
	tipoImportacao = 'SO'
	situacao = 'CONFIRMADO'
	tipo = 'DELIVERY'
	dataPedido = new Date()
	pago = false
	identificadorSecundario = ""
	
	iFoodOrder = {
		createdAt: new Date(),
		customer: undefined,
		deliveryFee: 0,
		levarTrocoPara: undefined,
		levarMaquinaCartao: false,
		deliveryAddress: undefined,
		items: [],
		payments: [],

		deliveryMethod: {
			minTime: undefined,
			maxTime: undefined,
			mode: "DELIVERY"
		  }

	}


}


