export const initialGenericCodes = () => {
    return ([])
}


export const setGenericCodes = (currentState, action) => {

    return {
        ...currentState,
        genericCodes: action.genericCodes
    }
}