import AuthService from "../../../service/AuthService";

export const SITUACOES_KDS = [
    { key: 'PENDENTE', style: { backgroundColor: '#ffc107', borderColor: '#ffc107' }, name: "Pendentes", singularName: "Pendente" },
    { key: 'INICIADO', style: { backgroundColor: '#4caf50', borderColor: '#4caf50' }, name: "Iniciados", singularName: "Iniciado" },
    { key: 'CONCLUIDO', style: { backgroundColor: '#795548', borderColor: '#795548' }, name: "Concluidos", singularName: "Concluido" },
    { key: 'DESPACHADO', style: { backgroundColor: '#bdbdbd', borderColor: '#bdbdbd' }, name: "Despachados", singularName: "Despachado" },
    { key: 'CANCELADO', style: { backgroundColor: '#ef5350', borderColor: '#ef5350' }, name: "Cancelados", singularName: "Cancelado" },
    { key: 'CANCELAMENTO_ACEITO', style: { backgroundColor: '#ef5350', borderColor: '#ef5350' }, name: "Cancelamento aceito", singularName: "Cancelamento aceito" },
]

export const getSituacaoKdsByKey = (key) => {
    const foundedSituacao = SITUACOES_KDS.find(situacao => situacao.key === key)

    return foundedSituacao;
}

export const imprimirItemsKDS = (itemsKDS, localImpressao) => {
    return new Promise((resolve, reject) => {
        let service = new AuthService()

        service.post(`/api-v2/impressaoAPI/enviarImpressaoKds?localImpressao=${localImpressao}`, itemsKDS)
            .then(itemPrintResp => {
                resolve(itemPrintResp)
            }).catch(err => {
                reject(err)
            })
    });
}

export function convertBoardKdsFromItemKds(boardKds) {
    let itemsKds = []

    boardKds.map(card => {
        card.map(itemKds => {
            itemsKds.push(itemKds)
            return null;
        })
        return null;
    })

    return itemsKds
}

function sortDate(a, b) {
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateB > dateA ? -1 : 1;
};

export const playSoundCancelItem = () => {
    try {
        var audio = new Audio(process.env.PUBLIC_URL + '/static/sounds/ring03.mp3');
        audio.addEventListener('loadeddata', function () {
            if (audio.readyState >= 2) {
                audio.play();
            }
        });
    } catch (err) {
        console.error("Falha ao tocar som 'playSoundCancelItem'");
    }

}

export const playSoundNewItem = () => {
    try {
        var audio = new Audio(process.env.PUBLIC_URL + '/static/sounds/ring02.mp3');
        audio.addEventListener('loadeddata', function () {
            if (audio.readyState >= 2) {
                audio.play();
            }
        });
    } catch (err) {
        console.error("Falha ao tocar som 'playSoundNewItem'");
    }
}

export function itemKdsIsCancelado(itemKds) {
    return ['CANCELADO', 'CANCELAMENTO_ACEITO'].includes(itemKds.item.status)
}

export function convertItemKdsFromBoardKds(itemsKds, settings) {

    let kdsBaord = []

    if (itemsKds && itemsKds.length > 0 && 
        settings && 
        settings.locais && settings.locais.length > 0 && 
        settings.exibir && settings.exibir.length > 0 && 
        settings.tiposPedido && settings.tiposPedido.length > 0) {

        const itemsFiltrados = filtrarItem(settings, itemsKds);

        itemsFiltrados.sort(sortDate)

        if (settings.agrupamento === 'AGRUPADO') {

            let agrupadorList = []

            itemsFiltrados.map((itemKds) => {
                const index = agrupadorList.findIndex((board) => board === itemKds.item.agrupador)

                if (index < 0) {
                    agrupadorList.push(itemKds.item.agrupador)
                }
                return null;
            })

            agrupadorList.map((agrupador) => {
                kdsBaord.push(itemsFiltrados.filter((itemKds) => itemKds.item.agrupador === agrupador))
                return null;
            })

        } else {
            itemsFiltrados.map((itemKds) =>
                kdsBaord.push([itemKds])
            )
        }
    }

    // console.log('return convertItemKdsFromBoardKds -> kdsBaord', kdsBaord)
    return kdsBaord
}

export function filtrarItem(settings, itemsKds) {
    const locais = [];
    const exibir = [];
    const tiposPedido = [];

    settings.locais.map((local) => local.checked && locais.push(local.codigo));
    settings.exibir.map((situacao) => situacao.checked && exibir.push(situacao.key));
    settings.tiposPedido.map((tipo) => tipo.checked && tiposPedido.push(tipo.key));

    const itemsFiltrados = itemsKds
        .filter((itemKds) => locais.includes(itemKds.item.localNotificacao))
        .filter((itemKds) => exibir.includes(itemKds.item.status))
        .filter((itemKds) => tiposPedido.includes(itemKds.pedidoKds.tipoPedido));

    return itemsFiltrados;
}
