import { createCardFromPedido } from "../util/board/BoardUtil";
import { groupItemsByName } from "../util/order/orderUtil";
import { addOrUpdateBoardFromOrder, removeOrderFromOrder } from "./boardStore";
import { addOrUpdateOrderOnCard, removeOrderOnCard } from "./identificadorStore";



export const addOrUpdateGenericFromOrder = (currentState, action) => {
    //console.log("addOrUpdateGenericFromOrder currentState -> ", currentState)

    let copyCurrentState = { ...currentState }
    const newOrder = action.newOrder;
    

    if (newOrder){
        
        /* CASO ESTEJA EDITANDO UM PEDIDO QUE FOI ALTERADO, FORCA O FECHAMENDO DA TELA DE LANCAMENTO DO PEDIDO */
        const editingOrderNow = copyCurrentState?.dialogOrder?.order

        if (newOrder && editingOrderNow && editingOrderNow.id === newOrder.id){
            console.log("O pedido que esta sendo editado foi modificado")
            const dialogOrder = copyCurrentState?.dialogOrder
            const importOrder = copyCurrentState?.importOrder //Fecha a tela de import
            copyCurrentState = { ...copyCurrentState, dialogOrder: { ...dialogOrder, open: false }, importOrder: { ...importOrder, open: false } } //Fecha a tela de import
        }

        /* CASO ESTEJA MOSTRANDO OS DETALHES DE UM PEDIDO E SEJA O MESMO, ATUALIZA ELE */
        //const detailOrderOpen = copyCurrentState?.detailOrder?.open
        const detailOrderData = copyCurrentState?.detailOrder?.card?.data
        const detailOrderPrint = copyCurrentState?.detailOrder?.card?.data?.printOrder

        if (!detailOrderPrint && newOrder && detailOrderData && detailOrderData.id === newOrder.id) {
            console.log("O pedido que esta sendo detalhado foi modificado")
            const detailOrder = copyCurrentState?.detailOrder
            detailOrder.card = createCardFromPedido(newOrder)
            const groupedItems = groupItemsByName(newOrder)
            const importOrder = copyCurrentState?.importOrder//Fecha a tela de import
            copyCurrentState = { ...copyCurrentState, detailOrder: { ...detailOrder, groupedItems }, importOrder: { ...importOrder, open: false } }//Fecha a tela de import
        }

        /* CASO ESTEJA REALIZANDO PGTO, FECHA A JANELA ABERTA*/
        //const paymentOrderOpen = copyCurrentState?.paymentOrder?.open
        const paymentOrderOrder = copyCurrentState?.paymentOrder?.order
        const paymentOrderPrint = copyCurrentState?.detailOrder?.card?.data?.printOrder

        if (!paymentOrderPrint && newOrder && paymentOrderOrder && paymentOrderOrder.id === newOrder.id) {
            console.log("O pedido que esta sendo pago foi modificado")
            const paymentOrder = copyCurrentState?.paymentOrder
            paymentOrder.card = createCardFromPedido(newOrder)
            const importOrder = copyCurrentState?.importOrder//Fecha a tela de import
            copyCurrentState = { ...copyCurrentState, paymentOrder: { ...paymentOrder, open: false }, importOrder: { ...importOrder, open: false } }//Fecha a tela de import
        }

        /*CASO ESTEJA TRANSFERINDO ITENS DA COMANDA/MESA ALTERADA FECHA A JANELA ABERTA */
        const originOrder = copyCurrentState?.dialogJoinOrders?.origin
        const destinyOrder = copyCurrentState?.dialogJoinOrders?.destiny

        if (newOrder?.identificadorPrincipal === originOrder?.identificadorPrincipal || newOrder?.identificadorPrincipal === destinyOrder?.identificadorPrincipal) {
            copyCurrentState = { ...copyCurrentState, dialogJoinOrders: { open: false } }//fecha a tela de transferir itens e zera os outros valores
        }

        const type = newOrder.tipo;
        if (type === "COMANDA" || type === "MESA" ){
            return addOrUpdateOrderOnCard(copyCurrentState, action)
        }
        if (type === "DELIVERY" || type === "BALCAO" ){
            return addOrUpdateBoardFromOrder(copyCurrentState, action)
        }

    }


    return {
        ...copyCurrentState,
    }
}



export const removeOrderGeneric = (currentState, action) => {
  
    let copyCurrentState = { ...currentState }
    const order = action.order;
    const converted = action.converted ? action.converted : false

    if (order){

        /* CASO ESTEJA EDITANDO UM PEDIDO QUE FOI ALTERADO, FORCA O FECHAMENDO DA TELA DE LANCAMENTO DO PEDIDO */
        const editingOrderNow = copyCurrentState?.dialogOrder?.order

        if (order && editingOrderNow && editingOrderNow.id === order.id) {
            console.log("O pedido que esta sendo editado foi modificado")
            const dialogOrder = copyCurrentState?.dialogOrder
            copyCurrentState = { ...copyCurrentState, dialogOrder: { ...dialogOrder, open: false } }
        }

        /* CASO ESTEJA MOSTRANDO OS DETALHES DE UM PEDIDO E SEJA O MESMO, ATUALIZA ELE */
        //const detailOrderOpen = copyCurrentState?.detailOrder?.open
        const detailOrderData = copyCurrentState?.detailOrder?.card?.data

        if (order && detailOrderData && detailOrderData.id === order.id) {
            console.log("O pedido que esta sendo detalhado foi modificado")
            const detailOrder = copyCurrentState?.detailOrder
            detailOrder.card = createCardFromPedido(order)
            const groupedItems = groupItemsByName(order)
            copyCurrentState = { ...copyCurrentState, detailOrder: { ...detailOrder, groupedItems } }
        }

        /* CASO ESTEJA REALIZANDO PGTO, FECHA A JANELA ABERTA*/
        const paymentOrderOrder = copyCurrentState?.paymentOrder?.order

        if (order && paymentOrderOrder && paymentOrderOrder.id === order.id) {
            console.log("O pedido que esta sendo pago foi modificado")
            const paymentOrder = copyCurrentState?.paymentOrder
            paymentOrder.card = createCardFromPedido(order)
            copyCurrentState = { ...copyCurrentState, paymentOrder: { ...paymentOrder, open: false } }
        }

        /*CASO ESTEJA TRANSFERINDO ITENS DA COMANDA/MESA ALTERADA FECHA A JANELA ABERTA */
        const originOrder = copyCurrentState?.dialogJoinOrders?.origin
        const destinyOrder = copyCurrentState?.dialogJoinOrders?.destiny

        if (order?.identificadorPrincipal === originOrder?.identificadorPrincipal || order?.identificadorPrincipal === destinyOrder?.identificadorPrincipal) {
            copyCurrentState = { ...copyCurrentState, dialogJoinOrders: { open: false } }//fecha a tela de transferir itens e zera os outros valores
        }


        const type = order.tipo;

        if ((type === "COMANDA" || type === "MESA") && !converted) {
            return removeOrderOnCard(copyCurrentState, action)
        }
        if (type === "DELIVERY" || type === "BALCAO" || converted) {
            return removeOrderFromOrder(copyCurrentState, action)
        }
    }

    return {
        ...copyCurrentState,
    }
}
 