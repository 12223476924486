import React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Badge, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { getParamByKey } from '../../../component/parameter';
import clsx from 'clsx';

const PREFIX = 'AvatarForIdentificador'

const classes = {
    color: `${PREFIX}-color`,
    small: `${PREFIX}-small`
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    [`&.${classes.color}`]: {
        backgroundColor: theme.palette.primary.main,
    },

    [`&.${classes.small}`]: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    }
}))

export const AvatarForIdentificador = (props) => {
    const { order, size } = props;

    let tooltip = "Nenhum pedido informado"
    let body = ""
    let badge = null
    const generalParameters = useSelector(state => state.generalParameters)
    let modeloIdenPrincipal = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString

    if (order) {
        const labelIdentificadorPrincipal = modeloIdenPrincipal === "COMANDA" ? "Comanda" : "Mesa"
        const labelIdentificadorSecundario = modeloIdenPrincipal === "COMANDA" ? "Mesa" : ""
        tooltip = `${labelIdentificadorPrincipal} ${order.identificadorPrincipal} 
                   ${order.identificadorSecundario ?
                " e " + labelIdentificadorSecundario + " " + order.identificadorSecundario : ""}`
        if (order.complementoIdentificador) {
            tooltip += " - " + order.complementoIdentificador.substring(0, 45)
        }
        body = order.identificadorPrincipal
        badge = order.identificadorSecundario
    }

    return (
        <Tooltip title={tooltip}>
            <Badge color="secondary"
                badgeContent={badge}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                overlap="rectangular"
                invisible={!badge}>
                <StyledAvatar variant="rounded" className={clsx([classes.color, (size === 'small' && classes.small)])} >
                    {body}
                </StyledAvatar>
            </Badge>
        </Tooltip>
    )
}