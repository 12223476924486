
export const toogleInvoiceDialog = (currentState, action) =>  {
    let { detailDialogInvoice } = action
    return {
        ...currentState, 
        detailDialogInvoice: { ...detailDialogInvoice, open: detailDialogInvoice.open } 
    }
}

export const initialInvoiceDialog = () =>  {

    return {
        open: false,
        order: null
    }
}