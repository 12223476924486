export const updateWhatsAppStatus = (currentState, action) => {
    const { whatsapp } = action
    const { status } = whatsapp

    return {
        ...currentState,
        whatsapp: { ...whatsapp, status }
    }
}

export const initialWhatsApp = () => {

    return {
        status: 'disconnected',
    }
}