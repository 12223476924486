import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthService from '../../service/AuthService';
import SocketIO from '../../pages/kds/socket';

export default function SocketContext({ startClosed = false }) {
    const dispatch = useDispatch()
    const [websocket, setWebsocket] = useState(null)
    const [connectionStatus, setConnectionStatus] = useState(null)
    const URL_SOCKET = process.env.REACT_APP_SOCKET_URL
    const envRoom = process.env.REACT_APP_SOCKET_ROOM
    const websocketAuthToken = process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN
    const service = new AuthService()
    const profile = service.getProfile()
    //auth - token uuid configurado no servidor socket e na variavel de ambiente
    const query = 'room=' + profile?.unidade?.uuid + envRoom + '&auth=' + websocketAuthToken + '&empresa=' + profile.unidade.nome + '&username=' + profile.username

    useEffect(() => {
        let profile = AuthService.profile()
        if (!!profile && !!profile.unidade && !!profile.unidade.uuid && profile.unidade.uuid.length > 3 && !!websocket) {
            dispatch({ type: 'UPDATE_SOCKET', websocket: websocket })
            console.log("Socket criado e atualizado")
        } else {
            if (websocket) {
                console.log("Nenhum usuário logado ou empresa encontrada - não é possível definir a sala para o socket")
            }

        }

        // eslint-disable-next-line
    }, [websocket])

    const addEvents = (socket) => {
        let profile = AuthService.profile()
        socket.room = profile.unidade.uuid + process.env.REACT_APP_SOCKET_ROOM
        setWebsocket(socket)

        socket.on("Update", (event) => {//recebe um update de socket de outros usuarios socket
            onMessageReceive(event)
        })

        socket.on("reciveSocketMessage", (event) => {//recebe um update do socket do servidor
            onMessageReceive(event)
        })
    }

    const onMessageReceive = (event) => {

        let data = event
        const { type } = data
        console.log("socket event recebido - type -> ", type)

        if (type === 'MOVE_CARD') {
            let { moveOptions } = data;
            dispatch({ type: 'MOVE_CARD', moveOptions })

        } else if (type === 'ADD_OR_UPDATE_BOARD_FROM_ORDER') {
            let { newOrder } = data;
            dispatch({ type: 'ADD_OR_UPDATE_BOARD_FROM_ORDER', newOrder })

        } else if (type === 'ADD_OR_UPDATE_GENERIC_FROM_ORDER') {
            let { newOrder } = data;
            dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder })

        } else if (type === 'UPDATE_BOARD_FROM_EVENTS') {
            let { events } = data;
            dispatch({ type: 'UPDATE_BOARD_FROM_EVENTS', events: events })

        } else if (type === 'REMOVE_ORDER_ON_BOARD') {
            let { order } = data;
            dispatch({ type: 'REMOVE_ORDER_ON_BOARD', order: order })

        } else if (type === 'REMOVE_ORDER_GENERIC') {
            let { order } = data;
            dispatch({ type: 'REMOVE_ORDER_GENERIC', order: order })
        } else if (type === 'WHATSAPP_STATUS_CHANGE') {
            let { status } = data;
            console.log("Status do WhatsApp:", status)

            setConnectionStatus(status)

            localStorage.setItem('whatsappStatus', status)

            dispatch({ type: 'WHATSAPP_STATUS_CHANGE', whatsapp: { status } })
        } else if (type === 'UPDATE_STATUS_TEF') {
            let open = false
            const { intencaoVendaId } = data
            const tef = JSON.parse(sessionStorage.getItem('_tef_'))

            if (tef) {
                open = tef.intencaoVendaId === intencaoVendaId
            }

            dispatch({ type: 'TOOGLE_TEF_DIALOG', dialogTef: { open, tef } })
        }
    }

    return (
        <>
            <SocketIO addEvents={addEvents} url={URL_SOCKET} query={query} />
        </>
    );
}