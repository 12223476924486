import React, { useState } from 'react';
import { Box, Dialog, Divider, Grid, IconButton, Tooltip } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { getKey } from '../../../../util/indexedDB/IdbUtils';
import { DialogContent, DialogTitle } from '../../CustomDialog/dialogComponents';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { currencyFormat } from '../../../../util/formatter';
import { totalOrder } from '../../../../util/order/orderUtil';
import { useConnectionStatus } from '../../../../util/http/HttpUtil';
import { useDispatch } from 'react-redux';


function ListOfflineOrders() {
    const [valor, setValor] = useState("")
    const [open, setOpen] = useState(false)
    const isConnected = useConnectionStatus()

    const onClickOpenSavedOrders = () => {
        setOpen(true)
        getKey("Cards").then(result => { setValor(result) });
    }

    return (
        <React.Fragment>
            {!isConnected && (
                <Grid item>
                    <Tooltip title="Listagem de pedidos OFFLINE">
                        <IconButton onClick={onClickOpenSavedOrders} size="large">
                            <WifiOffIcon color='error' />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}

            <Dialog
                open={open}
                onClose={() => setOpen(false)}>
                <DialogTitle
                    onClose={() => setOpen(false)}>
                    Listagem de pedidos
                </DialogTitle>

                <DialogContent>
                    {valor?.length > 0 ? (
                        <>
                            <Box mb={"5px"}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        {"Identificador"}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {"Cliente "}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {"Total "}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {"Visualização"}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            {valor.map((card) => (
                                card?.orderList?.length > 0 && (
                                    card.orderList.map((order, index) => (
                                        <ShowOrdersIdentificador order={order} identificador={card.identificador} key={index} />
                                    ))
                                )
                            ))}
                        </>
                    ) : (
                        <React.Fragment>
                            sem pedidos salvos
                        </React.Fragment>
                    )}
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}

function ShowOrdersIdentificador({ order, identificador }) {
    const dispatch = useDispatch()

    function openOrder() {
        dispatch({
            type: 'TOOGLE_DETAIL_ORDER_DIALOG_OFFLINE',
            detailOrderOffline: {
                order,
                open: true,
            }
        })
    }

    return (
        <Box displayPrint="none" minWidth={"500px"} mt={"5px"}>
            <Grid container>
                <Grid item xs={3}>
                    {identificador.tipo + ": " + identificador.identificador}
                </Grid>
                <Grid item xs={5}>
                    {order?.pessoaCliente?.nome}
                </Grid>
                <Grid item xs={3}>
                    {currencyFormat(totalOrder(order.iFoodOrder))}
                </Grid>
                <Grid item xs={1}>
                    <IconButton size='small' onClick={openOrder} >
                        <VisibilityIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Divider />
        </Box>
    )
}

export default ListOfflineOrders;