import { addDays, setHours, setMinutes, setSeconds, subDays, subHours } from 'date-fns'
import { dateFormat } from '../util/formatter';

/**
 * Gera um periodo de data
 * @param  {[String]} config       simbolo para verificar se data é no futuro (+), presente (=) ou passado (-) ou personalizada (custom)
 * @param  {[Number]} daysQuantity numero da diferença de dias entre uma data e outra
 * @return {[Object]}              Objeto de periodo { label, value }, onde value é uma string com data inicial e data final separadas por virgula
 */
export const getPeriod = (config, key, daysQuantity = 0) => {
    let label = ''
    let startDate = ''
    let endDate = ''

    switch (config) {
        case '+':
            startDate = normalizeDateSischef(subHours(addDays(new Date(), 1), 6))
            endDate = normalizeDateSischef(addDays(subHours(new Date(), 6), daysQuantity + 1))

            label = daysQuantity === 1 ? 'Amanhã' : `Próximos ${daysQuantity} dias`

            return { key, label, startDate, endDate }
        case '-':
            startDate = normalizeDateSischef(subDays(new Date(), daysQuantity))
            endDate = normalizeDateSischef(addDays(new Date(), 1))

            label = `Últimos ${daysQuantity} dias`

            return { key, label, startDate, endDate }
        case '=':
            startDate = normalizeDateSischef(subHours(new Date(), 6))
            endDate = normalizeDateSischef(addDays(subHours(new Date(), 6), 1))

            label = 'Hoje'

            return { key, label, startDate, endDate, default: true }
        case 'custom':
            startDate = normalizeDateSischef(subHours(new Date(), 6))
            endDate = normalizeDateSischef(addDays(subHours(new Date(), 6), 1))

            label = 'Personalizado'

            return { key, label, startDate, endDate }
        default:
            return null
    }
}

/**
 * Retorna valores default para periodos de data para filtros
 * @return {Array} array de periodos de datas
 */
export const getDefaultPeriodList = () => {

    let periods = [];

    periods.push(getPeriod('+', 0, 30));
    periods.push(getPeriod('+', 1, 15));
    periods.push(getPeriod('+', 2, 7));
    periods.push(getPeriod('+', 3, 3));
    periods.push(getPeriod('+', 4, 1));

    periods.push(getPeriod('=', 5));

    periods.push(getPeriod('-', 6, 2));
    periods.push(getPeriod('-', 7, 3));
    periods.push(getPeriod('-', 8, 7));
    periods.push(getPeriod('-', 9, 15));

    periods.push(getPeriod('custom'));

    return periods;

}

/**
 * Retorna o periodo default
 * @return {Object} periodo de data default (hoje)
 */
export const getDefaultPeriod = () => {
    return getPeriod('=');
}

/**
 * Retorna o periodo default já formatada
 * @return {Object} periodo de data default (hoje)
 */
export const getDefaultPeriodFormated = () => {
    const defaultPeriod = getDefaultPeriod()

    defaultPeriod.startDate = `${dateFormat(defaultPeriod.startDate, 'yyyy-MM-dd')} 04:00:00`
    defaultPeriod.endDate = `${dateFormat(defaultPeriod.endDate, 'yyyy-MM-dd')} 04:00:00`

    return defaultPeriod;
}

/**
 * está função irá retornar a data com as horas em 4 da manhã
 * @return {Date} data as 4 da manhã
 */
export const normalizeDateSischef = (date) => {
    let newDate = setHours(date, 4)
    newDate = setMinutes(newDate, 0)
    newDate = setSeconds(newDate, 0)

    return newDate
}