import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TextField,
    IconButton,
} from '@mui/material';
import AuthService from '../../../service/AuthService';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { currencyFormat, quantityFormat } from '../../../util/formatter'
import { Alert } from '@mui/material';
import { totalAdicionalTip } from '../../../util/order/orderUtil';

const PREFIX = 'OrderImportItens'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    productFounded: `${PREFIX}-productFounded`,
    productNotFound: `${PREFIX}-productNotFound`,
    rootTableRow: `${PREFIX}-rootTableRow`
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${classes.rootTableRow}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
    },

    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.productFounded}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.productNotFound}`]: {
        color: theme.palette.error.main,
    },
}))

export default function OrderImportItens({ order }) {
    const items = order?.iFoodOrder?.items || [];
    const [products, setProducts] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(false)
    const [adicionalTip, setAdicionalTip] = useState(0)
    // const productMenu = useSelector(state => state.globalCache.productMenu)

    useEffect(() => {
        setLoadingProducts(true)

        setAdicionalTip(totalAdicionalTip(order?.iFoodOrder))

        let service = new AuthService()
        service.get('/api-v2/produtoCardapio/listAllProducts')
            .then(products => {
                let productsWithoutServices = products.filter(product => product.tipoProduto !== 'S')

                setProducts(productsWithoutServices)
                setLoadingProducts(false)

            }).catch(err => {
                console.error(err)
                setLoadingProducts(false)
            })
        // eslint-disable-next-line
    }, [])


    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Itens/Produtos</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center" >Qtde</TableCell>
                        <TableCell align="right">V. total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {items.map((item, index) => (
                        <ImportItemTableRow key={index} item={item} isSubItem={false} products={products} loading={loadingProducts} />
                    ))}
                </TableBody>

            </Table>

            {adicionalTip > 0 && (
                <Alert severity="info" color="info">
                    Será adicionado automaticamente um item de {currencyFormat(adicionalTip)} referente a taxas adicionais.
                </Alert>
            )}
        </TableContainer>
    )
}

function ImportItemTableRow({ item, products, loading, isSubItem, isLast }) {
    const [openSelect, setOpenSelect] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(item.produto || null)
    const itemHasLinked = item.produto ? true : false;
    const temSubItem = item.subItems && item.subItems?.length > 0

    const handleClickAlterar = () => {
        setOpenSelect(!openSelect)
    }

    return (
        <React.Fragment>
            <StyledTableRow className={temSubItem ? classes.rootTableRow : isSubItem && !isLast ? classes.rootTableRow : null}>
                <TableCell size={isSubItem ? 'small' : 'medium'} style={{ width: "70%" }}>
                    <Typography className={itemHasLinked ? classes.productFounded : classes.productNotFound} variant={isSubItem ? 'body2' : 'body1'}>
                        {isSubItem ? `+ ${item.name}` : item.name}
                    </Typography>

                    {openSelect &&
                        <Autocomplete
                            size="small"
                            loading={loading}
                            options={products}
                            value={selectedProduct}
                            isOptionEqualToValue={(option, value) => {
                                return option && option.id === value.id
                            }}
                            onChange={(event, newValue) => {
                                setSelectedProduct(newValue);
                                item.produto = newValue
                            }}
                            getOptionLabel={(option) => option.descricao || null}
                        renderOption={(props, option) => <li {...props} key={option.id}>{option.descricao}</li>}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    }
                </TableCell>

                <TableCell padding="checkbox" size={isSubItem ? 'small' : 'medium'}  >
                    <IconButton
                        title="Alterar produto associado"
                        onClick={handleClickAlterar}
                        size="large">{openSelect ? <CheckIcon /> : <EditIcon />}</IconButton>
                </TableCell>

                <TableCell align="center" size={isSubItem ? 'small' : 'medium'}>
                    {quantityFormat(item.quantity)}
                </TableCell>

                <TableCell size={isSubItem ? 'small' : 'medium'} align="right" style={{ whiteSpace: 'nowrap' }}>
                    {currencyFormat(item.totalPrice)}
                </TableCell>
            </StyledTableRow>

            {
                item.subItems?.map((subItem, index) => (
                    <ImportItemTableRow key={index} isSubItem={true} item={subItem} isLast={index === item.subItems.length - 1} products={products} loading={loading} />
                ))
            }

        </React.Fragment>
    )
}