import React from 'react'
import { Skeleton } from '@mui/material'
import { AutoGridBox } from '../../../component/generics/AutoGridBox'

export function SkeletonProducts({ width = 130, height = 70, gap = 1, length = 10 }) {

    return (
        <AutoGridBox minWidth={width} gap={gap}>
            {Array(length).fill().map((e, index) =>
                <Skeleton key={index} variant="rectangular" height={height} />
            )}
        </AutoGridBox>
    )
}
