import { Button, Dialog, DialogActions, Box, Tooltip, Typography, Tabs, Tab } from '@mui/material';
import React, {useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import { useHotkeys } from 'react-hotkeys-hook';
import DoneIcon from '@mui/icons-material/Done';
import SplitCustomerInvoice from './SplitCustomerInvoice';
import SeparateItemsByCustomer from './SeparateItemsByCustomer';
import { Alert } from '@mui/material';

export default function ListItemReceive({ open, setOpen, setValueSelectedItem }) {

    const order = useSelector(state => state.paymentOrder.order)

    const [valueSelected, setValueSelected] = useState(0)
    const btnConfirmRef = useRef();
    const [saving, setSaving] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)

    useHotkeys('f10', (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    })

    function handleCancel() {
        setValueSelectedItem(0.0)



        order.iFoodOrder.items.map((item) => {

            let partialCopy = Array(item.divideInto || 1)

            item.pagar = 0.0

            if (!item.received) {
                if (item.partialPayment?.filter(pay => pay === 'RECEIVED').length > 0) {
                    item.partialPayment.map((pay, index) => {
                        partialCopy[index] = (pay === 'CHECKED') ? 'NOT_CHECKED' : pay
                        return null;
                    })
                } else {
                    item.divideInto = 1
                    partialCopy = Array(1).fill('NOT_CHECKED')
                }

                item.partialPayment = partialCopy
            }
            return null;

        })

        handleClose()
    }

    function handleClose() {
        setOpen(false)
    }

    const handleFinalizar = () => {

        setSaving(true)
        setValueSelectedItem(valueSelected)
        setSaving(false)

        handleClose()
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Dialog open={open}

            maxWidth='md'
            fullWidth
            onClose={handleCancel}>

            <DialogTitle onClose={handleCancel}>
                <Typography variant="caption" display="block" gutterBottom>
                    Divisão da conta
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    <Tab label='Dividir conta' tabIndex={0} />
                    <Tab label="Selecionar itens" tabIndex={1} />

                </Tabs>

                <Box pt={2}>
                    <Alert severity="info">
                        {`( ${order?.quantidadePessoas || 0} ) pessoas neste pedido`}
                    </Alert>
                </Box>

                <Box pt={2}>

                    {selectedTab === 0 ?
                        <SplitCustomerInvoice order={order} setValueSelected={setValueSelected} />
                        :
                        <SeparateItemsByCustomer order={order} setValueSelected={setValueSelected} />
                    }
                </Box>
            </DialogContent>

            <DialogActions>
                <Tooltip title="Atalho F10" aria-label="Finalizar">
                    <Button
                        ref={btnConfirmRef}
                        onClick={handleFinalizar}
                        variant="contained"
                        color="primary"
                        disabled={saving}
                        endIcon={<DoneIcon />}>
                        Confirmar
                    </Button>

                </Tooltip>
            </DialogActions>
        </Dialog >
    );
}
