export const toogleDialogLogistics = (currentState, action) => {
  const { dialogLogistics } = action

  return {
    ...currentState,
    dialogLogistics: { ...dialogLogistics, open: dialogLogistics.open }
  }
}

export const initialDialogLogistics = () => {
  return {
    open: false,
    order: null
  }
}