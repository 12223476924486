import React from 'react';
import { Dialog, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { DialogContent, DialogTitle } from '../../pages/layout-components/CustomDialog/dialogComponents';



/*Função para mostrar as informações do pedido em um dialog na tela*/
function OrderInfo({ order, open, setOpen }) {

    const handleClose = () => {//fecha o dialog
        setOpen(false)
    }
    /*Gera uma tabela dentro de um dialog com as informações do pedido  */
    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            scroll='paper'
            open={open}
            onClose={handleClose}>

            <DialogTitle onClose={handleClose}>
                <Typography>Informações do Pedido</Typography>
            </DialogTitle>

            <DialogContent>
                <Table size='small' aria-label='resumo do pedido' stickyHeader >
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography color="primary" variant="h5">ID PEDIDO {order.id ? order.id : "NÃO IDENTIFICADO"}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography>Logs</Typography>
                            </TableCell>
                            <TableCell>
                                {order?.logs ? <Typography style={{ whiteSpace: 'pre-line' }} >{order?.logs}</Typography> : <Typography>Sem informações</Typography>}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    )
}

export default OrderInfo