import { convertBoardKdsFromItemKds, convertItemKdsFromBoardKds } from "../utils";

export const createBoardKds = (currentState, action) => {

    let boardKds = action.boardKds;

    return {
        ...currentState,
        boardKds: boardKds

    }
}

export const updateItemKds = (currentState, action) => {
    const currentBoardKds = currentState.boardKds
    const itemKdsUpdated = action.itemKdsUpdated;
    const settings = JSON.parse(localStorage.getItem('_saved_settings_kds_'))

    let currentBoardKdsCopy = Object.assign([], currentBoardKds)

    let itemsKds = convertBoardKdsFromItemKds(currentBoardKdsCopy)

    const indexItem = itemsKds.findIndex(itemKds => itemKds.item.codigoReferencial === itemKdsUpdated.item.codigoReferencial)

    if (indexItem < 0) {
        itemsKds.push(itemKdsUpdated)
    } else {
        itemsKds[indexItem] = itemKdsUpdated   
    }

    const boardKds = convertItemKdsFromBoardKds(itemsKds, settings)

    return {
        ...currentState,
        boardKds: boardKds
    }
}

export const updateItemsKds = (currentState, action) => {
    const currentBoardKds = currentState.boardKds
    const itemsKdsUpdated = action.itemsKdsUpdated;
    const settings = JSON.parse(localStorage.getItem('_saved_settings_kds_'))

    let currentBoardKdsCopy = Object.assign([], currentBoardKds)

    let itemsKds = convertBoardKdsFromItemKds(currentBoardKdsCopy)

    itemsKdsUpdated.forEach(item => {

        let indexItem = itemsKds.findIndex(itemKds => itemKds.item.codigoReferencial === item.item.codigoReferencial)

        if (indexItem < 0) {
            itemsKds.push(item)
        } else {
            itemsKds[indexItem] = item   
        }

    })

    const boardKds = convertItemKdsFromBoardKds(itemsKds, settings)

    return {
        ...currentState,
        boardKds: boardKds
    }
}

export const createItemKds = (currentState, action) => {
    
    const currentBoardKds = currentState.boardKds
    const itemsKdsCreated = action.itemsKdsCreated;
    const settings = JSON.parse(localStorage.getItem('_saved_settings_kds_'))

    let currentBoardKdsCopy = Object.assign([], currentBoardKds)

    let itemsKds = convertBoardKdsFromItemKds(currentBoardKdsCopy)
    itemsKds = itemsKds.concat(itemsKdsCreated)
    
    const boardKds = convertItemKdsFromBoardKds(itemsKds, settings)

    return {
        ...currentState,
        boardKds: boardKds
    }
}