import React, { useRef } from 'react';
import { Button, Grid, TextField, Dialog, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import AuthService from '../../../service/AuthService';
import AutocompleteService from '../../layout-components/form-components/AutocompleteService';
import { usePdvContext } from '../../../component/generics/PdvProvider';
import { DialogTitle, DialogActions } from '../../layout-components/CustomDialog/dialogComponents';
import { useDispatch } from 'react-redux';
import { dateFormat } from '../../../util/formatter';

function AddressForm({ address, setAddress, handleChange }) {
    const numeroRef = useRef(null)
    const service = new AuthService()

    const handleLoadingMunicipios = (value) => {
        if (value === '') {
            return []
        }
        return service.get(`/api-v2/municipio/list?q=${value}`)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <TextField label="Endereço"
                    name="streetName"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={address.streetName || ''}
                    onChange={handleChange}
                    required />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField label="Núm."
                    name="streetNumber"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={address.streetNumber || ''}
                    inputRef={numeroRef}
                    onChange={handleChange}
                    required />
            </Grid>
            <Grid item pt={1} xs={12} sm={4}>
                <TextField label="Bairro"
                    name="neighborhood"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={address.neighborhood || ''}
                    onChange={handleChange}
                    required />
            </Grid>
            <Grid item pt={1} xs={12} sm={6}>
                <TextField label="Complemento"
                    name="reference"
                    variant="outlined"
                    size="small"
                    fullWidth
                    alue={address.reference || ''}
                    onChange={handleChange} />
            </Grid>
            <Grid item pt={1} xs={12} sm={6}>
                <AutocompleteService
                    renderinputprops={{
                        variant: "outlined",
                        required: true
                    }}
                    value={{ nome: address?.city || '' }}
                    label="Município"
                    size="small"
                    onSelect={element => setAddress({ ...address, city: element?.nome || '' })}
                    loadOptions={handleLoadingMunicipios}
                    getOptionLabel={(option) => option?.nome || ''}
                />
            </Grid>
        </Grid>
    )
}

export default function DialogAddress({ pedido, setPedido, open, setOpen, setSelectedPedido, onUpdate }) {
    const { confirmDialog } = usePdvContext()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const service = new AuthService()
    const profile = service.getProfile()
    const data = new Date()

    const handleChange = (event) => {
        const { name, value } = event.target
        setPedido(prevPedido => ({
            ...prevPedido, iFoodOrder: {
                ...prevPedido.iFoodOrder, deliveryAddress: { ...prevPedido.iFoodOrder?.deliveryAddress, [name]: value, },
            },
        }))
    }

    const handleChangeCity = (municipio) => {
        if (!municipio?.nome) return
        municipio.nome = municipio.nome.replaceAll('\'', '')
        setPedido(prevPedido => ({
            ...prevPedido, iFoodOrder: {
                ...prevPedido.iFoodOrder, deliveryAddress: { ...prevPedido.iFoodOrder?.deliveryAddress, municipio },
            },
        }))
    }

    const handleClickCancel = () => {
        confirmDialog({
            confirmLabel: 'Ok',
            showCancelButton: true,
            content: 'Deseja realmente cancelar a ação? A rota não será criada.',
        })
            .then(() => {
                setOpen(false)

            }).catch(() => { })

        return
    }

    const validateForm = () => {
        const { streetName, streetNumber, neighborhood, city } = pedido.iFoodOrder?.deliveryAddress || {}
        if (!streetName || !streetNumber || !neighborhood || !city) {
            enqueueSnackbar('Por favor, preencha todos os campos obrigatórios para que a rota seja criada.', { variant: 'error' })
            return false
        }
        return true
    }

    const handleSubmit = () => {
        if (!validateForm()) return

        const orderCopy = {
            ...pedido,
            iFoodOrder: {
                ...pedido.iFoodOrder,
                deliveryAddress: { ...pedido.iFoodOrder?.deliveryAddress }
            }
        }

        orderCopy.logs = !!orderCopy.logs ? orderCopy.logs + " \n" : ""
        orderCopy.logs = orderCopy.logs + "Endereço de entrega atualizado pelo usuário: " + profile.nome + ` em ${dateFormat(data)}`

        service.post('/api-v2/pedidoIntegracao', orderCopy)
            .then(savedOrder => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder } })
                enqueueSnackbar('Endereço atualizado com sucesso.', { variant: 'success' })
                setOpen(false)
                onUpdate()
            })
            .catch(error => {
                enqueueSnackbar('Erro ao atualizar o endereço. Tente novamente.', { variant: 'error' })
                console.error(' Erro ao atualizar o endereço', error)
            })
    }

    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle onClose={handleClickCancel}>
                Endereço de Entrega
            </DialogTitle>
            <Box p={2}>
                <Grid item xs={12} md={12}>
                    <AddressForm
                        handleChangeCity={handleChangeCity}
                        address={pedido.iFoodOrder?.deliveryAddress || {}}
                        setAddress={newAddress => setSelectedPedido(prev => {
                            return ({
                                ...prev,
                                iFoodOrder: {
                                    ...prev.iFoodOrder,
                                    deliveryAddress: newAddress,
                                },
                            })
                        })}
                        handleChange={handleChange}
                    />
                </Grid>
            </Box>

            <DialogActions>
                <Button onClick={handleSubmit} variant="contained" color="primary">Salvar</Button>
            </DialogActions>

        </Dialog>
    )
}