import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react'
import { dateFormat, currencyFormat, integrationFormat, quantityFormat } from '../../../util/formatter';
import { printOrder } from '../../../../src/util/order/orderUtil'
import { DialogTitle, DialogContent } from '../../layout-components/CustomDialog/dialogComponents'
import { useSnackbar } from 'notistack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { HeaderDetailOrder } from '../../layout-components/DetailOrder';
import TotalOrder from '../../layout-components/NewOrder/pages/TotalOrder';
import { Observations } from '../../layout-components/DetailOrder/detailOrderElements';
import PrintIcon from '@mui/icons-material/Print';
import { formatFullAddressDelivery } from '../../../util/customer/helper';
import { useConnectionStatus } from '../../../util/http/HttpUtil';
import { Alert } from '@mui/material';

const PREFIX = 'DetalhePedido'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    rootTableRow: `${PREFIX}-rootTableRow`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.rootTableRow}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
    }
}))

export default function DetalhePedido({ open, setOpen, order, showBtnPrint = true }) {
    const { enqueueSnackbar } = useSnackbar();
    const [openSubItem, setOpenSubItem] = React.useState(false);

    const addresDelivery = formatFullAddressDelivery(order?.iFoodOrder?.deliveryAddress)
    const isConnected = useConnectionStatus()

    const enviarParaImpressao = () => {
        order.origemImpressao = "USUARIO";
        printOrder(order, enqueueSnackbar)
    }

    return order?.iFoodOrder ? (
        <StyledDialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>

            <DialogTitle onClose={() => setOpen(false)}>
                {order && <HeaderDetailOrder order={order} />}
            </DialogTitle>

            <DialogContent className={classes.scrollBar}>
                {!isConnected && (
                    <Alert severity="error">ATENÇÃO - Você está offline. Este pedido está em modo 'apenas visualização'</Alert>
                )}
                <Table aria-label="Dados do pedido" size="small">

                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={2}>{integrationFormat(order.tipoImportacao)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={2}>{order.iFoodOrder?.deliveryMethod?.mode}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {order.iFoodOrder.customer ? (
                            <Fragment>
                                <TableRow>
                                    <TableCell component="th" scope="row">Cliente</TableCell>
                                    <TableCell align="right"><strong >{order.iFoodOrder.customer.name}</strong></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row">Telefone</TableCell>
                                    <TableCell align="right">{order.iFoodOrder.customer.phone}</TableCell>
                                </TableRow>
                            </Fragment>
                        ) : null}

                        <TableRow>
                            <TableCell component="th" scope="row">Data de lançamento</TableCell>
                            <TableCell align="right"><strong > {dateFormat(order.createdAt)}</strong></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">Data do pedido</TableCell>
                            <TableCell align="right"><strong > {dateFormat(order.dataPedido)}</strong></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">Observação</TableCell>
                            <TableCell align="right"><strong >{order.iFoodOrder.observations}</strong></TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

                {order.iFoodOrder.customer && order.iFoodOrder?.deliveryMethod?.mode === "DELIVERY" ? (

                    <Table aria-label="Dados do pedido" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={2}><strong>Endereço para entrega</strong></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{`${addresDelivery?.address} 
                                                            ${addresDelivery?.complement ? '| ' + addresDelivery?.complement : ''}`}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                ) : null}

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={6}><strong >Itens do pedido</strong></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"><strong>Produto</strong></TableCell>
                            <TableCell component="th" scope="row"><strong>Qtde.</strong></TableCell>
                            <TableCell component="th" scope="row"><strong>Unitário</strong></TableCell>
                            <TableCell component="th" scope="row"><strong>Valor</strong></TableCell>
                            {/* <TableCell component="th" scope="row"><strong>Observação</strong></TableCell> */}
                        </TableRow>

                        {order.iFoodOrder?.items?.map((item, index) => (
                            <Fragment key={index}>
                                <TableRow >
                                    <TableCell style={{ padding: "2px" }}>
                                        <IconButton aria-label="Subitens"
                                            disabled={!item.subItems || item.subItems.length === 0}
                                            size="small" onClick={() => setOpenSubItem(!openSubItem)}>
                                            {openSubItem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ padding: "5px" }}>
                                        <Tooltip title={`ID externo (cód PDV): ${item.externalCode ? item.externalCode : ' - não informado -'}`} placement="top-start" arrow>
                                            <Typography>
                                                {item.name}
                                            </Typography>
                                        </Tooltip>

                                        {item.observations &&
                                            <ListItemText
                                                secondary={'* ' + item.observations} />
                                        }
                                    </TableCell>
                                    <TableCell align="right">{quantityFormat(item.quantity)}</TableCell>
                                    <TableCell align="right">{currencyFormat(item.price, { simbol: '' })}</TableCell>
                                    <TableCell align="right">{currencyFormat(item.totalPrice, { simbol: '' })}</TableCell>
                                    {/* <TableCell align="left" style={{ maxWidth: '250px' }}>{item.observations}</TableCell> */}
                                </TableRow>

                                <TableRow >
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                        <Collapse in={openSubItem} timeout="auto" unmountOnExit>
                                            <Box mb={1} display="flex" justifyContent="flex-end">

                                                <Table size="small" aria-label="Subitens">
                                                    <TableBody>
                                                        {(item.subItems || []).map((subitem, index) => (

                                                            <TableRow key={index} className={classes.rootTableRow}>
                                                                <TableCell component="th" scope="row">
                                                                    {subitem.name}

                                                                    {subitem.observations &&
                                                                        <ListItemText
                                                                            secondary={'* ' + subitem.observations} />
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="right">{quantityFormat(subitem.quantity)}</TableCell>
                                                                <TableCell align="right">{currencyFormat(subitem.totalPrice, { simbol: '' })}</TableCell>
                                                            </TableRow>

                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </Box>
                                        </Collapse>

                                    </TableCell>
                                </TableRow>

                            </Fragment>
                        ))}

                    </TableBody>

                </Table>

                <List dense subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Pagamento
                    </ListSubheader>
                }>
                    {order.iFoodOrder?.payments?.map((pagamento, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={pagamento.code + " - " + currencyFormat(pagamento.value)}
                            />
                        </ListItem>
                    ))}
                </List>

                <Observations order={order} />
            </DialogContent>
            <DialogActions>

                <Grid
                    container
                    spacing={1}>

                    <Grid item xs={12}>
                        {order && <TotalOrder order={order} showPendingAmount={false} />}
                    </Grid>

                    <Grid item xs={12} >
                        <Typography color="textPrimary" variant="subtitle2" display="block" >
                            <strong>TROCO PARA {currencyFormat(order.iFoodOrder.levarTrocoPara)}</strong>
                        </Typography>
                    </Grid>

                    {showBtnPrint && (
                        <Grid item xs={12}>
                            <Box justifyContent="center" display="flex">
                                <Button startIcon={<PrintIcon />} variant="contained" color="primary" onClick={enviarParaImpressao}>
                                    Imprimir Pedido
                                </Button>
                            </Box>
                        </Grid>
                    )}

                </Grid>

            </DialogActions>

        </StyledDialog>
    ) : null
}
