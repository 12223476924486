import React, { useEffect } from 'react';
import AuthService from '../../service/AuthService';
import { Box, Grid, Button, Typography, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { getParamByKey } from '../../component/parameter';
import { useSelector } from 'react-redux';

export default function SequencePedidoIntegracaoSettings() {
    const [loading, setLoading] = React.useState(false)
    const [sequenceValue, setSequenceValue] = React.useState('')
    const { enqueueSnackbar } = useSnackbar();
    const generalParameters = useSelector(state => state.generalParameters)
    const TIPO_SEQUENCIAL_IDENTIFICADOR = getParamByKey(generalParameters, 'TIPO_SEQUENCIAL_IDENTIFICADOR')?.valorString !== 'S'

    useEffect(() => {
        let service = new AuthService()
        setLoading(true)
        service.get("/api-v2/pedidoIntegracao/lastPedidoIntegracaoSequence")
            .then(ret => {
                setSequenceValue(ret.sequenceValue)
                setLoading(false)
            }).catch(err => {
                console.error(err)
                setLoading(false)
            })
    }, [])

    const handleChangeSequence = () => {

        let service = new AuthService()
        setLoading(true)
        service.post(`/api-v2/pedidoIntegracao/updatePedidoIntegracaoSequence/${sequenceValue}`)
            .then(ret => {
                setLoading(false)
                setSequenceValue(ret.sequenceValue)
                enqueueSnackbar("Sequencial alterado com sucesso", { variant: 'success', });
            }).catch(err => {
                console.error(err)
                setLoading(false)
                enqueueSnackbar("Não foi possível alterar sequencial", { variant: 'error', });
            })

    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} sm={6}  >
                <Typography variant="subtitle1">
                    Alterar número sequencial dos pedidos
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-end">
                    <TextField variant="outlined" value={sequenceValue}
                        onChange={event => setSequenceValue(event.target.value)}
                        label={loading ? "Buscando sequence" : "Sequêncial atual"} disabled={loading || TIPO_SEQUENCIAL_IDENTIFICADOR}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Button color="primary" disabled={loading || TIPO_SEQUENCIAL_IDENTIFICADOR} onClick={handleChangeSequence}>Alterar</Button></InputAdornment>,
                        }} />
                </Box>
            </Grid>
        </Grid>
    );
}