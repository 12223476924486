import React, { useEffect } from 'react';
import { Grid, Hidden } from '@mui/material';
import { useSelector } from 'react-redux'
import InfoCard from '../../../layout-components/InfoCard';
import { convertBoardKdsFromItemKds, SITUACOES_KDS } from '../../utils';

export default function KdsTopBar() {
    const boardKds = useSelector(state => state.boardKds)
    const [stateValues, setStateValues] = React.useState({})
    const SAVED_SETTINGS_KDS = "_saved_settings_kds_"
    const savedFilters = JSON.parse(localStorage.getItem(SAVED_SETTINGS_KDS))

    useEffect(() => {

        let qtdeItens = []
        
        if (boardKds) {

            const itensKDS = convertBoardKdsFromItemKds(boardKds);

            SITUACOES_KDS.map(situacaoKDS => {

                if (savedFilters) {
                    const situacaoSaved = savedFilters.exibir?.filter((e) => e.key === situacaoKDS.key)
    
                    if (situacaoSaved.length > 0) {
                        let check = situacaoSaved[0].checked
                        if (check){
                            let qtde = itensKDS.filter( elem => elem.item.status === situacaoKDS.key)
                            let value = qtde.length;
                            qtdeItens.push( { situacao: situacaoKDS, value } ) ;
                        }
                    }
                }
                return null;
            })
            
        }

        setStateValues({
            ...stateValues,
            qtdeItens,
        })
        // eslint-disable-next-line
    }, [boardKds])

    return (
        <Hidden mdDown>
            <Grid container spacing={2}>

                {
                    stateValues?.qtdeItens?.map((situacaoKDS) => { 

                        let situacaoObj = situacaoKDS.situacao;
                        const value = situacaoKDS.value;
                       
                        return (
                            <Grid item key={situacaoObj.name}>
                                <InfoCard 
                                    caption={ situacaoObj.name } 
                                    value={ value } 
                                    valueColor={situacaoKDS.situacao.style.backgroundColor} />
                            </Grid>
                        )

                    })
                }

            </Grid>
        </Hidden>
    );
}
