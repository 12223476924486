export const toogleDiscountItemDialog = (currentState, action) =>  {
    let { discountItemDialog } = action

    return {
        ...currentState, 
        discountItemDialog: { ...discountItemDialog, open: discountItemDialog.open} 
    }
}

export const initialDiscountItemDialog = () =>  {

    return {
        open: false,
    }
}