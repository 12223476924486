import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Button, Chip, Typography } from '@mui/material';
import clsx from 'clsx';
import { CardBoard } from './CardBoard';

const PREFIX = 'ListBoard'

const classes = {
    listTitle: `${PREFIX}-listTitle`,
    listContent: `${PREFIX}-listContent`,
    listContentBox: `${PREFIX}-listContentBox`,
    isOver: `${PREFIX}-isOver`,
    scrollBar: `${PREFIX}-scrollBar`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.listContent}`]: {
        backgroundColor: theme.palette.personal.boardList,
        maxWidth: 360,
        minWidth: 220,
    },

    [`&.${classes.isOver}`]: {
        backgroundColor: theme.palette.personal.hoverList,
    },

    [`& .${classes.listTitle}`]: {
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.text.primary,
        fontWeight: '600'
    },

    [`& .${classes.listContentBox}`]: {
        heigth: "30hw",
    },

}))

export function ListBoard({ list, listIndex, display }) {
    const cleanedListCard = list.cards.filter(elem => elem !== undefined)
    const foundedCounter = cleanedListCard.filter(elem => elem.founded === 'FOUNDED').length
    const [paginate, setPaginate] = useState(50)
    const showMoreSizePaginate = 20

    return (
        <StyledBox
            p={1}
            flexGrow={1}
            display={display || "initial"}
            sx={{ overflow: 'hidden' }}
            className={clsx(classes.listContent)}>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="subtitle1"
                        component="h3" className={classes.listTitle}>
                        {list.title}
                    </Typography>
                </Box>

                {cleanedListCard.length > 0 ? (
                    <Chip label={`${foundedCounter > 0 ? foundedCounter + ' de ' : ''}${cleanedListCard.length}`} variant="outlined" size="small" />
                ) : null}
            </Box>
            <Droppable droppableId={`droppable-${listIndex}`}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        style={{
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            height: 'calc(100vh - 201px)',
                            borderRadius: '4px',
                            minHeight: '605px',
                        }}
                        {...provided.droppableProps}>
                        {cleanedListCard.map((item, index) => (
                            <Draggable
                                key={item.data.id}
                                index={index}
                                draggableId={`draggable-${item.data.id}`}>
                                {(providedCard, snapshotCard) => (
                                    <CardBoard
                                        card={item}
                                        founded={item.founded}
                                        provided={providedCard}
                                        isDragging={snapshotCard.isDragging} />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {cleanedListCard.length > paginate ? (
                            <Box textAlign="center" justifyContent="center" pt={3}>
                                <div>{`${cleanedListCard.length > paginate ? paginate : cleanedListCard.length} de ${cleanedListCard.length}`}</div>
                                <Button fullWidth size="small" onClick={() => setPaginate(paginate + showMoreSizePaginate)}>
                                    Mostrar mais
                                </Button>
                            </Box>
                        ) : null}
                    </div>
                )}
            </Droppable>
        </StyledBox>
    )
}