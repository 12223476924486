import React, { useState, useMemo, useEffect, useRef } from 'react'
import { enqueueSnackbar, useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, Badge, Box, Button, Card, CardHeader, Checkbox, Chip, Dialog, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, TextField, Tooltip, Typography, styled } from "@mui/material"
import { LoadingButton } from '@mui/lab'
import DoneIcon from '@mui/icons-material/Done'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import { DialogActions, DialogContent, DialogTitle } from "./CustomDialog/dialogComponents"
import { currencyFormat, dateFormat, normalizeText } from '../../util/formatter'
import { totalOrder } from '../../util/order/orderUtil'
import { SITUACOES, changeSituacaoMultiplosPedidosOnServer, useHasAguardandoRetirada } from '../../util/board/BoardUtil'
import { extractErrorMessage } from '../../util/http/HttpUtil'
import IntegrationAvatar from './Board/IntegrationAvatar'
import { usePdvContext } from '../../component/generics/PdvProvider'
import { getGenericCodeByKey } from '../../component/genericCode'

const DISABLED_SITUATIONS = ['ENTREGUE', 'CANCELADO', 'PENDENTE']

const StyledList = styled(List)(({ theme }) => ({
    '&': theme.scrollBar,
}))

function TransferListItem({ item, isLeft = false, errors = [], checked = false, onToggle, onRemoveItem, selectedCards }) {
    const { id, dataPedido, sequencial, iFoodOrder } = item.data
    const labelId = `transfer-list-item-${id}-label`
    const error = errors.find(item => Number(item.id) === id)

    const COD_GENERICO_LIMITE_DESPACHO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'LIMITE_DESPACHO')
    const LIMITE_DESPACHO = COD_GENERICO_LIMITE_DESPACHO?.length > 0 ? COD_GENERICO_LIMITE_DESPACHO[0].codigo : 20

    return (
        <ListItem
            disableGutters
            role="listitem"
            secondaryAction={
                <Box mr={1}>
                    {(error && isLeft) ? (
                        <Tooltip disableInteractive title={error.err}>
                            <Badge badgeContent="i" color="primary">
                                <IntegrationAvatar pedido={item.data} />
                            </Badge>
                        </Tooltip>
                    ) : <IntegrationAvatar pedido={item.data} />}
                </Box>
            }>

            {isLeft
                ? <ListItemIcon>
                    <Checkbox
                        tabIndex={-1}
                        checked={checked}
                        onChange={() => onToggle(item)}
                        disabled={selectedCards.length >= LIMITE_DESPACHO && !checked}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }} />
                </ListItemIcon>
                : <ListItemIcon>
                    <IconButton onClick={() => onRemoveItem(item)}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemIcon>
            }

            <ListItemText
                id={labelId}
                primary={<Box component="span">
                    <Chip label={sequencial} variant="outlined" size="small" sx={{ mr: 1 }} />
                    {iFoodOrder?.customer?.name}
                </Box>}
                secondary={`#${id} - ${dateFormat(dataPedido)} - ${currencyFormat(totalOrder(iFoodOrder))}`} />
        </ListItem>
    )
}

function TransferList({ left, right, situation, nextSituation, errors, onSendToRight, onChangeSituation, onRemoveItem }) {
    const [selectedCards, setSelectedCards] = useState([])
    const [filteredValue, setFilteredValue] = useState('')
    const [selectAll, setSelectAll] = useState(false);
    const hasAguardandoRetirada = useHasAguardandoRetirada()
    const [isCheckVisible, setIsCheckVisible] = useState(false)
    const [visibleLabel, setVisibleLabel] = useState(false)
    // se os itens selecionados não são todos e também não são nenhum, então está em estado indeterminado
    const indeterminate = selectedCards.length > 0 && selectedCards.length < left.length

    const COD_GENERICO_LIMITE_DESPACHO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'LIMITE_DESPACHO')
    const LIMITE_DESPACHO = COD_GENERICO_LIMITE_DESPACHO?.length > 0 ? COD_GENERICO_LIMITE_DESPACHO[0].codigo : 20

    useEffect(() => {
        setIsCheckVisible(left.length > 1);
    }, [left])

    const validSituacoes = SITUACOES.filter(item => {
        if (item.key === 'AGUARDANDO_RETIRADA') {
            return hasAguardandoRetirada
        }

        return !DISABLED_SITUATIONS.includes(item.key)
    })

    const filteredLeft = useMemo(() => !filteredValue ? left : left.filter(item => {
        const { sequencial, iFoodOrder } = item.data
        if (!iFoodOrder) {
            console.log('ITEM_IFOOD_ORDER_VAZIO', item)
            return false
        }

        const upperName = normalizeText(iFoodOrder.customer.name.toUpperCase())

        return `${sequencial}`.indexOf(filteredValue) > -1 || upperName.indexOf(filteredValue) > -1
    }), [filteredValue, left])


    function handleToggle(card) {
        setSelectedCards(previousValue => {
            const { id } = card.data

            if (previousValue.some(item => item.data.id === id)) {
                return [...previousValue.filter(item => item.data.id !== id)]
            }

            if (previousValue.length >= LIMITE_DESPACHO) {
                enqueueSnackbar(`Limite de ${LIMITE_DESPACHO} pedidos atingido.`, { variant: 'error' })
                return previousValue
            }

            return [...previousValue, card]
        })
        setSelectAll(true)
    }

    function sendToRight() {
        // Verificar se não ultrapassou o limite ao transferir
        if (right.length + selectedCards.length > LIMITE_DESPACHO) {
            const message = `Não é possível transferir. Limite máximo de transferência é de ${LIMITE_DESPACHO} pedidos.`
            enqueueSnackbar(message, { variant: 'error' })
            return
        }

        onSendToRight(selectedCards)
        setSelectedCards([])
        setFilteredValue('')
    }


    function onChangeSearch(event) {
        const { value = '' } = event.target
        const upperValue = normalizeText(value.toUpperCase())

        setFilteredValue(upperValue)
    }

    function handleChangeSituation(event) {
        const { value } = event.target

        onChangeSituation(value)
        setSelectedCards([])
        setFilteredValue('')
    }

    function handleSelectAll() {
        setVisibleLabel(!visibleLabel);
        setSelectAll(!selectAll);

        let newSelectedCards = [];

        if (!selectAll) {
            // Selecionar os primeiros X itens até o limite
            newSelectedCards = filteredLeft.slice(0, LIMITE_DESPACHO);

            setSelectedCards(newSelectedCards);

            // Verificar quantos itens foram selecionados
            const selectedCount = newSelectedCards.length;

            // Exibir mensagem de acordo com a quantidade selecionada
            if (selectedCount === LIMITE_DESPACHO) {
                enqueueSnackbar(`Adicionado limite de ${LIMITE_DESPACHO} itens.`, { variant: 'info' });
            } else {
                enqueueSnackbar(`Foram selecionados ${selectedCount} pedidos.`, { variant: 'info' });
            }
        } else {
            setSelectedCards([]);
        }
    }


    return (
        <Box display="grid" gridTemplateColumns="1fr 120px 1fr" alignItems="stretch">
            <Card elevation={0}>
                <CardHeader
                    sx={{ py: 1, px: 0 }}
                    title={
                        <>
                            <FormControl fullWidth>
                                <InputLabel>Origem</InputLabel>
                                <Select
                                    label="Origem"
                                    value={situation}
                                    onChange={handleChangeSituation}>
                                    {validSituacoes.map(item => <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                size="small"
                                label="Pedidos"
                                variant="standard"
                                value={filteredValue}
                                onChange={onChangeSearch}
                                placeholder="Busca pelo sequencial ou nome"
                                sx={{ mt: 1, }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true
                                }} />
                        </>

                    } />
                <Divider />

                {isCheckVisible && <FormControlLabel
                    control={<Checkbox tabIndex={-1}
                        checked={selectedCards.length === left.length}
                        onChange={handleSelectAll}
                        indeterminate={indeterminate} />}
                    label="Selecionar todos" />}

                <StyledList
                    dense
                    role="list"
                    sx={{ overflow: 'auto', maxHeight: 300, }}
                    subheader={
                        <Typography color="secondary" variant="overline">
                            {selectedCards.length} selecionado(s) de {left.length}
                        </Typography>
                    }>
                    {filteredValue && !filteredLeft.length
                        ? <ListItem role="listitem">
                            <ListItemText primary="Nenhum pedido encontrado" />
                        </ListItem>
                        : filteredLeft.map(item =>
                            <TransferListItem
                                isLeft
                                item={item}
                                checked={selectedCards.some(card => card.data.id === item.data.id)}
                                errors={errors}
                                key={item.data.id}
                                onToggle={handleToggle}
                                disabled={selectedCards.length >= LIMITE_DESPACHO && !selectedCards.some(card => card.data.id === item.data.id)}
                                selectedCards={selectedCards} />)
                    }
                </StyledList>
            </Card>
            <Grid container alignItems="center" justifyContent="center">
                <Button
                    sx={{ my: 0.5 }}
                    size="small"
                    variant="outlined"
                    onClick={sendToRight}
                    disabled={!filteredLeft.length || !selectedCards.length}>
                    ≫
                </Button>
            </Grid>

            <Card elevation={0}>
                <CardHeader
                    sx={{ py: 1, px: 0, mt: 7.25, }}
                    title={
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label={nextSituation.title}
                            variant="standard"
                            InputProps={{ disableUnderline: true }} />
                    } />
                <Divider />
                <StyledList
                    dense
                    role="list"
                    sx={{ overflow: 'auto', maxHeight: 300, }}
                    subheader={
                        <Typography color="secondary" variant="overline">
                            {right.length} item(s)
                        </Typography>
                    }>
                    {right.map(item => <TransferListItem key={item.data.id} item={item} onRemoveItem={onRemoveItem} />)}
                </StyledList>
            </Card>

        </Box>
    )
}

function SendOrdersDialogContent({ onFinish }) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { confirmDialog } = usePdvContext()

    const deliveryManInputRef = useRef()
    const boardData = useSelector(state => state.boardState.board)
    const listDeliveryMan = useSelector(state => state.globalCache.listDeliveryMan) || []
    const [selectedDeliveryMan, setSelectedDeliveryMan] = useState()
    const activeDeliveryMan = listDeliveryMan.filter(item => item.ativo)
    const [selectedSituation, setSelectedSituation] = useState('CONFIRMADO')
    const hasAguardandoRetirada = useHasAguardandoRetirada()

    const [left, setLeft] = useState([])
    const [right, setRight] = useState([])
    const [errors, setErrors] = useState([])
    const [sending, setSending] = useState(false)

    // Essa função faz com que não duplique os pedidos quando eles vem do pooling
    function uniqueCards(cards) {
        const rightIds = right.map(item => item.data.id)

        return cards.filter(item => !rightIds.includes(item.data.id))
    }

    // Pega os pedidos apenas da situação selecionada e do tipo DELIVERY
    const cards = useMemo(() => boardData.lists.reduce((acc, item) => {
        if (item.id !== selectedSituation)
            return acc

        const cardList = uniqueCards(item.cards).filter(card => !card.skeleton && card.data.tipo === 'DELIVERY')

        return [...acc, ...cardList]
    }, []), [boardData, selectedSituation])

    // Pega a próxima situação já verificando o parametro de aguardando retirada e tudo mais
    const nextSituation = useMemo(() => boardData.lists.reduce((acc, item) => {
        if (item.id !== selectedSituation) {
            return acc
        }

        let newSituation = boardData.lists.find(board => board.order === (item.order + 1)) || {}
        if (newSituation.id === 'AGUARDANDO_RETIRADA' && !hasAguardandoRetirada) {
            newSituation = boardData.lists.find(board => board.order === (newSituation.order + 1)) || {}
        }

        return newSituation
    }, {}), [boardData, selectedSituation])

    useEffect(() => setLeft(cards), [cards])

    function handleChangeSituation(value) {
        setLeft(cards)
        setRight([])
        setSelectedDeliveryMan(null)
        setSelectedSituation(value)
    }

    function handleRemoveItem(itemToRemove) {
        setRight(previousValue => [...previousValue.filter(item => item.data.id !== itemToRemove.data.id)])
        setLeft(previousValue => [...previousValue, itemToRemove])
    }

    function sendToRight(selectedCards) {
        setRight(previousValue => [...previousValue, ...selectedCards])

        setLeft(previousValue => {
            const list = previousValue.filter(item => {
                const ids = selectedCards.map(card => card.data.id)

                return !ids.includes(item.data.id)
            })

            return [...list]
        })
    }

    function changeSituacaoPedidos() {
        setSending(true)

        let isFinished = true
        const ids = right.map(item => item.data.id)

        changeSituacaoMultiplosPedidosOnServer(ids, nextSituation.id, selectedDeliveryMan?.id || '').then(response => {
            response.forEach(item => {
                if (item.err) {
                    const errOrder = right.find(order => order.data.id === Number(item.id))

                    setLeft(previousValue => [...previousValue, errOrder])
                    setErrors(previousValue => [...previousValue, item])

                    isFinished = false
                    return
                }

                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: item })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: item } })
            })
        }).catch(err => {
            console.error(err)

            extractErrorMessage(err, 'Falha ao alterar as situações dos pedidos selecionados')
                .then(msg => enqueueSnackbar(msg, { variant: 'error' }))
        }).finally(() => {
            setRight([])
            setSending(false)

            if (isFinished) {
                enqueueSnackbar('Situações dos pedidos selecionados alteradas com sucesso', { variant: 'success' })
                onFinish()
            }
        })
    }

    function onClickFinalizar() {
        if (!right.length) {
            enqueueSnackbar(`Não há nenhum item na lista de "${nextSituation.title}", insira um pedido para realizar essa operação`, { variant: 'error' })
            return
        }

        if (nextSituation.id === 'ENVIADO' && !selectedDeliveryMan) {
            confirmDialog({
                confirmLabel: 'Sim',
                cancelLabel: 'Selecionar entregador',
                content: 'Deseja enviar os pedidos sem informar o entregador?',
            })
                .then(() => changeSituacaoPedidos())
                .catch(() => deliveryManInputRef.current.focus())
            return
        }

        changeSituacaoPedidos()
    }

    return <>
        <DialogContent>

            <TransferList
                left={left}
                right={right}
                errors={errors}
                situation={selectedSituation}
                nextSituation={nextSituation}
                onSendToRight={sendToRight}
                onChangeSituation={handleChangeSituation}
                onRemoveItem={handleRemoveItem} />

            {nextSituation.id === 'ENVIADO' &&
                <Box pt={1} pb={2}>
                    <Autocomplete
                        openOnFocus
                        options={activeDeliveryMan}
                        onChange={(event, value) => setSelectedDeliveryMan(value)}
                        getOptionLabel={(option) => option ? `${option.nome}` : ''}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        renderOption={(props, option) => <li {...props} key={option.id}>{option.nome}</li>}
                        renderInput={(params) => <TextField {...params} inputRef={deliveryManInputRef} label="Selecione o entregador" variant="outlined" />} />
                </Box>}

        </DialogContent>

        <DialogActions>
            <LoadingButton
                loading={sending}
                loadingPosition="end"
                color="primary"
                variant="contained"
                onClick={onClickFinalizar}
                endIcon={<DoneIcon />}>
                <span>
                    {sending ? 'Enviando' : 'Enviar'}
                </span>
            </LoadingButton>
        </DialogActions>
    </>
}

export function SendOrdersDialog() {
    const dispatch = useDispatch()
    const open = useSelector(state => state.dialogSendOrders.open)

    function close() {
        dispatch({ type: 'TOOGLE_SEND_ORDERS_DIALOG', dialogSendOrders: { open: false } })
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="md"
            onClose={close}>

            <DialogTitle onClose={close}>
                Alterar status múltiplos pedidos
            </DialogTitle>

            <SendOrdersDialogContent onFinish={close} />

        </Dialog>
    )
}