import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PulseBadge from './elements/PulseBadge'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Badge, IconButton, Tooltip } from '@mui/material'
import { Socket } from 'socket.io-client'
import { ifAnyGranted } from '../../util/auth/authUtil'

export function WhatsappStatus() {
    const dispatch = useDispatch()
    const hasRole = ifAnyGranted('ROLE_WHATSAPP_CONNECT')
    const websocket = useSelector(state => state.socketContext.websocket)
    const statusWhatsApp = useSelector(state => state.whatsapp.status)
    const [sended, setSended] = useState(false)

    useEffect(() => {
        if (!websocket || !hasRole) {
            return
        }

        if (websocket instanceof Socket && !sended) {
            websocket.on('connect', () => {
                setSended(true)
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'WHATSAPP_STATUS_REFRESH' } })
            })
        }
    }, [websocket])

    if (!hasRole) {
        return null
    }

    if (statusWhatsApp === 'connected') {
        return (
            <Tooltip title="WhatsApp conectado" disableInteractive>
                <IconButton size="large">
                    <Badge
                        variant="dot"
                        badgeContent=" "
                        overlap="circular"
                        sx={{
                            '& .MuiBadge-dot': {
                                backgroundColor: 'green'
                            }
                        }}>
                        <WhatsAppIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Tooltip title="WhatsApp desconectado" disableInteractive>
            <IconButton size="large">
                <PulseBadge
                    color="red"
                    variant="dot"
                    badgeContent=" "
                    overlap="circular">
                    <WhatsAppIcon />
                </PulseBadge>
            </IconButton>
        </Tooltip>
    )
}