import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Grid,
} from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import DoneIcon from '@mui/icons-material/Done';
import { currencyFormat, quantityFormat } from '../../../../util/formatter';
import {
    addAllTaxaServicoByItems, addTaxaServicoItem,
    removeAllTaxaServicoByItems, totalItemReal, totalTaxaServicoItem, getTotalItem, totalItems
} from '../../../../util/order/orderUtil';
import { roundUp } from "../../../../util/genericUtil";
import { getParamByKey } from '../../../../component/parameter';
import { useSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector, useDispatch } from 'react-redux';
import { lime } from '@mui/material/colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PREFIX = 'ServiceFee'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    subitem: `${PREFIX}-subitem`,
    paddingLeft: `${PREFIX}-paddingLeft`,
    deliveryFree: `${PREFIX}-deliveryFree`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.subitem}`]: {
        opacity: "0.8",
        fontSize: "0.9em"
    },

    [`& .${classes.paddingLeft}`]: {
        paddingLeft: '4px'
    },

    [`& .${classes.deliveryFree}`]: {
        '& label, & input, & .MuiIconButton-label, & .MuiInputAdornment-root p': {
            color: theme.palette.mode === 'dark' ? lime[700] : lime[700],
        },
        '& input': {
            textAlign: "right",
            MozAppearance: "textfield"
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            WebkitAppearance: "none",
            margin: 0,
        }
    }
}))

export default function ServiceFee({ open, setOpen, order }) {

    const btnConfirmRef = useRef();
    const [items, setItems] = useState([])
    const generalParameters = useSelector(state => state.generalParameters)
    const UTILIZA_TAXA_SERVICO = getParamByKey(generalParameters, 'UTILIZA_TAXA_SERVICO')?.valorString
    const PERCENTUAL_TAXA_SERVICO = getParamByKey(generalParameters, 'PERCENTUAL_TAXA_SERVICO')?.valor
    const { enqueueSnackbar } = useSnackbar();
    const [stateValues, setStateValues] = useState({})
    const [taxaServicoValue, setTaxaServicoValue] = useState(0);
    const [checkboxTaxaManual, setCheckboxTaxaManual] = useState(false);
    const [hasTaxaServicoChanged, setHasTaxaServicoChanged] = useState(false);
    const dispatch = useDispatch()

    useHotkeys('f10', (event) => {

        event.preventDefault()
        event.stopPropagation()

        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
            confirmServiceFee()
        }
    })

    function handleClose() {
        setOpen(false)
    }

    const confirmServiceFee = () => {
        let orderCopy = JSON.parse(JSON.stringify(order))
        
        orderCopy.iFoodOrder.items = items

        if (hasTaxaServicoChanged) {
            const valueTotalOrder = totalItems(orderCopy.iFoodOrder);

            orderCopy.iFoodOrder.items = orderCopy.iFoodOrder.items.map(item => {
                const valueTotalItem = getTotalItem(item);
                const newValorTaxa = taxaServicoValue * (valueTotalItem / valueTotalOrder);

                item.totalServico = newValorTaxa;

                return item;
            });
        }

        dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: orderCopy } })
        handleClose()
    }

    const onChangeCheckBoxAll = (event) => {
        if (event.target.checked) {
            onClickAddTaxaDeServico()
        } else {
            onClickRemoveTaxaDeServico()
        }
    }

    const onClickRemoveTaxaDeServico = () => {
        const itemsCopy = JSON.parse(JSON.stringify(items))

        removeAllTaxaServicoByItems(itemsCopy)

        itemsCopy.map((item) => {
            item.checked = false
            return null;
        })

        setItems(itemsCopy)

        enqueueSnackbar('A taxa de serviço foi removida dos itens.', { variant: 'success', })
    }

    const onClickAddTaxaDeServico = () => {
        const itemsCopy = JSON.parse(JSON.stringify(items))
        const COBRAR_TAXA_VALOR_BRUTO_PEDIDO = getParamByKey(generalParameters, 'COBRAR_TAXA_VALOR_BRUTO_PEDIDO')

        addAllTaxaServicoByItems(itemsCopy, stateValues.percentualTaxaServico, COBRAR_TAXA_VALOR_BRUTO_PEDIDO)

        itemsCopy.map((item) => {

            item.checked = true
            return null;
        })

        setItems(itemsCopy)
        setCheckboxTaxaManual(false)
        setTaxaServicoValue(totalTaxaServicoItem(itemsCopy))

        enqueueSnackbar('A taxa de serviço foi adicionado aos itens.', { variant: 'success', })
    }

    useEffect(() => {

        if (order && open === true) {
            setStateValues({
                ...stateValues,
                percentualTaxaServico: PERCENTUAL_TAXA_SERVICO,
                recalcularTaxaDeServico: false,
            });

            let orderCopy = JSON.parse(JSON.stringify(order))
            let itemsTemp = []

            orderCopy.iFoodOrder.items.map((item) => {
             
                if (!item.checked) {
                    if (item.chargeServiceFee) {

                        item.checked = item.chargeServiceFee

                    } else if (!item.chargeServiceFee) {

                        item.checked = false

                    } else if (item.productChargeServiceFee && item.productChargeServiceFee === true) {

                        item.checked = true

                    } else {

                        item.checked = true

                    }
                }


                itemsTemp.push(item)
                return null;
            })

            setItems(itemsTemp)
            setCheckboxTaxaManual(false)
            setTaxaServicoValue(totalTaxaServicoItem(itemsTemp))
        }
        // eslint-disable-next-line
    }, [order, open])

    const onChange = (event) => {

        setStateValues({ ...stateValues, [event.target.name]: event.target.value });
    }


    const handleChangeCheckItem = (event, item) => {
        let itemsCopy = Object.assign([], items)

        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)

        itemTemp.checked = event.target.checked
        itemTemp.chargeServiceFee = event.target.checked

        if (itemTemp.checked === true) {
            addTaxaServicoItem(itemTemp, stateValues.percentualTaxaServico)
        } else {
            itemTemp.totalServico = 0.0
        }

        setItems(itemsCopy)
    }

    const handleChangeCheckBox = (event) => {
        setStateValues({ ...stateValues, [event.target.name]: event.target.checked });
    }

    const handleCheckboxTaxaManual = (event) => {
        setTaxaServicoValue(roundUp(totalTaxaServicoItem(items), 2))

        setCheckboxTaxaManual(event.target.checked)
        setHasTaxaServicoChanged(event.target.checked)
    }

    const handleTaxaChange = (event) => {
        const value = Number(event.target.value)

        if (value < 0) return

        setTaxaServicoValue(value)
    }

    return (
        <StyledDialog
            open={open}
            maxWidth={"sm"}
            fullWidth
            onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Typography variant="caption" display="block" gutterBottom>
                    Taxa de serviço
                </Typography>
            </DialogTitle>

            <DialogContent>
                <React.Fragment>

                    <Box pb={2}>

                        <Grid container spacing={1}>
                            <Grid item sm={5} xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary"
                                                checked={stateValues.recalcularTaxaDeServico}
                                                onChange={handleChangeCheckBox}
                                                name="recalcularTaxaDeServico"
                                            />}
                                        label={`Recalcular tx. serviço ${UTILIZA_TAXA_SERVICO !== 'S' ? ' (desativado)' : ''}`}
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item sm={3} xs={12}>
                                <TextField
                                    label="% tx. serviço"
                                    variant='outlined'
                                    name='percentualTaxaServico'
                                    type='number'
                                    fullWidth
                                    size='small'
                                    disabled={!stateValues.recalcularTaxaDeServico}
                                    defaultValue={stateValues.percentualTaxaServico}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    onChange={(event) => onChange(event)}
                                />

                            </Grid>

                            <Grid item sm={4} xs={12}>

                                <Button disabled={!stateValues.recalcularTaxaDeServico}
                                    onClick={onClickAddTaxaDeServico}
                                    fullWidth
                                    endIcon={<ArrowForwardIcon />}>
                                    Recalcular
                                </Button>
                            </Grid>

                        </Grid>

                    </Box>

                    <Table size='small' stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="none" className={classes.paddingLeft} >Item</TableCell>
                                <TableCell align='right'>Qtde</TableCell>
                                <TableCell align='right'>Total</TableCell>
                                <TableCell align='center'>Tx. Serviço</TableCell>
                                <TableCell align='center'>
                                    <Checkbox
                                        color="primary"
                                        defaultChecked={true}
                                        onClick={(event) => onChangeCheckBoxAll(event)}
                                        name='checked' />
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {items?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>

                                        <TableCell component='th' scope='row' padding="none" className={classes.paddingLeft} >
                                            <Typography>{item.name}</Typography>
                                        </TableCell>

                                        <TableCell align='right'>{quantityFormat(item.quantity)}</TableCell>

                                        <TableCell align='right'>
                                            {`${currencyFormat(totalItemReal(item), { simbol: '' })}`}
                                        </TableCell>

                                        <TableCell align='center'>
                                            {currencyFormat(item.totalServico, { simbol: '' })}
                                        </TableCell>

                                        <TableCell align='center'>
                                            {item.productChargeServiceFee === false ?
                                                <Tooltip title="Item marcado ou configurado para não cobrar taxa de serviço.">
                                                    <IconButton size="large">
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                :

                                                <Checkbox
                                                    color="primary"
                                                    checked={item.checked}
                                                    onClick={(event) => handleChangeCheckItem(event, item)}
                                                    name='checked' />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}

                        </TableBody>
                    </Table>


                </React.Fragment >
            </DialogContent>

            <DialogActions disableSpacing>
                <Box p={1}>
                    <Grid container>
                        <Grid item sm={5}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={checkboxTaxaManual}
                                            onChange={handleCheckboxTaxaManual}/>}
                                    className={classes.deliveryFree}
                                    label="Editar taxa calculada"
                                    labelPlacement="end" />
                            </FormGroup>
                        </Grid>
                        <Grid item sm={3}>
                            {checkboxTaxaManual
                                ? (<TextField
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className={classes.deliveryFree}
                                        label="Total tx. serviço"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        }}
                                        onChange={handleTaxaChange}
                                        value={taxaServicoValue} />)
                                : (<TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        className={classes.deliveryFree}
                                        label="Total tx. serviço"
                                        InputProps={{ readOnly: true }}
                                        value={currencyFormat(totalTaxaServicoItem(items))} />)}
                        </Grid>
                        <Grid item sm={4}>
                            <Box pl={3}>
                                <Tooltip title="Atalho F10" aria-label="Finalizar">
                                    <Button
                                        fullWidth
                                        ref={btnConfirmRef}
                                        variant="contained"
                                        color="primary"
                                        onClick={confirmServiceFee}
                                        endIcon={<DoneIcon />}>Confirmar</Button>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
        </StyledDialog>
    );
}