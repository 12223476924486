export const toggleOrderByMode = (currentState, action) => {
    let { orderBy } = action;

    localStorage.setItem("_saved_orderBy_", orderBy) //Seta a variavel no localStorage

    return {
        ...currentState,
        orderBy: { orderBy: orderBy }
    }
}

export const initialStateOrderBy = () => {

    let orderBy = localStorage.getItem("_saved_orderBy_") === 'opens' ? 'opens' : 'ids' //Pega a variavel do localStorage

    return {
        orderBy: orderBy
    }
}