import React, {useEffect} from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthService from '../../service/AuthService';
import { useHistory } from "react-router-dom";
import { ifAnyGrantedFromProfile } from "../../util/auth/authUtil"

const PREFIX = 'Load'

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  }
}))

export default function Load(props) {

  const history = useHistory();

  const { match: { params } } = props;
  const jwt = params.jwt

  useEffect(() => {
    let service = new AuthService();
    service.load(jwt) 
        .then(resp => {
           console.log("Dados do usuário/empresa carregados com sucesso.")
            const profile = resp;
            //atualizando profile
            const acessoTelaDelivery = ifAnyGrantedFromProfile("ROLE_PDV_DELIVERY", profile)
            const acessoTelaComandaMesa = ifAnyGrantedFromProfile("ROLE_PDV_COMANDA_MESA", profile)
            
            if (acessoTelaDelivery){
              history.push("/delivery");
            } else if (acessoTelaComandaMesa){
              history.push("/pedidosIdentificador");
            } else {
              history.push("/delivery");
            }
            
        }).catch(err => {
            console.error("Falha ao carregar dados do usuário/empresa carregados", err)
            history.push("/login");
        })
        // eslint-disable-next-line
  }, [])

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          Carregando....
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {'Sischef - Gestão inteligente'}
        </Typography>
      </Container>
       
    </Root>
  );
}