import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { usePdvContext } from '../../../../component/generics/PdvProvider';

const PREFIX = 'EditSubItemOrder';

const classes = {
    dialogPaper: `${PREFIX}-dialogPaper`,
    backgroundColumn1: `${PREFIX}-backgroundColumn1`,
    backgroundColumn2: `${PREFIX}-backgroundColumn2`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogPaper}`]: {
        // minHeight: '40vh',
        // maxHeight: '60vh',
    },

    [`& .${classes.backgroundColumn1}`]: {
        background: theme.palette.personal.boardCard
    },

    [`& .${classes.backgroundColumn2}`]: {
        background: theme.palette.personal.boardList
    }
}));

function EditSubItemOrder({ open, setOpen, subItem }) {

    let [subItemEdit, setSubItemEdit] = React.useState({})
    const [obsDefault, setObsDefault] = React.useState()
    const { confirmDialog } = usePdvContext()

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (event) => {
        const subItemCopy = Object.assign({}, subItemEdit)

        subItemCopy[event.target.name] = event.target.value

        setSubItemEdit(subItemCopy)
    }

    const handleClickCancel = () => {

        confirmDialog({
            showCancelButton: true,
            confirmLabel: 'Confirmar',
            content: 'Deseja realmente cancelar? Todas as alterações serão perdidas.',
        }).then(() => {
            handleClose(false)

        }).catch(() => { })
    }

    useEffect(() => {
        // console.log('SUBITEM', subItem)

        const obs = subItem?.product?.observacoesMobile?.split(';')?.filter((e) => e !== '')
        setObsDefault(obs)

        // console.log('OBs SUBITEM', obs)

        const subItemCopy = JSON.parse(JSON.stringify(subItem))

        setSubItemEdit(subItemCopy)
        // eslint-disable-next-line
    }, [open])


    const handleFinalizar = () => {
        for (const property in subItemEdit) {
            subItem[property] = subItemEdit[property];
        }

        setOpen(false)
    }

    const onClickAddObs = (obs) => {
        const subItemCopy = Object.assign({}, subItemEdit)

        if (subItemCopy?.observations) {
            subItemCopy.observations = subItemCopy?.observations + ' ' + obs
        } else {
            subItemCopy.observations = obs
        }

        setSubItemEdit(subItemCopy)
    }

    return (
        <StyledDialog
            maxWidth="sm"
            fullWidth
            open={open}
            scroll='paper'
            onClose={handleClickCancel}
        >

            <DialogTitle>
                {subItemEdit.name}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2} direction='row' alignItems='center' className={classes.dialogPaper} >
                    <Grid item xs={12}>
                        <Grid container spacing={1} direction="row" justifyContent="flex-start">

                            {obsDefault?.map((obs, index) => (
                                <Grid item key={index}>
                                    <Button variant='outlined' size='small' onClick={() => onClickAddObs(obs)} focusRipple={false}>
                                        {obs}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="observations"
                            label="Observação do item"
                            multiline
                            fullWidth
                            maxRows={4}
                            autoFocus={true}
                            minRows={2}
                            name='observations'
                            value={subItemEdit.observations || ''}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions className={classes.backgroundColumn1}>

                <Button
                    onClick={handleClickCancel}
                    variant='text'
                    color="primary"
                    endIcon={<CancelIcon />}>
                    Cancelar
                </Button>

                <Button onClick={handleFinalizar} variant="contained" color="primary" >
                    Confirmar
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default EditSubItemOrder;