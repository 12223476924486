import React, { useEffect } from 'react';
import Routes from './routes';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider, useDispatch, useSelector } from 'react-redux'
import store from "./store"
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AuthService from './service/AuthService';
import { getSettings } from './pages/settings/settings';
import VerifyMobile from './pages/settings/VerifyMobile';
import ReactGA from "react-ga4";
import PdvProvider from './component/generics/PdvProvider';

ReactGA.initialize("G-CRV1S8KEX6");

function App() {
  /*useEffect(() => {
    // Registra um evento no Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Pagina inicial" });

    ReactGA.event({
      eventCategory: 'rota',
      eventAction: 'Troca de rota',
      eventLabel: window.location.href
    });
    console.log("path", window.location)
  }, [])*/
  return (
    <Provider store={store}>
      <AppContext />
    </Provider>
  );
}

function AppContext() {
  const dispatch = useDispatch()
  const service = new AuthService()
  const profile = service.getProfile()
  const [open, setOpen] = React.useState(false);

  // atualiza a data atual da store a cada 1 minuto
  useEffect(() => {
    const interval = setInterval(() => dispatch({ type: 'UPDATE_MINUTE_UPDATE_ACTUAL_DATE', data: { minuteUpdateActualDate: new Date() } }), 60000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // verifica conexão com o servidor e coloca o status no store
  useEffect(() => {

    function verificaAPI() {
      if (profile && profile.unidade) {
        service.getWithoutAuth('/api-v2/sistema/status/' + profile.unidade.uuid)
          .then(retorno => {
            dispatch({ type: 'UPDATE_CONNECTION', data: { isConnectedToServer: true } })
          }).catch(err => {
            dispatch({ type: 'UPDATE_CONNECTION', data: { isConnectedToServer: false } })
            console.log('ERRO_STATUS_SISTEMA: ' + err)
          })
      }
    }

    const poolingInterval = setInterval(verificaAPI, 60 * 1000)

    return () => {
      clearInterval(poolingInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // verifica conexão com a internet e coloca o status no store
  useEffect(() => {

    function handleOnline() {
      dispatch({ type: 'UPDATE_CONNECTION', data: { isOnline: true } })
    }

    function handleOffline() {
      dispatch({ type: 'UPDATE_CONNECTION', data: { isOnline: false } })
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    let authService = new AuthService()
    authService
      .refreshProfile()

    var settingsAux = getSettings();
    const hasMobileConfig = !(settingsAux.enableMobilePDV !== 'true' && settingsAux.enableMobilePDV !== 'false');
    if (!hasMobileConfig && window.innerWidth <= 600) {
      setOpen(true)
    }

    if (window.pdvDesktop) {
      // Exibe logs do electron no console se o mesmo estiver usando a função logger
      window.pdvDesktop.onAppLog((_event, value) => console.log(value))
    }

  }, [])

  const themeSelected = useSelector(state => state.theme.currentTheme)
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }


  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={themeSelected}>

          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            ref={notistackRef}
            action={(key) => (
              <IconButton size="small" onClick={onClickDismiss(key)}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <PdvProvider>
              <VerifyMobile open={open} setOpen={setOpen} />
              <Routes />
            </PdvProvider>
          </SnackbarProvider>

        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}


export default App;
