import { OrderObj } from "../pages/layout-components/NewOrder/helper/OrderHelper"

export const toogleDialogOrder = (currentState, action) =>  {
    let { dialogOrder } = action
  
    return {
        ...currentState, 
        dialogOrder: { ...dialogOrder, open: dialogOrder.open } 
    }
}

export const updateOrderEdit = (currentState, action) =>  {
    let { dialogOrder } = action
    // console.log(action)
    return {
        ...currentState, 
        dialogOrder: { ...dialogOrder, order: dialogOrder.order } 
    }
}

export const initialStateDialogOrder = () =>  {
    return {
        open: false,
        order: new OrderObj()
    }
}