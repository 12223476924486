

// Gerar código de barras com padrão 99
export const generateBarCodeDeliveryMan = (id) => {
    // Converter id para string, garantindo que não exceda 10 dígitos
    let idString = id.toString().slice(0, 10)

    // Preencher com zeros à esquerda para garantir que idString tenha exatamente 10 dígitos
    idString = idString.padStart(10, '0')

    // Retornar '99' seguido pelo idString ajustado
    return '99' + idString
}

export const generateBarCodeConfirm = () => {

    const prefix = '98'

    const totalLength = 12;
    const zerosNeeded = totalLength - prefix.length;

    const zeros = '0'.repeat(zerosNeeded);

    const barcode = prefix + zeros;

    return barcode;
};
