import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, Paper, Tooltip } from '@mui/material';
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { SkeletonProducts } from '../../elements/Skeletons';
import clsx from 'clsx';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PREFIX = 'GroupProduct';

const classes = {
    paper: `${PREFIX}-paper`,
    groupItem: `${PREFIX}-groupItem`,
    selected: `${PREFIX}-selected`,
};

const StyledAutoSizer = styled(AutoSizer)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        overflow: "hidden",
        height: "100%",
        width: '100%',
    },

    [`& .${classes.groupItem}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    [`& .${classes.selected}`]: {
        fontWeight: 'bold',
    }
}));

const cellRowHeight = 40

const GroupProduct = memo(function GroupProduct({ groups, activeGroup, handleClickGroup, loading }) {

    console.log('RERENDER GroupProduct')

    const theme = useTheme()
    const colorDefault = '#cfd8dc'
    const GUTTER_SIZE = 0

    const ButtonBody = ({ group, bgColor, color }) => {
        const isSelected = activeGroup.id === group.id

        return (
            <Paper
                elevation={0}
                className={clsx([classes.paper, classes.groupItem, isSelected ? classes.selected : null])}
                style={{
                    color,
                    background: bgColor,
                }}>
                {group.descricao}
                {isSelected && (
                    <Box position="absolute" right="0">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Box>
                )}
            </Paper>
        )
    }

    const Cell = ({ index, style }) => {
        const group = groups[index]
        const bgColor = group?.cor || colorDefault
        const color = theme.palette.getContrastText(bgColor)

        const tipDescription = group ? (
            group.descricao.length > 25 ? `${group.descricao}` : null
        ) : null

        return (
            <div style={{
                ...style,
                left: style.left + GUTTER_SIZE,
                top: style.top + GUTTER_SIZE,
                width: style.width,
                height: style.height - GUTTER_SIZE,
                display: 'flex',
                overflow: 'hidden'
            }}>
                {group ? (
                    <Box flexGrow={1} p={0.2}>
                        <ButtonBase
                            focusRipple
                            onClick={() => handleClickGroup(group)}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}>
                            {tipDescription ? (
                                <Tooltip title={tipDescription} disableInteractive>
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                    }}>
                                        <ButtonBody group={group} bgColor={bgColor} color={color} />
                                    </div>
                                </Tooltip>
                            ) : (
                                    <ButtonBody group={group} bgColor={bgColor} color={color} />
                            )}
                        </ButtonBase>
                    </Box>
                ) : (
                    null
                )}
            </div>
        );
    }

    return (
        <StyledAutoSizer>
            {({ height, width }) => loading ? (
                <Box style={{ width, height, }}>
                    <SkeletonProducts />
                </Box>
            ) : (
                <FixedSizeList
                    width={width}
                    height={height}
                    itemCount={groups?.length}
                    itemSize={cellRowHeight + GUTTER_SIZE}
                >
                    {Cell}
                </FixedSizeList>
            )
            }
        </StyledAutoSizer>
    );
})

export default GroupProduct;