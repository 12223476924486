import { useEffect, useState } from "react";
import { onlyDigits } from "../../../util/formatter";
import { extractErrorMessage } from "../../../util/http/HttpUtil";
import { sendNotaFiscalToPrint } from "../../../util/board/BoardUtil";
import AuthService from "../../../service/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { getParamByKey } from "../../../component/parameter";
import { useSnackbar } from "notistack";

export const useFunction = () => {
    const dispatch = useDispatch();
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const generalParameters = useSelector(state => state.generalParameters)
    const MODELOS_DE_DOCUMENTOS_EMITIDOS = getParamByKey(generalParameters, 'MODELOS_DE_DOCUMENTOS_EMITIDOS')?.valorString.replace(' ', '').split(',') || []
    const order = useSelector(state => state.detailDialogInvoice?.order)
    const open = useSelector(state => state.detailDialogInvoice?.open) || false
    const [selectedCustomer, setSelectedCustomer] = useState(order?.pessoaCliente)
    const [infoNf, setInfoNf] = useState({})
    const isPJ = infoNf.tipo === "J"
    const [openSelectCustomer, setOpenSelectCustomer] = useState(false)
    const [respNf, setRespNf] = useState()
    const [isAproved, setIsAproved] = useState(false)
    const [enviando, setEnviando] = useState()

    useEffect(() => dispatch({ type: 'UPDATE_PAYMENT_SCREENS', paymentScreensStatus: { fiscal: open ? 'OPEN' : 'CLOSED' } }), [dispatch, open])

    useEffect(() => {
        if (respNf) {
            setIsAproved(verifyIfAproved(respNf))
        } else {
            setIsAproved(false)
        }
    }, [respNf])


    const verifyIfAproved = (respNF) => {
        if (respNF && (respNF.status || respNF.status === 0)) {
            return [100, 103, 105, 0].includes(parseInt(respNF.status));
        }
        return false;
    }

    function getDescriptionDocumentByModelo(modelo) {
        switch (modelo) {
            case '55':
                return '55 NF-e'
            case '65':
                return '65 NFC-e'
            case '2D':
                return '2D PAF-ECF'
            default:
                return 'Parametro "MODELOS_DE_DOCUMENTOS_EMITIDOS" não configurado'
        }
    }

    const handleClickPrintNF = () => {
        if (infoNf.modelo === '2D') {
            enqueueSnackbar(`Acesse o DJ PDV para realizar a impressão do cupom fiscal.`, { variant: "warning" });
            return
        }

        const URL_BASE = process.env.REACT_APP_APPLICATION_URL
        const URL_CAIXA = `${URL_BASE}/admin/danfe?chave=${respNf.chave}`

        window.open(`${URL_BASE}/loginToken?token=${AuthService.getToken()}&url=${URL_CAIXA}`)
    }

    const handleClickPrintNFApp = () => {
        if (infoNf.modelo === '2D') {
            enqueueSnackbar(`Acesse o DJ PDV para realizar a impressão do cupom fiscal.`, { variant: "warning" });
            return
        }

        const idNotaFiscal = infoNf.idNotaFiscal;
        //console.log(">>>>>> infoNf", infoNf)
        
        sendNotaFiscalToPrint(idNotaFiscal)
        .then(resp => {
            console.log('Nota fiscal enviada para impressao. Retorno: ', resp)
            
            enqueueSnackbar('Aguarde a impressão da NF ID' + idNotaFiscal);
        }).catch(err => {
            console.error('Erro ao enviar nota fiscal para impressao. Retorno: ', err)
            enqueueSnackbar('Erro ao enviar nota fiscal para impressao.', { variant: "error" });
        })
        
    }


    const handleClickGerar = () => {
      if (infoNf.modelo === "55") {
        if (!infoNf?.idPessoa) {
          enqueueSnackbar(
            `Para gerar um documento fiscal modelo ${infoNf.modelo} é obrigatório informar um cliente`,
            { variant: "warning" }
          );
          return;
        }
      }
      setEnviando(true);
      service
        .post("/api-v2/pedidoIntegracao/gerarNotaFiscal", infoNf)
        .then((respNF) => {
          const respOrder = respNF.pedidoIntegracao;
          updateInfoNf(respOrder);

          dispatch({
            type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER",
            newOrder: respOrder,
          });
          setRespNf({ ...respOrder.pedido });
          setEnviando(false);

          if (!verifyIfAproved(respOrder.pedido)) {
            console.log("Nota não aprovada. Msg:", respNF.mensagens);

            if (respNF.mensagens) {
              let msgCompleta = "";
              respNF.mensagens.map((msg) => {
                msgCompleta += msg + " ";
                return null;
              });
              enqueueSnackbar(msgCompleta, { variant: "error" });
            }
          }
        })
        .catch((err) => {
          setEnviando(false);
          extractErrorMessage(err, `Erro ao emitir a ${infoNf.modelo}`).then(
            (msg) => {
              enqueueSnackbar(
                `Erro ao emitir a ${infoNf.modelo} - Detalhes: ${msg}`,
                { variant: "error" }
              );
            }
          );
          console.error("ERRO_EMITIR_NF: ", err);
        });
    };

    const updateInfoNf = (order) => {
      setInfoNf({
        ...infoNf,
        id: order.id,
        nome: order.pedido?.nomeNotaFiscal || order.iFoodOrder?.customer.name,
        tipo: order.pedido?.cpfCnpj
          ? order.pedido?.cpfCnpj?.length === 14
            ? "J"
            : "F"
          : order.iFoodOrder?.customer.type,
        cpfCnpj:
          order.pedido?.cpfCnpj ||
          order.iFoodOrder?.customer.taxPayerIdentificationNumber,
        idPessoa: order?.pessoaCliente?.id,
        tipoEmissao: 1,
        idNotaFiscal: order?.pedido?.idNotaFiscal,
        modelo:
          order.pedido?.modelo ||
          (MODELOS_DE_DOCUMENTOS_EMITIDOS.length &&
            MODELOS_DE_DOCUMENTOS_EMITIDOS[0]),
      });
    };

    const handleClickCancel = () => {
        dispatch({ type: 'TOOGLE_DIALOG_INVOICE', detailDialogInvoice: { open: false, order: order } })
    }

    function handleSelectCustomer(paramCustomer) {
        setSelectedCustomer(paramCustomer)
    }

    useEffect(() => {
        setInfoNf({ ...infoNf, idPessoa: selectedCustomer?.id, nome: selectedCustomer?.nome, cpfCnpj: selectedCustomer?.cpfCnpj, tipo: selectedCustomer?.tipo || 'F' })

        // eslint-disable-next-line
    }, [selectedCustomer])

    const handleChange = (event) => {
        const attr = event.target.name
        let value = event.target.value.toUpperCase()

        if (attr === 'cpfCnpj') {
            value = onlyDigits(event.target.value)
        }

        setInfoNf({ ...infoNf, [attr]: value })
    }

    useEffect(() => {
        if (order) {
            updateInfoNf(order);

            setRespNf({ ...order.pedido })
        }
        // eslint-disable-next-line
    }, [order])

    // eslint-disable-next-line react/react-in-jsx-scope
    return {
            isPJ,           
            openSelectCustomer,
            setOpenSelectCustomer,
            isAproved,
            enviando,
            getDescriptionDocumentByModelo,
            handleClickPrintNF,
            handleClickPrintNFApp,
            handleClickGerar,
            handleClickCancel,
            handleSelectCustomer,
            handleChange,
            open,
            respNf,
            infoNf,
            MODELOS_DE_DOCUMENTOS_EMITIDOS,
            selectedCustomer,
            setSelectedCustomer,
            }
}