import React from 'react'
import { Dialog } from "@mui/material"
import MuiDialogContent from '@mui/material/DialogContent'
import { DialogActions, DialogTitle } from "../../CustomDialog/dialogComponents"
import ResumeOrder from "../pages/ResumeOrder"
import TotalOrder from "../pages/TotalOrder"


export function ResumeOrderDialog({ open, order, onClose, setOrder, handleClickOpenSteps }) {

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle onClose={onClose}>
                Resumo do Pedido
            </DialogTitle>
            <MuiDialogContent sx={{ p: 0 }}>
                <ResumeOrder
                    order={order}
                    setOrder={setOrder}
                    handleClickOpenSteps={handleClickOpenSteps} />
            </MuiDialogContent>
            <DialogActions>
                <TotalOrder order={order} showPendingAmount={false} />
            </DialogActions>
        </Dialog>
    )
}