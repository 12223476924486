export const initialPrinterLocations = () => {
    return ([])
}


export const setPrinterLocations = (currentState, action) => {

    return {
        ...currentState,
        printerLocations: action.printerLocations
    }
}