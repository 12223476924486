import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Avatar,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { useSnackbar } from 'notistack';
import AuthService from '../../service/AuthService'
import { extractErrorMessage } from '../../util/http/HttpUtil';
import { createCardFromPedido } from '../../util/board/BoardUtil';
import CallMadeIcon from '@mui/icons-material/CallMade';

export default function LogisticList({ order, hideIfNothingEnable }) {

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()

  const [listLogistic, setListLogistic] = useState([])
  const [loadingLogisticList, setLoadingLogisticList] = useState(false)
  const [loading, setLoading] = useState([])
  const detailOrderOpened = useSelector(state => state.detailOrder.open)
  const [cardBrands, setCardBrands] = useState([])
  const [selectedIndex, setSelectedIndex] = React.useState('')
  const [specialOptions, setSpecialOptions] = React.useState(null)


  useEffect(() => {
    console.log("order aqui: ", order)

    setLoadingLogisticList(true)
    let service = new AuthService()
    service.get('/api-v2/integracaoLogistica/list')
      .then(logistic => {
        setListLogistic(logistic)
        populateLogistcLoading(logistic.length)
      })
      .catch(err => {
        console.error(err)
      }).finally(() => {
        setLoadingLogisticList(false)
      })

  }, [])

  const handleSendToLogistic = (idLogistic, index) => {
    console.log("order aqui: ", order)
    let service = new AuthService()

    let loadingCopy = Object.assign({}, loading);//copia o objeto loading
    loadingCopy[index] = true;//associa a posição do index que está carregando
    setLoading(loadingCopy)//atualiza a variavel

    if (listLogistic[index].tipoImportacao === "ID" && order.tipoImportacao !== "IF" && selectedIndex === '') {
      enqueueSnackbar("Não é possivel enviar este pedido sem um método de pagamento válido", { variant: "warning" });
      return;
    }


    let optionsParam = ""
    if (specialOptions) {
      optionsParam = new URLSearchParams(specialOptions).toString();
    }

    let reqParams = `bandeiraCartao=${cardBrands[selectedIndex]?.brand}&tipoCartao=${cardBrands[selectedIndex]?.method}&${optionsParam}`;

    console.log("Parametros da logistica", reqParams)

    service.post(`/api-v2/integracaoLogistica/${order.id}/${idLogistic}?${reqParams}`)
      .then(pedidoIntegracao => {
        let updatedCard = createCardFromPedido(pedidoIntegracao)
        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { card: updatedCard, open: detailOrderOpened } })

        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } });

        dispatch({ type: 'TOOGLE_DIALOG_LOGISTICS', dialogLogistics: { open: false, order: pedidoIntegracao } })
        dispatch({ type: 'TOOGLE_DIALOG_DELIVERYMAN', detailDialogDeliveryman: { open: false, order: pedidoIntegracao } })

        enqueueSnackbar("Pedido enviado para logística com sucesso", { variant: "success" });
      })
      .catch(err => {
        console.error(err)
        extractErrorMessage(err, "Falha ao enviar pedido para logística")
          .then(msg => {
            enqueueSnackbar("Não foi possível enviar seu pedido. Experimente adicionar manualmente na plataforma. " + msg, { variant: "error" });
          })
      }).finally(() => {
        loadingCopy = Object.assign({}, loading);//copia o objeto loading
        loadingCopy[index] = false;//associa a posição do index que está carregando
        setLoading(loadingCopy)//atualiza a variavel
      })
  }

  const handleVerifyAvailability = (idLogistic, index) => {
    console.log("order aqui: ", order)
    let service = new AuthService()

    let loadingCopy = Object.assign({}, loading);//copia o objeto loading
    loadingCopy[index] = true;//associa a posição do index que está carregando
    setLoading(loadingCopy)//atualiza a variavel

    service.get(`/api-v2/integracaoLogistica/availability/${order.id}/${idLogistic}`)
      .then(paymentMethods => {

        const arr = JSON.parse(paymentMethods);

        const onlinePayment = {}
        onlinePayment.id = "ff9da4ff-3420-4983-b817-ffe6cc018cff"
        onlinePayment.brand = "PEDIDO"
        onlinePayment.method = "PAG"

        if (arr.paymentMethods != null) {
          arr.paymentMethods.push(onlinePayment)
        } else {
          arr.paymentMethods = []
          arr.paymentMethods.push(onlinePayment)
        }


        setCardBrands(arr.paymentMethods);
      }).catch(err => {
        console.error(err)
        extractErrorMessage(err, "Falha ao enviar pedido para logística")
          .then(msg => {
            enqueueSnackbar("Não foi possível enviar seu pedido. Experimente adicionar manualmente na plataforma. " + msg, { variant: "error" });
          })
      }).finally(() => {
        loadingCopy = Object.assign({}, loading);//copia o objeto loading
        loadingCopy[index] = false;//associa a posição do index que está carregando
        setLoading(loadingCopy)//atualiza a variavel
      })
  }

  const populateLogistcLoading = (length) => { //pega a quantidade de integrações de logistica
    console.log("order aqui: ", order)
    let loadingList = []//cria um vetor e o popula com false, para dizer que nenhuma delas está carregando

    for (var i; i < length; i++) {
      loadingList.add(false)
    }

    setLoading(loadingList)
  }

  const handleMenuItemClick = (event) => {
    setSelectedIndex(event.target.value);
    console.log(cardBrands)
  };

  return (
    <div>

      {(listLogistic && listLogistic.length > 0) && (
        <div>
          <Box p={1}>
            <Typography variant="subtitle1" >
              Logísticas disponíveis
            </Typography>
          </Box>


          <Paper variant='outlined'>
            <Box p={1}>
              {listLogistic.map((logistic, index) => (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  key={index}
                >
                  <Grid item xs={1}>
                    <Avatar
                      variant="rounded"
                      alt={logistic.descricao}
                      src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${logistic.tipoImportacao}.png`}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h6">{logistic.descricao}</Typography>
                    {order?.statusLogistica === "ENVIADO" && (
                      <Typography variant="caption">Já enviado</Typography>
                    )}

                    <SpecialOptionByLog sigla={logistic.tipoImportacao} siglaOrder={order.tipoImportacao} setSpecialOptions={setSpecialOptions} />
                  </Grid>

                  <Grid item xs={4} mt={1}>
                    {logistic.tipoImportacao === "ID" && order.tipoImportacao !== 'IF' ? (// logistica ID => iFood Delivery // tipoImportacao IF => iFood
                      <React.Fragment>
                        {cardBrands.length <= 0 ? (
                          <Button
                            color="grey"
                            variant="outlined"
                            disabled={loading[index] || order?.statusLogistica === "ENVIADO"}
                            fullWidth
                            onClick={() => handleVerifyAvailability(logistic.id, index)}
                            endIcon={<CallMadeIcon />} >
                            {loading[index] ? 'Verificando...' : 'Verificar disponibilidade'}
                          </Button>
                        ) : (
                            <>
                            <FormControl
                                disabled={loading[index] || order?.statusLogistica === "ENVIADO"}
                              size="small"
                              variant='outlined'
                                fullWidth >
                              <Select
                                value={selectedIndex}
                                onChange={(event) => handleMenuItemClick(event)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <MenuItem value=''>
                                  <em>Método de pagamento</em>
                                </MenuItem>
                                {cardBrands?.map((option, indexBrand) => {
                                  return (
                                    <MenuItem key={indexBrand} value={indexBrand}>{option.brand + " - " + option.method + "O"}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>

                              <Box pt={1}>
                                <Button
                                  color="grey"
                                  variant="outlined"
                                  disabled={loading[index] || order?.statusLogistica === "ENVIADO" || selectedIndex === ""}
                                  fullWidth
                                  onClick={() => handleSendToLogistic(logistic.id, index)}
                                  endIcon={<CallMadeIcon />} >
                                  {loading[index] ? 'Enviando...' : 'Enviar'}
                                </Button>
                              </Box>    

                          </>
                        )}
                      </React.Fragment>
                    ) : (
                      <Button
                          color="grey"
                        variant="outlined"
                        disabled={loading[index] || order?.statusLogistica === "ENVIADO"}
                        fullWidth
                        onClick={() => handleSendToLogistic(logistic.id, index)}
                        endIcon={<CallMadeIcon />} >
                        {loading[index] ? 'Enviando...' : 'Enviar'}
                      </Button>
                    )}
                  </Grid>
                </Grid>

              ))}

              {loadingLogisticList && ("Carregando opções de entrega...")}

            </Box>
          </Paper>

        </div>
      )
      }


      {
        !hideIfNothingEnable && (

          !loadingLogisticList && (!listLogistic || listLogistic.length === 0) ? (
            <Box p={2}>
              <Typography variant="subtitle1" >
                Você ainda não tem nenhuma integração com logística ativa.
              </Typography>

              <Typography variant="body1">
                Entre em contato com o suporte para saber quais estão disponíveis e como ativar.
              </Typography>
            </Box>
          ) : null

        )
      }


    </div >
  )
}


function SpecialOptionByLog({ sigla, siglaOrder, setSpecialOptions }) {

  let options = null;

  if ("KA" === sigla) {
    options = (
      <FormControlLabel
        control={<Checkbox onChange={event => setSpecialOptions({ retornoEntregador: event.target.checked })} />}
        label="Com retorno do entregador"
      />)
  } else if ("ID" === sigla && "IF" !== siglaOrder) {
    options = (
      <FormControlLabel
        control={<Checkbox onChange={event => setSpecialOptions({ naoConfirmarEntrega: event.target.checked })} />}
        label="Não utilizar confirmação de entrega"
      />)
  }


  return options;
}