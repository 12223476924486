import React, {useState, useRef } from 'react'
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import AuthService from '../../../service/AuthService';
import { currencyFormat, dateFormatedToNewFormat, dateParse, isToday } from '../../../util/formatter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoCard from '../../layout-components/InfoCard';
import ListaPedidosPorHorario from './ListaPedidosPorHorario';
import ListaDeItens from './ListaDeItens';
import CustomDateFilter from '../../../component/generics/customDateFilter';
import { useSnackbar } from 'notistack';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { PAGE_STYLE_PRINT } from '../../../util/printUtils';
import { getGenericCodeByKey } from '../../../component/genericCode';
import { useSelector } from 'react-redux';
import Input from '@mui/material/Input';
import { createHourlyOrder } from '../helper';
import { SITUACOES } from '../../../util/board/BoardUtil';
import { getDefaultPeriodFormated } from '../../../helpers/dateHelper';
import { useIsFirstRender } from '../../../util/genericUtil';

const PREFIX = 'ListaPedidosAgendados'

const classes = {
  scrollBar: `${PREFIX}-scrollBar`,
  useRowStyles: `${PREFIX}-useRowStyles`,
  headerStyle: `${PREFIX}-headerStyle`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
  noLabel: `${PREFIX}-noLabel`
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.scrollBar}`]: theme.scrollBar,

  [`& .${classes.useRowStyles}`]: {
    '& > *': {
      borderBottom: 'none',
    },
  },

  [`& .${classes.headerStyle}`]: {
    backgroundColor: theme.palette.personal.boardCard
  },

  [`& .${classes.chips}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.chip}`]: {
    margin: 2,
  },

  [`& .${classes.noLabel}`]: {
    marginTop: theme.spacing(3),
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function ListaPedidosAgendados() {

  const [finding, setFinding] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(getDefaultPeriodFormated())
  const { enqueueSnackbar } = useSnackbar();
  const service = new AuthService();
  const boxRef = useRef();
  const locaisDeNotificacao = getGenericCodeByKey(useSelector(state => state.genericCodes), 'TIPO_NOTIFICACAO_PRODUTO') || []
  const [locaisSelecionados, setLocaisSelecionados] = useState([]);
  const [situacoesSelecionadas, setSituacoesSelecionadas] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [openSelectNotificationLocal, setOpenSelectNotificationLocal] = useState(false)
  const [openSelectStatus, setOpenSelectStatus] = useState(false)
  const isFirst = useIsFirstRender()

  const handleChangePeriodo = (period) => {
    setSelectedPeriod(period)
  };

  const onClickSearch = () => {
    if (!selectedPeriod) {
      enqueueSnackbar(`Nenhum período informado.`, { variant: "error" });
      return
    }

    listPedidos()
  }

  const selectedNotificationLocations = locaisSelecionados.length > 0 ? locaisSelecionados.map(e => e.codigo) : []
  const selectedStatus = situacoesSelecionadas.length > 0 ? situacoesSelecionadas.map(e => e.key) : []

  const filteredOrder = orderList.length > 0 && !finding ? createHourlyOrder(orderList, selectedNotificationLocations, selectedStatus) : []

  function listPedidos() {

    if (!selectedPeriod) {
      return
    }

    setFinding(true)

    service.get(`/api-v2/pedidoIntegracao/listAllPedidosAgendados?dtInicio=${selectedPeriod?.startDate}&dtFinal=${selectedPeriod?.endDate}`)
      .then(respOrderList => {

        setOrderList(respOrderList)

      }).catch(err => {
        console.error(err)
        enqueueSnackbar(`Não foi possível buscar os pedidos agendados. Detalhe: ${err}`, { variant: "error" });
      }).finally(() => {
        setFinding(false)
      })
  }

  const handlePrint = useReactToPrint({
    content: () => boxRef.current,
    pageStyle: () => PAGE_STYLE_PRINT,
  });

  const handleChangeNotificationLocal = (event) => {
    setLocaisSelecionados(event.target.value);
    handleCloseNotificationLocal()
  };

  const handleChangeStatus = (event) => {
    setSituacoesSelecionadas(event.target.value);
    handleCloseStatus()
  };

  const handleCloseNotificationLocal = () => {
    setOpenSelectNotificationLocal(false);
  };

  const handleOpenNotificationLocal = () => {
    setOpenSelectNotificationLocal(true);
  };

  const handleCloseStatus = () => {
    setOpenSelectStatus(false);
  };

  const handleOpenStatus = () => {
    setOpenSelectStatus(true);
  };

  if (isFirst) {
    listPedidos()
  }

  return (
    <Root>
      <Box pt={2} pb={2}>

        <Box>
          <Grid container spacing={2} alignItems='flex-end'>

            <Grid item xs={12} sm={4}>
              <CustomDateFilter onChange={handleChangePeriodo} />
            </Grid>

            <Grid item xs={6} sm={2}>
              <Button variant="outlined"
                disabled={finding}
                fullWidth
                color="primary"
                onClick={() => onClickSearch()}> {finding ? 'Buscando...' : 'Buscar'}</Button>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2}>
          <Grid container spacing={2} alignItems='flex-end'>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={orderList.length <= 0}>
                <InputLabel id="locaisDeNotificacaoInputLabel">Locais de notificação</InputLabel>
                <Select
                  labelId="locaisDeNotificacaoChipLabelSelect"
                  id="locaisDeNotificacaoChipSelect"
                  multiple
                  open={openSelectNotificationLocal}
                  onClose={handleCloseNotificationLocal}
                  onOpen={handleOpenNotificationLocal}
                  value={locaisSelecionados}
                  onChange={handleChangeNotificationLocal}
                  input={
                    <Input id="locaisDeNotificacaoChipInput" />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip color="primary" size="small" key={value.codigo} label={value.descricao} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {locaisDeNotificacao?.filter((e) => e.codigo !== 'NN').map((option) => (
                    <MenuItem key={option.codigo} value={option} >
                      {option.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth disabled={orderList.length <= 0}>
                <InputLabel id="situacaoInputLabel">Situação do pedido</InputLabel>
                <Select
                  labelId="situacaoChipLabelSelect"
                  id="situacaoChipSelect"
                  multiple
                  open={openSelectStatus}
                  onClose={handleCloseStatus}
                  onOpen={handleOpenStatus}
                  value={situacoesSelecionadas}
                  onChange={handleChangeStatus}
                  input={
                    <Input id="situacaoChipInput" />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip color="primary" size="small" key={value.key} label={value.singularName} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {SITUACOES?.map((option) => (
                    <MenuItem key={option.key} value={option} >
                      {option.singularName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Button color="grey" disabled={orderList.length <= 0} size='small' fullWidth startIcon={<PrintIcon />} onClick={handlePrint}>Imprimir</Button>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2} ref={boxRef}>



          {filteredOrder.length > 0 ? filteredOrder.map((groupedDate, index) => (
            <PedidosAgendadosAccordion key={index} groupedDate={groupedDate} />
          )) : <Typography>Nenhum pedido agendado foi encontrado. </Typography>}
        </Box>
      </Box>

    </Root>
  );
}

function PedidosAgendadosAccordion({ groupedDate }) {

  const [openItens, setOpenItens] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false); //Define o estado do Accordion e é usada para passar o estado para o Accordion "filho"

  const toggleAccordion = () => { 
    setOpenAccordion((prev) => !prev) //Altera o estado do Accordion para aberto ou fechado dependendo do estado atual
  }

  return (
    <React.Fragment>
      {groupedDate ? (
        <Accordion disabled={!groupedDate.hourList.length > 0} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAccordion}>
            <TableContainer style={{ width: '100%' }} className={classes.scrollBar} >
              <Table size='small' stickyHeader>
                <TableBody >
                  <TableRow className={classes.useRowStyles}>
                    <TableCell align='left'>
                      <Typography variant="h5" display="block">
                        {dateFormatedToNewFormat(groupedDate.date, 'yyyy-MM-dd', 'dd MMMM yyyy')}
                        {isToday(dateParse(groupedDate.date, 'yyyy-MM-dd')) ? (
                          <Chip style={{ marginLeft: "5px" }} label="Hoje" size="small" color="primary" />
                        ) : null}
                      </Typography>
                    </TableCell>

                    {groupedDate.hourList.length < 1 ? (
                      <TableCell align='left'>
                        <Typography align="center" variant="body1" >
                          Não existem pedidos agendados para está data
                        </Typography>
                      </TableCell>
                    ) : (
                      <React.Fragment>
                          <TableCell align='right'>
                            <InfoCard variant='h6'
                              value={`${groupedDate.orderLength ? groupedDate.orderLength : '0'} pedido(s)`}
                              valueColor="primary" />
                          </TableCell>

                          <TableCell align='right'>
                            <InfoCard variant='h6'
                              value={`Total ${currencyFormat(groupedDate.orderTotalValue)}`}
                              valueColor="primary" />
                        </TableCell>
                      </React.Fragment>
                    )}

                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionSummary>

          <AccordionDetails>

            {groupedDate.hourList.length > 0 && (
              <Box pt={1} style={{ width: '100%' }}>
                {groupedDate.hourList.map((groupedHour, index) =>
                  <ListaPedidosPorHorario groupedHour={groupedHour} key={index} classes={classes} openAccordion={openAccordion} />
                )}
              </Box>
            )}

          </AccordionDetails>

          <Divider />

          <AccordionActions>
            <Box p={1} displayPrint='none'>

              <Button
                disabled={groupedDate.hourList.length <= 0}
                color="grey"
                size="small"
                startIcon={<PrintIcon />}
                onClick={() => setOpenItens(true)}>
                Imprimir itens do dia
              </Button>
            </Box>

            <ListaDeItens
              open={openItens}
              setOpenItens={setOpenItens}
              groupedDate={groupedDate} />

          </AccordionActions>

        </Accordion >
      ) : null}
    </React.Fragment>
  )
}
