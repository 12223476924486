import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import DeliveryManList from './components/DeliveryManList'
import { Box, Button, Tab } from '@mui/material'
import DeliveryMainDialog from './components/DeliveryManDialog'
import ReactGA from "react-ga4"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DeliveryManPayment } from './components/DeliveryManPayment'
import { ifAnyGranted } from '../../util/auth/authUtil'

const PREFIX = 'Load'

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(5),
  }
}))

export default function Load() {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState('1')
  const isROLE_ACERTO_ENTREGADORES = ifAnyGranted('ROLE_ACERTO_ENTREGADORES')

  useEffect(() => {//informa ao analytics que o usuário caiu na pagina de deliveryman
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "deliveryman" });
  }, [])

  function openListDeliveryManDiolog() {
    setOpen(true)
  }

  function closeListDeliveryManDiolog() {
    setOpen(false)
  }

  function handleTab(event, value) {
    setTab(value)
  }

  return (
    <Root className={classes.root}>
      <CssBaseline />

      <TabContext value={tab}>

        {isROLE_ACERTO_ENTREGADORES && (
          <TabList onChange={handleTab}>
            <Tab label="Entregadores" value="1" />
            <Tab label="Acerto de Entregadores" value="2" />
          </TabList>
        )}

        <TabPanel value="1">
          <Container component="main" className={classes.main} maxWidth={false}>
            <Typography variant="h2" component="h1">
              Conferência
            </Typography>

            <Typography variant="h5" component="h2">
              Conferência de pedidos por entregadores
            </Typography>

            <Box paddingTop={2}>
              <Button onClick={openListDeliveryManDiolog} size="small" color="primary" variant='outlined' >
                Listagem de  Entregadores
              </Button>
            </Box>

            <Box paddingTop={2}>
              <DeliveryManList />
            </Box>
          </Container>
        </TabPanel>

        <TabPanel value="2">
          <Container component="main" className={classes.main} maxWidth={false}>
            <Typography variant="h2" component="h1">
              Acerto de Entregadores
            </Typography>

            <Typography variant="h5" component="h2">
              Acertos com seus entregadores
            </Typography>

            <DeliveryManPayment />
          </Container>
        </TabPanel>

      </TabContext>

      <DeliveryMainDialog
        open={open}
        close={closeListDeliveryManDiolog} />
    </Root>
  );
}