import React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Badge, Tooltip } from '@mui/material';
import { importTypeDescription } from '../../../util/order/orderUtil';
import { useConnectionStatus } from '../../../util/http/HttpUtil';

const PREFIX = 'IntegrationAvatar'

const classes = {
  root: `${PREFIX}-root`,
  avatarEmpresa: `${PREFIX}-avatarEmpresa`
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${classes.avatarEmpresa}`]: {
    color: '#fff',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.personal.boardCard}`,
  },
}))

const IntegrationAvatar = ({ pedido }) => {
  const isConnected = useConnectionStatus()

  if (!isConnected) {
    return (
      <div>
        {pedido?.tipoImportacaoOriginal ?
          <Tooltip title={`Pedido convertido de ${importTypeDescription(pedido.tipoImportacaoOriginal)} para ${importTypeDescription(pedido.tipoImportacao)}`}>
            <Badge
              overlap="rectangular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<SmallAvatar
                alt={pedido.tipoImportacao}
                className={classes.root}>{pedido.tipoImportacao.charAt(0)}</SmallAvatar>}
            >
              <StyledAvatar
                variant="rounded"
                className={classes.avatarEmpresa}
                alt={pedido.tipoImportacaoOriginal}>
                {pedido.tipoImportacaoOriginal.charAt(0)}
              </StyledAvatar>
            </Badge>
          </Tooltip>
          :
          <StyledAvatar variant="rounded"
            className={classes.avatarEmpresa}
            alt={pedido.tipoImportacao}>
              {pedido.tipoImportacao.charAt(0)}
          </StyledAvatar>
        }
      </div>
    )
  }

  return (
    <div>
      {pedido?.tipoImportacaoOriginal ?
        <Tooltip title={`Pedido convertido de ${importTypeDescription(pedido.tipoImportacaoOriginal)} para ${importTypeDescription(pedido.tipoImportacao)}`}>
          <Badge
            overlap="rectangular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={<SmallAvatar
              alt={pedido.tipoImportacao}
              src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${pedido.tipoImportacao}.png`}
              className={classes.root} />}>
            <StyledAvatar
              variant="rounded"
              className={classes.avatarEmpresa}
              alt={pedido.tipoImportacaoOriginal}
              src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${pedido.tipoImportacaoOriginal}.png`} />
          </Badge>
        </Tooltip>
        :
        <StyledAvatar variant="rounded"
          className={classes.avatarEmpresa}
          alt={pedido.tipoImportacao}
          src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${pedido.tipoImportacao}.png`}>
        </StyledAvatar>

      }
    </div>
  )
}

export function TipoIntegracaoAvatar({ sigla }) {
  const isConnected = useConnectionStatus()

  if (!isConnected) {
    return (
      <StyledAvatar variant="rounded"
        alt={sigla}
        className={classes.avatarEmpresa}>
        {sigla}
      </StyledAvatar>
    )
  }

  return (
    <StyledAvatar variant="rounded"
      className={classes.avatarEmpresa}
      alt={sigla}
      src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${sigla}.png`}>
    </StyledAvatar>
  )
}

export default IntegrationAvatar;