import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import { Dialog, DialogContent, DialogTitle, IconButton, Button, InputLabel, FormControl } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


/**
 * To ignore Chrome’s secure origin policy, follow these steps. Navigate to chrome://flags/#unsafely-treat-insecure-origin-as-secure in Chrome.
 * Find and enable the Insecure origins treated as secure section (see below). 
 * Add any addresses you want to ignore the secure origin policy for. Remember to include the port number too (if required). 
 * Save and restart Chrome.
 * Remember this is for dev purposes only. 
 * The live working app will need to be hosted on https for users to be able to use their microphone or camera.
 */


const PREFIX = 'Cam';

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    container: `${PREFIX}-container`,
    barcodeScanner: `${PREFIX}-barcodeScanner`,
    visionCamera: `${PREFIX}-visionCamera`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,
    [`&.${classes.container}`]: {
        top: 0,
        left: 0,
        maxWidth: 'lg',
        height: '100%',
        position: 'absolute',
    },
    [`& .${classes.barcodeScanner}`]: {
        padding: '20px',
    },
    [`& .${classes.visionCamera}`]: {
        border: '0 !important'
    },
    [`& video`]: {
        width: '100%',
        height: '100%',
    }
}))

function notRepeatPlease(callback, duration = 1000) {
    if (window.noRepeatTimer) {
        return
    }

    callback()

    window.noRepeatTimer = setTimeout(() => window.noRepeatTimer = null, duration)
}

const QuaggaScanner = ({ onDetected, open, close, pedidoList }) => {
    const scannerRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar()
    const [cameras, setCameras] = useState([])
    const [selectedCamera, setSelectedCamera] = useState(localStorage.getItem('selectedCamera') || '');

    const handleOnDetected = (data) => {
        const code = data.codeResult.code;

        if (onDetected) {
            notRepeatPlease(() => onDetected(code))
        }
    }

    // Função para listar as câmeras
    const listCameras = async () => {
        try {            
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            setCameras(videoDevices);

            // Se não houver câmera selecionada (tanto no estado quanto no localStorage), use a primeira disponível
            if (!selectedCamera && videoDevices.length > 0) {
                // Verifique se uma câmera está salva no localStorage e é válida
                const savedCameraId = localStorage.getItem('selectedCamera');
                const isValidSavedCamera = videoDevices.some(device => device.deviceId === savedCameraId);

                // Se uma câmera válida está salva, use-a, caso contrário, selecione a primeira câmera disponível
                const initialCameraId = isValidSavedCamera ? savedCameraId : videoDevices[0].deviceId;

                setSelectedCamera(initialCameraId);

                // Se não havia uma câmera válida salva, atualize o localStorage
                if (!isValidSavedCamera) {
                    localStorage.setItem('selectedCamera', initialCameraId);
                }
            }
        } catch (err) {
            console.error('Erro ao listar dispositivos de mídia:', err);
            enqueueSnackbar('Erro ao listar câmeras', { variant: 'error' });
        }
    }

    useEffect(() => {
        listCameras();
        Quagga.onDetected(handleOnDetected);

        return () => {
            Quagga.offDetected(handleOnDetected);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidoList, onDetected])

    const initQuagga = (camera) => {
        if (scannerRef.current) {
            Quagga.init({
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        deviceId: camera || selectedCamera,
                        aspectRatio: { min: 1, max: 2 },
                    },
                    target: scannerRef.current
                },
                locate: true,
                numOfWorkers: 4,
                decoder: {
                    readers: ['code_128_reader', 'ean_reader', 'codabar_reader', 'upc_reader', 'upc_e_reader']
                },
            }, (err) => {
                if (err) {
                    console.error('Erro ao inicializar Quagga:', err)
                    enqueueSnackbar(`${err}`, { variant: 'error' })
                    return
                }
                Quagga.start()
            })
        }
    }

    const handleCameraChange = (event) => {
        const newSelectedCamera = event.target.value;
        setSelectedCamera(newSelectedCamera)

        localStorage.setItem('selectedCamera', newSelectedCamera)

        Quagga.stop()
        initQuagga(event.target.value)
    }

    const handleClose = () => {
        Quagga.offDetected(handleOnDetected)
        Quagga.stop()
        close()
    }

    return (
        <StyledDialog
            open={open}
            fullWidth={true}
            sx={{
                maxHeight: "lg",
            }}
            onClose={handleClose}
            TransitionProps={{
                onEntered: () => initQuagga(),
            }}
        >
            <DialogTitle>
                Escanear Código
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent style={{ height: '480px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div ref={scannerRef} style={{ width: '100%', height: '100%', position: 'relative' }}></div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <FormControl fullWidth>
                        <InputLabel id="camera-select-label">Selecionar câmera</InputLabel>
                        <Select
                            size='small'
                            labelId="camera-select-label"
                            value={selectedCamera}
                            onChange={handleCameraChange}
                            label="Selecionar câmera"
                        >
                            {cameras.map((camera) => (
                                <MenuItem key={camera.deviceId} value={camera.deviceId}>
                                    {camera.label || camera.deviceId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button
                        size='small'
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        style={{ marginTop: '20px' }}
                    >
                        Confirmar
                    </Button>

                </div>
            </DialogContent>

        </StyledDialog>
    )
}

export default QuaggaScanner;