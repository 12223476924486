

const UNITARIO_CHAR = "=";

/**
 * 
 * @param {String} codigoBarras 
 * @param {String} opt.formato - V -  "2 | PRODUTO | VALOR TOTAL | DV" (VALOR DEFAULT)
 * @param {String} opt.formato - P -  "2 | PRODUTO | PESO | DV"
 * 
 */
export const parseCodigoBarras = (codigoBarras, { formato }) => {

    let qtde = 1.0;
    let qtdeUnitario = 1.0;
    let valorUnitario = null;
    let valorTotal = null;
	let codigoBarrasRealProduto = codigoBarras.slice(); //FAZ UMA COPIA
	let codigoBarrasOriginal = codigoBarras.slice();
    let pareceCodBarBalanca = false; 
    let mensagemErro = null;


    const contemCharEspecial = codigoBarras.includes("=") ||
        codigoBarras.includes("x") ||
        codigoBarras.includes("*") ||
        codigoBarras.includes("X");

    let codigoTratado = codigoBarrasOriginal.replace("x", "*").replace("X", "*");

    if(codigoTratado.includes("*")){
        try {
            
            let qtdeStr = codigoTratado.split("*")[0].replace(",",".");
            qtde = parseFloat(qtdeStr);
            codigoBarrasRealProduto = codigoTratado.split("*")[1];
        } catch (err) {
            mensagemErro = `Código de barras inválido: ${codigoBarrasOriginal}`;
        }
    }

    if (codigoBarrasRealProduto.includes(UNITARIO_CHAR)){
        let valorUnitarioStr = codigoBarrasRealProduto.substring( codigoBarrasRealProduto.indexOf(UNITARIO_CHAR) +1 );
        valorUnitarioStr = valorUnitarioStr.replace(".","").replace(",",".");
        valorUnitario = parseFloat(valorUnitarioStr);
        if (valorUnitario != null){
            valorTotal = qtde * valorUnitario;
        }
        codigoBarrasRealProduto = codigoBarrasRealProduto.substring(0, codigoBarrasRealProduto.indexOf(UNITARIO_CHAR) );
    }


    /* VERIFICA SE PARECE SER UM CODIGO BALANCA */
	pareceCodBarBalanca = codigoBarrasOriginal.startsWith("2") && codigoBarrasOriginal.length === 13 && !contemCharEspecial;
    
    if (pareceCodBarBalanca){
        codigoBarrasRealProduto = codigoBarrasOriginal.substring(1,7);
        if ("V" === formato){
            let valorProdutoString = codigoBarrasOriginal.substring(7,12);
            valorTotal = parseFloat(valorProdutoString) / 100.0;
        } else if ("P" === formato){
            let pesoString = codigoBarrasOriginal.substring(7,12);
            qtde = parseFloat(pesoString) / 1000.0;
            qtdeUnitario = parseFloat(pesoString);
        }else {
            mensagemErro = "Formáto do tipo balança inválido. Valores possíveis 'V' ou 'F'"
        }
    }

    return {
        qtde,
        valorUnitario,
        valorTotal,
        codigoBarrasRealProduto,
        codigoBarrasOriginal,
        pareceCodBarBalanca,
        mensagemErro,
        qtdeUnitario
    }
}