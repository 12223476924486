import { purple, amber, red, blue } from '@mui/material/colors';

export const PRIMARY = '#f10b5c'
export const SECONDARY = '#747f86'
export const DEFAULT = '#fff'

export const AGENDADO_COLOR = purple[500]
export const TAKEOUT_COLOR = amber[500]
export const CONSUMIR_LOCAL_COLOR = blue[500]

export const LOGISTICS_SENT = red['A100']

export const createPalette = (mode = 'light') => {
  return  {
    mode,
    personal: {
      header: mode === 'light' ? '#ebebeb' : '#222f39',
      background: mode === 'light' ? '#f7f8fa' : '#1b252d',
      menu: mode === 'light' ? '#FFFFFF' : '#2a3a47',
      boardList: mode === 'light' ? '#FFFFFF' : '#222f39',
      boardCard: mode === 'light' ? '#f7f8fa' : '#2a3a47',
      hoverList: mode === 'light' ? '#ebebeb' : '#414b52',
      customArea: mode === 'light' ? '#ebebeb' : '#222f39',
    },
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    grey: {
      main: mode === 'light' ? '#2a3a47' : DEFAULT,
      contrastText: '#2a3a47',
    },
    background: {
      paper: mode === 'dark' ? '#2a3a47' : '#ffffff'
    }
  }
}

export default createPalette();