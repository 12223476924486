export const toogleDialogSendOrders = (currentState, action) => {
    const { dialogSendOrders } = action

    return {
        ...currentState,
        dialogSendOrders: { ...dialogSendOrders, open: dialogSendOrders.open }
    }
}

export const initialDialogSendOrders = () => {
    return {
        open: false,
    }
}