import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid } from '@mui/material';
import IntegrationAvatar from '../Board/IntegrationAvatar'
import TypeOrderAvatar from '../Board/typeOrderAvatar'
import ActionButtons from "./ActionButtons"
import DetailOrderBody, { DetailItensAndPayments } from "./DetailOrderBody"
import { DialogTitle, DialogContent } from "../CustomDialog/dialogComponents"
import { dateFormatedToNewFormat } from '../../../util/formatter';
import TotalOrder from '../NewOrder/pages/TotalOrder';
import { isDeliveryOrder } from '../../../util/order/orderUtil';
import { AvatarForIdentificador } from '../elements/AvatarForIdentificador';

const PREFIX = 'DetailOrder'

const classes = {
  scrollBar: `${PREFIX}-scrollBar`
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  [`&.${classes.scrollBar}`]: theme.scrollBar
}))

export default function DetailOrder() {
  const dispatch = useDispatch()
  const open = useSelector(state => state.detailOrder.open)
  const card = useSelector(state => state.detailOrder.card)

  const setOpen = (open) => {
    dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open, card: card } })
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {card && <HeaderDetailOrder order={card.data} />}
      </DialogTitle>
      <StyledDialogContent dividers className={classes.scrollBar}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            {card && <DetailOrderBody card={card} />}
          </Grid>

          <Grid item xs={12} sm={4}>
            {card && card?.data?.ativo && <ActionButtons card={card} />}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {card && <DetailItensAndPayments card={card} />}
          </Grid>
        </Grid>

      </StyledDialogContent>

      <DialogActions>
        {card && <TotalOrder order={card?.data} showPendingAmount={false} />}
      </DialogActions>

    </Dialog>
  )
}

export function HeaderDetailOrder({ order }) {

  const id = order?.id
  const sequencial = order?.sequencial
  const shortReference = order?.iFoodOrder?.shortReference || id
  const dataPedido = order?.dataPedido
  const isDelivery = isDeliveryOrder(order)

  return (
    <Box display="flex" >
      {!isDelivery && order?.tipo !== 'CAIXA_RAPIDO' ?
        <Box display="flex" pl={0} alignItems="center"  >
          <AvatarForIdentificador order={order} />
        </Box>
        :
        <Box display="flex" pl={0} alignItems="center"  >
          {order && <TypeOrderAvatar order={order} />}
        </Box>
      }

      <Box display="flex" pl={2} alignItems="center"  >
        {order && <IntegrationAvatar pedido={order} />}
      </Box>

      <Box display="flex" pl={2} flexDirection="column" >
        <Typography variant="caption">Seq</Typography>
        <Typography variant="h6">{sequencial}</Typography>
      </Box>

      <Box display="flex" pl={2} flexDirection="column">
        <Typography variant="caption">Ped #{id}</Typography>
        <Typography variant="h6">Ref {shortReference}</Typography>
      </Box>

      <Box display="flex" pl={2} flexDirection="column">
        <Typography variant="caption">Data {dateFormatedToNewFormat(dataPedido, 'ISO', 'dd MMM')}</Typography>
        <Typography variant="h5">{dateFormatedToNewFormat(dataPedido, 'ISO', 'HH:mm')}</Typography>
      </Box>

    </Box>
  )
}