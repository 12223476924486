export const toogleDialogOrderListByIdentificador = (currentState, action) => {
  const { dialogOrderListByIdentificador } = action

  return {
    ...currentState,
    dialogOrderListByIdentificador: { ...dialogOrderListByIdentificador, open: dialogOrderListByIdentificador.open }
  }
}

export const initialDialogOrderListByIdentificador = () => {
  return {
    open: false,
    data: null
  }
}