import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthService from '../../service/AuthService';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '../../util/http/HttpUtil';
import { Container } from '@mui/material';
import { ifAnyGrantedFromProfile } from '../../util/auth/authUtil';
import ReactGA from "react-ga4";

const md5 = require('md5');

const PREFIX = 'Login'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  loginBox: `${PREFIX}-loginBox`,
  promoText: `${PREFIX}-promoText`,
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  frasePrincipal: `${PREFIX}-frasePrincipal`
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
    //backgroundImage: 'url(https://source.unsplash.com/collection/11566673/1200x900)',
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/layout/login-bg.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    }, 
  },

  [`& .${classes.loginBox}`]: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.personal.menu,
    maxWidth: "500px",
    margin: "0 auto",
  },

  [`& .${classes.promoText}`]: {
    padding: theme.spacing(4, 4),
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(16, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.frasePrincipal}`]: {
    lineHeight: '1em',
    color: 'white',
    fontSize: "6em",
    [theme.breakpoints.down('md')]: {
      fontSize: "3em",
    },   
	  fontWeight: "bold",
  }
}))

export default function Login() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({ 
    username: '',
    password: ''
  })
  const [sending, setSending] = useState(false)
  const [logged, setLogged] = useState(AuthService.loggedIn())

 

  const profile = new AuthService().getProfile();

  useEffect(() => {//informa ao analytics que o usuário caiu na pagina de login
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "login" });
  }, [])


  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setValues({ ...values, [name]: value})
  }

  const hadleSubmit = (event) => {
    //event.stopPropagation();
    setSending(true)
    event.preventDefault();

    let username = values.username
    let password = values.password
    let passMD5 = md5(password)

    let service = new AuthService();
    service.login(username, passMD5)
    .then(profile => {
        setSending(false)
        enqueueSnackbar("Logado com sucesso", {variant: 'success',});
        console.log("Dados do usuário/empresa carregados com sucesso.")

        const acessoTelaDelivery = ifAnyGrantedFromProfile("ROLE_PDV_DELIVERY", profile)
        const acessoTelaComandaMesa = ifAnyGrantedFromProfile("ROLE_PDV_COMANDA_MESA", profile)
        
        if (acessoTelaDelivery){
          history.push("/delivery");
        } else if (acessoTelaComandaMesa){
          history.push("/pedidosIdentificador");
        } else {
          history.push("/delivery");
        }

        
    }).catch(err => {
        console.error("Falha ao carregar dados do usuário/empresa carregados", err)
        setSending(false)

        extractErrorMessage(err, "Falha ao realizar login")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })

        //enqueueSnackbar("Erro ao realizar login", {variant: 'error',});
    })
  }

  const handleLogout = () => {
    new AuthService().logout()
    setLogged(false)
  }

  const handleGoToMainPage = () => {
    history.push("/");
  }

  return (
    <>
      <StyledContainer className={classes.root} disableGutters maxWidth={false}>
        <Container>
          <CssBaseline />

          <Box pt={4} >
            <Grid container alignItems="center" spacing={2} >
              <Grid item>
                <img src={process.env.PUBLIC_URL + "/logo-sischef.svg"} style={{ width: '96px' }} alt="Logo do Sischef"></img>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: 'white', fontSize: "3em", fontWeight: "bold", letterSpacing: "0px" }}>Sischef</Typography>
              </Grid>

            </Grid>
          </Box>


          <Grid container>

            <Grid item sm={6} xs={12} className={classes.header} >
              <Box p={1} >
                <Typography className={classes.frasePrincipal} variant="h1">Gestão inteligente</Typography>
              </Box>
            </Grid>

            <Grid item sm={6} xs={12}>

              <Paper variant="outlined" elevation={0} className={classes.loginBox}>
                <Box p={1}>

                  <Typography component="h2" variant="h2">
                    Faça seu login
                  </Typography>

                  {logged ? (
                    <React.Fragment>

                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleGoToMainPage}
                      >
                        Acessando como {profile.nome} <br />Clique continuar
                      </Button>

                      <Button
                        fullWidth
                        variant="text"
                        className={classes.submit}
                        onClick={handleLogout}
                      >
                        Acessar como outro usuário
                      </Button>
                    </React.Fragment>


                  ) : (
                    <form className={classes.form} onSubmit={hadleSubmit}>
                      <TextField
                        variant="filled"
                        margin="normal"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        required
                        fullWidth
                        id="username"
                        label="Usuário"
                        name="username"
                        onChange={handleChange}
                        autoComplete="username"
                        value={values.username}
                        autoFocus
                      />
                      <TextField
                        variant="filled"
                        margin="normal"
                        required
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={handleChange}
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        value={values.password}
                        autoComplete="current-password"
                      />

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={sending}
                        >
                          {sending ? 'Enviando...' : 'Acessar'}
                        </Button>
                        {sending}
                      </form>

                  )}

                </Box>
              </Paper>

              <Box pt={3}>
                <Typography align="center" color="secondary">
                  Parseint Desenvolvimento de Sistemas Ltda
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </StyledContainer>
    </>
  );
}