import React, { useState, useMemo, useRef } from 'react'
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash'
import { Box, Dialog, DialogActions, DialogContent, Divider } from "@mui/material"
import InputSearch from '../../InputSearch'
import { ProductList } from './GroupAccordions'
import { DialogTitle } from '../../CustomDialog/dialogComponents'
import { normalizeText } from '../../../../util/formatter'
import { getParamByKey } from '../../../../component/parameter';
import { useSelector } from 'react-redux';
import { parseCodigoBarras } from '../../../../util/codigoBarras/codigoBarrasHelper';
import { addProductByCodBar } from '../InputCodBar';

const PREFIX = 'SearchProductDialog'

const classes = {
    dialogContent: `${PREFIX}-dialogContent`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogContent}`]: {
        padding: theme.spacing(),
        backgroundColor: theme.palette.mode === 'dark' ? 'none' : theme.palette.grey[300]
    }
}))

const MAX_ITENS = 20

export default function SearchProductDialog({ open, handleClose, products, handleSelectedProduct, setProdutoBalanca, produtobalanca }) {

    const [searchValue, setSearchValue] = useState('')
    const [filteredProducts, setFilteredProducts] = useState([])
    const [filteredProductCodBarra, setFilteredProductCodBarra] = useState()
    const inputRef = useRef(null)
    const generalParameters = useSelector(state => state.generalParameters)
    const FORMATO_DEFAULT = "V" //V ou P para codigos de balança
    const FORMATO = getParamByKey(generalParameters, 'MODELO_COD_BARRAS_BALANCA')?.valorString || FORMATO_DEFAULT;

    const handleSelectProductBalanca = (item, options) => {
        handleSelectedProduct(item, { ...options, snackbarPlacement: 'bottom' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleInput(target) {
        const { value } = target

        if (!value) {
            setSearchValue('')
            setFilteredProducts([])
            setFilteredProductCodBarra(null)
            return
        }

        const { pareceCodBarBalanca, codigoBarrasRealProduto, codigoBarrasOriginal } = parseCodigoBarras(value, { formato: FORMATO })

        setSearchValue(value)

        let findedItem
        const valueUpper = pareceCodBarBalanca ? normalizeText(codigoBarrasRealProduto.toUpperCase()) : normalizeText(value.toUpperCase())
        const newProducts = products.filter(item => {
            const { codigoBarras, descricao } = item.produto
            const normalizedDescricao = normalizeText(descricao.toUpperCase())

            if (codigoBarras === valueUpper) {            
                findedItem = item
                return false
            }   

            return normalizedDescricao.indexOf(valueUpper) > -1 || codigoBarras.indexOf(valueUpper) > -1
        })

        setFilteredProductCodBarra(findedItem)
        setProdutoBalanca({ isBalanca: pareceCodBarBalanca, codigoBarras: codigoBarrasOriginal})

        if (newProducts.length > MAX_ITENS) {
            newProducts.length = MAX_ITENS
        }

        setFilteredProducts(newProducts)
    }

    const handleInputDelay = useMemo(() =>
        debounce(target => handleInput(target), 200), [handleInput])

    function handleSelectedProductSearch(item) {
        if(produtobalanca.isBalanca && filteredProductCodBarra?.produto?.unidadeMedida?.sigla !== 'KG'){
            addProductByCodBar(searchValue, handleSelectProductBalanca, products, FORMATO)
        } else {
            handleSelectedProduct(item, { snackbarPlacement: 'bottom' })
        }       
    }    

    if (!open) return null

    let content = (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" textAlign="center" p={3}>
            Utilize a barra de pesquisa para encontrar o produto desejado
        </Box>
    )

    if (filteredProductCodBarra && !filteredProducts.length) {
        content = <Box>  
            <Divider sx= {{mb:2}}>Código Informado</Divider>
            <ProductList products={[filteredProductCodBarra]} handleClick={handleSelectedProductSearch} />
        </Box>
    } else if (searchValue && !filteredProducts.length) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                Nenhum produto encontrado
            </Box>
        )
    } else if (filteredProducts.length) {
        content = <>
             {filteredProductCodBarra &&
                <Box>  
                    <Divider sx= {{mb:2}}>Código Informado</Divider>
                    <ProductList products={[filteredProductCodBarra]} handleClick={handleSelectedProductSearch} />
                    <Divider sx= {{my:2}}>Outros</Divider>
                </Box>
            }
            <ProductList products={filteredProducts} handleClick={handleSelectedProductSearch} />
        </>
    }

    return (
        <StyledDialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle onClose={handleClose}>
                Buscar Produto
            </DialogTitle>
            <DialogActions disableSpacing>
                <InputSearch
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    autoFocus={true}
                    defaultValue={searchValue}
                    onFocus={event => event.target.select()}
                    onChange={event => handleInputDelay(event.target)}
                    placeholder="Procurar produto pelo nome"
                    inputProps={{ 'aria-label': 'procurar produto pelo nome' }} />
            </DialogActions>
            <DialogContent className={classes.dialogContent}>
                {content}
            </DialogContent>
        </StyledDialog>
    );
}