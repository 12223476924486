import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect } from 'react';
import AuthService from '../../service/AuthService';
import { extractErrorMessage } from '../../util/http/HttpUtil';

function Sistema() {

    const service = new AuthService()
    const { enqueueSnackbar, closeSnackbar  } = useSnackbar();
    const profile = service.getProfile()
    const URL_BASE = process.env.REACT_APP_APPLICATION_URL

    useEffect(() => {

        if (profile && profile.unidade){
            
            service.getWithoutAuth('/api-v2/sistema/status/' + profile.unidade.uuid)
            .then(retorno => {
                // console.log('RETORNO STATUS: ', retorno)
                if (retorno.status === 'EXPIRADO'){
                    enqueueSnackbar(retorno.informacoes, { 
                            variant: "warning",
                            persist: true,
                            action,
                          
                        })
                }

                if (retorno.status === 'INATIVO'){
                    enqueueSnackbar(retorno.informacoes, { 
                        variant: "error",
                        persist: true,
                        action,
                    })
                }
                
            }).catch(err => {
                extractErrorMessage(err, "Erro ao buscar status da empresa")
                    .then(msg => {
                        enqueueSnackbar(msg, { 
                                variant: "error",
                                persist: true,
                            });
                    })
                
                console.error('ERRO_STATUS_SISTEMA: ' + err)
            })
        }
        
        // eslint-disable-next-line
    }, [])


    const action = key => (
        <Fragment>
            <Button onClick={ () => {
                window.open(URL_BASE + "/boletos.jsf?empresa=" + profile.unidade.uuid )
            } }>
               Saber mais
            </Button>
            <Button onClick={ () => closeSnackbar(key) }>
              Fechar
            </Button>
        </Fragment>
    );



    return (
        <div>

        </div>
    );
}


export default Sistema