import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import DetalhePedido from "../../deliveryman/components/DetalhePedido"

export default function DetalhePedidoOffline() {
    const dispatch = useDispatch()
    const { open, order } = useSelector(state => state.detailOrderOffline)

    const setOpen = open => dispatch({
        type: 'TOOGLE_DETAIL_ORDER_DIALOG_OFFLINE',
        detailOrderOffline: { open, order }
    })

    return <DetalhePedido open={open} setOpen={setOpen} order={order} showBtnPrint={false} />
}