import React, { useRef } from 'react';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import { useSnackbar } from 'notistack';
import { TextMaskCEP } from "../../../../util/mask"
import AuthService from '../../../../service/AuthService';
import AutocompleteService from '../../form-components/AutocompleteService';
import { formatFullAddress, formatFullAddressCustomer } from '../../../../util/customer/helper';
import { usePdvContext } from '../../../../component/generics/PdvProvider';
import { useDispatch, useSelector } from 'react-redux';
import { extractErrorMessage } from '../../../../util/http/HttpUtil';

export default function AddressForm({ address, setAddress, handleChange, handleChangeCity, autoFocus }) {

    const { enqueueSnackbar } = useSnackbar();
    const numeroRef = useRef(null)
    const service = new AuthService()
    const { confirmDialog } = usePdvContext()
    const municipio = useSelector(state => state.municipioList.municipio)
    const dispatch = useDispatch()


    const calculateDistance = async () => {
        console.log('calculateDistance')

        let service = new AuthService()
        let profile = service.getProfile()

        if (profile) {
            const empresa = profile.unidade

            if (!empresa) {
                await confirmDialog({
                    confirmLabel: 'Ok',
                    showCancelButton: false,
                    content: 'Nenhuma empresa encontrada. Por favor, verifique.',
                }).catch(() => { })

                return
            }

            const pessoaUnidade = empresa.pessoa

            if (!pessoaUnidade) {
                confirmDialog({
                    confirmLabel: 'Ok',
                    showCancelButton: false,
                    content: 'Nenhuma pesssoa associada no cadastro da empresa. Por favor, verifique.',
                }).catch(() => { })

                return
            }

            const addressOrigin = encodeURIComponent(formatFullAddressCustomer(pessoaUnidade)?.address)
            const addressDetination = encodeURIComponent(formatFullAddress(address).address)
            const KEY_GOOGLE_MAPS = process.env.REACT_APP_GOOGLE_API_KEY

            service.get(`/api-v2/endereco/directions/${addressOrigin}/${addressDetination}/${KEY_GOOGLE_MAPS}`)
                .then(response => {
                    const resp = JSON.parse(response)
                    const distance = resp?.routes[0]?.legs[0]?.distance
                    const duration = resp?.routes[0]?.legs[0]?.duration
                    const infoRoute = { distance, duration }

                    setAddress({
                        ...address,
                        infoRoute
                    })

                    console.log('endereco', distance?.text, distance?.value, duration?.text, infoRoute, address)
                    console.log('address', address)
                }).catch(err => {
                    console.error(err)
                    enqueueSnackbar("Nenhuma rota encontrada para o endereço informado.", { variant: 'warning' });
                })
        }
    }

    const handleLoadingMunicipios = async (value) => {        
        if (value === '') {
            return []
        }
        let resultado
        if (!municipio) {
            console.log(value)
            value = value.replaceAll('\'', '')
            await service.get(`/api-v2/municipio/list?q=${value}`)
                .then(municipios => {                        
                    console.log('municipos', municipios)
                    dispatch({ type: 'TOOGLE_MUNICIPIO_LIST', municipioList: { municipio: municipios, open: false } })
                    resultado = municipios
                }).catch(err => {
                    resultado = []
                    extractErrorMessage(err, 'Erro ao buscar os municípios do sistema')
                        .then(msg => {
                            enqueueSnackbar(`Erro ao buscar os municípios - ${msg}`, { variant: "error" })
                        })
                })                
        } else {
            return municipio
        }
        return resultado
    }

    function handleSearchByCep() {

        let cep = address.cep
        console.log("CEP", cep)

        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(response => {

                return response.json().then((result) => {
                    if (result.erro) {
                        enqueueSnackbar("CEP não encontrado", { variant: 'warning' });
                        return;
                    }
                    console.log("result", result)
                    setAddress({
                        ...address,
                        endereco: result.logradouro,
                        bairro: result.bairro,
                        municipio: {
                            codigoIbge: result.ibge,
                            nome: result.localidade
                        },
                        complemento: result.complemento,
                    })
                    numeroRef.current.focus()
                })

            }).catch(err => {
                console.error(err)
                enqueueSnackbar("Nada encontrado para o CEP informado.", { variant: 'warning' });
            })
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>

                    <TextField label="CEP"
                        name="cep"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={address.cep || ''}
                        InputProps={{
                            inputComponent: TextMaskCEP,
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchByCep} size="small"><FindReplaceIcon /></IconButton>
                                </InputAdornment>
                        }}
                        onChange={handleChange}
                        autoFocus={autoFocus}
                    />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label="Endereço" name="endereco" variant="outlined" size="small"
                        fullWidth value={address.endereco || ''}
                        onChange={handleChange} />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <TextField label="Núm." name="numero" variant="outlined" size="small"
                        fullWidth value={address.numero || ''}
                        inputRef={numeroRef}
                        onChange={handleChange} />
                </Grid>

                <Grid item pt={1} xs={12} sm={5}>
                    <TextField label="Bairro" name="bairro" variant="outlined" size="small"
                        fullWidth value={address.bairro || ''}
                        onChange={handleChange} />
                </Grid>

                <Grid item pt={1} xs={12} sm={7}>
                    <TextField label="Complemento" name="complemento" variant="outlined" size="small"
                        fullWidth value={address.complemento || ''}
                        onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteService
                        renderinputprops={{
                            variant: "outlined",
                        }}
                        margin="dense"
                        value={address.municipio || {}}
                        label="Município"
                        size="small"
                        onSelect={element => handleChangeCity(element)}
                        loadOptions={handleLoadingMunicipios}
                        getOptionLabel={(option) => { return (option.nome ? option.nome : '') }}
                    />
                </Grid>

                <Grid container alignItems='center' direction="row" spacing={2}>
                    <Grid item sm={4} >
                        <Button size='small' color="grey" variant='text' onClick={calculateDistance}>Calcular Distância</Button>
                    </Grid>

                    <Grid item  >
                        <Typography align='left' variant='button' display='block'>
                            {`${address?.infoRoute?.distance?.text ?? ''} | ${address?.infoRoute?.duration?.text ?? ''}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </div >
    )
}