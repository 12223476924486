export const initialGeneralParameters = () => {
    return ([])
}


export const setGeneralParameters = (currentState, action) => {

    return {
        ...currentState,
        generalParameters: action.generalParameters
    }
}