import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
    Box, Button, Chip, CircularProgress, Dialog, DialogActions, Grid, IconButton, InputAdornment, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery
}
    from '@mui/material';
import Alert from '@mui/material/Alert';
import AuthService from '../../../service/AuthService';
import { useSelector, useDispatch } from 'react-redux'
import { currencyFormat, dateFormat, dateParseISO, dateToStringISO, isToday } from '../../../util/formatter';
import { createUUID, isOrderPayed, pendingAmount, printOrderHTML, totalDeliveryFee, totalDiscountItems, totalItemReal, totalItems, totalOrder } from '../../../util/order/orderUtil';
import { PaymentObj } from '../NewOrder/helper/OrderHelper';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone';
import TotalOrder from '../NewOrder/pages/TotalOrder';
import { HeaderDetailOrder } from '../DetailOrder';
import { useHotkeys } from 'react-hotkeys-hook';
import Discount from './Discount';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { DialogTitle, DialogContent } from "../CustomDialog/dialogComponents"
import { extractErrorMessage } from '../../../util/http/HttpUtil';
import { useState } from 'react';
import CustomerSelector from '../NewOrder/Customer/CustomerSelector';
import { getSettings } from '../../settings/settings';
import { roundUp } from '../../../util/genericUtil';
import { getParamByKey } from '../../../component/parameter';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ListItemReceive from './ListItensReceive';
import { ifAnyGranted } from '../../../util/auth/authUtil';
import ServiceFee from './ServiceFee';
import DeliveryFee from './DeliveryFee';
import { createCardFromPedido, sendPedidoToPrint, showMsgErrorOuSuccessOnReturPrint } from '../../../util/board/BoardUtil';
import ButtonGroup from '@mui/material/ButtonGroup';
import { isAuthorized } from '../../../routes';
import ReactGA from "react-ga4";
import { usePdvContext } from '../../../component/generics/PdvProvider';
import { createTefJson, getTefStatusById, tefFetchCancelarVenda, tefFetchVenda, useTefVendaElgin } from '../../../util/order/tefUtil';
import RefreshIcon from '@mui/icons-material/Refresh';
import PixIcon from '@mui/icons-material/Pix';
import useCarregarFormasPagamento from '../../../store/paymentOptions';

const PREFIX = 'PaymentDialog'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    dialogPadding: `${PREFIX}-dialogPadding`,
    backgroundColumn2: `${PREFIX}-backgroundColumn2`,
    resumeOrder: `${PREFIX}-resumeOrder`,
    paymentList: `${PREFIX}-paymentList`,
    chipPayed: `${PREFIX}-chipPayed`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.dialogPadding}`]: {
        padding: "2px 2px"
    },

    [`& .${classes.backgroundColumn2}`]: {
        background: theme.palette.personal.customArea
    },

    [`& .${classes.paymentList}`]: {
        height: '60vh',
    },

    [`& .${classes.chipPayed}`]: {
        backgroundColor: theme.palette.success.light,
    },
}))

const paymentIconByType = (type) => {

    if ("CX" === type) {
        return <LocalAtmIcon />
    }

    if ("CC" === type) {
        return <CreditCardIcon />
    }

    if ("CD" === type) {
        return <PaymentTwoToneIcon />
    }

    if ("CL" === type) {
        return <ReceiptIcon />
    }

    if ("BC" === type) {
        return <AccountBalanceIcon />
    }
    if ("PX" === type || "PD" === type) {
        return <PixIcon />
    }

    return <AttachMoneyIcon />
}

export function useOrderPayment() {
    const service = new AuthService()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const generalParameters = useSelector(state => state.generalParameters)
    const importOrderCheckout = getSettings().importOrderCheckout === 'ENABLED'
    const ROLE_PDV_ONLINE_SISCHEF_BLOQUEIA_IMPORTACAO_PEDIDO = ifAnyGranted('ROLE_PDV_ONLINE_SISCHEF_BLOQUEIA_IMPORTACAO_PEDIDO')

    function saveDeletedPaymentsLog(deletedPayments, order) {
        return Promise.all(deletedPayments.map(deletedPayment => service.post('/api-v2/logEvento', {
            tipo: 'DELETE',
            entidade: 'Payment',
            motivo: deletedPayment.justificativa || '',
            json: JSON.stringify(deletedPayment),
            pedidoIntegracao: {
                id: order.id
            },
        })))
    }

    function confirmPayment(order, orderPrint = false) {
        return new Promise((resolve, reject) => {
            const isROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_PAGAMENTO = isAuthorized('ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_PAGAMENTO')
            if (isROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_PAGAMENTO) {
                enqueueSnackbar(`Seu usuário não tem permissão para realizar o pagamento do pedido`, { variant: 'error' })
                return
            }

            const orderTemp = JSON.parse(JSON.stringify(order))
            orderTemp.printOrder = false
            orderTemp.iFoodOrder.subTotal = totalItems(orderTemp.iFoodOrder)
            orderTemp.iFoodOrder.totalPrice = totalOrder(orderTemp.iFoodOrder)

            const isPayed = isOrderPayed(orderTemp?.iFoodOrder)
            orderTemp.pago = isPayed

            if (isPayed) {
                orderTemp.iFoodOrder.divideInvoiceObservation = !!orderTemp.iFoodOrder.divideInvoiceObservation ? 'pagamento finalizado - nada para dividir' : ''
            }

            orderTemp.iFoodOrder.items.map((item) => {
                item.chargeServiceFee = item.checked
                return null
            })

            let printByParam = false
            const NOTIFICAR_PAGAMENTO_PEDIDO = getParamByKey(generalParameters, 'NOTIFICAR_PAGAMENTO_PEDIDO')
            if (isPayed && !!NOTIFICAR_PAGAMENTO_PEDIDO?.valor && orderTemp.tipo === 'BALCAO') {

                if ("NOTIFICAR_PAGAMENTO" === order.statusNotificacao) {
                    orderTemp.statusNotificacao = "NAO_NOTIFICADO"
                }

                orderTemp.iFoodOrder.items.map((item) => {
                    if (item.status === "NOTIFICAR_PAGAMENTO") {
                        item.status = "PENDENTE"
                        printByParam = !!!orderTemp.dataImpressao
                    }
                    return null
                })

                const data = new Date()
                const profile = new AuthService().getProfile()
                orderTemp.logs = !!orderTemp.logs ? orderTemp.logs + " \n" : ""
                // eslint-disable-next-line no-useless-concat
                orderTemp.logs = orderTemp.logs + "Pedido: " + orderTemp.id + " Renotificado todos os itens manualmente pelo usuário: " + profile.nome + ` em ${dateFormat(data)}` + " Parâmetro NOTIFICAR_PAGAMENTO_PEDIDO ativo"
            }

            service.post('/api-v2/pedidoIntegracao', orderTemp).then(orderResp => {
                if ((orderTemp.tipo === 'COMANDA' || orderTemp.tipo === 'MESA') && (!!orderResp.pago)) {
                    dispatch({ type: 'REMOVE_ORDER_GENERIC', order: orderResp })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'REMOVE_ORDER_GENERIC', order: orderResp } })
                } else {
                    dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderResp })
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderResp } })
                }


                if (orderPrint || printByParam) {
                    const printApplication = getSettings().printApplication

                    if (printApplication === 'APP') {
                        sendPedidoToPrint(orderResp).then(resp => {
                            showMsgErrorOuSuccessOnReturPrint(resp, enqueueSnackbar)
                        }).catch(err => {
                            console.error('Erro ao enviar pedido para impressao. Retorno: ', err)
                            enqueueSnackbar('Erro ao enviar pedido para impressao.', { variant: "error" });
                        })
                    } else {
                        printOrderHTML(orderResp.id)

                    }
                }

                resolve(orderResp)

                if (isPayed) {
                    if (importOrderCheckout && !ROLE_PDV_ONLINE_SISCHEF_BLOQUEIA_IMPORTACAO_PEDIDO) {
                        const createdCard = createCardFromPedido(orderResp)
                        dispatch({ type: 'TOOGLE_IMPORT_ORDER_DIALOG', importOrder: { open: true, card: createdCard } })
                    }
                }


            }).catch(err => reject(err))
        })
    }

    return { confirmPayment, saveDeletedPaymentsLog }
}

export default function PaymentDialog() {
    const { enqueueSnackbar } = useSnackbar();
    const { promptDialog } = usePdvContext()
    const { confirmPayment: confirmOrderPayment, saveDeletedPaymentsLog } = useOrderPayment()
    const dispatch = useDispatch()
    const open = useSelector(state => state.paymentOrder.open)
    const order = useSelector(state => state.paymentOrder.order)
    const confirmedPayments = order?.iFoodOrder?.payments || [];
    const [loading, setLoading] = React.useState(false)
    const [paymentSelected, setPaymentSelected] = React.useState(null)
    const [totais, setTotais] = React.useState({
        subTotal: 0.0,
        deliveryFee: 0.0,
        discount: 0.0,
        total: 0.0,
        pending: 0.0
    })
    const [errors, setErrors] = React.useState({ iputPayValue: null })
    const paymentValueRef = useRef();
    const btnConfirmRef = useRef();
    const btnConfirmAndPrintRef = useRef();
    const paymentOptionsRef = useRef([]);
    const [openDiscount, setOpenDiscount] = React.useState(false)
    const [openListItemToReceive, setOpenListItemToReceive] = React.useState(false)
    const [openServiceFee, setOpenServiceFee] = React.useState(false)
    const [openDeliveryFee, setOpenDeliveryFee] = React.useState(false)
    const [saving, setSaving] = useState(false)
    const service = new AuthService()
    const [selectedCustomer, setSelectedCustomer] = React.useState()
    const generalParameters = useSelector(state => state.generalParameters)
    const UTILIZA_TAXA_SERVICO = getParamByKey(generalParameters, 'UTILIZA_TAXA_SERVICO')?.valorString
    const ID_PESSOA_PADRAO_PEDIDO_SAIDA = getParamByKey(generalParameters, 'ID_PESSOA_PADRAO_PEDIDO_SAIDA')?.valor
    const [pedidosSecundarios, setPedidosSecundarios] = React.useState([])
    const [deletedPayments, setDeletedPayments] = useState([])
    const [dataVencimento, setDataVencimento] = React.useState()
    const [disableDiscount, setDisableDiscount] = React.useState(false)
    const cardList = useSelector(state => state.cards) //Lista todos os cards
    const [valueSelectedItem, setValueSelectedItem] = useState(0)
    const statusMoneyBox = useSelector(state => state.statusMoneyBox.status) // recebe o status global do caixa
    const [paymentDoc, setPaymentDoc] = React.useState('');
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const loadingPayment = useRef(false)
    const [loadingBalance, setLoadingBalance] = useState(false);
    const VALIDAR_LIMITE_CREDITO = getParamByKey(generalParameters, 'VALIDAR_LIMITE_CREDITO_PESSOA_SISCHEF')?.valorString
    const profile = AuthService.profile();
    const TAXA_SERVICO_PEDIDO_BALCAO = getParamByKey(generalParameters, 'TAXA_SERVICO_PEDIDO_BALCAO')?.valorString === 'S';
    const { confirmDialog } = usePdvContext()
    const { iniciaVenda: iniciaVendaElgin, integracao: integracaoElgin, cancelaVenda: cancelaVendaElgin } = useTefVendaElgin()
    const paymentOptions = useSelector(state => state.paymentOptions)

    const carregarFormasPagamento = useCarregarFormasPagamento();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch({ type: 'UPDATE_PAYMENT_SCREENS', paymentScreensStatus: { payment: open ? 'OPEN' : 'CLOSED' } }), [open])

    const clearAll = () => {
        setErrors({ iputPayValue: null })
        setPaymentSelected(null)
        setSaving(false)
        setPedidosSecundarios([])
        setValueSelectedItem(0)
    }

    async function getAvailableBalance(id) {
        setLoadingBalance(true)
        try {
            const res = await service.get(`/service?action=get&entity=limiteCredito&idPessoa=${id}`);
            const availableBalance = res.limiteCredito - res.saldoPendente;

            return availableBalance;
        } catch (err) {
            console.error(err);
            throw err;
        } finally { setLoadingBalance(false) }
    }

    const setOrder = (order) => {
        dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { order: order, open } })
    }

    useEffect(() => {
        if (!!!open) {
            return
        }
        setLoading(true)
        console.log("Caiu no loading payment")
        console.log("paymentOptions", paymentOptions)
        if (!!paymentOptions && paymentOptions.length > 0) {
            setLoading(false)
        } else {
            carregarFormasPagamento()
        }

        // eslint-disable-next-line    
    }, [open, paymentOptions])

    useEffect(() => {
        if (!order) {
            return;
        }
        buscarPedidoPeloIdentificadorSecundario() //atualiza a lista de pedidos secundarios sempre que a order é alterada
        const iFoodOrder = order.iFoodOrder

        const subTotal = totalItems(iFoodOrder);
        const deliveryFee = totalDeliveryFee(iFoodOrder);
        const total = totalOrder(iFoodOrder);
        const discount = totalDiscountItems(iFoodOrder);
        const pending = pendingAmount(iFoodOrder)
        const isPayed = isOrderPayed(iFoodOrder)

        setTotais({ ...totais, subTotal, deliveryFee, discount, total, pending, isPayed })
        // eslint-disable-next-line
    }, [order])

    useEffect(() => {
        if (selectedCustomer) {
            let customerTemp = {}
            customerTemp.id = selectedCustomer.id
            customerTemp.name = selectedCustomer.nome
            customerTemp.phone = selectedCustomer.fone
            customerTemp.email = selectedCustomer.email
            customerTemp.taxPayerIdentificationNumber = selectedCustomer.cpfCnpj
            customerTemp.type = selectedCustomer.tipo

            let paymentSelectedTemp = Object.assign({}, paymentSelected)
            paymentSelectedTemp.customer = customerTemp

            setPaymentSelected(paymentSelectedTemp)
        }
        // eslint-disable-next-line
    }, [selectedCustomer])

    useEffect(() => {
        if (totais?.pending <= 0) {
            if ((totais?.subTotal === totais?.discount)) {
                setDisableDiscount(false)
            } else {
                setDisableDiscount(true)
            }
        } else {
            setDisableDiscount(false)
        }
        // eslint-disable-next-line
    }, [totais])

    useHotkeys('f9', (event) => {


        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmAndPrintRef.current) {
            btnConfirmAndPrintRef.current.click()
        }
    })

    useHotkeys('f10', (event) => {


        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    })

    useHotkeys('Ctrl+*', (event) => {
        if (!open) {
            return
        }

        if (event.key > 0 && event.key < 10) {
            let key = event.key;

            if (paymentOptionsRef.current) {
                let paymentOptionElement = paymentOptionsRef.current[key - 1];
                if (paymentOptionElement) {
                    paymentOptionElement.click()
                }
            }

        }
    }, {
        filter: () => true
    }, [paymentOptions, open])


    const handleClickPaymentType = (paymentType) => {
        const pending = pendingAmount(order.iFoodOrder)

        const dataVencimento = new Date();
        dataVencimento.setDate(dataVencimento.getDate() + (paymentType.intervalo || 0));

        setDataVencimento(dataVencimento)

        let payment = new PaymentObj()
        payment.id = createUUID()
        payment.externalCode = paymentType.id
        payment.code = paymentType.conta.tipoConta
        payment.name = paymentType.descricao
        payment.formaPagamento = paymentType
        payment.value = roundUp(pending, 2); //roundTo.up(pending, 2);

        if ((valueSelectedItem <= pending) && (valueSelectedItem > 0)) {
            payment.value = roundUp(valueSelectedItem, 2); //roundTo.up(pending, 2);
        }

        setPaymentSelected(payment)

        setSelectedCustomer(undefined)

        if (paymentType?.pessoaRequerida) {
            setSelectedCustomer(order.pessoaCliente)
        }

        setTimeout(() => {
            if (paymentValueRef.current) {
                paymentValueRef.current.focus()
            }
        }, 100)
    }

    const handleClickAddPayment = async () => {
        if (loadingPayment.current) return

        try {
            loadingPayment.current = true

            if (statusMoneyBox === 'CLOSE') {
                /*verifica o status do caixa e confere se ele não está fechado
                utilizando a variável statusMoneyBox
                */
                enqueueSnackbar('O caixa está fechado, é necessário abrir o caixa para realizar pagamentos.', { variant: "error" });
                return;
            }

            setErrors({ ...errors, iputPayValue: null })

            let paymentValue = parseFloat(paymentValueRef.current.value);
            const pending = (pendingAmount(order?.iFoodOrder));
            let paymentTemp = Object.assign({}, paymentSelected)

            paymentTemp.dataOcorrencia = dateToStringISO(new Date())
            paymentTemp.username = profile?.username
            paymentTemp.authorizationCode = paymentDoc;

            if (paymentValue <= 0 || isNaN(paymentValue)) {
                setErrors({ ...errors, iputPayValue: `O valor deve ser maior que zero.` })
                return;
            }

            if (paymentTemp.formaPagamento.pessoaRequerida) {

                if (selectedCustomer !== null && selectedCustomer !== undefined) {
                    if (selectedCustomer.id === ID_PESSOA_PADRAO_PEDIDO_SAIDA) {
                        setErrors({ ...errors, iputPayValue: `Informe um cliente` })
                        return;
                    }
                } else {
                    enqueueSnackbar('Informe um cliente para adicionar essa forma de pagamento ', { variant: "error" });
                    return
                }
                //Caso seja pedido de contas a receber seta como vencimento a data selecionada
                paymentTemp.dataOcorrencia = new Date();
                paymentTemp.dataVencimentoOriginal = dataVencimento
                paymentTemp.dataVencimento = dataVencimento

                /*VALIDAÇÃO DE LIMITE DE CRÉDITO */
                if (VALIDAR_LIMITE_CREDITO === 'S') {
                    const availableBalance = await getAvailableBalance(selectedCustomer.id);

                    if (confirmedPayments.length > 0) {
                        let allPayments = 0;
                        confirmedPayments.map((payment) => {

                            //eslint-disable-next-line
                            if (payment.formaPagamento?.conta?.pendente && payment.customer?.id == selectedCustomer.id) {
                                allPayments = allPayments + payment.value
                            }
                            return null
                        })
                        if ((allPayments + paymentValue) > availableBalance) {
                            enqueueSnackbar('O pagamento excede o limite de crédito disponível deste cliente, limite disponível: ' + currencyFormat(availableBalance - allPayments) + '. Faça a baixa dos valores pendentes ou atribua um valor maior', { variant: "error" });
                            return;
                        }
                    }
                    if (paymentSelected.formaPagamento?.conta?.pendente) {
                        if (availableBalance < 0) {
                            enqueueSnackbar('Este cliente excedeu o limite de crédito, faça a baixa dos valores pendentes ou atribua um valor maior', { variant: "error" });
                            return;
                        } else if (paymentValue > availableBalance) {
                            enqueueSnackbar('O pagamento excede o limite de crédito disponível deste cliente, limite disponível: ' + currencyFormat(availableBalance) + '. Faça a baixa dos valores pendentes ou atribua um valor maior', { variant: "error" });
                            return;
                        }
                    }
                }
            }

            let restCalculated = paymentValue - roundUp(pending, 2);

            if (['CX', 'OT'].includes(paymentTemp.code) && valueSelectedItem > 0.0 && paymentValue > valueSelectedItem && restCalculated < 0.01) {

                paymentTemp.value = valueSelectedItem
                const transshipmentTemp = (paymentValue)
                paymentTemp.changeFor = transshipmentTemp;

            } else if (restCalculated >= 0.01) {

                if (!['CX', 'OT'].includes(paymentTemp.code)) {
                    setErrors({ ...errors, iputPayValue: `Valor maior que o pendente ${currencyFormat(pending)}` })
                    return;
                }

                paymentTemp.value = pending
                const transshipmentTemp = (paymentValue)
                paymentTemp.changeFor = transshipmentTemp;

            } else {
                paymentTemp.value = paymentValue
                paymentTemp.changeFor = null
            }

            if (selectedCustomer) {
                let customerTemp = {}

                customerTemp.id = selectedCustomer.id
                customerTemp.name = selectedCustomer.nome
                customerTemp.email = selectedCustomer.email
                customerTemp.phone = selectedCustomer.fone
                customerTemp.type = selectedCustomer.tipo

                paymentTemp.customer = customerTemp
            }

            let orderTemp = JSON.parse(JSON.stringify(order))
            orderTemp.iFoodOrder.payments = [...confirmedPayments]
            const confirmedPaymentsIndex = orderTemp.iFoodOrder.payments.push(paymentTemp)

            orderTemp.iFoodOrder.items.filter((item) => item.pagar > 0).map((item) => {
                const totalItem = totalItemReal(item)
                const totalPago = (item.amountPaid || 0.0)

                item.amountPaid = roundUp(totalPago + item.pagar, 2)
                item.pagar = 0.0

                if (item.amountPaid >= totalItem) {
                    item.received = true
                }

                let partialCopy = Array(item.divideInto || 1)

                item.partialPayment.map((pay, index) => {
                    partialCopy[index] = pay === 'CHECKED' ? 'RECEIVED' : pay
                    return null
                })

                item.partialPayment = partialCopy
                return null
            })

            setPaymentSelected(null)
            setValueSelectedItem(0)
            setPaymentDoc('')

            if (paymentTemp.formaPagamento?.utilizaTef) {
                const isROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_PAGAMENTO = isAuthorized('ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_PAGAMENTO')
                if (isROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_PAGAMENTO) {
                    enqueueSnackbar('Seu usuário não tem permissão para realizar o pagamento do pedido', { variant: 'error' })
                    return
                }

                if (integracaoElgin) {
                    await iniciaVendaElgin(orderTemp, paymentTemp)
                    return
                }

                const integracoesTef = await service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=TEF')
                if (!integracoesTef?.length) {
                    enqueueSnackbar('Você deve configurar uma integração do TEF nas configurações do PDV', { variant: 'error' })
                    return
                }

                const terminalId = localStorage.getItem('_tef_terminal_id_')
                if (!terminalId) {
                    enqueueSnackbar('Você deve selecionar um terminal do TEF no menu de integrações', { variant: 'error' })
                    return
                }

                const { TEF_ON_KEY } = JSON.parse(integracoesTef[0].dados)

                dispatch({ type: 'TOOGLE_TEF_DIALOG', dialogTef: { open: true } })

                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: false } })
                dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { order: null, open: false } })

                console.log('Fazendo requisição de intenção de venda')

                const tefJson = createTefJson(paymentTemp, terminalId)
                tefFetchVenda(TEF_ON_KEY, tefJson).then(({ intencaoVenda }) => {

                    console.log('Iniciado processo de intenção de venda', intencaoVenda)

                    orderTemp.iFoodOrder.payments.map((payment, index) => {
                        if ((confirmedPaymentsIndex - 1) === index) {
                            payment.tefInfo = {
                                terminalId,
                                intencaoVendaId: `${intencaoVenda.id}`,
                            }
                        }
                        return null
                    })

                    const status = getTefStatusById(intencaoVenda.intencaoVendaStatus.id)
                    const tef = {
                        status,
                        order: orderTemp,
                        intencaoVendaId: intencaoVenda.id,
                    }

                    localStorage.setItem('_tef_', JSON.stringify(tef))
                    sessionStorage.setItem('_tef_', JSON.stringify(tef))

                    dispatch({ type: 'TOOGLE_TEF_DIALOG', dialogTef: { open: true, tef } })
                }).catch(err => {
                    console.log(err)
                    enqueueSnackbar(err, { variant: 'error' })
                })
            } else {
                setOrder(orderTemp)

                setTimeout(() => {
                    btnConfirmRef.current.focus()
                }, 100);
            }

        } finally {
            loadingPayment.current = false
        }
    }

    const handleClickRemovePayment = async (payment) => {
        try {
            if (payment?.formaPagamento?.modeloPos) {
                payment.justificativa = await promptDialog({
                    confirmLabel: 'Excluir',
                    content: 'Você está prestes a excluir um pagamento feito na SmartPOS.',
                    footerContent: 'Importante: O Sischef não irá cancelar o pagamento já realizado. Acesse o menu da SmartPOS e faça o cancelamento do pagamento manualmente.'
                })
            }

            let paymentsFiltred = confirmedPayments.filter(confirmedPayment => confirmedPayment.id !== payment.id)

            let orderTemp = JSON.parse(JSON.stringify(order))

            orderTemp.iFoodOrder.payments = paymentsFiltred

            if (paymentsFiltred.length < 1) {
                //Caso não tenha mais pagamentos remove dos itens o status pago.
                orderTemp.iFoodOrder.items.map((item) => {
                    item.received = undefined
                    item.amountPaid = undefined
                    item.divideInto = undefined
                    item.partialPayment = undefined
                    return null;
                })
            }

            if (payment.formaPagamento?.utilizaTef) {

                if (integracaoElgin) {
                    await cancelaVendaElgin(orderTemp, payment)
                    return
                }

                // a paygo só deixa cancelar pagamentos no mesmo dia onde houve o pagamento,
                // então, foi criada essa mensagem para avisar ao cliente do mesmo
                if (!isToday(dateParseISO(payment.dataOcorrencia))) {
                    enqueueSnackbar(`O tempo para a exclusão desse pagamento expirou. Data de ocorrência: ${dateFormat(dateParseISO(payment.dataOcorrencia), 'dd/MM/yyyy HH:mm')}`, { variant: 'error' })
                    return
                }

                // o usuário deve informar a senha técnica da ControlPay
                // a senha se encontra em "Integrações / Config. -> Opções de Venda" dentro do painel da ControlPay
                //
                // senha ténica sandbox (DEV): 314159
                const senhaTecnica = await promptDialog({
                    inputType: 'password',
                    inputLabel: 'Senha técnica',
                    content: 'Digite a senha técnica para prosseguir com a exclusão do pagamento TEF',
                })

                const integracoesTef = await service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=TEF')
                if (!integracoesTef?.length) {
                    enqueueSnackbar('Você deve configurar uma integração do TEF nas configurações do PDV', { variant: 'error' })
                    return
                }

                const { TEF_ON_KEY } = JSON.parse(integracoesTef[0].dados)

                console.log('Fazendo requisição de cancelamento do TEF')

                tefFetchCancelarVenda(TEF_ON_KEY, {
                    senhaTecnica,
                    ...payment.tefInfo,
                }).then(({ intencaoVenda }) => {
                    console.log('Iniciado processo de cancelamento do pagamento', intencaoVenda)

                    const status = getTefStatusById(intencaoVenda.intencaoVendaStatus.id)
                    const tef = {
                        status,
                        payment,
                        order: orderTemp,
                        intencaoVendaId: intencaoVenda.id,
                    }

                    localStorage.setItem('_tef_', JSON.stringify(tef))
                    sessionStorage.setItem('_tef_', JSON.stringify(tef))

                    dispatch({ type: 'TOOGLE_TEF_DIALOG', dialogTef: { open: true, tef } })
                }).catch(err => {
                    console.log(err)
                    enqueueSnackbar(err.message, { variant: 'error' })
                })
                return
            }

            setOrder(orderTemp)
            setDeletedPayments(state => [...state, payment])

            setValueSelectedItem(payment)
        } catch (err) {
            console.log(err)
        }
    }

    const handleClickCancel = () => {

        confirmDialog({
            showCancelButton: true,
            confirmLabel: 'Confirmar',
            content: 'Deseja sair sem confirmar?',
        }).then(() => {
            handleClose(false)

        }).catch(() => { })
    }

    const handleClose = () => {
        clearAll()
        let orderTemp = Object.assign({}, order)
        orderTemp.printOrder = false
        dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: false, order: orderTemp } })
    }

    const confirmPayment = (orderPrint) => {
        setSaving(true)

        confirmOrderPayment(order, orderPrint).then((orderResp) => {
            saveDeletedPaymentsLog(deletedPayments, orderResp)
            setDeletedPayments([])

            setOrder(orderResp)
            handleClose()
        }).catch((err) => {
            extractErrorMessage(err, "Tente novamente").then(msg => {
                enqueueSnackbar('Erro ao salvar pedido - Detalhes: ' + msg, { variant: "error" })
            })

            console.log('ERRO_SALVAR_PEDIDO: ', err)
        }).finally(() => {
            setSaving(false)
        })
    }

    const buscarPedidoPeloIdentificadorSecundario = () => {
        //busca no banco de dados uma lista de pedidos com identificador secundario igual ao da ordem aberta
        if (order.identificadorSecundario) {//se a ordem não possuir identificador secundario não faz a busca
            let service = new AuthService()

            service//.get faz a busca no banco de dados suas funções de busca do pedido integração são encontrados em PedidoIntegracaoAPI.java
                .get(`/api-v2/pedidoIntegracao/listByIdentificadorSecundario?identificadorSecundario=${order.identificadorSecundario}&tipos=${'COMANDA'}&importado=false&pago=NAO`)
                .then(pedidos => { //retorna do banco todos os pedidos com identificador secundario igual ao da ordem, inclusive o pedido da ordem                   

                    setPedidosSecundarios(pedidos)
                })
        }
    }

    const handleOnClickVerMesas = () => {
        if (order.identificadorSecundario) {//caso o identificador secundario exista pode executar essa operação
            let testCard = cardList.filter((card) => card.identificador.identificador === order.identificadorSecundario && card.identificador.tipo === 'MESA')//retorna uma lista, mas como só existe um identificador retorna uma lista com um elemento, depois é selecionado o elemento 0 da lista
            let identificadorCard = testCard[0] //Busca o card pelo identificador para enviar o identificador correto na exibição de pagamentos
            // a variável deve possuir o nome identificadorCard para listar os pedidos corretamente

            dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard } } })
            //abre a tela para listar todos os pedidos de uma comanda
        }
        //handleClose()//fecha a tela de pagamentos
    }

    const handleKeyPresValorPago = event => {
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault()
            handleClickAddPayment()

        }
    }

    function openModalServiceFee() {

        setOpenServiceFee(true)
    }

    function openModalListItensToReceive() {
        //Envia para o analytics a informação do clique no botão de dividir conta
        ReactGA.event({
            category: 'Button',
            action: 'Click dividir conta',
            label: 'Botão dividir conta pagamento'
        });
        setOpenListItemToReceive(true)
    }

    function openModalDiscount() {
        //Envia para o analytics a informação do clique no botão de desconto na tela de pagamento
        ReactGA.event({
            category: 'Button',
            action: 'Click desconto pagamento',
            label: 'Botão de desconto na tela de pagamento'
        });

        dispatch({ type: 'TOOGLE_DISCOUNT_DIALOG', discountOrder: { open: true, order } })
        setOpenDiscount(true)
    }

    /**
     * Remove o pagamento pendente
     * Não causa adversidades no TEF já que só é executado após adicionar o pagamento
     */
    const handleClickRemovePaymentTemp = () => {
        setPaymentSelected(null)
    }

    /*function handleChangePrintOrder(event) {
        const checked = event.target.checked
        const settingsSave = { ...settings, printWhenConfirmingPayment: checked }

        setSettings(settingsSave)
        saveSettings(settingsSave)
    }*/

    function handleChangeDoc(event) {
        let value = event.target.value;

        if (value.length > 80) {
            value = value.slice(0, 80)
        }
        setPaymentDoc(value);
    }

    const handleClickAddValueSelected = () => {
        paymentValueRef.current.value = valueSelectedItem
    }

    return (
        <div>
            {order ? (
                <StyledDialog open={open}
                    maxWidth={"md"}
                    fullWidth
                    onClose={handleClickCancel} >

                    <DialogTitle onClose={handleClickCancel} >
                        <HeaderDetailOrder order={order} />
                    </DialogTitle>

                    <DialogContent className={clsx([classes.scrollBar, classes.dialogPadding])}>

                        <Grid container>
                            <Grid item md={4} xs={12} className={clsx([classes.paymentList, classes.scrollBar, classes.backgroundColumn2])}>

                                {!totais.isPayed ? (
                                    <Box visibility={totais.isPayed ? 'hidden' : 'visible'}>
                                        {loading ? <CircularProgress /> : null}
                                        <List
                                            dense
                                            subheader={
                                                <ListSubheader component="div" id="nested-list-subheader">
                                                    Formas de pagamento
                                                </ListSubheader>
                                            }
                                        >
                                            {paymentOptions.map((payment, index) => (
                                                <ListItemButton
                                                    key={payment.id}
                                                    ref={el => paymentOptionsRef.current[index] = el}
                                                    onClick={() => handleClickPaymentType(payment)}
                                                >
                                                    <ListItemIcon>
                                                        {paymentIconByType(payment.conta.tipoConta)}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={payment.descricao.substring(0, 25)}
                                                    />
                                                    {payment?.utilizaTef ? (
                                                        <ListItemSecondaryAction>
                                                            <Chip label="TEF" size="small" color="primary" variant="outlined" />
                                                        </ListItemSecondaryAction>
                                                    ) : null}
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        flexGrow={1}
                                        alignItems="center"
                                        justifyContent="center"
                                        className={clsx([classes.paymentList])} >
                                            <Chip label="PEDIDO PAGO" icon={<DoneIcon />} className={classes.chipPayed} />
                                    </Box>
                                )}

                            </Grid>

                            <Grid item md={8} xs={12} className={clsx([classes.paymentList, classes.scrollBar])}>
                                <Box px={1}>
                                    <TotalOrder order={order} showPendingAmount={true} />
                                </Box>

                                <Box p={1} visibility={valueSelectedItem > 0 ? 'visible' : 'hidden'}>
                                    <Alert variant="outlined" severity="info" color="info"
                                        action={
                                            valueSelectedItem > 0 && paymentSelected && (
                                                <Button startIcon={<RefreshIcon />} color="inherit" size="small" onClick={handleClickAddValueSelected}>
                                                    Aplicar pagamento
                                                </Button>
                                            )
                                        }
                                    >
                                        {`A divisão da conta foi de ${currencyFormat(valueSelectedItem)}`}
                                    </Alert>
                                </Box>

                                <Box p={1} visibility={(pedidosSecundarios.length < 2) ? 'hidden' : 'visible'}>
                                    {/*caso a variavel pedidos secundarios tenha um tamanho menor que 2 não mostra a informação de mais pedidos na mesa*/}
                                    <Alert variant="outlined" severity="info" color="info"
                                        action={
                                            <Button color="inherit" size="small" onClick={handleOnClickVerMesas}>
                                                ver mesa {/*Abre o dialog de pedidos na mesa */}
                                            </Button>
                                        }
                                    >
                                        Existem outras comandas na mesa {order.identificadorSecundario}
                                    </Alert>

                                </Box>

                                <Box p={1} >
                                    {paymentSelected ?
                                        <Paper variant="outlined">

                                            <Box p={2} >
                                                <Grid container spacing={1}>

                                                    <Grid item xs={12} sm={5}>
                                                        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                                                            <Typography variant="body1">
                                                                {paymentSelected.name}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={3}>

                                                        <TextField
                                                            inputRef={paymentValueRef}
                                                            variant='outlined'
                                                            label='Valor pago'
                                                            name='paymentValue'
                                                            size='small'
                                                            type='number'
                                                            fullWidth
                                                            defaultValue={paymentSelected.value}
                                                            autoFocus
                                                            error={!!errors.iputPayValue}
                                                            helperText={errors.iputPayValue}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                            }}
                                                            onKeyUp={handleKeyPresValorPago} />
                                                    </Grid>

                                                    {/* <Grid item xs={12} sm={1}>
                                                        <IconButton color="secondary"
                                                            aria-label='opções'
                                                            size="small"
                                                            onClick={handleClickRemovePaymentTemp} >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid> */}
                                                    {!isMobile && (
                                                        <Grid item xs={12} sm={3}>
                                                            <Button
                                                                aria-label='opções'
                                                                variant="outlined"
                                                                color="primary"
                                                                fullWidth
                                                                disabled={loadingBalance}
                                                                onClick={handleClickAddPayment}
                                                                startIcon={!loadingBalance ? <AddIcon /> : null}> {!loadingBalance ? 'Adicionar' : 'Verificando...'}</Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Box>

                                            {paymentSelected?.formaPagamento?.pessoaRequerida &&
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={8}>
                                                        <CustomerSelector
                                                            size="large"
                                                            color="primary"
                                                            variant="contained"
                                                            customer={selectedCustomer}
                                                            setCustomer={setSelectedCustomer}
                                                            disableElevation
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={4}>
                                                        <Box p={1}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    disableToolbar
                                                                    format="dd/MM/yyyy"
                                                                    margin="normal"
                                                                    id="paymentDate"
                                                                    name="paymentDate"
                                                                    fullWidth
                                                                    inputVariant="outlined"
                                                                    label="Data de vencimento"
                                                                    value={dataVencimento}
                                                                    onChange={(date) => { setDataVencimento(date) }}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'Alterar período inicial',
                                                                    }}
                                                                    slotProps={{ textField: { size: 'small' } }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            }
                                            {(paymentSelected?.code === 'CC' || paymentSelected?.code === 'CD' || paymentSelected?.code === 'PX') && (
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box p={1}>
                                                            <TextField
                                                                variant='outlined'
                                                                label={paymentSelected?.code === 'PX' ? 'Aut. pix (opcional)' : 'Aut. cartão (opcional)'}
                                                                name='document'
                                                                size='small'
                                                                fullWidth={isMobile}
                                                                value={paymentDoc || ""}
                                                                onChange={handleChangeDoc}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {isMobile && (
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={3}>
                                                        <Box p={1}>
                                                            <Button
                                                                aria-label='opções'
                                                                variant="outlined"
                                                                color="primary"
                                                                fullWidth
                                                                disabled={loadingBalance}
                                                                onClick={handleClickAddPayment}
                                                                startIcon={!loadingBalance ? <AddIcon /> : null}> {!loadingBalance ? 'Adicionar' : 'Verificando...'}</Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Paper>
                                        :
                                        null}
                                </Box>

                                <Box p={1}>

                                    <TableContainer>
                                        <Table size='small' aria-label='pagamentos do pedido'>

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pagamentos realizados</TableCell>
                                                    <TableCell align="right">Valor pago</TableCell>
                                                    <TableCell ></TableCell>
                                                    <TableCell align="right"> </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {confirmedPayments.length === 0 ? (<TableRow><TableCell colSpan={4}>Nenhum pagamento realizado</TableCell></TableRow>) : null}
                                                {confirmedPayments.map((payment, index) => (
                                                    <TableRow selected key={index}>

                                                        <TableCell component="th" scope="row">
                                                            {payment.name}

                                                            {payment.dataOcorrencia && (
                                                                <Typography variant="caption" display="block" gutterBottom style={{ opacity: "0.5" }}>
                                                                    {dateFormat(dateParseISO(payment.dataOcorrencia))}
                                                                </Typography>
                                                            )}

                                                            {payment.username && (
                                                                <Typography variant="caption" display="block" gutterBottom style={{ opacity: "0.5" }}>
                                                                    {payment.username}
                                                                </Typography>
                                                            )}

                                                            {payment.customer && (
                                                                <Typography variant="caption" display="block" gutterBottom style={{ opacity: "0.5" }}>
                                                                    {payment.customer.name}
                                                                </Typography>
                                                            )}
                                                        </TableCell>

                                                        <TableCell style={{ width: 200 }} align='right'>
                                                            {currencyFormat(payment.value)}
                                                        </TableCell>

                                                        <TableCell align='center'>
                                                            {payment.changeFor ? (<Chip size="small" color="primary" variant="outlined" label={`TROCO ${currencyFormat(payment.changeFor - payment.value)}`} />) : null}
                                                        </TableCell>

                                                        <TableCell align='right'>
                                                            <IconButton color="secondary"
                                                                aria-label='opções'
                                                                size="small"
                                                                onClick={() => handleClickRemovePayment(payment)} >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>

                        <Grid container spacing={1}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">

                            <Grid item>
                                <Button color="grey" disabled={totais.isPayed} onClick={openModalListItensToReceive}>
                                    Dividir conta
                                </Button>
                            </Grid>

                            <Grid item>
                                {(((order.tipo === 'COMANDA' || order.tipo === 'MESA') || (order.tipo === 'BALCAO' && TAXA_SERVICO_PEDIDO_BALCAO)) && UTILIZA_TAXA_SERVICO === 'S') &&
                                    <Button
                                        color="grey"
                                        disabled={totais.isPayed}
                                        onClick={openModalServiceFee}>
                                        Tx. Serviço
                                    </Button>
                                }

                                {order.tipo === 'DELIVERY' &&
                                    <Button
                                        color="grey"
                                        disabled={totais.isPayed}
                                        onClick={() => setOpenDeliveryFee(true)}>
                                        Tx. Entrega
                                    </Button>
                                }

                                {/* {(order?.tipo === 'BALCAO' && TAXA_SERVICO_PEDIDO_BALCAO) && (
                                    <Grid item>
                                        <Button
                                            color="grey"
                                            onClick={event => setAnchorElMaisOpcoes(event.currentTarget)}>
                                            Taxas
                                        </Button>
                                        <Menu id="mais-opcoes"
                                            anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            anchorEl={anchorElMaisOpcoes}
                                            keepMounted
                                            open={Boolean(anchorElMaisOpcoes)}
                                            onClose={event => setAnchorElMaisOpcoes(null)}>

                                            <MenuItem>
                                                <ListItemIcon>
                                                    <Button
                                                        color="grey"
                                                        disabled={totais.isPayed}
                                                        onClick={openModalServiceFee}>
                                                        Tx. Serviço
                                                    </Button>
                                                </ListItemIcon>
                                            </MenuItem>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <Button
                                                        color="grey"
                                                        disabled={totais.isPayed}
                                                        onClick={() => setOpenDeliveryFee(true)}>
                                                        Tx. Entrega
                                                    </Button>
                                                </ListItemIcon>
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                )} */}
                            </Grid>

                            <Grid item>
                                <Button
                                    color="grey"
                                    size='small'
                                    disabled={disableDiscount} //Adicionar verificação do botão desconto para deixar habilitado quando não há pagamentos
                                    onClick={openModalDiscount}
                                    endIcon={<MoneyOffIcon />}>
                                    Desconto
                                </Button>
                            </Grid>

                            {/*<Grid item >
                                <IconButton
                                    aria-controls="mais-opcoes"
                                    aria-haspopup="true"
                                    size="small"
                                    onClick={event => setAnchorElMaisOpcoes(event.currentTarget)}>
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id="mais-opcoes"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    anchorEl={anchorElMaisOpcoes}
                                    keepMounted
                                    open={Boolean(anchorElMaisOpcoes)}
                                    onClose={event => setAnchorElMaisOpcoes(null)}
                                >
                                    <MenuItem >
                                        <ListItemIcon>
                                            <FormControlLabel control={
                                                <Checkbox name="printOrder" checked={settings.printWhenConfirmingPayment} onChange={handleChangePrintOrder} />
                                            } label="Imprimir ao confirmar" />
                                        </ListItemIcon>
                                    </MenuItem>
                                </Menu>
                            </Grid>*/}

                            <Grid item >
                                <Box component="span">
                                    <ButtonGroup variant="contained" color="secondary" disableElevation >
                                        <Tooltip title="Confirmar - Atalho F10" aria-label="Confirmar">
                                            <Button
                                                ref={btnConfirmRef}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                onClick={() => { confirmPayment(false) }}
                                                disabled={paymentSelected !== null || saving}>Confirmar
                                                <DoneIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Confirmar e Imprimir - Atalho F9" aria-label="Confirmar e Imprimir">
                                            <Button
                                                ref={btnConfirmAndPrintRef}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    confirmPayment(true);
                                                }}
                                                disabled={paymentSelected !== null || saving}>
                                                <PrintIcon />
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                </Box>
                            </Grid>

                        </Grid>

                    </DialogActions>

                </StyledDialog>
            ) : null
            }

            <Discount open={openDiscount} setOpen={setOpenDiscount} discountOrder={totalDiscountItems(order?.iFoodOrder)} removePendingPayment={handleClickRemovePaymentTemp} />
            <ListItemReceive open={openListItemToReceive} setOpen={setOpenListItemToReceive} setValueSelectedItem={setValueSelectedItem} />
            <ServiceFee open={openServiceFee} setOpen={setOpenServiceFee} order={order} />
            <DeliveryFee open={openDeliveryFee} setOpen={setOpenDeliveryFee} order={order} />
        </div>
    );
}
