import React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, DialogActions } from "../layout-components/CustomDialog/dialogComponents";
import { Button, Grid } from '@mui/material';
import { getSettings, saveSettings } from './settings';

export default function VerifyMobile({ open, setOpen, }) {

    const handleChangeMobileConfiguration = (value) => {
        var settingsAux = getSettings();
        settingsAux.enableMobilePDV = value;
        saveSettings(settingsAux);
        setOpen(!open);
    }

    const handleClose = () => {
        var settingsAux = getSettings();
        settingsAux.enableMobilePDV = 'false';
        saveSettings(settingsAux);
        setOpen(!open)
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth='xs'
                open={open}
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle onClose={handleClose}>
                    Deseja utilizar o PDV no modo ?
                </DialogTitle>

                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}>

                        <Grid item >
                            <Button
                                onClick={() => { handleChangeMobileConfiguration('true') }}
                                size="small"
                                color="primary"
                                variant="contained"
                                value='true'
                                label="mobile"
                            >
                                Mobile
                            </Button>
                        </Grid>

                        <Grid item >
                            <Button
                                label="desktop"
                                value='false'
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => { handleChangeMobileConfiguration('false') }}
                            >
                                Desktop
                            </Button>
                        </Grid>

                    </Grid>

                </DialogActions>

            </Dialog>
        </React.Fragment >
    );
}