import React, { useState } from 'react'

import { Typography } from '@mui/material'
import DefaultDialogLayout from './DefaultDialogLayout'

export default function ConfirmDialog({ open, content, maxWidth, cancelLabel, showCancelButton, contentAlignment = 'center', confirmLabel, onClose }) {
    const [isOpen, setIsOpen] = useState(open)
    const [confirmed, setConfirmed] = useState(false)

    function cancel() {
        setIsOpen(false)
    }

    function confirm() {
        setIsOpen(false)
        setConfirmed(true)
    }

    function close() {
        onClose(confirmed)
    }

    return (
        <DefaultDialogLayout
            open={isOpen}
            maxWidth={maxWidth}
            onClose={close}
            onCancel={cancel}
            onConfirm={confirm}
            showCancelButton={showCancelButton}
            cancelLabel={cancelLabel}
            confirmLabel={confirmLabel}>

            <Typography align={contentAlignment}>
                <span dangerouslySetInnerHTML={{ __html: content }} />
            </Typography>

        </DefaultDialogLayout>
    )
}