import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Parameter from '../../component/parameter';
import LinearProgress from '@mui/material/LinearProgress';
import { Container, CssBaseline, Box } from '@mui/material';
import Sistema from '../../component/generics/sistema';
import GenericCode from '../../component/genericCode';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  const [loadingParams, setLoadingParams] = useState(false)
  const [loadingGenericCode, setLoadingGenericCode] = useState(false)

  //const [loadingAsyncRscsComplete, setLoadingAsyncRscsComplete] = useState(false)

  useEffect(()=>{
    ReactGA.initialize('UA-18260904-4', { debug: false });
  },[])

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[Component])


  return (
    <Route
      {...rest}
      render={matchProps => (
        <React.Fragment>

          {(loadingParams && loadingGenericCode) ? (
          <Layout>
          <Component  {...matchProps} />
          </Layout>
        ) : (
          <Container maxWidth="sm">
            <CssBaseline />
            <Box pt={4}>
              <Box py={2}>
                <LinearProgress color="secondary"/>
              </Box>
              😎 Carregando o PDV Sischef... 
            </Box>
          </Container>
        )}

        <Sistema />
          <Parameter setComplete={setLoadingParams} />
          <GenericCode setComplete={setLoadingGenericCode} />
        </React.Fragment>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  layoutTop: PropTypes.any,
  path: PropTypes.string
};

export default RouteWithLayout;