import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Button, Typography, FormControlLabel, Checkbox, Box, LinearProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { DialogTitle, DialogContent, DialogActions } from "../CustomDialog/dialogComponents"
import AuthService from '../../../service/AuthService';
import OrderImportItens from "./OrderImportItens"
import OrderImportPayments, { handleClickVerificarPagamento } from "./OrderImportPayments"
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from "../../../util/http/HttpUtil"
import { createCardFromPedido, getTipoImportacaoBySigla } from '../../../util/board/BoardUtil'
import DoneIcon from '@mui/icons-material/Done';
import { HeaderDetailOrder } from '../DetailOrder';
import OrderImportTaxFee from './OrderImportTaxFee';
import { ifAnyGrantedUnidade } from '../../../util/auth/authUtil';
import TotalOrder from '../NewOrder/pages/TotalOrder';
import { totalOrder, getBenefitsApp } from '../../../util/order/orderUtil';
import { currencyFormat } from '../../../util/formatter';
import { Skeleton } from '@mui/material';

const PREFIX = 'ImportOrder'

const classes = {
  scrollBar: `${PREFIX}-scrollBar`,
  productFounded: `${PREFIX}-productFounded`,
  productNotFound: `${PREFIX}-productNotFound`,
  rootTableRow: `${PREFIX}-rootTableRow`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.scrollBar}`]: theme.scrollBar,

  [`& .${classes.productFounded}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.productNotFound}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.rootTableRow}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
  }
}))

export default function ImportOrder() {
  const dispatch = useDispatch()
  const open = useSelector(state => state.importOrder.open)
  const cardImport = useSelector(state => state.importOrder.card)
  //const orderId = useSelector(state => state.importOrder.card?.id)
  const [loadingOrder, setLoadingOrder] = useState(false)
  const [order, setOrder] = useState(null)
  const [sending, setSending] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const { enqueueSnackbar } = useSnackbar();
  const [generateInvoice, setGenerateInvoice] = useState(localStorage.getItem('GENERATE_INVOICE_FINISH_IMPORT') === 'true' ? true : false)
  const ROLE_NOTA_FISCAL = ifAnyGrantedUnidade('ROLE_NOTA_FISCAL')

  useEffect(() => dispatch({ type: 'UPDATE_PAYMENT_SCREENS', paymentScreensStatus: { import: open ? 'OPEN' : 'CLOSED' } }), [open])

  useEffect(() => {

    if (!cardImport) {
      setOrder(null)
      return;
    }

    let orderId = cardImport.data.id;
    //busca o pedido na API para iniciar a integracao

    //if (!orderId) return;

    setLoadingOrder(true)
    let service = new AuthService()
    service.get(`/api-v2/pedidoIntegracao/prepararIntegracao/${orderId}`)
      .then(pedidoIntegracao => {
        setOrder(pedidoIntegracao)
      }).catch(err => {
        console.error(err)
        extractErrorMessage(err, "Falha ao tentar preparar integração " + orderId)
          .then(msg => {
            enqueueSnackbar(msg, { variant: "error" });
          })

      }).finally(() => {
        setLoadingOrder(false)
      })

    // eslint-disable-next-line 
  }, [cardImport]);

  const setOpen = (open) => {
    dispatch({ type: 'TOOGLE_IMPORT_ORDER_DIALOG', importOrder: { open } })
  }

  const handleClose = () => {
    if (!sending) {
      setDisableButton(true);
      setOpen(false);
    }  
  };

  const handleClickGerarNFCe = (orderTemp) => {

    if (!ROLE_NOTA_FISCAL) {
      enqueueSnackbar(`Permissão negada para emissão de documentos fiscais`, { variant: "warning" });
      return
    }

    if (!orderTemp.dataImportacao) {
      enqueueSnackbar(`Pedido #${orderTemp.id} precisa estar importado para gerar uma NFC-e/NF-e`, { variant: "warning" });
      return
    }

    dispatch({ type: 'TOOGLE_DIALOG_INVOICE', detailDialogInvoice: { open: true, order: orderTemp } })
  }

  const handleVerificaImport = () => { // verifica os métodos de pagamento para confirmar se todos são validos, possuem descrição e tem valor maior que 0
    
    let liberarImport = null;

    order.iFoodOrder.payments.map(payment => { //mapeia os pagamentos e faz a verificação unitária deles
      let verificado = handleClickVerificarPagamento(payment, order) //faz a verificação do pagamento e retorna true, false ou undefined

      if (verificado) { // vaso seja true chama o método de confirmar importação
        if (liberarImport !== false) {
          liberarImport = true;
        }
      } else if (verificado === false) { // caso seja falso o valor do pagamento é menor ou igual a zero
        enqueueSnackbar("Não é possível adicionar pagamentos com valor igual ou menor que zero", { variant: "error" });
        liberarImport = false;
      } else { // e caso não seja nem true e nem false é por que é undefined e o campo descrição do pagamento está vazio
        enqueueSnackbar("Não é possível adicionar pagamentos com descrição vazia", { variant: "error" });
        liberarImport = false;
      }
      return null;
    })

    if (liberarImport) { //caso não tenha problema com algum dos pagamentos é chamada a função para importar o pedido
      //handleConfirmImport()
      handleValidatePayment()
    }
  }

  const handleConfirmImport = () => {
    setSending(true)

    localStorage.removeItem('_fast_sale_')

    const service = new AuthService()
    service.post(`/api-v2/pedidoIntegracao/finalizarIntegracao/`, order)
      .then(pedidoIntegracao => {


        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

        let updatedCard = createCardFromPedido(pedidoIntegracao)
        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: false, card: updatedCard } })

        setSending(false)

        if (ROLE_NOTA_FISCAL && generateInvoice) {
          handleClickGerarNFCe(pedidoIntegracao)
        }
        setDisableButton(true)
        handleClose()
      }).catch(err => {
        setSending(false)
        console.error(err)
        extractErrorMessage(err, "Falha desconhecida ao tentar integrar pedido #" + order.id)
          .then(msg => {
            enqueueSnackbar(msg, { variant: "error" });
          })
      })
  };

  const handleChange = (event) => {
    setGenerateInvoice(event.target.checked);
  };

  // useEffect(() => {
  //   localStorage.setItem('GENERATE_INVOICE_FINISH_IMPORT', generateInvoice)
  // }, [generateInvoice])

  useEffect(() => {
    if (order && open) {
      setDisableButton(false)
      const tipoDoPedido = getTipoImportacaoBySigla(order.tipoImportacao)
      if (!tipoDoPedido.integracao) {
        handleConfirmImport()
      }
    }
    // eslint-disable-next-line
  }, [order])

  //Validação comitada até que seja achado uma forma validar os valores pagaos dos pedidos de integração
  const handleValidatePayment = () => {

    let valorPago = 0.0

    order.iFoodOrder.payments.map(payment => { //mapeia os pagamentos e soma seus valores

      let pagamento = payment.value ? payment.value : 0.0;

      valorPago += parseFloat(pagamento)
      return null;

    })

    let totalPedido = totalOrder(order?.iFoodOrder); //pega o valor total do pedido ou 0
    const descontoApp = getBenefitsApp(order?.iFoodOrder)
    const diff = Math.abs(parseFloat(valorPago + descontoApp - totalPedido))

    console.log(`descontoApp: ${descontoApp} | valorPago: ${valorPago} | totalPedido: ${totalPedido} | diff: ${diff}`)

    if (diff > 0.009) { //caso o valor total seja diferente do total do item + taxas não deixa importar
      enqueueSnackbar(`Existe uma diferença de ${currencyFormat(diff)} entre o valor do pagamento e o valor total do pedido`, { variant: 'error' });

      return
    }

    handleConfirmImport()
  }

  return (
    <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title"
      open={open} fullWidth maxWidth="sm" >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <HeaderDetailOrder order={order} />
      </DialogTitle>

      <DialogContent className={classes.scrollBar}>
        {loadingOrder ? (
          <Skeleton variant="rectangular" height={60} />
        ) : null}

        {order && !loadingOrder && getTipoImportacaoBySigla(order.tipoImportacao).integracao ? (
          <React.Fragment>
            <OrderImportItens order={order} />
            <OrderImportPayments order={order} setOrder={setOrder} />
            <OrderImportTaxFee order={order} setOrder={setOrder} />
          </React.Fragment>
        ) : (
          <React.Fragment>
              {
                !loadingOrder && (
                  <Typography variant="body1" gutterBottom>
                    O pedido está sendo importado...
                  </Typography>
                )
              }


            {sending && (
              <LinearProgress />
            )}

          </React.Fragment>
        )}

        <Box py={1}>
          <TotalOrder order={order} />
        </Box>

      </DialogContent>

      <DialogActions>
        {ROLE_NOTA_FISCAL &&
          <FormControlLabel
            control={
              <Checkbox
                checked={generateInvoice}
                onChange={handleChange}
                name='generateInvoice'
                size='small'
                color='primary'
              />
            }
            label='Gerar nota fiscal ao confirmar'
          />
        }

        <Button
          onClick={handleVerificaImport} color="primary"
          variant="contained" disabled={sending || disableButton}
          endIcon={<DoneIcon />}>
          {sending ? 'Confirmando...' : 'Confirmar'}
        </Button>

      </DialogActions>

    </StyledDialog>
  )
}

