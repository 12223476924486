import React, { useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Checkbox, TextField, Box, Grid, InputAdornment, ListItemIcon, List, ListItemText, Typography, Switch, ListItemButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { isValidDate } from '../../../../util/formatter';
import { formatFullAddress } from '../../../../util/customer/helper';
import ComponentAdditionalInformation from '../../AdditionalInformation/Component';
import { isAuthorized } from '../../../../routes';
import ptBRLocaleAdapter from 'date-fns/locale/pt-BR';
import { ptBR } from '@mui/x-date-pickers/locales';
import debounce from 'lodash/debounce';
import { useLoadEntregadores } from '../../../deliveryman/components/LoadDeliveryMan';

const ptBRLocale = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

const ScheduleDate = function ({ order, setOrder }) {
    const orderDataPedido = new Date(order.dataPedido)

    const handleDateChange = (date, tipo) => {
        if (isValidDate(date)) {
            let orderTemp = { ...order }
            let newDate = new Date(date)

            if (orderTemp.dataPedido) {
                newDate = new Date(orderTemp.dataPedido)
            }

            if (tipo === 'DATE' && date) {
                newDate.setUTCDate(date.getUTCDate())
                newDate.setUTCMonth(date.getUTCMonth())
                newDate.setUTCFullYear(date.getUTCFullYear())
            }

            if (tipo === 'TIME' && date) {
                //newDate.setUTCHours(date.getUTCHours()) Utilizando esse parâmetro o dia era alterado conforme o horário
                newDate.setHours(date.getHours())
                newDate.setUTCMinutes(date.getUTCMinutes())
            }

            orderTemp.dataPedido = newDate;
            setOrder(orderTemp)
        }
    }

    const deboucedOnChange = useMemo(() => debounce(handleDateChange, 300), [handleDateChange])

    return (
        <Box display="flex" pt={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocaleAdapter} localeText={ptBRLocale}>

                <Box flexGrow={1} pr={1}>
                    <DatePicker
                        size="small"
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-dialog-pedidoAgendado"
                        label="Data do agendamento"
                        format="dd/MM/yyyy"
                        defaultValue={orderDataPedido}
                        onChange={date => deboucedOnChange(date, 'DATE')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }} />
                </Box>

                <Box flexGrow={1} >
                    <TimePicker
                        size="small"
                        margin="normal"
                        inputVariant="outlined"
                        id="time-picker-pedidoAgendado"
                        label="Hora do agendamento"
                        ampm={false}
                        defaultValue={orderDataPedido}
                        onChange={date => deboucedOnChange(date, 'TIME')}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                        }}
                        slotProps={{
                            popper: { placement: "top" }
                        }} />
                </Box>
            </LocalizationProvider>
        </Box>
    )
}

function MoreInfoOrder({ setOrder, order, customer, listDeliveryFee }) {

    const [checkAddress, setCheckAddress] = useState(undefined);
    const [listAddress, setListAddress] = useState([]);
    const [info, setInfo] = useState({})
    const [deliveryFee, setDeliveryFee] = useState(listDeliveryFee)
    const [listDeliveryMan] = useLoadEntregadores()

    useEffect(() => {
        /** SE PEDIDO BALCAO E NAO SALVO - SETA PARA RETIRADA COMO DEFAULT */
        if (order.tipo === 'BALCAO' && !order.id) {
            setDeliveryModeToOrder('TAKEOUT')
        }

        setInfo({ observations: order.iFoodOrder.observations, complementoIdentificador: order.complementoIdentificador, quantidadePessoas: order.quantidadePessoas })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {//sempre que ocorre alteração nos parametros reordena as taxas de entrega
        var taxaDelivery = orderProductsDelivery(listDeliveryFee)
        setDeliveryFee(taxaDelivery)
        // eslint-disable-next-line
    }, [deliveryFee]);

    useEffect(() => {
        let orderCopy = Object.assign({}, order)

        orderCopy.iFoodOrder.observations = info.observations
        orderCopy.complementoIdentificador = info.complementoIdentificador
        orderCopy.quantidadePessoas = info.quantidadePessoas

        setOrder(orderCopy)
        // eslint-disable-next-line
    }, [info])

    const handleSelectAddress = (address) => () => {
        setCheckAddress(address);
    };

    function orderProductsDelivery(produtosTaxa) {//ordena as taxas de entrega por ordem alfabética
        var taxasOrdenadas = produtosTaxa //recebe a lista de taxas de entrega


        taxasOrdenadas && taxasOrdenadas.sort(function (a, b) { //de acordo com o calculo abaixo ordena o item na lista
            return (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)
        })

        return taxasOrdenadas
    }

    function getProductDeliveryFeeByID(id) {
        if (listDeliveryFee) {
            // eslint-disable-next-line
            return listDeliveryFee.find(element => element.id == id)
        }
        return null
    }

    function handleCheckTakeOut(event) {

        const takeOut = event.target.checked
        let deliveryMode = 'DELIVERY'

        if (takeOut) {
            deliveryMode = 'TAKEOUT'
        }

        setDeliveryModeToOrder(deliveryMode)
    };

    function handleCheckAgendamento(event) {

        const pedidoAgendado = event.target.checked

        let orderTemp = Object.assign({}, order)

        if (pedidoAgendado) {
            orderTemp.pedidoAgendado = 'AGENDADO'
            orderTemp.iFoodOrder.scheduled = true
        } else {
            orderTemp.pedidoAgendado = ""
            orderTemp.iFoodOrder.scheduled = false
        }

        setOrder(orderTemp)
    };

    function setDeliveryModeToOrder(deliveryMode) {
        let orderTemp = Object.assign({}, order)
        let deliveryMethod = { mode: deliveryMode }
        const takeOut = deliveryMode === 'TAKEOUT'

        if (takeOut) {
            deliveryMethod.mode = 'TAKEOUT'

            orderTemp.iFoodOrder.levarMaquinaCartao = false
            orderTemp.iFoodOrder.deliveryAddress = undefined
            orderTemp.iFoodOrder.deliveryMan = undefined
            orderTemp.iFoodOrder.deliveryFee = 0
            orderTemp.iFoodOrder.productDeliveryFee = undefined
            orderTemp.iFoodOrder.levarTrocoPara = 0

            setCheckAddress(undefined)
        } else {
            const addressTemp = listAddress.find(element => element.id === -1)

            if (addressTemp)
                setCheckAddress(addressTemp)
        }

        orderTemp.iFoodOrder.deliveryMethod = deliveryMethod
        setOrder(orderTemp)
    }

    function handleSelectDeliveryFee(value) {
        let orderTemp = Object.assign({}, order)
        if (value) {
            orderTemp.iFoodOrder.deliveryFee = value.valorUnitarioVenda
            orderTemp.iFoodOrder.productDeliveryFee = value
        } else {
            orderTemp.iFoodOrder.deliveryFee = 0.0
            orderTemp.iFoodOrder.productDeliveryFee = null
        }

        setOrder(orderTemp)
    };

    const handleChangeMoreInfo = (event) => {
        let orderTemp = Object.assign({}, order)

        if (event.target.type === 'checkbox') {
            orderTemp.iFoodOrder[event.target.name] = event.target.checked
        } else if (event.target.type === 'textarea' || event.target.type === 'number') {
            orderTemp.iFoodOrder[event.target.name] = event.target.value
        }

        setOrder(orderTemp)
    }

    function handleSelectDeliveryMan(value) {

        let orderTemp = Object.assign({}, order)
        orderTemp.pessoaEntregador = value

        setOrder(orderTemp)
    }

    useEffect(() => {
        if (checkAddress && checkAddress !== undefined && checkAddress !== null) {
            if (order?.tipo === 'COMANDA' || order?.tipo === 'MESA') {
                return
            }

            let deliveryAddress = {}

            deliveryAddress.id = checkAddress.id
            deliveryAddress.city = checkAddress.municipio?.nome
            deliveryAddress.reference = checkAddress.reference
            deliveryAddress.complement = checkAddress.complemento

            if (checkAddress.endereco) {
                deliveryAddress.formattedAddress = checkAddress.endereco + ', Nr ' + checkAddress.numero
            }

            deliveryAddress.neighborhood = checkAddress.bairro
            deliveryAddress.postalCode = checkAddress.cep
            deliveryAddress.state = checkAddress.estado?.sigla
            deliveryAddress.streetName = checkAddress.endereco
            deliveryAddress.streetNumber = checkAddress.numero
            deliveryAddress.idProdutoTaxaEntrega = checkAddress.idProdutoTaxaEntrega

            let orderTemp = Object.assign({}, order)
            orderTemp.iFoodOrder.deliveryAddress = deliveryAddress

            if (order && order.id > 0) {
                //NAO ALTERA SE PEDIDO JA FOI SALVO
            } else {
                const pdf = getProductDeliveryFeeByID(checkAddress.idProdutoTaxaEntrega)
                if (pdf) {
                    orderTemp.iFoodOrder.deliveryFee = pdf.valorUnitarioVenda
                    orderTemp.iFoodOrder.productDeliveryFee = pdf
                }
            }

            setOrder(orderTemp)

        }
        // eslint-disable-next-line
    }, [checkAddress])

    useEffect(() => {
        let addressTemp = Object.assign([], customer?.enderecos)
        let address = {}

        if (customer) {
            address.id = -1
            address.endereco = customer.endereco
            address.bairro = customer.bairro
            address.numero = customer.numero
            address.complemento = customer.complemento
            address.cep = customer.cep
            address.estado = customer.estado
            address.municipio = customer.municipio
            address.idProdutoTaxaEntrega = customer.servicoProduto

            addressTemp.push(address)
        } else {
            addressTemp = []
        }

        setListAddress(addressTemp)
    }, [customer])


    useEffect(() => {
        if (listAddress && listAddress.length > 0) {

            const addressOrder = order?.iFoodOrder?.deliveryAddress
            const takeOut = order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'

            //tem endereço no pedido então deve selecionar o pedido independente se cliente vai retirar
            if (addressOrder && !!addressOrder.id) {
                const selectedAddress = listAddress.find(element => element.id === addressOrder.id)
                setCheckAddress(selectedAddress)
            }
            //caso o pedido não tenha endereço de entrega então é verificado se o cliente vai retirar
            else if (takeOut) {
                setCheckAddress(undefined)
            }
            // caso não tenha endereço no pedido e não está marcado para retirar então e atribuido o endereço do cadastro do cliente no pedido
            else {
                if (listAddress.length === 1) {
                    setCheckAddress(listAddress[0])
                } else {
                    setCheckAddress(undefined)
                }
            }
        }
        // eslint-disable-next-line
    }, [listAddress])



    return (
        <Box
            px={2}
            display="flex"
            flexDirection='column'
            height='100%'>

            {(order.tipo === 'COMANDA' || order.tipo === 'MESA') &&
                <Box paddingBottom={3}>
                    <Grid container spacing={2} direction='row' alignItems='center'>
                        <Grid item xs={12}>
                            <ComponentAdditionalInformation info={info} setInfo={setInfo} />
                        </Grid>
                    </Grid>
                </Box>
            }

            {(order.tipo === 'DELIVERY' || order.tipo === 'BALCAO') &&
                <Box>
                    <Grid item xs={12}>
                        <TextField
                            id="observations"
                            label="Observação do pedido"
                            multiline
                            fullWidth
                            maxRows={4}
                            minRows={2}
                            name='observations'
                            defaultValue={order?.iFoodOrder?.observations}
                            onBlur={handleChangeMoreInfo}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid container
                        spacing={2}
                        direction='row'>

                        <Grid item >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'}
                                        onChange={handleCheckTakeOut}
                                        name="takeOut"
                                        color="primary" />
                                }
                                label="Cliente vai retirar o pedido pessoalmente"
                            />
                        </Grid>

                        <Grid item xs={12}>

                            <Typography variant="h6" align="center">
                                Endereços de entrega
                            </Typography>

                            {customer ?
                                (
                                    <List>
                                        {listAddress.map((address) => {
                                            let formatedAddress = formatFullAddress(address)

                                            return (
                                                <ListItemButton disabled={order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'}
                                                    dense
                                                    disableGutters
                                                    key={address.id}
                                                    role={undefined}
                                                    onClick={handleSelectAddress(address)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            size='small'
                                                            edge="start"
                                                            checked={checkAddress?.id === address.id}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={formatedAddress?.address}
                                                        secondary={formatedAddress?.complement} />
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    <Typography variant="overline" gutterBottom align='center' display="block">
                                        Informe um cliente para poder selecionar um endereço de entrega
                                    </Typography>
                                )
                            }

                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <FormControlLabel disabled={order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'}
                                checked={order?.iFoodOrder?.levarMaquinaCartao || false}
                                control={<Checkbox color="primary" />}
                                onChange={handleChangeMoreInfo}
                                label="Levar máquina de cartão"
                                labelPlacement="end"
                                name='levarMaquinaCartao'
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>

                            <TextField
                                disabled={order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'}
                                fullWidth
                                variant='outlined'
                                label='Levar troco para'
                                defaultValue={order?.iFoodOrder?.levarTrocoPara || ''}
                                onChange={handleChangeMoreInfo}
                                name='levarTrocoPara'
                                type='number'
                                size='small'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                }}
                            />
                        </Grid>


                        <Grid item xs={12}>

                            <Autocomplete
                                disabled={order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'}
                                size='small'
                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                value={order?.iFoodOrder?.productDeliveryFee || null}
                                onChange={(event, newInputValue) => { handleSelectDeliveryFee(newInputValue) }}
                                autoHighlight
                                id="deliveryFee"
                                options={deliveryFee || []}
                                getOptionLabel={(option) => option ? `${option.descricao} | R$ ${option.valorUnitarioVenda?.toFixed(2)}` : ''}
                                renderOption={(props, option) => <li {...props} key={option.id}>
                                    {option ? `${option.descricao} | R$ ${option.valorUnitarioVenda?.toFixed(2)}` : ''}
                                </li>}
                                renderInput={(params) => <TextField {...params} label="Informe a taxa de entrega" variant="outlined" />}
                            />

                        </Grid>

                        <Grid item xs={12}>
                            {/* <SelectDeliveryman
                            handleClose={() => setOpenDialogEntregador(false)}
                            open={openDialogEntregador}
                            pedidoIntegracao={order}
                            onConfirm={onConfirmSelectDialog}
                        /> */}


                            <Autocomplete
                                disabled={order?.iFoodOrder?.deliveryMethod?.mode === 'TAKEOUT'}
                                size='small'
                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                value={order?.pessoaEntregador || null}
                                onChange={(event, newInputValue) => { handleSelectDeliveryMan(newInputValue) }}
                                autoHighlight
                                id="deiveryMan"
                                options={listDeliveryMan || []}
                                getOptionLabel={(option) => option ? `${option.nome}` : ''}
                                renderOption={(props, option) => <li {...props} key={option.id}>{option.nome}</li>}
                                renderInput={(params) => <TextField {...params} label="Selecione o entregador" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>


                    {isAuthorized('ROLE_PDV_PEDIDOS_AGENDADOS') &&
                        <Grid container spacing={2} direction='row' alignItems='center'>

                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={order?.pedidoAgendado === 'AGENDADO'}
                                            onChange={handleCheckAgendamento}
                                            name="pedidoAgendado"
                                            color="primary" />
                                    }
                                    label="Pedido agendado"
                                />
                            </Grid>

                            {order?.pedidoAgendado === 'AGENDADO' && (
                                <Grid item xs={12} sm={8}>
                                    <ScheduleDate order={order} setOrder={setOrder} />
                                </Grid>)}

                        </Grid>
                    }

                </Box>
            }

            {order.tipo === 'CAIXA_RAPIDO' && (
                <Box>
                    <Grid item xs={12}>
                        <TextField
                            id="observations"
                            label="Observação do pedido"
                            multiline
                            fullWidth
                            maxRows={4}
                            minRows={2}
                            name='observations'
                            defaultValue={order?.iFoodOrder?.observations}
                            onBlur={handleChangeMoreInfo}
                            variant="outlined"
                        />
                    </Grid>
                </Box>
            )}

        </Box >
    );

}

export default MoreInfoOrder;