import React, { memo, useMemo } from 'react'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material'
import TypeOrderAvatar from './typeOrderAvatar'
import { currencyFormat, dateFormat, dateFormatDistance, dateParseISO } from '../../../util/formatter'
import PaymentIcon from '@mui/icons-material/Payment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import RefreshIcon from '@mui/icons-material/Refresh'
import PinDropIcon from '@mui/icons-material/PinDrop'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import EventIcon from '@mui/icons-material/Event'
import CancelIcon from '@mui/icons-material/Cancel'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports'
import { convertOrderAddressToCustomerAddress, formatFullAddress } from '../../../util/customer/helper'
import { AGENDADO_COLOR, CONSUMIR_LOCAL_COLOR, LOGISTICS_SENT, TAKEOUT_COLOR } from '../../../theme/palette'
import IntegrationAvatar from './IntegrationAvatar'
import PulseBadge from '../elements/PulseBadge'
import StorefrontIcon from '@mui/icons-material/Storefront'
import PlaceIcon from '@mui/icons-material/Place'
import { totalOrder } from '../../../util/order/orderUtil'
import AuthService from '../../../service/AuthService'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'

const PREFIX = 'CardBoard'

const classes = {
    root: `${PREFIX}-root`,
    cardDragging: `${PREFIX}-cardDragging`,
    cardImported: `${PREFIX}-cardImported`,
    cardFounded: `${PREFIX}-cardFounded`,
    cardNotFounded: `${PREFIX}-cardNotFounded`,
    cardHeader: `${PREFIX}-cardHeader`,
    cardHeaderText: `${PREFIX}-cardHeaderText`,
    cardHeaderSubtile: `${PREFIX}-cardHeaderSubtile`,
    cardContent: `${PREFIX}-cardContent`,
    cardIcon: `${PREFIX}-cardIcon`,
    cardListItem: `${PREFIX}-cardListItem`,
    avatarEmpresa: `${PREFIX}-avatarEmpresa`,
    canceledColor: `${PREFIX}-canceledColor`,
    infoPaymentColor: `${PREFIX}-infoPaymentColor`,
    fromNowStyle: `${PREFIX}-fromNowStyle`
}

const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.root}`]: {
        //maxWidth: 300,
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.personal.boardCard,
        cursor: "grab",
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        willChange: "opacity"
    },

    [`&.${classes.cardDragging}`]: {
        outline: `1px dashed ${theme.palette.primary.main}`,
    },

    [`&.${classes.cardImported}`]: {
        opacity: 0.4
    },

    [`&.${classes.cardFounded}`]: {
        opacity: 1,
        boxShadow: `inset 0 0 3px ${theme.palette.primary.main}`
    },

    [`&.${classes.cardNotFounded}`]: {
        opacity: 0.1,
        display: 'none',
    },

    [`& .${classes.cardHeader}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: 0,
    },

    [`& .${classes.cardHeaderText}`]: {
        fontSize: 14
    },

    [`& .${classes.cardHeaderSubtile}`]: {
        fontSize: 11,
    },

    [`& .${classes.cardContent}`]: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    },

    [`& .${classes.cardIcon}`]: {
        minWidth: '24px'
    },

    [`& .${classes.cardListItem}`]: {
        paddingTop: "2px",
        paddingBottom: "0",
    },

    [`& .${classes.avatarEmpresa}`]: {
        color: '#fff',
        width: theme.spacing(4),
        height: theme.spacing(4),
    },

    [`& .${classes.canceledColor}`]: {
        color: theme.palette.error.main
    },

    [`& .${classes.infoPaymentColor}`]: {
        color: theme.palette.success.main
    },

    [`& .${classes.fromNowStyle}`]: {
        color: theme.palette.mode === 'light' ? '#222f39' : 'white',
    }
}))

const CardBoardSubtitle = memo(function CardBoardTesteSubtitle({ subTitle, data }) {
    const minuteUpdateActualDate = useSelector(state => state.minuteUpdateActualDate)
    const formatedDate = dateFormatDistance(dateParseISO(data), minuteUpdateActualDate)

    return (
        <>
            <span>{subTitle}</span>
            <span className={classes.fromNowStyle}> ({formatedDate})</span>
        </>
    )
})

const CardBoardAddress = memo(function CardBoardAddress({
    mode,
    pedidoAgendado,
    addressCustomer,
    dataCancelamento,
    dataPedido,
    levarTrocoPara,
    pessoaEntregador,
    levarMaquinaCartao,
    statusLogistica,
}) {
    const takeOut = mode === 'TAKEOUT'
    const delivery = mode !== 'TAKEOUT' && mode !== 'INDOOR'
    const mesa = mode === 'Mesa'
    const balcao = mode === 'BALCAO'
    const consumirLocal = mode === 'INDOOR'
    const canceled = !!dataCancelamento

    return (
        <List dense disablePadding>
            {takeOut && (
                <ListItem disableGutters className={classes.cardListItem}>
                    <ListItemIcon className={classes.cardIcon} >
                        <StorefrontIcon fontSize="small" style={{ color: TAKEOUT_COLOR }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary="Para retirar"
                    />
                </ListItem>
            )}

            {mesa && (
                <ListItem disableGutters className={classes.cardListItem}>
                    <ListItemIcon className={classes.cardIcon} >
                        <StorefrontIcon fontSize="small" style={{ color: TAKEOUT_COLOR }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary={mode}
                    />
                </ListItem>
            )}

            {balcao && (
                <ListItem disableGutters className={classes.cardListItem}>
                    <ListItemIcon className={classes.cardIcon} >
                        <StorefrontIcon fontSize="small" style={{ color: TAKEOUT_COLOR }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary="Pedido de balcão"
                    />
                </ListItem>
            )}

            {consumirLocal && (
                <ListItem disableGutters className={classes.cardListItem}>
                    <ListItemIcon className={classes.cardIcon} >
                        <RestaurantIcon fontSize="small" style={{ color: CONSUMIR_LOCAL_COLOR }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary="Consumo no local"
                    />
                </ListItem>
            )}

            {delivery && mode !== 'BALCAO' && (
                <ListItem disableGutters className={classes.cardListItem} >
                    <ListItemIcon className={classes.cardIcon} >
                        <PlaceIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        secondaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary={addressCustomer?.address}
                        secondary={addressCustomer?.complement}
                    />
                </ListItem>
            )}

            {statusLogistica === "ENVIADO" && (
                <ListItem disableGutters className={classes.cardListItem} >
                    <ListItemIcon className={classes.cardIcon} >
                        <PinDropIcon fontSize="small" style={{ color: LOGISTICS_SENT }} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        secondaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary="Enviado p/ logistica"
                    />
                </ListItem>
            )}

            {pedidoAgendado ? (
                <ListItem disableGutters className={classes.cardListItem} >
                    <ListItemIcon className={classes.cardIcon} style={{ color: AGENDADO_COLOR }} >
                        <EventIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary={"Agendado " + dateFormat(dataPedido)}
                    />
                </ListItem>
            ) : null}

            {canceled ? (
                <ListItem disableGutters className={classes.cardListItem}>
                    <ListItemIcon className={classes.cardIcon}>
                        <CancelIcon fontSize="small" className={classes.canceledColor} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption" }}
                        className={classes.canceledColor}
                        primary={`CANCELADO ${dateFormat(dataCancelamento)}`}
                    />
                </ListItem>
            ) : null}

            {levarMaquinaCartao && (
                <ListItem disableGutters className={classes.cardListItem} >
                    <ListItemIcon className={classes.cardIcon} >
                        <PaymentIcon fontSize="small" className={classes.infoPaymentColor} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        secondaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary="Levar máquina de cartão"
                    />
                </ListItem>
            )}

            {levarTrocoPara > 0 && (
                <ListItem disableGutters className={classes.cardListItem} >
                    <ListItemIcon className={classes.cardIcon} >
                        <AttachMoneyIcon fontSize="small" className={classes.infoPaymentColor} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary={`Levar troco para ${currencyFormat(levarTrocoPara)}`}
                    />
                </ListItem>
            )}

            {pessoaEntregador && (
                <ListItem disableGutters className={classes.cardListItem}>
                    <ListItemIcon className={classes.cardIcon} >
                        <SportsMotorsportsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ variant: "caption", color: "textSecondary" }}
                        primary={`Por ${pessoaEntregador.nome}`}
                    />
                </ListItem>
            )}
        </List>
    )
})

export const CardBoard = memo(function CardBoard({ provided, card = {}, founded, isDragging }) {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const iFoodOrder = card.data?.iFoodOrder
    const pedidoAgendado = card.data?.pedidoAgendado === 'AGENDADO'

    const addressCustomer = useMemo(() => formatFullAddress(convertOrderAddressToCustomerAddress(iFoodOrder?.deliveryAddress)), [iFoodOrder])
    const totalOrderValue = useMemo(() => currencyFormat(totalOrder(iFoodOrder)), [iFoodOrder])

    const openDetailOrder = () => {
        if (card.data.iFoodOrder) {
            dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: card } })
        } else {
            enqueueSnackbar("Pedido inválido. Tente clicar em 'Atualizar dados'", { variant: "error" });
        }
    }

    function handleUpdatedOrder(event, order) {
        event.stopPropagation()
        const id = order.id
        const service = new AuthService()

        service.get(`/api-v2/pedidoIntegracao/atualizarPedido/${id}`)
            .then(pedidoIntegracao => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                enqueueSnackbar("Pedido atualizado com sucesso", { variant: "success" });
            })
            .catch(err => {
                err.response.json().then(elem => {
                    enqueueSnackbar(elem.errorMessage, { variant: "error" });
                    console.error(elem);
                })
            })
    }

    if (card?.skeleton) {
        return (
            <Skeleton
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                key={card.id}
                height={118}
                animation="wave"
                variant="rectangular"
                ref={provided.innerRef} />
        )
    }

    return (
        <StyledCard
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            elevation={0}
            ref={provided.innerRef}
            onClick={openDetailOrder}
            className={clsx(
                classes.root,
                isDragging && classes.cardDragging,
                founded === 'FOUNDED' && classes.cardFounded,
                founded === 'NOT_FOUNDED' && classes.cardNotFounded,
                !!card.data.dataImportacao && classes.cardImported,
            )}>
            <CardHeader
                action={
                    <Box flexShrink={0} m={1}>
                        <TypeOrderAvatar order={card.data} />
                    </Box>
                }
                title={card.title}
                className={classes.cardHeader}
                titleTypographyProps={{
                    className: classes.cardHeaderText
                }}
                subheader={
                    <CardBoardSubtitle
                        subTitle={card.subTitle}
                        data={card.data?.dataPedido} />
                }
                subheaderTypographyProps={{
                    className: classes.cardHeaderSubtile
                }}
            />

            <CardContent className={classes.cardContent}>

                <Box display="flex">
                    <Box flexGrow={1}>
                        {!card.data.iFoodOrder && (
                            <div>
                                <Box pt={1} justifyContent="center">
                                    <Typography color='primary'>Pedido com erro</Typography>
                                    <Button size='small' fullWidth
                                        color='primary'
                                        variant='outlined' startIcon={<RefreshIcon />}
                                        onClick={event => handleUpdatedOrder(event, card.data)}>
                                        Atualizar dados
                                    </Button>
                                </Box>
                            </div>
                        )}

                        {card.data.iFoodOrder && (
                            <CardBoardAddress
                                mode={card.data.iFoodOrder?.deliveryMethod?.mode}
                                pedidoAgendado={pedidoAgendado}
                                addressCustomer={addressCustomer}
                                dataCancelamento={card.data?.dataCancelamento}
                                dataPedido={card.data?.dataPedido}
                                levarTrocoPara={card.data?.iFoodOrder?.levarTrocoPara}
                                pessoaEntregador={card.data.pessoaEntregador}
                                statusLogistica={card.data?.statusLogistica}
                                levarMaquinaCartao={card.data?.iFoodOrder?.levarMaquinaCartao} />
                        )}
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column" >
                        {card.data.situacao === "PENDENTE" ? (
                            <PulseBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                variant="dot">
                                <IntegrationAvatar pedido={card.data} />
                            </PulseBadge>
                        ) : (
                            <IntegrationAvatar pedido={card.data} />
                        )}
                    </Box>
                </Box>
            </CardContent>

            <CardActions disableSpacing>
                <Box display='flex' width='100%' alignItems='center' mr={1} ml={1}>
                    <Box flexGrow={1}>
                        <Typography variant="caption" color="textSecondary" >
                            {totalOrderValue}
                        </Typography>
                    </Box>
                    <Box flexShrink={0}>
                        <Chip variant="outlined" size="small" label={card.data.sequencial} />
                    </Box>
                </Box>
            </CardActions>

        </StyledCard>
    )
})