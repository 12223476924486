import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

export const TextMaskPhone = forwardRef((props, inputRef) => {

    return (
      <MaskedInput
        {...props}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
      />
  )
})

export const TextMaskCPF = forwardRef((props, inputRef) => {

    return (
      <MaskedInput
        {...props}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/,'.', /\d/,/\d/,/\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
      />
  )
})

export const TextMaskCNPJ = forwardRef((props, inputRef) => {

    return (
      <MaskedInput
        {...props}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, '.', /\d/, /\d/, /\d/,'.', /\d/,/\d/,/\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
      />
  )
})

export const TextMaskCEP = forwardRef((props, inputRef) => {

    return (
      <MaskedInput
        {...props}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[ /\d/, /\d/, /\d/, /\d/, /\d/,'-', /\d/,/\d/,/\d/ ]}
        placeholderChar={'\u2000'}
      />
  )
})