
export const toogleDrawerIntegracoes = (currentState, action) =>  {
    let { drawerIntegracoes } = action
    return {
        ...currentState, 
        drawerIntegracoes: { ...drawerIntegracoes, open: drawerIntegracoes.open } 
    }
}

export const initialDrawerIntegracoes = () =>  {

    return {
        open: false,
    }
}