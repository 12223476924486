import React, { useState } from "react"
import { Box, Typography, styled } from "@mui/material"
import { AvatarForString } from "../../../pages/layout-components/elements/AvatarForString"
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from "@emotion/react";

const PREFIX = "BoxWaiter"

const classes = {
    boxMain: `${PREFIX}-boxMain`,
    boxWaiter: `${PREFIX}-boxWaiter`,
    boxWaiterChecked: `${PREFIX}-boxWaiterChecked`,    
    boxViewWaiters: `${PREFIX}-boxViewWaiters`,
    box: `${PREFIX}-box`
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.boxMain}`]: {
        display: 'flex',
        position: 'absolute',
        right: '0',
        top: '0',
        width: '28px',
        height: '28px', 
        justifyContent: 'center',
        alignItens: 'center',
        //backgroundColor: theme.palette.success.main,
        //clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%)',
        transformOrigin: 'top right',
        transform: 'scale(0)',
        transition: '0.2s'
    },

    [`& .${classes.box}`]: {
        position: 'relative',
        maxHeight: 300,
        cursor: 'pointer',
        backgroundColor: theme.palette.personal.boardCard,
        overflow: "hidden",
        height: "100%",
        width: '100%',
        verticalAlign: 'center'
    },

    [`& .${classes.boxWaiter}`]: {
        padding: theme.spacing(1),
        maxHeight: 380,
        cursor: 'pointer',
        backgroundColor: theme.palette.personal.boardCard,
        '&:hover':{
            backgroundColor: theme.palette.personal.hoverList
        },
        overflow: "hidden",
        height: "100%",
        width: '100%',
        verticalAlign: 'center'
    },

    [`& .${classes.boxWaiterChecked}`]: {
        padding: theme.spacing(1),
        maxHeight: 380,
        cursor: 'pointer',
        // border: '1px solid',
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.success.main : theme.palette.primary.main,
        [`& .${classes.boxMain}`]:{
            transform: 'scale(1)',
        },
        '&:hover':{
            backgroundColor: theme.palette.personal.hoverList
        },
        overflow: "hidden",
        height: "100%",
        width: '100%',
        verticalAlign: 'center'
    },
}))

export const BoxWaiter = ({ usuario, Paper, selectedWaiter, hadleSelectWaiter}) => {
    const theme = useTheme()
    const returnWaiterChecked = (user) => {
        if((selectedWaiter?.id !== user?.id)){ 
            return classes.boxWaiter
        } else return classes.boxWaiterChecked
    }

    return(
        <Root>            
            <Box className={classes.box}>
                <Box sx={{display: 'grid', alignContent: 'center', alignItems: 'center', justifyContent: 'space-evenly'}}
                    flexGrow={1} 
                    className={returnWaiterChecked(usuario)}                 
                    component={Paper} 
                    onClick={() => hadleSelectWaiter(usuario)}
                >
                    <Box className={classes.boxMain}>
                        <CheckIcon fontSize="medium" style={{color: theme.palette.success.main}}/>
                    </Box>
                    <Box sx={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', mb: 1}}>
                        <AvatarForString value={(usuario.pessoa ? usuario.pessoa.nome : usuario.nome)} />
                    </Box>                                        
                    <Box sx={{display: 'grid', alignContent: 'center', textAlign: 'center'}}>
                        <Typography sx={{fontSize: '10px'}} gutterBottom>
                            {(usuario.pessoa ? usuario.pessoa.nome : usuario.nome)}
                        </Typography>
                    </Box>                                                                             
                </Box>
            </Box>
        </Root>
    )
}