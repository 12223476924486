import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

export default function ComponentAdditionalInformation({ info, setInfo }) {
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event) => {

        if (event.target.name === 'quantidadePessoas') { // adicionada verificação para não inserir numeros negativos

            if (event.target.value > -1) {
                setInfo({ ...info, [event.target.name]: event.target.value })
            } else {
                enqueueSnackbar('Não é possível inserir um valor negativo de pessoas.', { variant: "error" })
                event.target.value = ""
            }

        } else {
            setInfo({ ...info, [event.target.name]: event.target.value })
        }

    }

    return (
        <Grid container spacing={2} direction='column'>
            <Grid item xs>
                <TextField
                    id="observations"
                    label="Observação do pedido"
                    multiline
                    fullWidth
                    maxRows={4}
                    minRows={2}
                    name='observations'
                    value={info?.observations || ""}
                    onChange={handleChange}
                    variant="outlined"
                />
            </Grid>

            <Grid item >
                <TextField
                    fullWidth
                    label='Complemento identificador'
                    type='text'
                    size='small'
                    value={info?.complementoIdentificador || ""}
                    onChange={handleChange}
                    name='complementoIdentificador'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                />
            </Grid>

            <Grid item sm={4} xs={12}>
                <TextField
                    fullWidth
                    label="Qtde pessoas"
                    type="number"
                    size='small'
                    value={info?.quantidadePessoas || ""}
                    name='quantidadePessoas'
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                        inputMode: 'numeric',
                        min: "0"
                    }}
                    variant="outlined"
                />
            </Grid>

        </Grid>
    );
}
