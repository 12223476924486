import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AuthService from '../../../service/AuthService'

export function useLoadEntregadores() {
    const service = new AuthService()
    const dispatch = useDispatch()
    const loadingRef = useRef(false)
    const listDeliveryManCache = useSelector(state => state.globalCache.listDeliveryMan)
    const activeDeliveryMan = useMemo(() => listDeliveryManCache?.filter(item => item.ativo) || [], [listDeliveryManCache])

    useEffect(() => {
        if (!listDeliveryManCache && !loadingRef.current) {
            const fetchListDeliveryMan = async () => {
                loadingRef.current = true

                try {
                    const resp = await service.get('/api-v2/pessoa/entregador')
                    dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { listDeliveryMan: resp } })
                } catch (err) {
                    console.error("Falha ao buscar entregadores", err)
                } finally {
                    loadingRef.current = false
                }
            }

            fetchListDeliveryMan()
        }
    }, [listDeliveryManCache, loadingRef])

    return [activeDeliveryMan]
}