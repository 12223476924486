import { Button, Grid, Checkbox, Dialog, DialogActions, FormControlLabel, FormGroup, InputAdornment, TextField, Tooltip, FormControl, InputLabel, OutlinedInput, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle, DialogContent } from "./../CustomDialog/dialogComponents"
import DoneIcon from '@mui/icons-material/Done';
import AuthService from '../../../service/AuthService';
import { getParamByKey } from '../../../component/parameter';
import { addAllTaxaServico, pendingAmount, printOrder, removeAllTaxaServico, totalItems, totalOrder } from '../../../util/order/orderUtil';
import { extractErrorMessage } from '../../../util/http/HttpUtil';
import { useSnackbar } from 'notistack';
import { useHotkeys } from 'react-hotkeys-hook';
import { currencyFormat } from '../../../util/formatter';
import { Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function CloseOrder() {
    const dispatch = useDispatch()

    const order = useSelector(state => state.closeOrder?.order)
    const open = useSelector(state => state.closeOrder?.open) || false
    const [stateValues, setStateValue] = useState({ recalcularTaxaDeServico: true, imprimirPedido: true, percentualTaxaServico: 0.0, divideInto: 1 })
    const btnConfirmRef = useRef()
    const [saving, setSaving] = useState(false)
    const service = new AuthService()
    const generalParameters = useSelector(state => state.generalParameters)
    const { enqueueSnackbar } = useSnackbar();
    const PERCENTUAL_TAXA_SERVICO = getParamByKey(generalParameters, 'PERCENTUAL_TAXA_SERVICO')?.valor
    const UTILIZA_TAXA_SERVICO = getParamByKey(generalParameters, 'UTILIZA_TAXA_SERVICO')?.valorString

    useHotkeys('f10', (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
            setSaving(true)
        }
    })

    useEffect(() => {

        setStateValue({
            ...stateValues,
            percentualTaxaServico: PERCENTUAL_TAXA_SERVICO,
            recalcularTaxaDeServico: UTILIZA_TAXA_SERVICO === 'S',
            divideInto: order?.quantidadePessoas || 1,
        });
        // eslint-disable-next-line
    }, [open])

    const setOpen = (open) => {
        dispatch({ type: 'OPEN_DIALOG_CLOSE_ORDER', closeOrder: { open } })
    }

    function handleClose() {
        setOpen(false)
    }

    const handleChangeCheckBox = (event) => {
        setStateValue({ ...stateValues, [event.target.name]: event.target.checked });
    }

    const onChange = (event) => {

        setStateValue({ ...stateValues, [event.target.name]: event.target.value });
    }

    function splitInvoice(orderParam, divideInto) {

        if (divideInto > 1) {

            let pendingAmountValue = pendingAmount(orderParam?.iFoodOrder)

            if (pendingAmountValue > 0 && divideInto > 0) {
                pendingAmountValue = pendingAmountValue / divideInto
            }

            if (pendingAmountValue > 0) {
                orderParam.iFoodOrder.divideInvoiceObservation = `TOTAL POR PESSOA ( ${divideInto}x ) ${currencyFormat(pendingAmountValue)}`
            } else {
                orderParam.iFoodOrder.divideInvoiceObservation = 'pagamento finalizado - nada para dividir'
            }
        } else {
            orderParam.iFoodOrder.divideInvoiceObservation = ''
        }
    }

    function recalcularTaxaDeServico(orderParam) {
        if (orderParam.tipo === 'COMANDA' || order.tipo === 'MESA') {

            orderParam.iFoodOrder.deliveryMethod.mode = 'COMANDA';

            if (UTILIZA_TAXA_SERVICO === 'S') {
                if (stateValues.percentualTaxaServico > 0) {
                    const COBRAR_TAXA_VALOR_BRUTO_PEDIDO = getParamByKey(generalParameters, 'COBRAR_TAXA_VALOR_BRUTO_PEDIDO')
                    addAllTaxaServico(orderParam.iFoodOrder, stateValues.percentualTaxaServico, COBRAR_TAXA_VALOR_BRUTO_PEDIDO);
                } else {
                    removeAllTaxaServico(orderParam.iFoodOrder)
                }
            }
        }

        orderParam.iFoodOrder.subTotal = totalItems(orderParam.iFoodOrder);
        orderParam.iFoodOrder.totalPrice = totalOrder(orderParam.iFoodOrder);
    }


    const confirmOptions = () => {
        let orderCopy = Object.assign({}, order)

        if (stateValues.recalcularTaxaDeServico === true) {
            recalcularTaxaDeServico(orderCopy);
        }

        if (stateValues.divideInto > 0) {
            splitInvoice(orderCopy, stateValues.divideInto)
        }

        service.post('/api-v2/pedidoIntegracao', orderCopy).then(savedOrder => {
            dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder });
            dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder } });

            // AQUI IMPRIMIR PEDIDO
            if (stateValues.imprimirPedido === true) {
                savedOrder.origemImpressao = "AUT AO FINALIZAR";
                printOrder(savedOrder, enqueueSnackbar);
            }

            handleClose();
        }).catch(err => {
            extractErrorMessage(err, "Erro ao salvar o pedido")
                .then(msg => {
                    enqueueSnackbar('Não foi possível salvar o pedido - Detalhes: ' + msg, { variant: "error" });
                });

            console.error('ERRO_FECHAR_PEDIDO: ', err);
        }).finally(() => {
            setSaving(false);
        });
    }

    const onFocus = (event) => {
        event.target.select()
    }

    const handleClickAddOrRemove = (sinal) => {
        let value = 0

        if (sinal === '+') {
            value = parseInt(stateValues.divideInto) + 1
        } else {

            value = parseInt(stateValues.divideInto) - 1

            if (value === 0) {
                value = 1
            }
        }

        setStateValue({ ...stateValues, divideInto: value })
    }




    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth
            onClose={handleClose}>

            <DialogTitle onClose={handleClose}>
                Fechar pedido
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Alert severity="info">

                            {`( ${order?.quantidadePessoas || 0} ) pessoas neste pedido`}
                        </Alert>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl variant="outlined" >
                            <InputLabel>Dividir a conta em</InputLabel>
                            <OutlinedInput
                                label="Dividir a conta em"
                                inputProps={{style: { textAlign: 'center' } }}
                                value={stateValues.divideInto || 0}
                                onFocus={onFocus}
                                onChange={onChange}
                                name='divideInto'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton onClick={() => handleClickAddOrRemove('+')} edge="start" size="large">
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleClickAddOrRemove('-')} edge="end" size="large">
                                            <RemoveIcon />
                                        </IconButton>
                                    </InputAdornment>
                                } />
                        </FormControl>
                    </Grid>

                    {UTILIZA_TAXA_SERVICO === 'S' &&
                        <Grid item xs={12}>
                            <FormGroup aria-label="position">
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            checked={stateValues.recalcularTaxaDeServico}
                                            onChange={handleChangeCheckBox}
                                            name="recalcularTaxaDeServico"
                                        />}
                                    label={`Recalcular a taxa de serviço ${UTILIZA_TAXA_SERVICO !== 'S' ? ' (desativado)' : ''}`}
                                    labelPlacement="end"
                                />
                            </FormGroup>

                            <TextField
                                label="Taxa de serviço"
                                variant='outlined'
                                name='percentualTaxaServico'
                                type='number'
                                fullWidth
                                disabled={!stateValues.recalcularTaxaDeServico}
                                defaultValue={stateValues.percentualTaxaServico}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                                onChange={(event) => onChange(event)}
                            />
                        </Grid>
                    }


                    <Grid item xs={12}>
                        <FormGroup aria-label="position">

                            <FormControlLabel
                                control={<Checkbox color="primary" checked={stateValues.imprimirPedido} onChange={handleChangeCheckBox} name="imprimirPedido" />}
                                label="Imprimir pedido ao confirmar"
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Tooltip title="Atalho F10" aria-label="Confirmar">
                    <Button
                        ref={btnConfirmRef}
                        variant="contained"
                        color="primary"
                        onClick={confirmOptions}
                        disabled={saving}
                        endIcon={<DoneIcon />}>Confirmar</Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}