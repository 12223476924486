

const INTERVALO_ENTRE_TENTATIVAS_MS = 1000;
const NUMERO_MAX_TENTATIVAS = 5;
const PASTA_SISCHEF_BALANCA = "/Sischef/Balanca"
const ARQUIVO_SOLICITACAO_PESO = "lepeso"
const ARQUIVO_RETORNO_PESO = "resp.json"
//NO APP DELPHI O TEMPO DE BUSCA EH 50MS. OU SEJA, MENOS QUE ISSO NAO FAZ SENTIDO
const TEMPO_RETORNO_LE_PESO = 150;


const LOG_ENABLED = false;

/**
 * Retorna o peso na balanca simulando um retorno
 * @return {Object} retorna um peso aleatorio ou erro
 *  Em caso de erro, irá retornar um JSON com seguinte informacao
 *  99 - erro fake
 *  10 - Rodando fora do electron
 *  15 - Timeout (provavelmente Lepeso fechado)
 *  20 - Erro ao criar arquivo de solicitação de peso
 *  20 - Erro ao deletar arquivo de solicitação de balanca
 *  -1 - Peso instável;
 *  -9 - Peso instável;
 *  -2 - Peso negativo;
 *  -10 - Sobrepeso;
 */
export const getPesoBalanca = () => {

    if (isModoPesoFakeAtivo()) {
        return getPesoBalancaFake();
    }

    return getPesoBalancaReal();
}

export const isModoPesoFakeAtivo = () => {
    return !!localStorage.getItem("_PESO_FAKE_")
}

/**
 * Retorna o peso na balanca simulando um retorno
 * @return {Object} retorna um peso aleatorio ou erro
 */
const getPesoBalancaFake = () => {
    return new Promise((resolve, reject) => {

        if (Math.random() > 0.9) {
            reject({ code: 99, message: "Erro simulado de leitura de peso" })
        }

        let pesoRandomico = (Math.random() + Math.floor(Math.random() * 3)).toFixed(3)
        resolve(parseFloat(pesoRandomico))

    });
}

/**
 * Retorna o peso na balanca
 * @return {Object} peso informado na balanca
 */
const getPesoBalancaReal = () => {

    return new Promise((resolve, reject) => {

        if (!verificaSeEstaRodandoNoElectron()) {
            reject({ code: 10, message: "Para utilizar a balança você precisa estar utilizando o PDV Sischef instalado na sua máquina." });
        }

        const pdvDesktop = window.pdvDesktop;
        const deletaLepesoPromisse = pdvDesktop.deleteFile(PASTA_SISCHEF_BALANCA, ARQUIVO_SOLICITACAO_PESO);
        const deletaRetornoPromisse = pdvDesktop.deleteFile(PASTA_SISCHEF_BALANCA, ARQUIVO_RETORNO_PESO);

        Promise.all([deletaLepesoPromisse, deletaRetornoPromisse])
            .then(([respDeleteLepeso, respDeleteRetorno]) => {
                //console.log("Arquivos da balanca excluidos com sucesso ")

                pdvDesktop.createFile(PASTA_SISCHEF_BALANCA, ARQUIVO_SOLICITACAO_PESO, 'Solicitacao de peso')
                    .then(retornoCriacaoLepeso => {

                        let numeroTentativasLeitura = 0;
                        // let buscaEmAndamento = false;

                        /* AGUARDA UM TEMPO */
                        setTimeout(() => {
                            verificarPesagem()
                        }, TEMPO_RETORNO_LE_PESO)


                        const verificarPesagem = () => {

                            if (LOG_ENABLED) console.log(`======= verificarPesagem =======`);


                            if (numeroTentativasLeitura > NUMERO_MAX_TENTATIVAS) {
                                if (LOG_ENABLED) console.log(`Tentativa ${numeroTentativasLeitura} de ${NUMERO_MAX_TENTATIVAS}. Retornando TIMEOUT`);
                                reject({ code: 10, message: "Não foi possível buscar o peso. Tempo total excedido. Verifique se o aplicativo 'Sischef Le Peso' está aberto" });

                            } else {
                                let obteveRetornoBalanca = false;
                                pdvDesktop.readFileContent(PASTA_SISCHEF_BALANCA, ARQUIVO_RETORNO_PESO, 'utf8')
                                    .then(retornoComPeso => {

                                        if (retornoComPeso) {
                                            obteveRetornoBalanca = true;

                                            let retornoJson = JSON.parse(retornoComPeso);

                                            if (retornoJson.status === "SUCCESS") {
                                                if (LOG_ENABLED) console.log("Peso recuperado com sucesso", retornoJson.value)
                                                resolve(retornoJson.value)
                                            } else {
                                                if (LOG_ENABLED) console.log(`Balança retornou peso inválido. Retorno: ${retornoJson.value} - ${retornoJson.message}`)
                                                reject({ code: retornoJson.value, message: retornoJson.message });
                                            }
                                        }

                                    }).catch(error => {
                                        console.error(`Erro ao ler arquivo de retorno da balanca. Tentativa ${numeroTentativasLeitura} de ${NUMERO_MAX_TENTATIVAS} `, error);
                                    }).finally(() => {

                                        if (!obteveRetornoBalanca) {
                                            /* FAZ NOVA TENTATIVA  */
                                            setTimeout(() => {
                                                verificarPesagem()
                                            }, INTERVALO_ENTRE_TENTATIVAS_MS)
                                        }
                                    })

                                if (LOG_ENABLED) console.log(`Tentativa ${numeroTentativasLeitura} de ${NUMERO_MAX_TENTATIVAS} de leitura do peso (no arquivo)`);
                                numeroTentativasLeitura++;
                            }

                        }

                    }).catch(error => {
                        console.error("Erro ao tentar criar o arquivo de solicitação de peso da balança", error);
                        reject({ code: 20, message: `Erro ao tentar criar o arquivo de solicitação de peso da balança` })
                    })

            }).catch(error => {
                console.error("Erro ao deletar arquivo de solicitação de peso da balança", error);
                reject({ code: 25, message: `Erro ao deletar arquivo de solicitação de peso da balança` })
            })

    }) 

}

export const verificaSeEstaRodandoNoElectron = () => {
    return !!window.pdvDesktop;
}

export const openSischefLepeso = () => {
    if (!verificaSeEstaRodandoNoElectron()) {
        throw "Para utilizar a balança você precisa estar utilizando o PDV Sischef instalado na sua máquina."
    }

    const pdvDesktop = window.pdvDesktop;

    pdvDesktop.openSischefLepeso();
}

