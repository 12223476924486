import { createTheme } from '@mui/material';
import { createPalette } from './palette';
import typography from './typography';
import { ptBR } from '@mui/material/locale';
import components from './components';

function generateScrollBarStyle(mode) {
  const options = {
    track: ('dark' === mode ? 'rgba(65, 75, 82, 0.3)' : '#F5F5F5'),
    active: ('dark' === mode ? 'rgba(79, 91, 100)' : 'rgba(191, 191, 191, 0.4)'),
    thumb: ('dark' === mode ? 'rgba(79, 91, 100, 0.6)' : 'rgba(191, 191, 191, 0.6)'),
  }

  return {
    '@supports not selector(::-webkit-scrollbar)': {
      scrollbarColor: `${options.thumb} ${options.track}`,
      scrollbarWidth: 'thin',
    },

    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      backgroundColor: options.track,
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      minHeight: 10,
      border: `1px solid ${options.track}`,
      borderRadius: '5px',
      backgroundColor: options.thumb,
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
      backgroundColor: options.active,
    },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: options.active,
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: options.active,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: options.track,
    }
  }
}

export const createThemeApp = (mode) => {

  return createTheme({
    palette: createPalette(mode),
    typography,
    components: {
      ...components,
      MuiCssBaseline: {
        styleOverrides: {
          body: generateScrollBarStyle(mode)
        },
      },
    },
    zIndex: {
      appBar: 1200,
      // drawer: 1100
    },
    myAppBar: {
      height: '80px'
    },
    scrollBar: {
      overflowY: 'auto',
      overflowX: 'auto',
      '&::-webkit-scrollbar-track': {
        borderRadius: '3px',
        backgroundColor: ('dark' === mode ? 'rgba(65, 75, 82, 0.3)' : '#F5F5F5')
      },
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        backgroundColor: ('dark' === mode ? 'rgba(65, 75, 82, 0.3)' : '#F5F5F5')
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '10px',
        backgroundColor: ('dark' === mode ? 'rgba(65, 75, 82, 0.3)' : '#F5F5F5')
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: ('dark' === mode ? 'rgba(79, 91, 100, 0.6)' : 'rgba(191, 191, 191, 0.6)')
      },
      '&::-webkit-scrollbar-button': {
        width: '10px',
        height: '10px',
        backgroundColor: ('dark' === mode ? 'rgba(65, 75, 82, 0.3)' : '#F5F5F5')
      },
    },
  }, ptBR)
} 
