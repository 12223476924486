import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SideBarTop from "./SideBarTop"
import Profile from "./Profile"
import SideList from "./SideList"
import TopBar from "./TopBar"
import { useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { OrderObj } from './NewOrder/helper/OrderHelper';
import { getSettings } from '../settings/settings';
import { hasModalOpen, useHotkeysCustom } from '../../util/genericUtil';
import { ifAnyGranted } from '../../util/auth/authUtil';
import { useSnackbar } from 'notistack';

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.toolbar}`]: {
    paddingRight: 24, // keep right padding when drawer closed
    minHeight: theme.myAppBar.height,
  },

  [`& .${classes.toolbarIcon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.appBar,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.mode === 'light' ? '#f0f0f0' : '#222f39',
  },

  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },

  [`& .${classes.menuButtonHidden}`]: {
    display: 'none',
  },

  [`& .${classes.drawerPaper}`]: {
    zIndex: 1100,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: "0"
  },

  [`& .${classes.drawerPaperClose}`]: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },

  [`& .${classes.appBarSpacer}`]: {
    minHeight: theme.myAppBar.height
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.mode === 'light' ? '#F5F5F5' : '#1b252d'
  },

  [`& .${classes.container}`]: {
    paddingTop: 0,
    paddingInline: "10px"

    //height: "100%",
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  [`& .${classes.fixedHeight}`]: {
    height: 240,
  }
}))

const drawerWidth = 240;

export default function Layout({ children, layoutTop }) {

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [open, setOpen] = React.useState(localStorage.getItem('_side_panel_') ? localStorage.getItem('_side_panel_') === "true" : true);
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line 
  const dispatch = useDispatch();
  const isDialogOpen = useSelector(state => state.dialogOrder.open)
  const { enqueueSnackbar } = useSnackbar();
 // const layoutUpdate = useRef(false); //variável utilizada para identificar o estado atual para o useEffect

  /*USE EFFECT PARA ATIVAR O FOCO NO LAYOUT AO ABRIR OU AO FINALIZAR O DIALOG DE UM PEDIDO*/
  /*
  useEffect(() => {
    if (layoutUpdate.current) {
      //seta o foco para o layout
      document.getElementById('layout').focus()
    } else {
      document.getElementById('layout').focus()
      layoutUpdate.current = true;
    }
  });
*/
  const handleDrawerOpen = () => {
    setOpen(true)
    localStorage.setItem('_side_panel_', true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    localStorage.setItem('_side_panel_', false)
  }

  useHotkeysCustom('home', () => {
    if (isDialogOpen || hasModalOpen()) return

    handClickOpenNewOrderBalcao()
  })

  useHotkeysCustom('insert', () => {
    if (isDialogOpen || hasModalOpen()) return

    handClickOpenNewOrderDelivery()
  })

  /*FUNÇÃO PARA ABRIR PEDIDO DE BALCÃO, UTILIZADO PELO ATALHO ACIMA DEFINIDO */
  const handClickOpenNewOrderBalcao = () => {

    if (!ifAnyGranted('ROLE_PDV_DELIVERY')) {
      enqueueSnackbar("Seu usuário não tem permissão para lançar pedido de balcão", { variant: "warning" });
      return;
    }

    if (location.pathname !== '/delivery') {
      history.push('/delivery')
    }

    const order = new OrderObj()
    order.tipo = 'BALCAO'
    order.printOrder = getSettings().printAutomaticallyOnCreate === "ENABLED";
    dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: order, initActiveStep: 1 } })
  }

  /*FUNÇÃO PARA ABRIR PEDIDO DE Delivery, UTILIZADO PELO ATALHO ACIMA DEFINIDO */
  const handClickOpenNewOrderDelivery = () => {

    if (!ifAnyGranted('ROLE_PDV_DELIVERY')) {
      enqueueSnackbar("Seu usuário não tem permissão para lançar pedido de delivery", { variant: "warning" });
      return;
    }

    if (location.pathname !== '/delivery') {
      history.push('/delivery')
    }

    const order = new OrderObj()
    order.printOrder = getSettings().printAutomaticallyOnCreate === "ENABLED";
    dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: order } })
  }

  return (
    <Root className={classes.root} /*ref={ref} tabIndex={-1} id={'layout'} /*onMouseOver={() => { document.getElementById('layout').focus() }}*/>
      <CssBaseline />
      <AppBar position="absolute"
        elevation={0}
        color="default"
        className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            size="large">
            <MenuIcon />
          </IconButton>

          <TopBar layoutTop={layoutTop} />

        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onClose={handleDrawerClose}>

        <div className={classes.toolbarIcon}>
          <SideBarTop />
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Profile dense={!open} />

        <SideList open={open} />

      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>

      </main>
    </Root>
  );
}