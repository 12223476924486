import { groupItemsByName } from "../util/order/orderUtil"

export const toogleDetailOrderDialog = (currentState, action) =>  {
    let { detailOrder } = action
    
    const order = detailOrder?.card?.data
    const groupedItems = groupItemsByName(order)
  
    return {
        ...currentState, 
        detailOrder: { ...detailOrder, open: detailOrder.open, groupedItems } 
    }
}

export const initialStateDialogDetail = () =>  {

    return {
        open: false,
        card: null,
        groupedItems: null,
    }
}