import React, { useState } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';

export default function AutocompleteService({ onSelect, loadOptions, ...others }) {

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = React.useState([]);

    const handleSearch = (value) => {

        if (value === '') {
            setOptions([]);
            return undefined;
        }

        setLoading(true)

        loadOptions(value)
            .then(options => {
                setLoading(false)
                setOptions(options)
            }).catch(err => {
                setLoading(false)
                console.error(err)
            })

    }

    const handleSearchThrottle = React.useMemo(
        () => throttle(handleSearch, 200),
        // eslint-disable-next-line  
        [],
    );

    return (
        <Autocomplete
            {...others}

            options={options}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    console.log("IF ", newValue)
                } else if (newValue && newValue.inputValue) {
                    //new element
                } else {
                    onSelect(newValue)
                }
            }}
            onInputChange={(event, newInputValue, reason) => {
                handleSearchThrottle(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...others.renderinputprops}
                    label={others.label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            isOptionEqualToValue={
                (option, value) => {
                    return option.id === value.id
                }
            }

        />
    );
}