import { Avatar, Box, Card, CardHeader, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../service/AuthService';
import { extractErrorMessage } from '../../util/http/HttpUtil';
import CardKds from './components/card';
import FilterKds from './components/filter';
import { convertItemKdsFromBoardKds, filtrarItem, playSoundCancelItem, playSoundNewItem } from './utils';
import SocketIO from './socket';
import { addHours, subHours } from 'date-fns'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { getGenericCodeByKey } from '../../component/genericCode';
import ReactGA from "react-ga4";
import { SkeletonProducts } from '../layout-components/elements/Skeletons';
import { AutoGridBox } from '../../component/generics/AutoGridBox';

export default function BoardKds() {
    const SAVED_SETTINGS_KDS = "_saved_settings_kds_"
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const boadKds = useSelector(state => state.boardKds)

    //const URL_SERVIDOR_IMPRESSAO = process.env.REACT_APP_URL_SERVIDOR_IMPRESSAO
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO')
    const URL_SERVIDOR_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com'
    console.log("COD_GENERICO_URL_SERVER_IMPRESSAO", COD_GENERICO_URL_SERVER_IMPRESSAO)
    console.log("URL_SERVIDOR_IMPRESSAO", URL_SERVIDOR_IMPRESSAO)

    const serviceImpressao = new AuthService(URL_SERVIDOR_IMPRESSAO)
    const profile = serviceImpressao.getProfile()
    const query = 'uuid=' + profile.unidade.uuid

    useEffect(() => {//informa ao analytics que o usuário caiu na pagina de kds
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "kds" });
    }, [])

    function carregarNotificacaoKds() {
        setLoading(true)

        const savedSettingsKds = JSON.parse(localStorage.getItem(SAVED_SETTINGS_KDS))

        let dataInicio = new Date()
        let dataFim = new Date()

        if (savedSettingsKds && savedSettingsKds.horasAnterior) {
            dataInicio = subHours(new Date(), savedSettingsKds.horasAnterior)
            console.log("Data Inicio", dataInicio)
        }
        
        if (savedSettingsKds && savedSettingsKds.horasPosterior) {
            dataFim = addHours(new Date(), savedSettingsKds.horasPosterior)
            console.log("Data Fim: ", dataFim)
        }

        const options = {
            method: 'GET',
            headers: {
                "unidadeuuid": profile.unidade.uuid,
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "datainicio": dataInicio,
                "datafim": dataFim,
            }
        }

        serviceImpressao.request(`/api/kds/listAllByDate?limit=1000`, options).then(response => {

            response.json().then(
                retorno => {
                    const boardKds = convertItemKdsFromBoardKds(retorno?.docs, savedSettingsKds)

                    dispatch({ type: 'CREATE_BOARD_KDS', boardKds: boardKds })
                }
            )

        }).catch(err => {
            console.error(err)
            extractErrorMessage(err, '')
                .then(msg => {
                    enqueueSnackbar("Não foi possível buscar os itens para o KDS. " + msg, { variant: "error" });
                })
        }).finally(() => {
            setLoading(false)
        })
    }

    const addEvents = (socket) => {

        socket.on("connect", () => {
            console.log('connect SocketIO no KDS', socket.id);
            carregarNotificacaoKds()
        });

        socket.on('updateItemStatus', (itemKds) => {

            dispatch({ type: 'UPDATE_ITEM_KDS', itemKdsUpdated: itemKds })
            console.log('Evento -> updateItemStatus', itemKds)

            const savedSettingsKds = JSON.parse(localStorage.getItem(SAVED_SETTINGS_KDS))

            if (savedSettingsKds &&
                savedSettingsKds.avisoSonoro === true &&
                itemKds.item.status === 'CANCELADO' &&
                filtrarItem(savedSettingsKds, [itemKds])?.length > 0) {

                console.log('Cancelou item e teve aviso sonoro')
                playSoundCancelItem()
            }
        })

        socket.on('updateItemsStatus', (itemsKds) => {

            dispatch({ type: 'UPDATE_ITEMS_KDS', itemsKdsUpdated: itemsKds })
            console.log('Evento -> updateItemsStatus', itemsKds)

            const savedSettingsKds = JSON.parse(localStorage.getItem(SAVED_SETTINGS_KDS))

            var haveItemCancelado = false;
            // eslint-disable-next-line
            haveItemCancelado = itemsKds.filter(item => item.item.status == 'CANCELADO');

            if (savedSettingsKds &&
                savedSettingsKds.avisoSonoro === true &&
                haveItemCancelado &&
                filtrarItem(savedSettingsKds, itemsKds)?.length > 0) {

                console.log('Cancelou item e teve aviso sonoro')
                playSoundCancelItem()
            }
        })

        socket.on('createItemKds', (itemsKds) => {

            dispatch({ type: 'CREATE_ITEM_KDS', itemsKdsCreated: itemsKds })
            console.log('Evento -> createItemKds', itemsKds)

            const savedSettingsKds = JSON.parse(localStorage.getItem(SAVED_SETTINGS_KDS))

            if (savedSettingsKds &&
                savedSettingsKds.avisoSonoro === true &&
                filtrarItem(savedSettingsKds, itemsKds)?.length > 0) {
                console.log('Aviso sonoro')
                playSoundNewItem()
            }
        })
    }

    return (
        <Box p={1} >
            <FilterKds atualizarTela={carregarNotificacaoKds} />

            {loading &&
                <Box pt={2} >
                    <SkeletonProducts width={330} height={170} gap={2} length={8} />
                </Box>
            }

            {!loading && boadKds?.length === 0 && <EmptyKDSCard />}

            <Box pt={2}>
                <AutoGridBox minWidth={330} gap={2}>
                    {boadKds?.map((cardKds, index) => (
                        <Box>
                            <CardKds key={index} cardKds={cardKds} />
                        </Box>
                    ))}
                </AutoGridBox>
            </Box>

            <SocketIO addEvents={addEvents} url={URL_SERVIDOR_IMPRESSAO} query={query} />
        </Box >
    );
}


const EmptyKDSCard = () => {

    return (
        <Box display="flex" justifyContent="center" pt={4}>
            <Box p={1}>
                <Card style={{ width: "300px" }} variant='outlined'>
                    <CardHeader
                        avatar={
                            <Avatar><DoneOutlineIcon /></Avatar>
                        }
                        title={
                            "Nenhum pedido encontrado"
                        }
                    />

                    <Box height="60px" p={3}>
                        <Typography component="h4">Só aguardar! <span role="img" aria-label="emoji">😴</span> </Typography>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}