import React from 'react'

import { Box, Dialog, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { DialogContent, DialogTitle } from "../../CustomDialog/dialogComponents"

function SaleInfoItem({ label, content }) {
    return (
        <Box mb={1} display="flex" flexDirection="row">
            <Typography fontWeight={700} mr={1}>
                {label}:
            </Typography>
            <Typography>
                {content}
            </Typography>
        </Box>
    )
}

function SaleInfoDialogContent({ sale }) {
    if (!sale) {
        return null
    }

    const intencaoVenda = sale.intencoesVendas[0]
    const pagamentoExterno = intencaoVenda.pagamentosExternos[0]

    return (
        <>
            <SaleInfoItem label="Data" content={intencaoVenda.data} />
            <SaleInfoItem label="Status" content={intencaoVenda.intencaoVendaStatus.nome} />
            <SaleInfoItem label="Adquirente" content={pagamentoExterno.adquirente} />
            <SaleInfoItem label="Autorização" content={pagamentoExterno.autorizacao} />
            <SaleInfoItem label="Bandeira" content={pagamentoExterno.bandeira} />
            <SaleInfoItem label="Resposta Adquirente" content={pagamentoExterno.mensagemRespostaAdquirente} />
            <SaleInfoItem label="Valor Final" content={`R$ ${intencaoVenda.valorFinalFormat}`} />
        </>
    )
}

export function SaleInfoDialog() {
    const dispatch = useDispatch()
    const { open, sale } = useSelector(state => state.dialogTefSaleInfo)

    function close() {
        dispatch({ type: 'TOOGLE_TEF_SALE_INFO_DIALOG', dialogTefSaleInfo: { open: false, sale: null } })
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="xs"
            onClose={close}>

            <DialogTitle onClose={close}>
                Detalhes da intenção de venda TEF
            </DialogTitle>

            <DialogContent>
                <SaleInfoDialogContent sale={sale} />
            </DialogContent>

        </Dialog>
    )
}