import { Box, Button, Card, CardHeader, Checkbox, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { getParamByKey } from '../../../../../component/parameter';
import AuthService from '../../../../../service/AuthService';
import { extractErrorMessage } from '../../../../../util/http/HttpUtil';
import { useSnackbar } from 'notistack';
import { OrderObj } from '../../../NewOrder/helper/OrderHelper';
import { Alert } from '@mui/material';
import { totalDiscountItems } from '../../../../../util/order/orderUtil';

export default function TransferItem({ onSentDestino }) {
    const identificadorOrigemRef = useRef();
    const identificadorDestinoRef = useRef();
    const generalParameters = useSelector(state => state.generalParameters)
    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString
    const LABEL_IDENTIFICADOR_PRINCIPAL = MODELO_IDENTIFICADOR_PRINCIPAL === 'MESA' ? 'Mesa' : 'Comanda'
    const [loadingOrigemOrder, setLoadingOrigemOrder] = useState(false)
    const [loadingDestinationOrder, setLoadingDestinationOrder] = useState(false)
    const [msgErroBusca, setMsgErroBusca] = useState("")
    const service = new AuthService()
    const [orderOrigem, setOrderOrigem] = useState()
    const [orderDestino, setOrderDestino] = useState()
    const [maisPedidosOrigem, setMaisPedidosOrigem] = useState(0)
    const [maisPedidosDestino, setMaisPedidosDestino] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const existsOrderOrigem = orderOrigem && !loadingOrigemOrder
    const dialogJoinOrders = useSelector(state => state.dialogJoinOrders)
    const dispatch = useDispatch()
    const cardList = useSelector(state => state.cards)

    useEffect(() => {
        dispatch({ type: 'TOOGLE_DIALOG_JOIN_ORDERS', dialogJoinOrders: { open: dialogJoinOrders.open, origin: orderOrigem, destiny: orderDestino } })
        //eslint-disable-next-line
    }, [orderOrigem, orderDestino])

    const handleChangeCheckbox = (item) => {
        item.checked = !item.checked

        let orderCopy = Object.assign({}, orderOrigem)

        setOrderOrigem(orderCopy)
    };

    const onClickSearchIdentificadorOrigem = () => {
        handleSearchOrderOrigem()
    }

    const handleSearchOrderOrigem = () => {

        if (identificadorOrigemRef !== null && identificadorOrigemRef.current.value !== identificadorDestinoRef.current.value) {//verifica se o identificador não é nulo e se é diferente do outro identificador para não transferir itens para a mesma comanda
            setLoadingOrigemOrder(true)

            buscarPedidoPeloIdentificadorPrincipal(identificadorOrigemRef.current.value, (pedidos) => {

                if (pedidos && pedidos.length > 0) {
                    /*
                    lista todos os pedidos e seleciona somente o primeiro

                    verificar onde utiliza o order origem para listar todos os pedidos


                    */

                    setMaisPedidosOrigem(pedidos.length)//seta o tamanho do identificador de pedidos 
                    //para quando ter mais de 1 pedido mostar o alerta para agrupar comanda                    

                    setOrderOrigem(pedidos[0])
                    setMsgErroBusca('')
                    setTimeout(() => {
                        identificadorDestinoRef.current.select();
                    }, 150);
                } else {
                    setMsgErroBusca('Pedido não encontrado')

                    setTimeout(() => {
                        identificadorOrigemRef.current.select();
                    }, 150);
                }

                setLoadingOrigemOrder(false)
            })
        }
        else {
            enqueueSnackbar("Impossível enviar pedidos de uma comanda para ela mesma", { variant: "error" })
        }

    }

    const handleKeyDownIdentificadorOrigem = (event) => {
        if (13 === event.keyCode) {
            handleSearchOrderOrigem()
        }
    }

    const handleChangeIdentificadorOrigem = () => {
        setMsgErroBusca("")
        setOrderOrigem()
    }

    const onClickSearchIdentificadorDestino = () => {
        handleSearchOrderDestino()
    }

    const handleSearchOrderDestino = () => {

        if (identificadorDestinoRef !== null && identificadorDestinoRef.current.value !== identificadorOrigemRef.current.value) { //verifica se o identificador não é nulo e se é diferente do outro identificador para não transferir itens para a mesma comanda
            setLoadingDestinationOrder(true)

            buscarPedidoPeloIdentificadorPrincipal(identificadorDestinoRef.current.value, (pedidos) => {
                if (pedidos && pedidos.length > 0) {


                    setMaisPedidosDestino(pedidos.length) //seta o tamanho do identificador de pedidos 
                    //para quando ter mais de 1 pedido mostar o alerta para agrupar comanda                    

                    setOrderDestino(pedidos[0])
                    setMsgErroBusca('')
                } else if (orderOrigem !== undefined && orderOrigem !== null) {
                    let order = new OrderObj()
                    order.identificadorPrincipal = identificadorDestinoRef.current.value
                    order.tipo = orderOrigem.tipo

                    setOrderDestino(order)
                }

                setLoadingDestinationOrder(false)
            })
        }
        else {
            enqueueSnackbar("Impossível enviar pedidos para esta comanda", { variant: "error" })
        }
    }

    const handleKeyDownIdentificadorDestino = (event) => {
        if (13 === event.keyCode) {
            handleSearchOrderDestino()
        }
    }

    const handleChangeIdentificadorDestino = () => {
        setMsgErroBusca("")
        setOrderDestino()
    }

    const buscarPedidoPeloIdentificadorPrincipal = (identificador, onComplet) => {
        //verifica se o card existe na lista dos cards
        const foundIdentificador = cardList.find((card) => card.identificador?.identificador === identificador)

        if (!foundIdentificador) {
            onComplet()
            setMsgErroBusca()
            enqueueSnackbar("Nenhuma " + MODELO_IDENTIFICADOR_PRINCIPAL + " Encontrada para o identificador informado", { variant: "error" });
            setOrderDestino()
            return;
        }

        service.get(`/api-v2/pedidoIntegracao/listByIdentificadorPrincipal?identificadorPrincipal=${identificador}&tipos=${MODELO_IDENTIFICADOR_PRINCIPAL}&importado=false&cancelado=false&pago=NAO`)
            .then(pedidos => {

                onComplet(pedidos)

            }).catch(err => {

                onComplet()

                setMsgErroBusca()
                console.error(err)

                extractErrorMessage(err, "Não foi possível buscar pedidos pelo identificador principal.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            })
    }

    const handleToggleAll = () => {
        let orderCopy = Object.assign({}, orderOrigem)


        orderCopy.iFoodOrder.items.map((item) => {
            item.checked = !item.checked
            return null;
        })

        setOrderOrigem(orderCopy)
    }

    const customListItemsOrigem = (items) => (
      <div>
        <Card variant="outlined">
          <CardHeader
            style={{
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            avatar={
              <Checkbox onClick={handleToggleAll} disabled={!items?.length} />
            }
            title="Selecionar todos"
          />

          <List dense component="div" role="list">
            <Divider />

            {items?.map((item) => {
              const label = `${item.quantity} x ${item.name}`;

              return (
                <ListItemButton key={item.referenceCode} role="listitem">
                  <ListItemIcon>
                    <Checkbox
                      name={item.referenceCode}
                      checked={item.checked || false}
                      onClick={() => handleChangeCheckbox(item)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={item.referenceCode} primary={label} />
                </ListItemButton>
              );
            })}
            <ListItem />
          </List>
        </Card>
      </div>
    );

    const customListItemsDestino = (items) => (

        <Card variant="outlined">
            <List dense component="div" role="list">
                {items?.map((item) => {
                    const label = `${item.quantity} x ${item.name}`
                    let style = {}
                    if (item.checked) {
                        style = { color: 'primary.main' }
                    }

                    return (
                        <ListItemButton key={item.referenceCode} role="listitem">
                            <ListItemText id={item.referenceCode} sx={style} primary={label} />
                        </ListItemButton>
                    );
                })}
                <ListItem />
            </List>

        </Card>
    );

    const handleAllDestino = () => {
        let origemCopy = Object.assign({}, orderOrigem)
        let origemOrignal = JSON.parse(JSON.stringify(orderOrigem))
        let itemsChecked = origemCopy.iFoodOrder.items.filter((item) => item.checked)
        /*Verificar se ordem de destino não é a mesma de origem palitativo para pedidos que só pegam o prineiro elemento  */
        if (itemsChecked && itemsChecked.length > 0) {
            let destinoCopy = Object.assign({}, orderDestino)

            if (!orderDestino) {
                enqueueSnackbar("Nenhum pedido de destino informado", { variant: "error" });
                return;
            }

            itemsChecked.map((item) => {
                // origemCopy.deletedItems.push(item)

                const indexOf = origemCopy.iFoodOrder.items.indexOf(item)
                origemCopy.iFoodOrder.items.splice(indexOf, 1)

                item.logs = `${(item.observations || '')} Item transferido do pedido de origem #${origemCopy.id}`

                destinoCopy.iFoodOrder.items.push(item)

                return null;

            })

            //copia para não afetar os itens originais
            origemCopy.deletedItems = JSON.parse(JSON.stringify(itemsChecked))

            origemCopy.deletedItems.map((item) => {
                item.deletedByTransference = true;
                return null;
            })

            if (origemCopy.iFoodOrder?.benefits && origemCopy.iFoodOrder?.benefits?.length) {
                origemCopy.iFoodOrder.benefits[0].value = totalDiscountItems(origemCopy.iFoodOrder)
            }

            if (destinoCopy.iFoodOrder?.benefits && destinoCopy.iFoodOrder?.benefits?.length) {
                destinoCopy.iFoodOrder.benefits[0].value = totalDiscountItems(destinoCopy.iFoodOrder)
            } else {
                destinoCopy.iFoodOrder.benefits = [{
                    target: 'CART',
                    value: totalDiscountItems(destinoCopy.iFoodOrder),
                }]
            }

            setOrderOrigem(origemCopy)
            setOrderDestino(destinoCopy)

            onSentDestino([origemCopy, destinoCopy], origemOrignal)
        }
    }

    return (
        <div>
            <Grid
                container
                spacing={1}>

                <Grid item xs={5}>
                    {maisPedidosOrigem >= 2 && (
                        <Alert severity="warning">
                            Esta comanda possuí {maisPedidosOrigem} pedidos, por favor agrupe os pedidos antes de transferir os itens. Os itens estão sendo transferidos do pedido Ref. {orderOrigem?.id}.
                        </Alert>)
                    }
                    <TextField
                        label={`${LABEL_IDENTIFICADOR_PRINCIPAL} de origem`}
                        placeholder="'Enter' para buscar"
                        variant="filled"
                        autoFocus
                        error={!!msgErroBusca}
                        helperText={msgErroBusca}
                        fullWidth
                        inputRef={identificadorOrigemRef}
                        onKeyDown={handleKeyDownIdentificadorOrigem}
                        onChange={handleChangeIdentificadorOrigem}
                        disabled={loadingOrigemOrder}
                        type="number"
                        InputProps={{
                            endAdornment: (
                                loadingOrigemOrder ? (
                                    <InputAdornment position="end">Buscando...</InputAdornment>
                                ) :
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={onClickSearchIdentificadorOrigem} size="large">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}></Grid>

                <Grid item xs={5}>
                    {maisPedidosDestino >= 2 && (
                        <Alert severity="warning">
                            Esta comanda possuí {maisPedidosDestino} pedidos, por favor agrupe os pedidos antes de transferir os itens. Os itens estão sendo transferidos para o pedido Ref. {orderDestino?.id}.
                        </Alert>)
                    }
                    <TextField
                        label={`${LABEL_IDENTIFICADOR_PRINCIPAL} de destino`}
                        placeholder="'Enter' para buscar"
                        variant="filled"
                        fullWidth
                        inputRef={identificadorDestinoRef}
                        onKeyDown={handleKeyDownIdentificadorDestino}
                        onChange={handleChangeIdentificadorDestino}
                        disabled={loadingDestinationOrder}
                        type="number"
                        InputProps={{
                            endAdornment: (
                                loadingDestinationOrder ? (
                                    <InputAdornment position="end">Buscando...</InputAdornment>
                                ) :
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={loadingDestinationOrder}
                                            edge="end"
                                            onClick={onClickSearchIdentificadorDestino}
                                            size="large">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                    />

                </Grid>
            </Grid>

            <Grid item>
                <Grid
                    container
                    spacing={1}>

                    <Grid item xs={5}>
                        <Box>
                            {/*Essa função será alterada para comportar todos os pedidos pode ser usado um map para listar ela em cada pedido dentro da ordem*/}
                            {customListItemsOrigem(orderOrigem?.iFoodOrder.items)}
                        </Box>
                    </Grid>

                    <Grid item xs={2}>
                        <Grid container spacing={1} direction="column" alignItems="center">
                            <Grid item>
                                <Button
                                    disabled={!existsOrderOrigem || maisPedidosOrigem >= 2 || maisPedidosDestino >= 2}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllDestino}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                            </Grid>

                            {/* <Grid item>
                                <Button
                                    disabled={!existsOrderOrigem}
                                    variant="outlined"
                                    size="small"
                                    // onClick={handleCheckedRight}
                                    // disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                            </Grid> */}

                            {/* <Grid item>
                                <Button
                                    disabled={!existsOrderOrigem}
                                    variant="outlined"
                                    size="small"
                                    // onClick={handleCheckedLeft}
                                    // disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>

                            <Grid item>

                                <Button
                                    disabled={!existsOrderOrigem}
                                    variant="outlined"
                                    size="small"
                                    // onClick={handleAllLeft}
                                    // disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={5}>
                        <Box>
                            {/*Pode ser editada e utilizada da mesma forma que a anterior */}
                            {customListItemsDestino(orderDestino?.iFoodOrder.items)}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
