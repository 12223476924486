import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'AvatarForString';

const classes = {
  root: `${PREFIX}-root`,
  small: `${PREFIX}-small`,
  large: `${PREFIX}-large`
};

const StyledAvatar = styled(Avatar)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  [`& .${classes.small}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  [`& .${classes.large}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: "130%"
  }
}));

const stringToColor = (string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
  
    return color;
}

export function AvatarForString({ value, size, ...other }){



    return (
      <StyledAvatar 
      className={size ? ((size === "small" && classes.small) || (size === "large" && classes.small )) : null }
          style={{
              backgroundColor: stringToColor(value)
          }} {...other} >
          { value.substring(0,1).toUpperCase() }
      </StyledAvatar>
    );
}
