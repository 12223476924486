import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <App /> ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      console.log("Nova versão disponível... Aplicativo será atualizado");
      //if (window.confirm('New version available!  refresh to update your app?')) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
      //}
    }
  }
};

serviceWorker.register(configuration);
