import { FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import { pendingAmount } from '../../../../../util/order/orderUtil';
import { currencyFormat } from '../../../../../util/formatter';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


export default function SplitCustomerInvoice({ order, setValueSelected }) {
    const [divideInto, setDivideInto] = useState(order?.quantidadePessoas || 1)
    const [dividedValue, setDivideValue] = useState(0.0)
    const [dividedValuePending, setDivideValuePending] = useState(0.0)
    const pendingAmountValue = pendingAmount(order?.iFoodOrder)

    const handleChange = (event) => {
        setDivideInto(event.target.value);
    };

    useEffect(() => {

        if (pendingAmountValue > 0 && divideInto > 0) {
            //faz a divisão pelo numero de pessoas solicitado, e fixa as casas decimais a 2
            const div = parseFloat((pendingAmountValue / divideInto).toFixed(2))

            //Utiliza o curencyFormat para arredondar o valor da divisão pelo oq é mostrado na tela
            var temp = currencyFormat(div).replace(",", ".")//troca as virgulas por pontos na string do formato de moeda
            temp = parseFloat(temp.replace(/[^0-9.-]+/g, ""));//substitui os itens que não são números ou pontos por vazio
            var diferenca = pendingAmountValue - (temp * divideInto)//utiliza o valor mostrado na tela que seria pago por cliente 
            //para calcular o valor excedente ou faltante da divisão

            //fixa o valor faltante a 4 casas decimais e depois o converte a Double para salvar a variavel
            setDivideValuePending(parseFloat(diferenca.toFixed(4)))
            setDivideValue(div)
            setValueSelected(div)
        }
        // eslint-disable-next-line
    }, [divideInto])

    const onFocus = (event) => {
        event.target.select()
    }

    const handleClickAddOrRemove = (sinal) => {
        let value = 0

        if (sinal === '+') {
            value = parseInt(divideInto) + 1
        } else {
            value = parseInt(divideInto) - 1

            if (value === 0) {
                value = 1
            }
        }

        setDivideInto(value)
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <FormControl variant="outlined">
                        <InputLabel>Dividir a conta em</InputLabel>
                        <OutlinedInput
                            type='text'
                            label="Dividir a conta em"
                            inputProps={{ min: 1, style: { textAlign: 'center' } }}
                            onFocus={onFocus}
                            value={divideInto}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton onClick={() => handleClickAddOrRemove('+')} edge="start" size="large">
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleClickAddOrRemove('-')} edge="end" size="large">
                                        <RemoveIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelwidth={130}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    {/*Utiliza o sistema de divisão de comandas para verificar se existe resto na divisão */}
                    {divideInto > 1 && (-0.009 >= dividedValuePending || dividedValuePending >= 0.009) ? (
                        <>{/*Caso exista resto separa em 2 contas onde uma delas é somado o resto, e as outras ficam somente com o valor da divisão */}
                            <Alert variant="outlined" severity="success">
                                {`${currencyFormat(dividedValue)} para ${divideInto - 1} ${divideInto - 1 > 1 ? "pessoas" : "pessoa"} e ${currencyFormat(dividedValue + dividedValuePending)} para uma pessoa`}
                            </Alert>
                        </>
                    ) : (
                        <>
                            {divideInto > 1 && (
                                <Alert variant="outlined" severity="success">
                                    {`${currencyFormat(dividedValue)} para cada pessoa `}
                                </Alert>
                            )}
                        </>
                    )}

                </Grid>
            </Grid>

        </React.Fragment >
    );
}
