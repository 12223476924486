export const toogleTefDialog = (currentState, action) => {
    const { dialogTef } = action
    const { open, tef } = dialogTef

    return {
        ...currentState,
        dialogTef: { ...dialogTef, open, tef }
    }
}

export const initialTefDialog = () => {
    const tef = JSON.parse(localStorage.getItem('_tef_'))

    return {
        tef,
        open: !!tef,
    }
}