import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SearchBox from '../SearchBox';
import { useSelector, useDispatch } from 'react-redux'
import AuthService from '../../../service/AuthService';
import { useSnackbar } from 'notistack';
import DetailOrder from "../DetailOrder"
import ImportOrder from "../ImportOrder"
import { onConfirmOrder, playSoundForPendingOrder } from "../../../util/order/orderUtil"
import { getSettings } from "../../settings/settings"
import { Button, Grid, Hidden, Tooltip } from '@mui/material';
import PaymentDialog from '../Payment';
import SocketContext from '../../../util/socket';
import { hashCode } from '../../../util/genericUtil';
import BrowserNotification, { spawnNotification } from '../../../util/browserNotification';
import AcoesIntegracao from '../Integracoes/AcoesIntegracoes';
import SelectDeliverymanBoard from '../NewOrder/SelectDeliveryman/SelectDeliverymanBoard';
import clsx from 'clsx';
import FiscalDocument from '../fiscalDocument';
import Order from '../NewOrder/Order';
import LinkIcon from '@mui/icons-material/Link';
import { useHistory } from 'react-router-dom';
import ViewListIcon from '@mui/icons-material/ViewList';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ListAllOrder from '../ListAllOrder';
import { getParamByKey } from '../../../component/parameter';
import MinimizedOrders from '../NewOrder/MinimizedOrders';
import ReactGA from "react-ga4";
import { SendOrdersDialog } from '../SendOrdersDialog';
import { TefDialog, TefElginDialog } from '../Payment/Tef';
import SimpleBackdrop from '../../../component/generics/SimpleBackdrop';
import { ListBoard } from './ListBoard';
import { DragDropContext } from 'react-beautiful-dnd';
import { SITUACOES, changeSituacaoPedidoOnServer } from '../../../util/board/BoardUtil';
import { extractErrorMessage } from '../../../util/http/HttpUtil';

import { SaleInfoDialog } from '../Payment/Tef/SaleInfoDialog';
import { IfoodWidget } from '../../../component/WidgetIfood/WidgetIfood';

const PREFIX = 'Board';

const classes = {
  listContent: `${PREFIX}-listContent`,
  listContainer: `${PREFIX}-listContainer`
};

const Root = styled('div')(() => ({
  [`& .${classes.listContent}`]: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 280px))',
    minWidth: '1750px',
  },

  [`& .${classes.listContainer}`]: {
    minHeight: 'calc(100vh - 100px)',
    overflowX: 'auto',
    overflowY: 'auto',
  }
}));

export function PedidosButton() {
  const dispatch = useDispatch()

  function onClickListAllOrder() {
    //Envia para o analytics a informação do clique no botão da lista de pedidos
    ReactGA.event({
      category: 'Button',
      action: 'Click pedidos',
      label: 'Botão lista de pedidos'
    });
    dispatch({ type: 'TOOGLE_DIALOG_ALL_ORDER', dialogListAllOrder: { open: true } })
  }

  return (
    <Tooltip disableInteractive title="Listagem de pedidos">
      <Button startIcon={<ViewListIcon />} onClick={onClickListAllOrder} size="small" color="grey">
        Pedidos
      </Button>
    </Tooltip>
  )
}

export function IntegracoesButton() {
  const history = useHistory()
  const dispatch = useDispatch()

  function openIntegracoes({ goTo }) {
    history.push(goTo)

    //Envia para o analytics a informação do clique no botão da lista de integrações
    ReactGA.event({
      category: 'Button',
      action: 'Click integrações',
      label: 'Botão lista integrações'
    })

    dispatch({ type: 'TOOGLE_DRAWER_INTEGRACOES', drawerIntegracoes: { open: true } })
  }

  return (
    <Tooltip disableInteractive title="Integrações ativas">
      <Button size="small" startIcon={<LinkIcon />} onClick={openIntegracoes} color="grey">
        Integrações
      </Button>
    </Tooltip>
  )
}

function Board() {

  const boardData = useSelector(state => state.boardState.board)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const poolingIntegrationRunning = useRef(false);
  const lastEventHashRef = useRef(null);
  const generalParameters = useSelector(state => state.generalParameters);
  const COLUNA_AGUARDANDO_RETIRADA_PDV = getParamByKey(generalParameters, 'COLUNA_AGUARDANDO_RETIRADA_PDV')?.valor;

  useEffect(() => {
    //console.log('criou o pooling')
    const poolingInterval = setInterval(tryStartPooling, 35 * 1000);

    return () => {
      return clearTimeout(poolingInterval);
    }
    //  eslint - disable - next - line
  }, [])


  function tryStartPooling() {

    if (poolingIntegrationRunning.current) {
      console.log('Busca pooling abortado - Motivo: busca em andamento - novo polling' + new Date())
    } else {
      poolingPedidosIntegracao()
    }
  }


  function poolingPedidosIntegracao() {
    poolingIntegrationRunning.current = true
    let service = new AuthService()
    service.post('/api-v2/pedidoIntegracao/poolingIntegracoes')
      .then(eventos => {
        console.log("Eventros retornados pelo pooling", eventos)

        let eventsToBeAddOnBoard = []
        eventos.map(evento => {
          if (evento.mensagemErro) {
            enqueueSnackbar(evento.mensagemErro, { variant: 'error', });
          } else {
            eventsToBeAddOnBoard.push(evento)
          }
          return null;
        })

        addOrReplaceOnBoard(eventsToBeAddOnBoard)

        /* APLICA ACOES CONFIGURADAS PARA NOVOS PEDIDOS */
        applyActionsOnEvents(eventsToBeAddOnBoard);


      }).catch(err => {
        console.error("ERRO poolingPedidosIntegracao", err)
        enqueueSnackbar("Falha ao buscar pedidos das integrações.", { variant: 'error', });
      }).finally(() => {
        poolingIntegrationRunning.current = false
        console.log("Finalizou pooling")
      })
  }

  function addOrReplaceOnBoard(eventos) {
    dispatch({ type: 'UPDATE_BOARD_FROM_EVENTS', events: eventos })
    if (eventos && eventos.length > 0) {

      let currentCalculedHashEventos = hashCode(JSON.stringify(eventos))
      let lastHashEventos = lastEventHashRef.current

      console.log("LAST HASH", lastHashEventos)

      if (lastHashEventos && lastHashEventos === currentCalculedHashEventos) {
        //EVENTOS NAO MUDARAM - NAO ENVIA SOCKET (EVITA USO DESNECESSARIO)
        console.log("OS EVENTOS NAO MUDARAM", lastHashEventos)
      } else {
        //EVENTOS MUDARAM - ENVIA PELO SOCKET
        console.log("EVENTOS MUDARAM - SOCKET UPDATE_BOARD_FROM_EVENTS", eventos)
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'UPDATE_BOARD_FROM_EVENTS', events: eventos } })
      }

      lastEventHashRef.current = currentCalculedHashEventos;
    }

  }

  function playSoundForPendingOrderIfEnabled(eventsToBeAddOnBoard) {

    if (eventsToBeAddOnBoard.length > 0) {
      let eventsPending = eventsToBeAddOnBoard.filter(event => 'PENDENTE' === event.pedidoIntegracao.situacao);

      if (eventsPending.length > 0) {
        const settings = getSettings()
        if ("ENABLED" === settings.enableRingSongNewOrder) {
          playSoundForPendingOrder()
        } else {
          console.log('PLAY SOUND DISABLED')
        }
      }
    }
  }

  function notifyForPendingOrder(eventsToBeAddOnBoard) {

    if (eventsToBeAddOnBoard.length > 0) {
      let eventsPending = eventsToBeAddOnBoard.filter(event => 'PENDENTE' === event.pedidoIntegracao.situacao);

      if (eventsPending.length > 0) {

        spawnNotification("Existem pedidos pendentes", "Clique para ver", process.env.PUBLIC_URL + "/static/images/integrations/logo-SO.png", (event) => {
          let notification = event.currentTarget
          notification.close()
          window.focus();
        })

      }
    }

  }

  function applyActionsOnEvents(eventsToBeAddOnBoard) {

    //RING TONE IF NECESSARY

    playSoundForPendingOrderIfEnabled(eventsToBeAddOnBoard)

    //Mostra alerta no navegador (aquele do SO)
    notifyForPendingOrder(eventsToBeAddOnBoard)
  }

  const onClickSendOrders = () => {
    //Envia para o analytics a informação do clique no botão do enviar pedidos
    ReactGA.event({
      category: 'Button',
      action: 'Click enviar pedidos',
      label: 'Botão enviar multíplos pedidos'
    });
    dispatch({ type: 'TOOGLE_SEND_ORDERS_DIALOG', dialogSendOrders: { open: true } })
  }

  const getBoards = () => {
    return boardData.lists
  }

  const moveCard = (moveOptions) => {
    dispatch({ type: 'MOVE_CARD', moveOptions })
  }

  function getCardById(id) {
    return boardData.lists.reduce((acc, item) => {
      const card = item.cards.find(card => card.data.id === id)

      return card || acc
    }, {})
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const draggedListIndex = Number(result.source.droppableId.replace('droppable-', ''))
    const targetListIndex = Number(result.destination.droppableId.replace('droppable-', ''))

    if (draggedListIndex === targetListIndex && result.source.index === result.destination.index) {
      console.log('Card solto na mesma posição', draggedListIndex, targetListIndex)
      return
    }

    console.log('List origem x destino', draggedListIndex, targetListIndex)

    const cardId = Number(result.draggableId.replace('draggable-', ''))
    const card = getCardById(cardId)

    const novaSituacao = SITUACOES[targetListIndex].key
    const SITUACAO_ANTIGA = card.data.situacao;

    if (novaSituacao === 'ENVIADO') {
      dispatch({ type: 'TOOGLE_SIMPLE_DROPBOX', simpleBackdrop: { open: true } })
    }

    moveCard({
      draggedListIndex,
      targetListIndex,
      draggedIndex: result.source.index,
      targetIndex: result.destination.index
    })

    changeSituacaoPedidoOnServer(card.data, novaSituacao)
      .then(pedido => {
        const SITUACAO_ATUAL = pedido.situacao
        if ("CONFIRMADO" === SITUACAO_ATUAL && "PENDENTE" === SITUACAO_ANTIGA) {
          onConfirmOrder(pedido, enqueueSnackbar, (respNotificationOrders) => {
            respNotificationOrders.map((orderTemp) => {
              dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderTemp })
              dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderTemp } })
            })
          })
        }

        /*
         * SE MOVIDO PRA ENVIADO,
         * FOR PRA ENTREGA E NAO TIVER ENTREGADOR 
         * E NAO TIVER SIDO ENVIADO P LOGISTICA 
         **/
        const isDelivery = pedido.iFoodOrder?.deliveryMethod?.mode === 'DELIVERY'
        if ("ENVIADO" === SITUACAO_ATUAL
          && (SITUACAO_ANTIGA !== "ENVIADO")
          && isDelivery
          && !pedido.pessoaEntregador
          && "ENVIADO" !== pedido.statusLogistica) {
          dispatch({ type: 'TOOGLE_DIALOG_DELIVERYMAN', detailDialogDeliveryman: { open: true, order: pedido } })
        }

        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedido })
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedido } })

        if (pedido.mensagemParaUsuario) {
          enqueueSnackbar(pedido.mensagemParaUsuario, { variant: "warning" })
        }

      }).catch(err => {
        console.error(err)

        extractErrorMessage(err, "Falha ao alterar situação do pedido")
          .then(msg => enqueueSnackbar(msg, { variant: "error" }))

        //desfaz
        const moveCardObj = {
          targetListIndex: draggedListIndex,
          draggedListIndex: targetListIndex,
          draggedIndex: 0,
          targetIndex: result.source.index
        }

        console.log("VOLTANDO CARD PARA SUA POSICAO DE ORIGEM")
        console.log(moveCardObj)

        moveCard(moveCardObj)
      }).finally(() => {
        dispatch({ type: 'TOOGLE_SIMPLE_DROPBOX', simpleBackdrop: { open: false } })
      })
  }

  return (
    <Root sx={{ overflow: 'hidden' }}>
      <AcoesIntegracao />

      <Box className={classes.listContainer} sx={{ overflow: 'hidden' }} >

        <TefElginDialog />

        <Box pl={1} pt={1}>
          <Grid container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center">

            <Grid item>
              <IntegracoesButton />

              <PedidosButton />

              <Hidden mdDown>
                <Tooltip disableInteractive title="Alterar status multiplos pedidos">
                  <Button startIcon={<DoubleArrowIcon />} onClick={onClickSendOrders} size="small" color="grey">
                    Alterar status
                  </Button>
                </Tooltip>
              </Hidden>

              <MinimizedOrders />

            </Grid>

            <Grid item>
              <SearchBox />
            </Grid>

          </Grid>
        </Box>

        <Box p={1} id="board-box" className={clsx(classes.listContent)} sx={{ overflow: 'hidden' }}>
          <DragDropContext onDragEnd={onDragEnd} >
            {getBoards().map((list, index) => (
              <ListBoard
                key={list.id}
                list={list}
                listIndex={index}
                display={(index === 2 && COLUNA_AGUARDANDO_RETIRADA_PDV == 0) && boardData?.lists[2].cards?.length === 0 ? 'none' : 'initial'} />
            ))}
          </DragDropContext>
        </Box>

      </Box>

      <DetailOrder />
      <ImportOrder />
      <PaymentDialog />
      <FiscalDocument />
      <SocketContext />

      <BrowserNotification />
      <SelectDeliverymanBoard />
      <Order />
      <ListAllOrder />
      <TefDialog />
      <SaleInfoDialog />
      <SimpleBackdrop />
      <IfoodWidget />

      <Hidden mdDown>
        <SendOrdersDialog />
      </Hidden>

    </Root>
  );
}


export default Board;