import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const PulseBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color = '#ffc107' }) => ({
  '.MuiBadge-badge': {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: `1px solid ${color}`,
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default PulseBadge;
