import {
    Badge,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Grid,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import clsx from 'clsx';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { useDispatch, useSelector } from 'react-redux';
import { getParamByKey } from '../../../../../component/parameter';
import AuthService from '../../../../../service/AuthService';
import { useSnackbar } from 'notistack';
import { useConnectionStatus } from '../../../../../util/http/HttpUtil';

const PREFIX = 'CardIdentificadorSecundario'

const classes = {
    card: `${PREFIX}-card`,
    cardHeader: `${PREFIX}-cardHeader`,
    cardContent: `${PREFIX}-cardContent`,
    customerName: `${PREFIX}-customerName`,
    openedCard: `${PREFIX}-openedCard`
}

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
    [`& .${classes.card}`]: {
        height: theme.spacing(14),
    },

    [`& .${classes.cardHeader}`]: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1.2),
        backgroundColor: theme.palette.personal.header
    },

    [`& .${classes.cardContent}`]: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },

    [`& .${classes.customerName}`]: {
        whiteSpace: 'nowrap',
        fontSize: '0.9em'
    },

    [`& .${classes.openedCard}`]: {
        
    }
}))

export default function CardIdentificadorSecundario({ identificadorCard }) {

    const identificador = identificadorCard.identificador
    const orderList = identificadorCard.orderList
    const countOrders = orderList && orderList.length
    const openedCard = orderList && countOrders > 0

    const generalParameters = useSelector(state => state.generalParameters)
    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString
    const labelIdentificadorSecundario = MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' ? 'Mesa ' : 'Comanda'

    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const isConnected = useConnectionStatus()

    /*const onClickOpenDatailOrdersOld = () => {
        dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard } } })
    }*/

    const onClickOpenDatailOrders = () => {
        if (!isConnected) {
            enqueueSnackbar('Não é possivel carregar a mesa sem estar conectado', {
                variant: 'error',
                preventDuplicate: true,
            })
            return
        }

        setLoading(true)

        let identificadorPrincipal = identificador.identificador

        let service = new AuthService()
        service.get(`/api-v2/pedidoIntegracao/listByIdentificadorSecundario?identificadorSecundario=${identificadorPrincipal}&importado=false&pago=NAO&cancelado=false`)
            .then(orderList => {
                let identificadorCardCopy = Object.assign({}, identificadorCard) 
                identificadorCardCopy.orderList = orderList
                dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: true, data: { identificadorCard: identificadorCardCopy } } })
            }).catch(err =>{
                console.error("Erro ao carregar pedidos por identificador", err)  
            }).finally(() =>{
                  setLoading(false)
            });
    }

    return (
        <StyledCardActionArea onClick={onClickOpenDatailOrders}>

            <Card
                variant="outlined"
                className={clsx([classes.card, openedCard && classes.openedCard])}>

                <CardHeader
                    className={clsx(openedCard && classes.cardHeader)}
                    title={null}
                    subheader={null}
                    avatar={
                        countOrders > 0 &&
                        <Tooltip title={`${countOrders} comanda(s) nesta mesa`}>
                            <Badge
                                color="primary"
                                overlap="circular"
                                badgeContent={countOrders}
                                variant="standard">
                                <FilterNoneIcon />
                            </Badge>
                        </Tooltip>
                    }
                />

                <CardContent className={classes.cardContent}>
                    {loading ? <LinearProgress /> : null}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item>
                            <Typography variant='body1' align='center'>
                                {labelIdentificadorSecundario}
                            </Typography>
                            <Typography variant='h3' align='center'>
                                {identificador.identificador}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </StyledCardActionArea>
    );
}