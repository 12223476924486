import React from 'react';
import { Box, Typography } from '@mui/material';
export default function FormSection({ title, subtitle, children, pt=2 }) {
  return (
      <Box pb={2}>
      { title && <Typography variant="subtitle1">{title}</Typography> }
      { subtitle && <Typography variant="subtitle2">{subtitle}</Typography> }
      <Box pt={1}>
        {children}
      </Box>
      
      </Box>
  );
}