import { Box, Dialog, Button, Slide, TextField, Tooltip, Hidden } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { parseCodigoBarras } from '../../../util/codigoBarras/codigoBarrasHelper';
import { useSelector } from 'react-redux';
import hotkeys from 'hotkeys-js'
import { getParamByKey } from '../../../component/parameter';
import AddIcon from '@mui/icons-material/Add';

const FORMATO_DEFAULT = "V" //V ou P para codigos de balança

export default function InputCodBar({ productMenu, handleClickAddProduct, parentEle }) {

    const inputCodBarRef = useRef()
    const orderOpen = useSelector(state => state.dialogOrder.open)
    const [open, setOpen] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [inputErrorMode, setInputErrorMode] = useState(false)
    const generalParameters = useSelector(state => state.generalParameters)
    const FORMATO = getParamByKey(generalParameters, 'MODELO_COD_BARRAS_BALANCA')?.valorString || FORMATO_DEFAULT;
    const openRef = useRef()

    openRef.current = open;

    useEffect(() => {
        let isMounted = true;

        hotkeys('f8', (event) => {
            if (!orderOpen || !isMounted) return;

            event.preventDefault();
            event.stopPropagation();

            const isHidden = parentEle.current?.getAttribute('aria-hidden') || false;

            if (isHidden && !openRef.current) return;

            setOpen(open => !open);
        })

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleKeyDown = (event) => {

        const inputValue = inputCodBarRef.current.value;

        if (event.key === 'Enter') {
            addProductByCodBar(inputValue, handleClickAddProduct, productMenu, FORMATO, setErrorMsg, setInputErrorMode, inputCodBarRef)
        }
    }

    return (
        <div>
            <Hidden smDown>
                <Tooltip title="Adicionar por código de barras (F8)">
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setOpen(true)}
                        size="small"
                        aria-label="adidionar-produto-por-codigo-barras" >
                        Cód. de barras
                    </Button>
                </Tooltip>
            </Hidden>

            <Dialog
                aria-labelledby="dialog-codigo-barras"
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="sm">
                <Box p={2}>
                    <Slide in={open}>

                        <TextField
                            inputRef={inputCodBarRef}
                            label="Código de barras"
                            autoFocus
                            variant="outlined"
                            onKeyDown={handleKeyDown}
                            fullWidth
                            error={inputErrorMode}
                            helperText={errorMsg}
                        />
                    </Slide>
                </Box>
            </Dialog>
        </div>
    )
}

/**
 * Separada a função de adicionar produto por código de barras, de forma que possa ser chamada em outros pontos do sistema
 * A função busca um produto por codigo de barras e caso encontre já o adiciona ao order, utilizando a função handleClickAddProduct.
 * @param {*} codigo 
 * @param {*} handleClickAddProduct 
 * @param {*} productMenu 
 * @param {*} FORMATO 
 * @param {*} setErrorMsg 
 * @param {*} setInputErrorMode 
 * @param {*} inputCodBarRef 
 */
export const addProductByCodBar = (codigo, handleClickAddProduct, productMenu, FORMATO, setErrorMsg, setInputErrorMode, inputCodBarRef) => {
    const { qtde, qtdeUnitario, valorTotal, codigoBarrasRealProduto, pareceCodBarBalanca, valorUnitario } = parseCodigoBarras(codigo, { formato: FORMATO })
    let productAdded = false;
    let lastProductAdded = null

    productMenu.map(productFather => {
        let openKgPanel = productFather.produto.unidadeMedida.sigla === 'KG'
        const codigoBarrasProduto = productFather.produto.codigoBarras

        if (codigoBarrasProduto === codigoBarrasRealProduto) {
            const { price, quantity } = calculatePriceAndQuantity({
                qtde,
                valorTotal,
                qtdeUnitario,
                valorUnitario,
                pareceCodBarBalanca,
                formato: FORMATO,
                produto: productFather.produto,
            })

            handleClickAddProduct(productFather, { quantity, price, openKgPanel }, codigo)
            productAdded = true;
            lastProductAdded = productFather.produto

        }
        return null;
    })

    if (!!setErrorMsg && !!setInputErrorMode && !!inputCodBarRef) {
        if (productAdded) {
            setErrorMsg(`Último produto adicionado: '${lastProductAdded.descricao}'`)
            inputCodBarRef.current.value = ""
            setInputErrorMode(false)

        } else {
            setErrorMsg(`Nenhum produto encontrado para o cód ${codigo}`)
            inputCodBarRef.current.select()
            setInputErrorMode(true)
        }
    }

}

export function calculatePriceAndQuantity({ formato, produto, pareceCodBarBalanca, valorUnitario, valorTotal, qtde, qtdeUnitario }) {
    const valorUnitarioVendaProduto = produto.valorUnitarioVenda

    let quantity = qtde || 1
    let price = valorUnitario || valorUnitarioVendaProduto
    let openKgPanel = produto.unidadeMedida.sigla === 'KG'

    if (pareceCodBarBalanca) {
        openKgPanel = false

        if (formato === 'V') {
            price = valorUnitarioVendaProduto
            quantity = valorTotal / valorUnitarioVendaProduto
        } else if (formato === 'P') {
            quantity = qtde

            if (produto.unidadeMedida.sigla !== 'KG') {
                quantity = qtdeUnitario
            }

            price = valorUnitarioVendaProduto
        }
    }

    return { price, quantity, openKgPanel }
}