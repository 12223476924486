import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography, ButtonBase, Tooltip, useMediaQuery } from '@mui/material';
import AuthService from '../../service/AuthService';
import { dateFormat, dateParse } from "../../util/formatter"
import { useDispatch } from 'react-redux';

const PREFIX = 'MoneyBoxStatus';

const classes = {
    root: `${PREFIX}-root`,
    button: `${PREFIX}-button`,
    caption: `${PREFIX}-caption`,
    value: `${PREFIX}-value`
};

const StyledTooltip = styled(Tooltip)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
    },

    [`& .${classes.button}`]: {
        '&:hover': {
            opacity: 0.5,
        },
    },

    [`& .${classes.caption}`]: {
        opacity: "0.8",
    },

    [`& .${classes.value}`]: {
        fontWeight: "500",
    }
}));

export default function MoneyBoxStatus() {


    const [loading, setLoading] = useState(false)
    const [statusMoneyInfo, setStatusMoneyInfo] = useState({
        status: "DISABLED", info: "Carregando informações..."
    })
    const URL_BASE = process.env.REACT_APP_APPLICATION_URL
    const URL_CAIXA = `${URL_BASE}/admin/caixa/caixa.jsf`
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

    const verifyStatusAbertura = () => {

        let service = new AuthService()
        let profile = service.getProfile()
        if (profile) {
            setLoading(true)
            service.get("/service?entity=aberturaTurno&action=get&formato=json&username=" + profile.username)
                .then(abertura => {
                    setLoading(false)

                    let BOX_STATUS = ""
                    let informations = ""

                    if (abertura && abertura.status !== 'N' && !abertura.dataFechamento) {
                        BOX_STATUS = "OPEN"
                        informations = `Aberto ${dateFormat(dateParse(abertura.dataAbertura, 'dd/MM/yyyy HH:mm:ss'))}`
                        dispatch({ type: 'UPDATE_MONEY_BOX_STATUS', statusMoneyBox: { status: BOX_STATUS } }) //salva na variável global
                        //o status do caixa como aberto
                    } else if (abertura && abertura.status !== 'N' && abertura.dataFechamento) {

                        BOX_STATUS = "CLOSE"
                        informations = `Caixa fechado ${dateFormat(dateParse(abertura.dataFechamento, 'dd/MM/yyyy HH:mm:ss'))}`
                        dispatch({ type: 'UPDATE_MONEY_BOX_STATUS', statusMoneyBox: { status: BOX_STATUS } }) //salva na variável global
                        //o status do caixa como fechado
                    } else if (abertura && abertura.status === 'N') {

                        BOX_STATUS = "DISABLED"
                        informations = `O Caixa não foi ativado para esse usuário`
                        dispatch({ type: 'UPDATE_MONEY_BOX_STATUS', statusMoneyBox: { status: BOX_STATUS } }) //salva na variável global
                        //o status do caixa como desabilitado
                    }

                    setStatusMoneyInfo({
                        status: BOX_STATUS,
                        info: informations
                    })

                }).catch(err => {
                    setLoading(false)
                    console.error(err)
                })
        }
    }

    const visibilitychangeEvent = (ev) => {
        if ("visible" === document.visibilityState) {
            verifyStatusAbertura()
        }
    }

    useEffect(() => {

        document.addEventListener('visibilitychange', visibilitychangeEvent);

        verifyStatusAbertura()

        return () => {
            document.removeEventListener('visibilitychange', visibilitychangeEvent);
        }
        // eslint-disable-next-line
    }, [])

    /* OPEN, CLOSE, DISABLED */


    return (
        isMobile && statusMoneyInfo.status === 'DISABLED') ? (
        null
    ) : (
        <StyledTooltip title={statusMoneyInfo.info} arrow>
            <ButtonBase
                className={classes.button}
                href={`${URL_BASE}/loginToken?token=${AuthService.getToken()}&url=${URL_CAIXA}`} target="_blank">
                <Paper elevation={0} className={classes.root}>
                    <Box pl={2} pt={1} pr={2} pb={1} textAlign="center">
                        <Typography variant="caption" display="block" className={classes.caption} >Caixa</Typography>
                        {loading && !statusMoneyInfo.status ? 'Buscando...' : null}
                        {
                            statusMoneyInfo.status === "OPEN" && (

                                <Box color="success.main" display="flex" flexDirection="row"
                                    justifyContent="center" alignContent="center">
                                    <Typography variant="h5" display="block" className={classes.value}>aberto</Typography>
                                </Box>

                            )
                        }

                        {
                            statusMoneyInfo.status === "CLOSE" && (
                                <Box color="error.main">
                                    <Typography variant="h5" display="block" className={classes.value}>fechado</Typography>
                                </Box>
                            )
                        }

                        {
                            statusMoneyInfo.status === "DISABLED" && (
                                <Box color="text.disabled">
                                    <Typography variant="h5" display="block" className={classes.value}>inativo</Typography>
                                </Box>
                            )
                        }

                    </Box>
                </Paper>
            </ButtonBase>
        </StyledTooltip>
    );

}