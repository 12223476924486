import { enqueueSnackbar } from 'notistack'
import { usePdvContext } from '../../component/generics/PdvProvider'
import { sendTefToPrint } from '../board/BoardUtil'
import { currencyFormat, onlyDigitsFixed } from '../formatter'
import { extractErrorMessage } from '../http/HttpUtil'
import { useOrderPayment } from '../../pages/layout-components/Payment'
import { useEffect, useState } from 'react'
import AuthService from '../../service/AuthService'
import { useDispatch } from 'react-redux'

// ==============================
//           TEF PAYGO
// ==============================

// status de intenção de venda
// seguintes informações estão presentes no link:
// https://paygodev.readme.io/docs/valores-de-status-ids-e-tipos
export const getTefStatusById = (id) => ({
    5: {
        variant: 'info',
        message: 'Transação pendente de pagamento',
    },
    6: {
        variant: 'info',
        message: 'Transação iniciou o processo de pagamento',
    },
    10: {
        variant: 'success',
        message: 'Pagamento aprovado',
    },
    15: {
        variant: 'error',
        message: 'Transação expirada',
    },
    18: {
        variant: 'info',
        message: 'Solicitado processo de cancelamento',
    },
    19: {
        variant: 'info',
        message: 'Transação iniciou o processo de cancelamento',
    },
    20: {
        variant: 'success',
        message: 'Cancelamento concluído',
    },
    25: {
        variant: 'error',
        message: 'Pagamento não aprovado pela adquirente ou banco emissor',
    },
})[id]

// método que converte a resposta do adquirente para objeto
export function convertRespostaAdquirenteToObject(respostaAdquirente) {
    return respostaAdquirente.split('PWINFO').reduce((acc, item) => {
        if (!item) return acc

        const itemArr = item.split('=')

        acc['PWINFO' + itemArr[0].trim()] = itemArr[1].trim()

        return acc
    }, {})
}

// método para obter o código de autorização da Banrisul
export function getBanrisulAut(pagamentoExterno) {
    const { bandeira, comprovanteAdquirente } = pagamentoExterno

    if (!bandeira || !comprovanteAdquirente) {
        return
    }

    // verifica se a bandeira é da Banrisul
    if (bandeira.indexOf('BANRICOMPRAS') < 0 && bandeira.indexOf('BANRISUL') < 0) {
        return
    }

    // cria um array separando os itens por linha e removendo os espacos em branco e linhas em branco
    const arr = comprovanteAdquirente.split('\n')
        .map(item => item.trim())
        .filter(item => item)

    // verifica se o array contém o código de autorização da Banrisul
    const aut = arr.find(item => {
        if (item.charAt(0) !== 'A') return false
        if (item.length !== 14) return false
        return true
    })

    return aut
}

// O id da forma de pagamento deve ser uma das seguintes opções para o TEF
// 21 - TEF - Crédito
// 22 - TEF - Débito
// 23 - TEF - Voucher
// 24 - TEF - Outros
// 25 - TEF - Carteira Digital
// https://paygodev.readme.io/docs/valores-de-status-ids-e-tipos
export function createTefJson(payment, terminalId) {
    let formaPagamentoId = 0
    const { code, value } = payment
    const formattedValue = currencyFormat(value, { simbol: '' })

    if (code === 'CC') {
        formaPagamentoId = 21
    } else if (code === 'CD') {
        formaPagamentoId = 22
    } else if (code === 'PX' || code === 'PD') {
        formaPagamentoId = 25
    }

    return {
        terminalId,
        formaPagamentoId,
        referencia: null,
        aguardarTefIniciarTransacao: true,
        parcelamentoAdmin: null,
        quantidadeParcelas: 1,
        adquirente: '',
        valorTotalVendido: formattedValue,
    }
}

// requisição que faz o login para a obtenção das informações da pessoa
// https://paygodev.readme.io/docs/operacional
export function tefFetchLogin(json) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TEF_CONTROLPAY}/Login/Login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(json)
        }).then(data => data.json()).then(data => {
            if (data.message) {
                reject(data.message)
                return
            }

            resolve(data)
        }).catch(reject)
    })
}

// requisição que busca os terminais dependendo da pessoa informada
// https://paygodev.readme.io/docs/operacional
export function tefFetchTerminal(key, pessoaId) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TEF_CONTROLPAY}/Terminal/GetByPessoaId?key=${key}&pessoaId=${pessoaId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(data => data.json()).then(data => {
            if (data.message) {
                reject(data.message)
                return
            }

            resolve(data)
        }).catch(reject)
    })
}

// requisição que inicia a venda do TEF
// https://paygodev.readme.io/docs/transacional
export function tefFetchVenda(key, json) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TEF_CONTROLPAY}/Venda/Vender/?key=${key}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(json)
        }).then(data => data.json()).then(data => {
            if (data.message) {
                reject(data.message)
                return
            }

            resolve(data)
        }).catch(reject)
    })
}

// requisição que faz a consulta do pagamento pelo id da intenção de venda
// https://paygodev.readme.io/docs/transacional
export function tefFetchConsultaVenda(key, json) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TEF_CONTROLPAY}/IntencaoVenda/GetByFiltros?key=${key}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(json)
        }).then(data => data.json()).then(data => {
            if (data.message) {
                reject(data.message)
                return
            }

            resolve(data)
        }).catch(reject)
    })
}

// requisição que inicia o processo de cancelar a venda
// https://paygodev.readme.io/docs/transacional
export function tefFetchCancelarVenda(key, json) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_TEF_CONTROLPAY}/Venda/CancelarVenda?key=${key}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify({
                    aguardarTefIniciarTransacao: true,
                    ...json,
                })
            })

            const data = await response.json()

            if (!response.ok) {
                throw new TefError(data.message, data.data, data.code)
            }

            resolve(data)
        } catch (err) {
            reject(err)
        }
    })
}

class TefError extends Error {

    constructor(message = 'Ocorreu um erro', data = '', code = '') {
        super()
        this.message = message
        this.data = data
        this.code = code
    }

}

// ==============================
//           TEF ELGIN
// ==============================

export function tefElginConfigure() {
    const producao = process.env.NODE_ENV === 'development' ? '0' : '1'

    return fetch(`${process.env.REACT_APP_ELGIN_URL}/configuracao`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            producao,
            nome: 'Sischef',
            versao: '1.0.0',
            textoPinpad: 'Sistemas para restaurantes',
        })
    }).then(data => data.json())
}

export function tefElginVendaPix(valor) {
    const newValue = onlyDigitsFixed(valor)

    return fetch(`${process.env.REACT_APP_ELGIN_URL}/pix`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ valor: newValue })
    }).then(data => data.json())
}

export function tefElginVendaDebito(valor) {
    const newValue = onlyDigitsFixed(valor)

    return fetch(`${process.env.REACT_APP_ELGIN_URL}/venda/debito`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ valor: newValue })
    }).then(data => data.json())
}

export function tefElginVendaCredito(valor) {
    const newValue = onlyDigitsFixed(valor)

    return fetch(`${process.env.REACT_APP_ELGIN_URL}/venda/credito`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            valor: newValue,
            parcelas: '1',
            financiamento: '1'
        })
    }).then(data => data.json())
}

export function tefElginCancelarVenda({ valor, nsu, data }) {
    return fetch(`${process.env.REACT_APP_ELGIN_URL}/adm/cancelamento`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nsu,
            data,
            valor,
        })
    }).then(data => data.json())
}

export function useTefVendaElgin() {
    const dispatch = useDispatch()
    const service = new AuthService()
    const { tefElginDialog } = usePdvContext()
    const { confirmPayment } = useOrderPayment()
    const [isLoading, setIsLoading] = useState(false)
    const [integracao, setIntegracao] = useState()

    // busca a integração TEF Elgin e configura a integração
    useEffect(() => {
        async function fetchIntegracao() {
            const integracaoReq = await service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=EL')
                .catch(err => console.error(err))

            if (integracaoReq?.length) {
                await tefElginConfigure().catch(err => console.log(err))

                setIntegracao(integracaoReq[0])
            }
        }

        fetchIntegracao()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // não deixa o usuário fechar a tela enquanto o processo de venda não estiver concluído
    useEffect(() => {
        function handleBeforeUnload(event) {
            if (isLoading) {
                event.preventDefault()
                event.returnValue = 'O processo de venda da Elgin ainda não foi concluído. Por favor, aguarde.'
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [isLoading])

    // retorna um objeto com a mensagem e a função de iniciar a venda
    function getSaleByType(paymentType) {
        const saleType = {
            'PX': { message: 'Iniciando venda de PIX...', func: tefElginVendaPix },
            'PD': { message: 'Iniciando venda de PIX...', func: tefElginVendaPix },
            'CD': { message: 'Iniciando venda de cartão de débito...', func: tefElginVendaDebito },
            'CC': { message: 'Iniciando venda de cartão de crédito...', func: tefElginVendaCredito },
        }

        return saleType[paymentType] || null
    }

    // imprime os comprovantes do TEF
    function imprimirComprovantes(order, resultado) {
        const localImpressao = localStorage.getItem('_tef_local_impressao_')

        // envia as informações para a impressão do comprovante do TEF via Estabelecimento e Cliente
        Promise.all([
            sendTefToPrint({
                intencaoVendaId: resultado.tef.uniqueID + '',
                pedidoId: order.id + '',
                localImpressao: localImpressao || 'CAIXA',
                comprovante: resultado.tef.comprovanteDiferenciadoLoja,
            }),
            sendTefToPrint({
                intencaoVendaId: resultado.tef.uniqueID + '',
                pedidoId: order.id + '',
                localImpressao: localImpressao || 'CAIXA',
                comprovante: resultado.tef.comprovanteDiferenciadoPortador,
            })
        ]).catch((err) => {
            console.log('ERRO_TEF_PRINT_COMPROVANTE', err)
            enqueueSnackbar('Houve um erro ao imprimir os comprovantes do estabelecimento e cliente', { variant: 'error' })
        })
    }

    /**
     * Inicia a venda de acordo com o tipo de pagamento escolhido e mostra o dialog de conforme vai executando a venda.
     *
     * @param {Object} payment - O objeto de pagamento contendo o código do tipo de pagamento e o valor.
     * @return {Promise} Uma promessa que é resolvida quando a venda é concluída com sucesso ou rejeitada caso ocorra algum erro no processo.
     */
    function iniciaVenda(order, payment) {
        return new Promise((resolve, reject) => {
            const sale = getSaleByType(payment.code)
            if (!sale) {
                reject({ mensagemResultado: 'Tipo de pagamento desconhecido' })
                return
            }

            function getCodigoAutorizacao(resultado) {
                if (resultado.tef.nomeBandeira === 'Banrisul') {
                    return resultado.tef.nsuTransacao
                }

                return resultado.tef.codigoAutorizacao
            }

            // mostra o dialog conforme o resultado da venda
            function handleResponse(resultado) {

                if (!resultado.tef) {
                    tefElginDialog({
                        status: {
                            variant: 'error',
                            message: resultado.mensagemResultado || 'Houve um erro desconhecido durante o processo',
                        },
                    }).then(() => reject(resultado))
                    return
                }

                if (resultado.tef.resultadoTransacao !== '0') {
                    tefElginDialog({
                        status: {
                            variant: 'error',
                            message: resultado.tef.mensagemResultado,
                        },
                    }).then(() => reject(resultado.tef))
                    return
                }

                tefElginDialog({
                    status: {
                        variant: 'success',
                        message: 'Venda de credito realizada com sucesso',
                    },
                })

                // resgata as informações necessárias para o PDV através da resposta do adquirente
                order.iFoodOrder.payments.map((item) => {
                    if (item.formaPagamento?.utilizaTef && !item.tefJson) {
                        item.identificacaoTef = resultado.tef.uniqueID
                        item.codigoAutorizacao = getCodigoAutorizacao(resultado)
                        item.indiceRedeAdquirenteTef = resultado.tef.codigoAdquirente || '???'
                        item.tipoCartaoTef = resultado.tef.tipoCartao
                        item.nomeDoCartaoOuDaAdministradora = resultado.tef.nomeBandeira
                        item.tefJson = JSON.stringify({ ...resultado.tef })
                    }
                    return null;
                })

                confirmPayment(order).then(async (newOrderResp) => {
                    imprimirComprovantes(newOrderResp, resultado)

                    tefElginDialog({
                        confirmed: true,
                        status: {
                            variant: 'success',
                            message: 'Venda realizada com sucesso',
                        },
                    }).then(() => resolve())

                    //caso o pedido não tenha sido pago completamente abre a tela de pagamento atualizada
                    if (!newOrderResp.pago) {
                        dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { order: newOrderResp, open: true } })
                    }
                }).catch((err) => {
                    extractErrorMessage(err, 'Tente novamente')
                        .then(msg => enqueueSnackbar('Erro ao salvar pedido - Detalhes: ' + msg, { variant: 'error' }))

                    console.log('ERRO_SALVAR_PEDIDO: ', err)

                    reject(err)
                })
            }

            setIsLoading(true)

            // mostra o dialog de aguardando
            tefElginDialog({
                status: {
                    variant: 'info',
                    message: sale.message,
                },
            })

            // faz a requisição da venda de acordo com o tipo de pagamento
            sale.func(payment.value).then((resp) => {
                const resultado = JSON.parse(resp.resultado)

                handleResponse(resultado)
            }).catch(err => reject(err)).finally(() => setIsLoading(false))
        })
    }

    /**
     * Cancela a venda escolhida
     *
     * @param {Object} payment - O objeto de pagamento selecionado.
     * @return {Promise} Uma promessa que é resolvida quando a venda é ccancelada.
     */
    function cancelaVenda(order, payment) {
        return new Promise((resolve, reject) => {

            setIsLoading(true)

            // mostra o dialog de aguardando
            tefElginDialog({
                status: {
                    variant: 'info',
                    message: 'Cancelando venda...',
                },
            })

            const json = JSON.parse(payment.tefJson)
            tefElginCancelarVenda({
                nsu: json.nsuTerminal,
                valor: json.valorTotal,
                data: json.dataHoraTransacao,
            }).then(async (resp) => {
                const resultado = JSON.parse(resp.resultado)

                if (!resultado.tef) {
                    tefElginDialog({
                        status: {
                            variant: 'error',
                            message: resultado.mensagemResultado || 'Houve um erro desconhecido durante o processo',
                        },
                    }).then(() => reject(resultado))
                    return
                }

                if (resultado.tef.resultadoTransacao !== '0') {
                    tefElginDialog({
                        status: {
                            variant: 'error',
                            message: resultado.tef.mensagemResultado,
                        },
                    }).then(() => reject(resultado.tef))
                    return
                }

                tefElginDialog({
                    status: {
                        variant: 'success',
                        message: 'Venda cancelada com sucesso',
                    },
                })

                const newOrderResp = await confirmPayment(order)

                imprimirComprovantes(newOrderResp, resultado)

                tefElginDialog({
                    confirmed: true,
                    status: {
                        variant: 'success',
                        message: 'Venda cancelada com sucesso',
                    },
                }).then(() => resolve())

            }).catch(err => reject(err)).finally(() => setIsLoading(false))
        })
    }

    return { integracao, iniciaVenda, cancelaVenda }
}