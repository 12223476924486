import { useEffect, useState } from 'react'
import AuthService from '../../service/AuthService'
import { useSnackbar } from 'notistack'
import { extractErrorMessage } from '../../util/http/HttpUtil'

export function IfoodWidget() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar()
    const isTouchDevice = window.matchMedia("(hover: none)").matches || window.matchMedia("(pointer: coarse)").matches
    const [integracoesIFood, setIntegracoesIFood] = useState([])

    useEffect(() => {
        // caso ele já exista, não precisa buscar novamente a integração
        // e então mostra o widget quando o componente for criado ou esconder quando o componente for destruído
        if (window.iFoodWidget) {
            window.iFoodWidget.show()
            return () => window.iFoodWidget.hide()
        }

        if (integracoesIFood.length) return

        service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=IF').then(integracoesIF => {
            if (!integracoesIF?.length) {
                return
            }

            setIntegracoesIFood(integracoesIF)
        }).catch(err => {
            console.error(err)
            extractErrorMessage(err, '')
                .then(msg => {
                    enqueueSnackbar('Não foi possível buscar a configuração do widget do iFood. ' + msg, { variant: 'error' })
                })
        })

        return () => {
            if (window.iFoodWidget) {
                window.iFoodWidget.hide()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // pega apenas as integrações com o chat habilitado
        const integracoesIFoodWithChat = integracoesIFood.filter(item => {
            const dados = JSON.parse(item.dados)
            return dados.IFOOD_ATIVAR_WIDGET_CHAT !== 'N'
        })

        if (!integracoesIFoodWithChat.length) return

        // carrega o widget do iFood inserindo o merchandId de todas as integracões onde o chat estiver habilitado
        async function loadIfoodWidget() {
            try {
                const merchantIdsArray = integracoesIFoodWithChat.map((item) => {
                    const dadosObj = JSON.parse(item.dados)

                    return dadosObj.IFOOD_MERCHANT_ID
                })

                await window.iFoodWidget.init({
                    widgetId: 'e230496a-4fc5-44bf-9652-289908f4af66',
                    // list of merchant uuids (up to 10)
                    merchantIds: merchantIdsArray,
                    autoShow: !isTouchDevice,
                })

                document.querySelector('.embeddables-focus-wrapper').style.zIndex = 1200
            } catch (err) {
                console.error(err)
            }
        }

        // caso o iFoodWidget não exista adiciona o script ao header
        if (!window.iFoodWidget) {
            const script = document.createElement('script')
            script.src = 'https://widgets.ifood.com.br/widget.js'
            script.type = 'text/javascript'
            script.async = true
            script.onload = () => loadIfoodWidget()
            document.head.appendChild(script)
        }

        // caso o iFoodWidget exista mostre ou esconda o widget dependendo se ele é touch ou desktop
        else if (isTouchDevice) {
            isTouchDevice ? window.iFoodWidget.hide() : window.iFoodWidget.show()
        }
    }, [isTouchDevice, integracoesIFood])

    return null
}