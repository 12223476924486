
export const toogleImportDialog = (currentState, action) =>  {
    let { importOrder } = action
    return {
        ...currentState, 
        importOrder: { ...importOrder, open: importOrder.open } 
    }
}

export const initialStateDialogImport = () =>  {
    return {
        open: false,
        card: null
    }
}