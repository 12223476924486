import { useDispatch } from "react-redux"
import { useSnackbar } from 'notistack';
import { getOrdersByOrderList, getOrdersByTipoAndIdentificador } from "../../../../../../util/order/orderUtil"
import { OrderObj } from "../../../../NewOrder/helper/OrderHelper"
import { isEmpty } from "lodash"
import { getSettings } from "../../../../../settings/settings"

export const createCardList = (identificadorlist, pedidoIntegracaoList, modeloIdentificadorPrincipal) => {

    let cards = []

    identificadorlist
        .filter(identificador => identificador.tipo === modeloIdentificadorPrincipal)
        .map(identificador => {

            const orderList = pedidoIntegracaoList.filter(pedido => pedido.situacao !== "CANCELADO" && pedido.tipo === modeloIdentificadorPrincipal && String(pedido.identificadorPrincipal) === identificador.identificador)

            const identificadorTemp = { identificador, orderList }

            /* cria uma flag _adicionadoCard no order pra depois saber quais pedidos são 'orfãos'  */
            orderList.map(order => {
                order._addOnCard = true;
                return null;
            })

            cards.push(identificadorTemp)
            return null;
        })

    /* busca pedidos orfãos para criar um 'card' dinamico */
    const orderOrphanList = pedidoIntegracaoList.filter(pedido => pedido.situacao !== "CANCELADO" && !pedido._addOnCard && pedido.tipo === modeloIdentificadorPrincipal)

    orderOrphanList.map(pedidoOrphan => {

        // eslint-disable-next-line
        const identificadorOrphan = !!pedidoOrphan.identificadorPrincipal ? pedidoOrphan.identificadorPrincipal : 'Sem identificador'
        //Converte o identificador para JSON antes de comparar por causa do bug de identificador vindo em array de string
        let identificadorTemp = cards.find(card => JSON.stringify(card.identificador.identificador) === JSON.stringify(identificadorOrphan))

        if (!identificadorTemp) {
            const identificadorTemp = {
                identificador: {
                    tipo: modeloIdentificadorPrincipal,
                    status: "NORMAL",
                    identificador: identificadorOrphan,
                    ativo: true
                },
                orderList: [pedidoOrphan]
            }
            cards.push(identificadorTemp)
        } else {
            identificadorTemp.orderList.push(pedidoOrphan)
        }

        pedidoOrphan._addOnCard = true;
        return null;
    })


    if (modeloIdentificadorPrincipal === 'COMANDA') {
        identificadorlist
            .filter(identificador => identificador.tipo === 'MESA')
            .map(identificador => {

                const orderList = pedidoIntegracaoList.filter(pedido => pedido.situacao !== "CANCELADO" && String(pedido.identificadorSecundario) === identificador.identificador)

                const identificadorTemp = { identificador, orderList }
                cards.push(identificadorTemp)
                return null;
            })
    }

    return cards
}

export const useCardPrincipal = identificadorCard => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const orderList = identificadorCard.orderList
    const identificador = identificadorCard.identificador

    const onError = (msg = '') => {
        enqueueSnackbar(msg, {
            variant: 'error'
        })
    }

    /*
    * Função que busca os pedidos de acordo com o identificador principal, caso o mesmo seja nulo e existam pedidos naquele identificador
    * busca os pedidos pela lista dos ids dos mesmos
    */
    const getOrders = async () => {
        const response = await getOrdersByTipoAndIdentificador(identificador.tipo, identificador.identificador, onError)

        if (!identificador.identificador && !response.length) {
            return await getOrdersByOrderList(orderList, onError)
        }

        return response
    }

    const onClickOpenOrderAsync = async (onOpenManyOrders, onOpenOneOrder, onFinally) => {

        try {
            let orderList = await getOrders()

            let initActiveStep = 1
            let hasSischefOrder = false
            let firstOrderSischef = null
            // let hasIntegrationOrder = false
            let hasConvertedOrder = false

            for (var i = 0; i < orderList.length; i++) { //dentro da lista de pedidos busca um pedido sischef

                if (orderList[i].tipoImportacao === 'SO' && orderList[i].tipoImportacaoOriginal === undefined) {
                    //orderCopy = orderList[i] //ordercopy recebe um pedido sischef
                    hasSischefOrder = true
                }

                if (orderList[i].tipoImportacaoOriginal) {
                    hasConvertedOrder = true
                }

                if (!firstOrderSischef && (hasSischefOrder || hasConvertedOrder)) {
                    firstOrderSischef = orderList[i]
                }
            }

            /* Atualiza os pedidos do card */
            orderList.map(pedidoIntegracao => {
                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                return null;
            })

            /* se o firstOrderSischef não é nulo mas o ifoodOrder é, isso deve ser algum erro no pedido */
            if (firstOrderSischef != null && firstOrderSischef.iFoodOrder == null) {
                console.error('Erro. Pedido sem iFoodOrder', firstOrderSischef)
                onError(`O pedido ID ${firstOrderSischef.id} está com o JSON NULO. Entre em contato com o suporte`)
                return;
            }

            let orderCopy;

            /**
            * 1 - Quando não tem um nenhum pedido cria um novo pedido sischef
            * 2 - Quando só existir pedido sischef editar o pedido sem abrir a tela de listagem de pedidos
            * 3 - Quando só existe pedido de integração abrir a tela com a listagem de pedidos com o botao novo pedido sischef
            * 4 - Quando existir pedido de integração e pedido sischef abrir atela de listagem de pedido com um botão editar pedido sischef
            */

            // Situação 1
            if (!hasSischefOrder && !hasConvertedOrder) {
                orderCopy = new OrderObj()
                orderCopy.tipo = identificador.tipo
                orderCopy.identificadorPrincipal = identificador.identificador
                orderCopy.printOrder = getSettings.printAutomaticallyOnCreate === 'ENABLED';

                if (identificador.pessoa) {
                    let customerTemp = {
                        id: identificador.pessoa.id,
                        name: identificador.pessoa.nome,
                        phone: identificador.pessoa.fone,
                        email: identificador.pessoa.email,
                        taxPayerIdentificationNumber: identificador.pessoa.cpfCnpj,
                        type: identificador.pessoa.tipo
                    }

                    orderCopy.iFoodOrder.customer = customerTemp
                }
                    initActiveStep = 0


            } else if (hasSischefOrder && orderList.length <= 1) {
                // Situação 2
                orderCopy = Object.assign({}, firstOrderSischef)

            } else if (hasConvertedOrder && orderList.length <= 1) {
                // Situação 2
                orderCopy = Object.assign({}, firstOrderSischef)

            } else {

                if (onOpenManyOrders) {
                    onOpenManyOrders(identificadorCard)
                }

                //para aqui
                return;
            }

            if (isEmpty(orderCopy.identificadorPrincipal)) {
                orderCopy.identificadorPrincipalEditavel = true;
                initActiveStep = 0
            }

            if (onOpenOneOrder) {
                onOpenOneOrder(orderCopy, initActiveStep)
            }

        } finally {
            if (onFinally) {
                onFinally()
            }
        };

        //abre de edição de produtos
        //dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: orderCopy, initActiveStep } })
    }

    return [onClickOpenOrderAsync]
}