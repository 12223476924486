import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ptBRLocale from "date-fns/locale/pt-BR";
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { getDefaultPeriodList } from '../../../helpers/dateHelper';
import { addDays, setHours } from 'date-fns';
import debounce from 'lodash/debounce';
import { dateFormat } from '../../../util/formatter';

function CustomDateFilter({ onChange, apiFilter }) {
    const periodOptions = getDefaultPeriodList();
    const [openFilterCustom, setOpenFilterCustom] = useState(false)

    const periodKey = apiFilter?.periodKey ? apiFilter?.periodKey : 5

    const [defaultPeriod, setDefaultPeriod] = useState(periodOptions.filter(elem => elem.key === periodKey)[0])
    const [filterSelected, setFilterSelected] = useState(defaultPeriod)
    const [selectedDate, setSelectedDate] = useState({ startDate: defaultPeriod.startDate, endDate: defaultPeriod.endDate, periodKey: defaultPeriod.key })

    const handleChangeDate = (date, name) => {
        let dateFormatted = setHours(date, 4)

        if (filterSelected.label === "Personalizado") {
            if (name === 'endDate' && dateFormatted === selectedDate.startDate) {
                dateFormatted = setHours(addDays(date, 1), 4)
            }
        }

        setSelectedDate({ ...selectedDate, [name]: dateFormatted })
    }

    const deboucedOnChangeDate = useMemo(() => debounce(handleChangeDate, 300), [handleChangeDate])

    const initialRenderFilterSelected = useRef(true);
    useEffect(() => {

        if (initialRenderFilterSelected.current) {
            initialRenderFilterSelected.current = false;
        } else {

            if (filterSelected) {
                const { key, label, startDate, endDate } = filterSelected

                if (label === 'Personalizado') {
                    setOpenFilterCustom(true)
                } else {
                    setOpenFilterCustom(false)
                }

                setSelectedDate({ startDate, endDate, periodKey: key })
                setDefaultPeriod(filterSelected)
            }
        }


    }, [filterSelected])

    const handleChangeSelect = (event) => {
        const keySelected = event.target.value

        // eslint-disable-next-line
        if (keySelected == "Personalizado") {
            let startDate = setHours(new Date(), 4)
            let endDate = setHours(addDays(new Date(), 1), 4)

            setSelectedDate({ startDate, endDate, periodKey: keySelected });

        }

        //localiza na lista de periodos o elemento selecionado pela chave
        setFilterSelected(periodOptions.find(elem => elem.key === keySelected))
    }

    const initialRenderSelectedDate = useRef(true);
    useEffect(() => {

        if (initialRenderSelectedDate.current) {
            initialRenderSelectedDate.current = false;
        } else {

            if (selectedDate?.startDate && selectedDate?.endDate) {
                const newSelectedDate = { ...selectedDate }

                newSelectedDate.startDate = `${dateFormat(newSelectedDate.startDate, 'yyyy-MM-dd')} 04:00:00`
                newSelectedDate.endDate = `${dateFormat(newSelectedDate.endDate, 'yyyy-MM-dd')} 04:00:00`

                onChange(newSelectedDate)
            }
        }
        //eslint-disable-next-line
    }, [selectedDate])

    return (
        <div>
            <FormControl variant="filled" fullWidth size="small">
                <InputLabel>Filtrar por período</InputLabel>
                <Select
                    id="periodSelect"
                    value={defaultPeriod.key ?? ''}
                    onChange={handleChangeSelect}>
                    {periodOptions.map((option, index) => (
                        <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                    ))}
                </Select>

                {openFilterCustom &&
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={6}>
                                <DatePicker
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="startDate"
                                    name="startDate"
                                    size='small'
                                    label="Período inicial"
                                    value={selectedDate.startDate}
                                    onChange={(date, context) => {
                                        if (context.validationError == null)
                                            deboucedOnChangeDate(date, 'startDate')
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período inicial',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    size='small'
                                    id="endDate"
                                    name="endDate"
                                    label="Período final"
                                    value={selectedDate.endDate}
                                    onChange={(date, context) => {
                                        if (context.validationError == null)
                                            deboucedOnChangeDate(date, 'endDate')
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período final',
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                }
            </FormControl>
        </div>
    )
}

export default CustomDateFilter;