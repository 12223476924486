import React, { useRef, useState } from 'react';
import {
    Menu, MenuItem, ListItemIcon, Dialog
    , Button, DialogActions, TextField, Grid
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import ExposurePlus1Icon from '@mui/icons-material/PlusOne';
import EditItemOrder from './EditItemOrder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { currencyFormat } from '../../../../util/formatter';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ItemObj } from '../helper/OrderHelper';
import { createUUID, pendingAmount, importTypeDescription, removeDiscountValueOnItems, distributeDiscountValueOnItems, totalDiscountItems, totalPriceItem } from '../../../../util/order/orderUtil';
import { useSnackbar } from 'notistack';
import { ifAnyGrantedUnidade } from '../../../../util/auth/authUtil';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import ItemInfo from '../../../../util/item/itemInfo';
import { useDispatch, useSelector } from 'react-redux'
import { isAuthorized } from '../../../../routes';
import { roundUp } from '../../../../util/genericUtil';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { ResumeOrderList } from '../ResumeOrder/ResumeOrderItem';

function ResumeOrder({ order, setOrder, handleClickOpenSteps }) {
    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const [openInfoItem, setOpenInfoItem] = useState(false) //Variável que torna o dialog das infos dos itens visíveis
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const items = order ? Object.assign([], order.iFoodOrder.items) : []

    const openEditItem = useSelector(state => state.detailDialogEditItem.open)
    const setOpenEditItem = (open) => {
        dispatch({ type: 'TOOGLE_DIALOG_EDIT_ITEM', detailDialogEditItem: { open: open, item: itemSelected } })
    }

    const setOpenDiscountItem = (open) => {
        dispatch({ type: 'TOOGLE_DISCOUNT_ITEM_DIALOG', discountItemDialog: { open: open, item: itemSelected, order: order } })
    }

    const itemSelected = useSelector(state => state.detailDialogEditItem.item)
    const setItemSelected = (item) => {
        dispatch({ type: 'TOOGLE_DIALOG_EDIT_ITEM', detailDialogEditItem: { item: item, open: openInfoItem } })
    }

    const handleClick = (event, item) => {
        setItemSelected(item)
        setAnchorEl(event.currentTarget)
    }

    const handleClickAdicionais = () => {
        handleClickOpenSteps(itemSelected)
        handleClose()
    }

    const duplicateItem = () => {

        if (itemSelected) {
            let item = new ItemObj()

            for (const property in itemSelected) {
                item[property] = itemSelected[property]
            }

            if (itemSelected.subItems) {
                item.subItems = JSON.parse(JSON.stringify(itemSelected.subItems))
            }

            item.referenceCode = createUUID()
            if (item.localNotification === 'NN') {
                item.status = 'NAO_NOTIFICAR'
                item.statusKds = 'NAO_NOTIFICAR'
            } else {
                item.status = 'PENDENTE'
                item.statusKds = 'PENDENTE'
            }

            const orderCopy = Object.assign({}, order)
            orderCopy.iFoodOrder.items.push(item)

            setOrder(orderCopy)
        }

        handleClose()
    }

    const removeTempOrshowDialogDeleteItem = (item) => {
        const itemToDelete = item ? item : itemSelected

        if (itemToDelete.notSaved) {
            deleteItem({ justificativa: 'item não salvo ainda', item })
            return
        }

        setOpenDeleteDialog(true)
    }

    const deleteItem = ({ justificativa, item }) => {
        const valuePending = pendingAmount(order.iFoodOrder)
        const itemToDelete = item ? item : itemSelected

        if (valuePending < itemToDelete.totalPrice) {
            enqueueSnackbar(`O valor total deste item é maior que o valor pendente do pedido.`, { variant: 'error', })
            return
        }

        console.log("itemToDelete", itemToDelete)
        let itemsCopy = Object.assign([], order.iFoodOrder.items)
        let indexof = itemsCopy.indexOf(itemToDelete)

        if (indexof >= 0) {

            let deletedItem = itemsCopy[indexof]
            deletedItem.dadosExclusao = {
                identificadorPrincipal: order.tipo + " " + order.identificadorPrincipal,
                identificadorSecundario: order.identificadorSecundario,
                justificativaExclusao: justificativa,
                dataExclusao: new Date()
            }

            //SE NAO FOR COMANDA E MESA ADD INFO NO IDENTIFICADOR SECUNDARIO
            if (["COMANDA", "MESA"].includes(order.tipo)) {
                //
            } else {
                deletedItem.dadosExclusao.identificadorPrincipal = order.tipo + " " + order.id
                deletedItem.dadosExclusao.identificadorSecundario = importTypeDescription(order.tipoImportacao) + " SEQ " + order.sequencial
            }

            itemsCopy.splice(indexof, 1)

            let orderCopy = Object.assign({}, order)
            orderCopy.iFoodOrder.items = itemsCopy

            if (!orderCopy.deletedItems) {
                orderCopy.deletedItems = []
            }

            if (itemToDelete.discount > 0.0) { //caso o item excluido tenha desconto recalcula o desconto do pedido
                let discount = roundUp(totalDiscountItems(orderCopy.iFoodOrder), 2)

                removeDiscountValueOnItems(orderCopy.iFoodOrder)

                if (discount >= 0) {
                    distributeDiscountValueOnItems(orderCopy.iFoodOrder, discount)
                    enqueueSnackbar(`O desconto do item excluido foi removido, e o saldo restante foi redistribuido entre os itens`, { variant: 'warning', })
                }
            }

            //SO ADICIONA O ITEM PARA O LOG CASO O MESMO TENHA SIDO SALVO\
            if (!deletedItem.notSaved) {
                orderCopy.deletedItems.push(deletedItem)
            }

            setOrder(orderCopy)
        }

        handleClose()
    }

    const handleClickEdit = () => {
        setOpenEditItem(true)
        handleClose()
    }

    const handleClickDiscountItem = () => {
        setOpenDiscountItem(true)
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const visibleColumnStatusNotification = () => {
        return visibleColumnStatusNotificationPrinter() || visibleColumnStatusNotificationKds()
    }

    const visibleColumnStatusNotificationPrinter = () => {
        return ifAnyGrantedUnidade('ROLE_UTILIZA_NOTIFICACAO')
    }

    const visibleColumnStatusNotificationKds = () => {
        return ifAnyGrantedUnidade('ROLE_UTILIZA_KDS')
    }

    /*Função para abrir o dialog das informações do item*/
    const handleClickOpenInfo = () => {
        setOpenInfoItem(true)//seta como true a variável openInfoItem que torna o Dialog visivel
        handleClose()
    }

    const moduloNotificacaoAtivos = useRef({
        impressao: visibleColumnStatusNotificationPrinter(),
        kds: visibleColumnStatusNotificationKds(),
        ambos: visibleColumnStatusNotification()
    })

    const handleQuantidadeItem = (item, value) => {
        setItemSelected(item)

        if (item.product?.abrirPassos === 'S' && value > 0) {
            duplicateItemWithSteps(item)
            return
        }

        const itemsCopy = [...order.iFoodOrder.items]
        const indexof = itemsCopy.indexOf(item)

        if (indexof >= 0) {
            const orderCopy = { ...order }
            const itemSelecionado = itemsCopy[indexof]

            const newSubTotal = (itemSelecionado.quantity - 1) * itemSelecionado.price
            if (newSubTotal < (itemSelecionado.discount || 0) && itemSelecionado.quantity > 1 && itemSelecionado.quantity > value) {
                enqueueSnackbar('Não é possivel remover o item pois o desconto é maior que o subtotal', { variant: 'error' })
                return itemSelecionado.quantity
            }

            if (value <= 0) {
                removeTempOrshowDialogDeleteItem(itemSelecionado)
                return
            }

            itemSelecionado.quantity = value
            itemSelecionado.totalPrice = totalPriceItem(itemSelecionado)

            itemsCopy[indexof] = itemSelecionado

            orderCopy.iFoodOrder.items = itemsCopy

            setOrder(orderCopy)
        }
    }

    const duplicateItemWithSteps = (selectedItem) => {
        if (selectedItem) {
            let item = new ItemObj()

            for (const property in selectedItem) {
                item[property] = selectedItem[property]
            }

            item.referenceCode = createUUID()
            if (item.localNotification === 'NN') {
                item.status = 'NAO_NOTIFICAR'
            } else {
                item.status = 'PENDENTE'
            }

            const orderCopy = Object.assign({}, order)
            orderCopy.iFoodOrder.items.push(item)

            setOrder(orderCopy)
        }

        handleClose()
    }

    return <>

        <ResumeOrderList
            items={items}
            moduloNotificacaoAtivos={moduloNotificacaoAtivos}
            onToggleMenu={handleClick}
            onQuantityChange={handleQuantidadeItem} />

        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}>

            <MenuItem key={0} onClick={handleClickAdicionais}>
                <ListItemIcon>
                    <ListAltIcon fontSize="small" />
                </ListItemIcon>
                Adicionais
            </MenuItem>

            <MenuItem key={1} onClick={handleClickEdit}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                Editar
            </MenuItem>

            <MenuItem key={2} onClick={handleClickDiscountItem}>
                <ListItemIcon>
                    <MoneyOffIcon fontSize="small" />
                </ListItemIcon>
                Desconto
            </MenuItem>

            <MenuItem key={3} onClick={duplicateItem}>
                <ListItemIcon>
                    <ExposurePlus1Icon fontSize="small" />
                </ListItemIcon>
                Duplicar
            </MenuItem>

            <MenuItem key={4} onClick={() => removeTempOrshowDialogDeleteItem()}>
                <ListItemIcon>
                    <DeleteForeverIcon fontSize="small" />
                </ListItemIcon>
                Remover
            </MenuItem>

            <MenuItem key={5} onClick={handleClickOpenInfo}>
                <ListItemIcon>
                    <InfoIcon fontSize="small" />
                </ListItemIcon>
                Infos
            </MenuItem>

        </Menu>

        <EditItemOrder open={openEditItem} setOpen={setOpenEditItem} item={itemSelected} order={order} />

        <ItemInfo open={openInfoItem} item={itemSelected} setOpen={setOpenInfoItem} />

        <AlertDialogDialogItem open={openDeleteDialog} setOpen={setOpenDeleteDialog} handleConfirm={deleteItem} item={itemSelected} />

    </>
}


function AlertDialogDialogItem({ open, setOpen, handleConfirm, item }) {

    const [justificativa, setJustificativa] = useState("")
    const [msgErro, setMsgErro] = useState(null)
    const ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_EXCLUSAO_ITEM = isAuthorized('ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_EXCLUSAO_ITEM')
    const { enqueueSnackbar } = useSnackbar()

    const handleClose = () => {
        setOpen(false);
        setJustificativa("")
    }

    const confirm = () => {
        if (ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_EXCLUSAO_ITEM) {
            enqueueSnackbar("Seu usuário não tem permissão para excluir itens do pedido", { variant: 'error', })
            return
        }

        if (!justificativa?.trim()) {
            setMsgErro("Justificativa é obrigatória")
            return
        } else {
            setMsgErro(null)
        }

        handleConfirm({
            // eslint-disable-next-line
            justificativa: new String(justificativa.trim())
        })

        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            id="dialog-alert">
            <DialogTitle onClose={handleClose} />

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        Item <strong>{item.name} {currencyFormat(item.totalPrice)}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        Informe uma justificativa para a exclusão
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            autoFocus
                            error={!!msgErro}
                            helperText={msgErro}
                            fullWidth
                            defaultValue={justificativa}
                            onBlur={elem => setJustificativa(elem.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant='contained' onClick={confirm} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResumeOrder;