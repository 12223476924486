import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Typography,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    useTheme,
    ThemeProvider,
    Paper,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    TableBody,
} from '@mui/material';
import AuthService from '../../../service/AuthService';
import Barcode from 'react-barcode';
import { generateBarCodeDeliveryMan, generateBarCodeConfirm } from './despachoHelper';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Drawer } from '@mui/material';
import { useLoadEntregadores } from './LoadDeliveryMan';

// Definindo a largura do Drawer
const drawerWidth = 300;

const PREFIX = 'DeliveryManBox';

const classes = {
    dialogPaper: `${PREFIX}-dialogPaper`,
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.dialogPaper}`]: {
        minHeight: '70vh',
    },

}))

export default function DeliveryManBox({ isDrawerOpen, originalListDeliveryMan, toggleDrawer }) {
    const [listDeliveryMan, setListDeliveryMan] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [barCodeValue, setBarCodeValue] = useState('')
    const [confirmBarCodes, setConfirmBarCodes] = useState([])

    function loadDeliveryManList() {
        setListDeliveryMan(originalListDeliveryMan)
    }

    const handleBarCodeProcess = () => {
        if (barCodeValue.startsWith('99')) {
            const deliveryManId = barCodeValue.substring(2) // Se o código começa com "99", remove os dois primeiros dígitos (o prefixo 99) para obter o ID do entregador do restante do código de barras

            const updatedList = listDeliveryMan.map(deliveryMan => {
                if (generateBarCodeDeliveryMan(deliveryMan.id).endsWith(deliveryManId)) {
                    return { ...deliveryMan, selected: !deliveryMan.selected }
                }
                return deliveryMan
            })
            setListDeliveryMan(updatedList)
        } else if (barCodeValue.startsWith('98')) {
            setConfirmBarCodes(prev => [...prev, barCodeValue]);
        }
        if (!barCodeValue.startsWith('98')) {
            setBarCodeValue('');
        }
    }

    useEffect(() => {
        if (barCodeValue) {
            handleBarCodeProcess()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barCodeValue])

    useEffect(() => {
        loadDeliveryManList()
        setConfirmBarCodes([generateBarCodeConfirm()])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalListDeliveryMan])

    const theme = useTheme();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: '.text { color: black !important;} .print-hide { display: none !important; }'
    })

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll
        setSelectAll(newSelectAll)
        setListDeliveryMan(listDeliveryMan.map(deliveryMan => ({
            ...deliveryMan,
            selected: newSelectAll,
        })))
    }

    const handleCheckboxChange = (id) => {
        const updatedList = listDeliveryMan.map(deliveryMan => {
            if (deliveryMan.id === id) {
                return { ...deliveryMan, selected: !deliveryMan.selected }
            }
            return deliveryMan
        })
        setListDeliveryMan(updatedList)
        // Atualiza o estado de seleção total baseado na lista atualizada
        setSelectAll(updatedList.every(deliveryMan => deliveryMan.selected))
    }

    // Calcula se algum item está selecionado para controlar a visibilidade do botão
    const isAnySelected = listDeliveryMan.some(deliveryMan => deliveryMan.selected)

    return (
        <Box>
            <ThemeProvider theme={theme}>

                <Button startIcon={<ViewWeekIcon />} variant="outlined" color="grey" size='small' onClick={() => toggleDrawer()}>
                    Códigos entregadores
                </Button>

                <Drawer
                    anchor="right"
                    variant="persistent"
                    open={isDrawerOpen}
                    onClose={() => toggleDrawer()}
                    sx={{
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            top: '80px',
                        },
                    }}
                >
                    <StyledBox ref={componentRef}>
                        <Box className="print-hide"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                p: 2,
                                backgroundColor: 'primary.main',
                                color: 'white'
                            }}
                        >
                            <IconButton onClick={() => toggleDrawer()} sx={{ color: 'white' }}>
                                <KeyboardArrowRightIcon />
                            </IconButton>

                            <Typography variant="h5" noWrap component="div" sx={{ mr: 1 }}>
                                Códigos Entregadores
                            </Typography>
                        </Box>

                        <Box>
                            {isAnySelected && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', mb: 2 }} className="print-hide">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={<PrintIcon />}
                                        onClick={handlePrint}
                                        sx={{ width: 'auto', maxWidth: '50%', mt: 2 }}
                                    >
                                        Imprimir
                                    </Button>
                                </Box>
                            )}

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="print-hide">
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                        color="primary"
                                    />}
                                    label="Selecionar todos para impressão"
                                    className="print-hide"
                                />
                            </Box>
                        </Box>

                        <TableContainer component={Paper} style={{ maxHeight: 'auto', overflowY: 'auto' }} aria-label="a dense table">
                            <Table aria-label="caption table">
                                <TableBody>
                                    {listDeliveryMan.map(deliveryMan => (
                                        <TableRow key={deliveryMan.id} sx={{ borderBottom: 'unset', p: '10px 16px' }} className={deliveryMan.selected ? '' : 'print-hide'}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={!!deliveryMan.selected}
                                                    onChange={() => handleCheckboxChange(deliveryMan.id)}
                                                    className="print-hide"
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Barcode value={generateBarCodeDeliveryMan(deliveryMan.id)} format="EAN13" width={1} height={40} margin={4} />
                                                    <Typography variant="caption" sx={{ mt: '8px', fontSize: '18px', textAlign: 'center' }} className="text">{deliveryMan.nome}</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer component={Paper} style={{ marginTop: '5px', maxHeight: 200, overflowY: 'hidden', marginBottom: '70px' }}>
                            <Table>
                                <TableBody>
                                    {confirmBarCodes.map((code, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Barcode value={code} format="EAN13" width={1} height={40} margin={4} />
                                                    <Typography variant="caption" sx={{ mt: '8px', fontSize: '18px' }} className="text">CONFIRMAR</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </StyledBox>
                </Drawer>
            </ThemeProvider >
        </Box>
    );
}