import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import AuthService from '../../../service/AuthService'
import { TextMaskPhone } from '../../../util/mask'
import { DialogTitle, DialogContent, DialogActions } from '../../layout-components/CustomDialog/dialogComponents'
import { Box, Button, Dialog, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material'

export default function DeliveryManRegister({ open, setOpen, deliveryMan, afterSave }) {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar()
    const [deliveryManEdit, setDeliveryManEdit] = useState({ ativo: true })

    function isValid() {
        if (deliveryManEdit.nome === '') {
            enqueueSnackbar('Informe um nome para o entregador.', { variant: 'error' })
            return false
        }

        if (deliveryManEdit.fone === '') {
            enqueueSnackbar('Informe um telefone para o entregador.', { variant: 'error' })
            return false
        }

        return true
    }

    const handleSave = () => {
        if (!isValid()) return

        deliveryManEdit.nome = deliveryManEdit.nome.toUpperCase()
        service.post('/api-v2/pessoa', deliveryManEdit).then(() => {
            enqueueSnackbar('Entregador salvo com sucesso.', { variant: 'success' })
            setOpen(false)
            afterSave()
        })
    }

    const handleChange = (event) => {
        let deliveryManEditCopy = Object.assign({}, deliveryManEdit)
        const deliveryManValueFields = ['entregadorValorDiaria', 'entregadorValorFixoPedido', 'entregadorPercentualTaxaEntrega']

        if (event.target.type === 'checkbox') {
            deliveryManEditCopy[event.target.name] = event.target.checked
        } else if (deliveryManValueFields.includes(event.target.name)) {
            deliveryManEditCopy[event.target.name] = event.target.value || 0
        } else {
            deliveryManEditCopy[event.target.name] = event.target.value
        }

        setDeliveryManEdit(deliveryManEditCopy)
    }

    useEffect(() => {
        if (deliveryMan) {
            setDeliveryManEdit(Object.assign({}, deliveryMan))
        }
    }, [deliveryMan])

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return
        }

        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Typography>
                    Cadastro de entregador
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Box pt={1}>
                            <TextField
                                required
                                fullWidth
                                name="nome"
                                size="small"
                                variant="outlined"
                                label="Nome completo"
                                value={deliveryManEdit.nome || ''}
                                onChange={handleChange} />
                        </Box>
                    </Grid>

                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            name="email"
                            type="email"
                            label="E-Mail"
                            variant="outlined"
                            onChange={handleChange}
                            value={deliveryManEdit.email || ''} />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            required
                            fullWidth
                            name="fone"
                            size="small"
                            label="Telefone"
                            variant="outlined"
                            value={deliveryManEdit.fone || ''}
                            onChange={handleChange}
                            InputProps={{
                                inputComponent: TextMaskPhone
                            }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            name="entregadorValorDiaria"
                            variant="outlined"
                            label="Valor diária"
                            onChange={handleChange}
                            value={deliveryManEdit.entregadorValorDiaria || ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            name="entregadorValorFixoPedido"
                            label="Valor fixo por pedido"
                            variant="outlined"
                            onChange={handleChange}
                            value={deliveryManEdit.entregadorValorFixoPedido || ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }} />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            name="entregadorPercentualTaxaEntrega"
                            label="Percentual sobre a taxa de entrega"
                            variant="outlined"
                            onChange={handleChange}
                            value={deliveryManEdit.entregadorPercentualTaxaEntrega || ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }} />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="ativo" row>
                                <FormControlLabel
                                    label="Ativo"
                                    labelPlacement="start"
                                    control={<Switch
                                        name="ativo"
                                        label="Ativo"
                                        color="primary"
                                        onChange={handleChange}
                                        checked={deliveryManEdit.ativo} />} />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSave(deliveryManEdit)}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}