import React from 'react';
import parsePhoneNumber from 'libphonenumber-js'
import { roundUp } from './genericUtil';
import { format, formatDistance, formatDistanceToNow, formatISO, isSameDay, isValid, parse, parseISO, setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale'

setDefaultOptions({ locale: ptBR })

export const quantityFormat = (value, digits = 3, minDigits = 3) => {
    if (value === null || value === "") {
        return ""
    }

    const floatValue = parseFloat(value)

    let decimalCount = 0;
    if (Math.floor(floatValue) !== floatValue) {
        decimalCount = floatValue.toString().split(".")[1].length || 0;
    }

    if (decimalCount < minDigits) {
        return roundUp(floatValue, minDigits).toFixed(minDigits).replace('.', ',');
    }

    if (decimalCount > digits) {
        return roundUp(floatValue, 2).toFixed(digits).replace('.', ',');
    }

    return roundUp(floatValue, 3).toFixed(decimalCount).replace('.', ',');
}

export const currencyFormat = (value, options) => {

    let { simbol } = { simbol: 'R$', ...options }

    let formated = null
    if (!value && 0 !== value) {
        formated = '0.00'
    } else {
        let val = parseFloat(value)
        formated = roundUp(val, 2).toFixed(2).replace('.', ',');
    }


    return `${simbol} ${formated}`
}

export const dateFormat = (date, newFormat) => {
    try {
        const formatStr = newFormat || 'dd MMM HH:mm'

        if (!date)
            return date

        if (isValidDate(date))
            return format(date, formatStr)

        return format(parseISO(date), formatStr)
    } catch (e) {
        console.error('ERRO: dateFormat', date, e)
        return null
    }
}

export const dateFormatedToNewFormat = (dateStr, oldPattern, newPattern) => {
    try {
        if (!dateStr)
            return dateStr

        if (dateStr instanceof Date)
            return format(dateStr, newPattern)

        const date = oldPattern === 'ISO' ? dateParseISO(dateStr) : dateParse(dateStr, oldPattern)

        return format(date, newPattern)
    } catch (e) {
        console.error('ERRO: dateFormatedToNewFormat', dateStr, oldPattern, newPattern, e)
        return null
    }
}

export const dateParse = (dateStr, pattern) => {
    try {
        return parse(dateStr, pattern, new Date(), {
            locale: ptBR,
        })
    } catch (e) {
        console.error('ERRO: dateParse', dateStr, pattern, e)
        return null
    }
}

export const dateToStringISO = (date) => {
    try {
        if (date) {
            return formatISO(date)
        }

        return null;
    } catch (e) {
        console.error('ERRO: dateToStringISO', date, e)
        return null
    }
}

export const dateParseISO = (dateStr) => {
    try {
        if (dateStr instanceof Date) {
            return dateStr
        }

        if (dateStr) {
            return parseISO(dateStr)
        }

        return null;
    } catch (e) {
        console.error('ERRO: dateParseISO', dateStr, e)
        return null
    }
}

export const dateFormatDistance = (dateFrom, dateTo) => {
    try {
        if (!isValid(dateFrom) || !isValid(dateTo)) {
            console.log('Alguma data não é válida:', dateFrom, dateTo)
            return formatDistance(new Date(), new Date())
        }

        return formatDistance(dateFrom, dateTo)
    } catch (e) {
        console.error('ERRO: dateFormatDistance', dateFrom, dateTo, e)
        return null
    }
}

export const dateFormatDistanceToNow = (date) => {
    try {
        if (!date) {
            return formatDistanceToNow(new Date())
        }

        return formatDistanceToNow(date)
    } catch (e) {
        console.error('ERRO: dateFormatDistanceToNow', date, e)
        return null
    }
}

export const isToday = (date) => {
    try {
        return isSameDay(date, new Date());
    } catch (e) {
        console.error('ERRO: isToday', date, e)
        return null
    }
}

export const onlyDigits = (value) => {
    if (value) {
        let replaced = value.toString().replace(/[^0-9]/g, '')
        return replaced;
    }
    return null
}

// transforma o valor em string com as 2 casas decimais removendo a virgula
// Ex.: 1 -> 100 ou 1.000,00 -> 100000
export const onlyDigitsFixed = (value) => {
    if (value) {
        return value.toFixed(2).replace('.', '')
    }
    return null
}

export const integrationFormat = (initials) => {
    let integrations = ["Ifood", "Neemo", "Delivery Direto - V1", "Qrpedir", "PedZap", "Accon", "Uber Eats", "Rappi", "Anota Ai", "Delivery Much", "Delivery Direto - V2"];
    let initialsArr = ["IF", "NE", "DD", "QR", "PZ", "AC", "UE", "RA", "AI", "DM", "D2"];

    let index = initialsArr.indexOf(initials);

    if (index < 0) {
        return "";
    } else {
        return integrations[index];
    }
}

export const normalizeText = (value) => {
    return String(value).toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export const formatPhoneBR = (value) => {
    if (value) {
        const phoneNumber = parsePhoneNumber(String(value), 'BR')
        if (phoneNumber) {
            return phoneNumber.formatNational();
        }
    }
    return value;
}

export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}

export function LongText({ text, maxLength }) {

    return (
        <>
            {text?.length > maxLength ? (
                <div title={text}>
                    {text?.slice(0, maxLength) + " ..."}
                </div>
            ) : (
                text
            )}
        </>
    );
}

LongText.defaultProps = {
    text: "",
    maxLength: 50,
};