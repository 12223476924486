import React, { useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import Badge from '@mui/material/Badge';


export default function HelpButton() {

    return (
        <Tooltip title="Converse com um atendente">
            <IconButton href="/helpdesk" target="_blank" size="large" color="warning">
                <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                    <HeadsetMicIcon />
                </Badge>
            </IconButton>
        </Tooltip>
    );
}
