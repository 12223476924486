import AuthService from "../service/AuthService"

export const updateMinimizedOptions = (currentState, action) => {
    let { minimizedOptions } = action

    let orders = minimizedOptions.minimizedOrders

    const key = getToken()
    window.localStorage.setItem(key, JSON.stringify(orders))

    return {
        ...currentState,
        minimizedOptions: { ...minimizedOptions }
    }
}

export const updateMinimizedOptionsFromLocalstorage = (currentState, action) => {
    return {
        ...currentState,
        minimizedOptions: initialStateMinimizedOrders()
    }
}

export const initialStateMinimizedOrders = () => {
    let minimizedOrders = []

    try {
        const key = getToken()
        minimizedOrders = JSON.parse(window.localStorage.getItem(key)) || [];
    } catch (err) {
        console.error("Erro ao carregar pedidos minimizados", err)
    }

    return {
        minimizedOrders: minimizedOrders,
    }
}

const getToken = () => {
    const profile = AuthService.profile()
    const prefix = (profile && profile.unidade && profile.unidade.id) || "_NAO_LOGADO_";
    const token = "_minimized_orders_" + prefix;
    /* console.log("TOKEN MINIMIZED", token) */
    return token;
}