import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Box,
  Collapse,
  ListItemAvatar,
  ListSubheader,
  Switch,
  FormControlLabel,
  Tooltip,
  ListItemButton,
  Dialog,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import AuthService from '../../../service/AuthService';
import { TipoIntegracaoAvatar } from '../Board/IntegrationAvatar'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { extractErrorMessage } from '../../../util/http/HttpUtil';
import { green } from '@mui/material/colors';
import MessageIcon from '@mui/icons-material/Message';
import Skeleton from '@mui/material/Skeleton';
import { DialogContent, DialogTitle } from '../CustomDialog/dialogComponents';
import { tefFetchLogin, tefFetchTerminal } from '../../../util/order/tefUtil';
import { LoadingButton } from '@mui/lab';
import InterrupcoesIntegracoes from './InterrupcoesIntegracoes';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ifAnyGranted } from '../../../util/auth/authUtil';

const PREFIX = 'AcoesIntegracoes'

const classes = {
  nested: `${PREFIX}-nested`,
  listaIntegracoes: `${PREFIX}-listaIntegracoes`,
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.listaIntegracoes}`]: {
    minWidth: theme.spacing(40),
  },
}))

function DialogTefContent({ tef, onClose }) {
  const { enqueueSnackbar } = useSnackbar()
  const [tefData, setTefData] = useState({
    terminalId: '',
    localImpressao: '',
  })
  const [loginData, setLoginData] = useState({
    cpfCnpj: '',
    senha: '',
  })
  const [terminals, setTerminals] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const locaisDeImpressao = useSelector(state => state.printerLocations) || []

  useEffect(() => {
    const terminalId = localStorage.getItem('_tef_terminal_id_') || ''
    const localImpressao = localStorage.getItem('_tef_local_impressao_') || ''

    setTefData({ terminalId, localImpressao })
  }, [])

  function handleChange(event) {
    const { name, value } = event.target

    setLoginData(state => ({ ...state, [name]: value }))
  }

  function handleChangeTefData(event) {
    const { name, value } = event.target

    setTefData(state => ({ ...state, [name]: value }))
  }

  async function handleLogin() {
    try {
      setIsLoading(true)
      const { TEF_ON_KEY } = JSON.parse(tef.dados)

      const { pessoa } = await tefFetchLogin(loginData)

      setIsLoggedIn(true)

      const { terminais } = await tefFetchTerminal(TEF_ON_KEY, pessoa.id)

      setTerminals(terminais)
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err, { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  function save() {
    if (!tefData.terminalId) {
      enqueueSnackbar('Selecione um terminal para o TEF', { variant: 'error' })
      return
    }

    localStorage.setItem('_tef_terminal_id_', tefData.terminalId)
    localStorage.setItem('_tef_local_impressao_', tefData.localImpressao)
    enqueueSnackbar(`Dados do TEF salvos com sucesso!`, { variant: 'success' })
    onClose()
  }

  return (
    <>
      <DialogTitle onClose={onClose}>
        TEF
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {!isLoggedIn ? (
          <>
            <TextField
              sx={{ mt: 2 }}
              label="CNPJ"
              name="cpfCnpj"
              value={loginData.cpfCnpj}
              onInput={handleChange} />

            <TextField
              sx={{ my: 2 }}
              label="Senha"
              type="password"
              name="senha"
              value={loginData.senha}
              onInput={handleChange} />

            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={handleLogin}>
              Logar
            </LoadingButton>
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>

            <FormControl sx={{ mt: 1, width: '100%' }}>
              <InputLabel>Local de impressão</InputLabel>
              <Select
                value={tefData.localImpressao}
                label="Local de impressão"
                name="localImpressao"
                onChange={handleChangeTefData}>
                {locaisDeImpressao.map(item => (
                  <MenuItem key={item.chave} value={item.chave}>{item.descricao}</MenuItem>
                ))}
              </Select>
              <FormHelperText>Local padrão: CAIXA</FormHelperText>
            </FormControl>

            <FormControl sx={{ my: 2, width: '100%' }}>
              <InputLabel>Terminal</InputLabel>
              <Select
                value={tefData.terminalId}
                label="Terminal"
                name="terminalId"
                onChange={handleChangeTefData}>
                {terminals.map(item => (
                  <MenuItem key={item.id} value={item.id}>{item.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <LoadingButton
              variant="contained"
              onClick={save}>
              Salvar
            </LoadingButton>
          </Box>
        )}
      </DialogContent>
    </>
  )
}

export default function AcoesIntegracao() {
  const [integracoes, setIntegracoes] = useState([]);
  const [atualizandoStatus, setAtualizandoStatus] = useState(null);
  const [colapseOpen, setColapseOpen] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const open = useSelector(state => state.drawerIntegracoes.open)
  const service = new AuthService()
  const [loadingAcao, setLoadingAcao] = useState(false)
  const [buscandoIntegracoes, setBuscandoIntegracoes] = useState(false)
  const [integracoesTef, setIntegracoesTef] = useState([])
  const [selectedTef, setSelectedTef] = useState()
  const [modalTefOpen, setModalTefOpen] = useState(false)
  const [openInterruptions, setOpenInterruptions] = useState(false)
  const [interruptionList, setInterruptionList] = useState(null)
  const statusWhatsApp = useSelector(state => state.whatsapp.status)
  const hasRoleWhatsapp = ifAnyGranted('ROLE_WHATSAPP_CONNECT')

  const [chatIfoodAtivo, setChatIfoodAtivo] = useState(() => {
    const savedState = localStorage.getItem('chatIfoodAtivo')
    return savedState === 'S' // converte a string para boolean
  })

  const handleToggleChatIfood = (event, integracao) => {
    const isActivated = event.target.checked;
    setChatIfoodAtivo(isActivated);
    let acao = isActivated
    let idIntegracao = integracao?.informacoesLoja[0].idConfiguracao

    service.post(`/api-v2/pedidoIntegracao/atualizarParametro/${idIntegracao}/${acao}`)
      .then(resp => {
        if (!!isActivated) {
          localStorage.setItem('chatIfoodAtivo', 'S')
        } else {
          localStorage.setItem('chatIfoodAtivo', 'N')
        }

        //Dispara um evento storage após o chatIfoodAtivo ser alterado
        window.dispatchEvent(new Event('storage'))
        enqueueSnackbar("Ação executada com sucesso", { variant: 'success', });
      }).catch(err => {
        console.error(err)
        enqueueSnackbar("Não foi possível realizar esta ação", { variant: 'error', });
      }).finally(() => {
        setLoadingAcao(false)
      })
  }

  const setOpen = (open) => {
    dispatch({ type: 'TOOGLE_DRAWER_INTEGRACOES', drawerIntegracoes: { open } })
  }

  useEffect(() => {
    if (open) {
      async function loadIntegracoes() {
        try {
          setBuscandoIntegracoes(true)

          const [respIntegracoes, respTef] = await Promise.all([
            service.get('/api-v2/pedidoIntegracao/integracoesAtivas'),
            service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=TEF')
          ])

          setIntegracoesTef(respTef)
          handleGetStatusLoja(respIntegracoes)
        } catch (err) {
          console.error(err)
          setBuscandoIntegracoes(false)
        }
      }

      loadIntegracoes()
    }
    // eslint-disable-next-line
  }, [open])

  const toggleDrawer = (open) => (event) => {
    setOpen(open);
  };

  const chamarFuncao = (idConfiguracao, acao) => {
    setLoadingAcao(true)

    service.post(`/api-v2/pedidoIntegracao/aplicarAcao/${idConfiguracao}/${acao}`)
      .then(resp => {
        if (acao === "LIST_INTERRUPTIONS") {
          //caso seja para listar as interrupções abre o dialog das interrupções
          setOpenInterruptions(true)
          let listInterruptions = JSON.parse(resp.message)
          if (!!listInterruptions) {
            setInterruptionList(listInterruptions)
          }
        }
        enqueueSnackbar("Ação executada com sucesso", { variant: 'success', });
      }).catch(err => {
        console.error(err)
        enqueueSnackbar("Não foi possível realizar esta ação", { variant: 'error', });
      }).finally(() => {
        setLoadingAcao(false)
      })

  }

  const handleGetStatusLoja = (integracoesParam) => {

    let integracoesCopia = Object.assign([], integracoesParam)

    setBuscandoIntegracoes(true)
    service.get(`/api-v2/pedidoIntegracao/statusLojas`)
      .then(respStatusLojas => {

        respStatusLojas.map(statusLoja => {

          let integracao = integracoesCopia.find(integracaoGroup => integracaoGroup.sigla === statusLoja.tipoIntegracao)

          if (integracao && integracao.informacoesLoja) {

            statusLoja.informacoesLoja.map(informacaoLoja => {

              integracao.informacoesLoja.push(informacaoLoja)
              return null;
            })

          } else if (integracao) {
            integracao.informacoesLoja = statusLoja.informacoesLoja
          }
          return null;
        })
        setIntegracoes(integracoesCopia)
      })
      .catch(err => {
        console.error(err)

        extractErrorMessage(err, "Falha ao buscar status das lojas")
          .then(msg => {
            enqueueSnackbar(msg, { variant: "error" });
          })
      })
      .finally(() => {
        setBuscandoIntegracoes(false)
      })
  }

  const handleAlterarStatusLoja = (event, idConfiguracao, id) => {
    let integracoesCopia = Object.assign([], integracoes)
    let status

    if (event.target.checked) {
      status = "ABERTO"
    } else {
      status = "FECHADO"
    }

    setAtualizandoStatus(idConfiguracao)

    service.post(`/api-v2/pedidoIntegracao/alterarStatusLoja/${idConfiguracao}/${id}/${status}`).then(resp => {
      let integracao
      integracao = integracoesCopia.find(integracaoGroup => integracaoGroup.sigla === resp.tipoIntegracao)

      resp.informacoesLoja.map(informacaoLoja => {
        let statusLojaAntigo = integracao.informacoesLoja.find(integracaoGroup => integracaoGroup.idConfiguracao === informacaoLoja.idConfiguracao && integracaoGroup.id === informacaoLoja.id && informacaoLoja.nomeLoja === integracaoGroup.nomeLoja)
        let index = integracao.informacoesLoja.indexOf(statusLojaAntigo)
        integracao.informacoesLoja[index] = informacaoLoja
        return null;
      })
      setIntegracoes(integracoesCopia)

      if ("FECHADO" === status) {
        enqueueSnackbar("Alterado status da loja para 'Fechada'", { variant: 'warning', });
      } else if ("ABERTO") {
        enqueueSnackbar("Alterado status da loja para 'Aberta'", { variant: 'success', });
      } else {
        enqueueSnackbar("Status da loja atualizado com sucesso", { variant: 'info', });
      }

      setAtualizandoStatus(null)
    })
      .catch(err => {
        console.error(err)

        extractErrorMessage(err, "Falha ao alterar status da loja")
          .then(msg => {
            enqueueSnackbar(msg, { variant: "error" });
          })

        setAtualizandoStatus(null)
      })
  }

  function openModalTef(tef) {
    setSelectedTef(tef)
    setModalTefOpen(true)
  }

  function modalTefClose() {
    setModalTefOpen(false)
    setSelectedTef(null)
  }

  return (
    <StyledDrawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>

      <Box px={0} py={5}>

        <List
          className={classes.listaIntegracoes}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Integrações ativas
            </ListSubheader>
          }>

          {integracoesTef.length ? (
            <>
              <ListItemButton onClick={() => openModalTef(integracoesTef[0])}>
                <ListItemAvatar>
                  <TipoIntegracaoAvatar sigla="TEF" />
                </ListItemAvatar>
                <ListItemText primary="TEF" />
              </ListItemButton>

              <Divider />
            </>
          ) : null}

          {hasRoleWhatsapp && (
            <>
              <ListItemButton>
                <WhatsAppIcon style={{ fontSize: 35, marginRight: 20, color: "green" }} />
                <ListItemText primary="WhatsApp" />
                <div style={{ marginRight: 6 }}>
                  <div style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: statusWhatsApp === 'connected' ? 'green' : 'red',
                  }} />
                </div>
              </ListItemButton>

              <Divider />
            </>
          )}

          {
            integracoes.map(integracao => (

              <React.Fragment key={integracao.sigla} >

                <ListItemButton onClick={() => setColapseOpen({ ...colapseOpen, [integracao.sigla]: !colapseOpen[integracao.sigla] })}>

                  <ListItemAvatar>
                    <TipoIntegracaoAvatar sigla={integracao.sigla} />
                  </ListItemAvatar>

                  <ListItemText primary={integracao.descricao} />
                  {colapseOpen[integracao.sigla] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse
                  in={colapseOpen[integracao.sigla]}
                  timeout="auto"
                  unmountOnExit>

                  <List
                    component="div"
                    style={{
                      paddingBottom: "20px"
                    }}>

                    {!integracao.informacoesLoja ? (
                      <ListItem disabled className={classes.nested}>
                        Carregando...
                      </ListItem>
                    ) : null}

                    {integracao.sigla === 'IF' && (
                      <ListItem style={{ display: 'flex', paddingLeft: '30px', paddingRight: '10px' }}>
                        <ListItemText secondary={"Ativar/Desativar chat"} />
                        <FormControlLabel
                          control={<StatusStoreSwitch
                            checked={chatIfoodAtivo}
                            onChange={(event) => handleToggleChatIfood(event, integracao)}
                            color="primary"
                          />}
                          label={chatIfoodAtivo ? "Ativado" : "Desativado"}
                        />
                      </ListItem>
                    )}

                    {integracao.informacoesLoja && integracao.informacoesLoja.map((informacaoLoja, index) => (
                      <React.Fragment key={index}>
                        {informacaoLoja.status !== "NAO_IMPLEMENTADO" ? (
                          <ListItem
                            className={classes.nested}
                            key={index}
                          >
                            {informacaoLoja.mensagens ? (
                              <ListItemIcon>
                                <Tooltip title={informacaoLoja.mensagens} >
                                  <MessageIcon />
                                </Tooltip>
                              </ListItemIcon>
                            ) : null}
                            <ListItemText secondary={informacaoLoja.nomeLoja} />
                            <FormControlLabel
                              control={<StatusStoreSwitch
                                checked={informacaoLoja.status === "ABERTO"}
                                onChange={event => handleAlterarStatusLoja(event, informacaoLoja.idConfiguracao, informacaoLoja.id)}
                                color="primary"
                                disabled={atualizandoStatus === informacaoLoja.idConfiguracao} />}
                              label={atualizandoStatus === informacaoLoja.idConfiguracao ? "Atualizando" :
                                informacaoLoja.status === "ABERTO" ? "Aberta" : "Fechada"}
                            />
                          </ListItem>
                        ) : (
                          <React.Fragment>
                            {!integracao.informacaoLoja && informacaoLoja.status === "NAO_IMPLEMENTADO" &&
                              <ListItemButton className={classes.nested} disabled>
                                <ListItemText primary={`informações da loja ${integracao.descricao} não encontrada`} />
                              </ListItemButton>
                            }
                          </React.Fragment>
                        )
                        }
                      </React.Fragment>
                    ))
                    }

                    {integracao.integracaoInfoList && integracao.integracaoInfoList.map(loja => (
                      loja.acoesDisponiveis && loja.acoesDisponiveis.map((acaoDisponivel, index) => (
                        <ListItemButton
                          disabled={loadingAcao}
                          key={index}
                          className={classes.nested}
                          onClick={() => chamarFuncao(loja.idConfiguracao, acaoDisponivel.acao)}>
                          <ListItemIcon>
                            <CallMadeIcon />
                          </ListItemIcon>

                          <ListItemText primary={`${loja.descricao} - ${acaoDisponivel.descricao}`} />
                        </ListItemButton>
                      ))))
                    }

                  </List>
                </Collapse>

                <Divider />
              </React.Fragment>
            ))
          }

        </List>

        {(integracoes.length === 0 && !buscandoIntegracoes) ?
          <Box px={2} py={3}>
            <Box color="text.disabled" fontSize="h6.fontSize">
              Nenhuma integração ativa <span role="img" aria-label="emoji">😴</span>
            </Box>
          </Box>
          : null}

        {buscandoIntegracoes && (
          <React.Fragment>
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </React.Fragment>
        )}

      </Box>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={modalTefOpen}
        onClose={modalTefClose}>

        <DialogTefContent
          tef={selectedTef}
          onClose={modalTefClose} />

      </Dialog>

      <InterrupcoesIntegracoes open={openInterruptions} setOpen={setOpenInterruptions} interruptionList={interruptionList} setInterruptionList={setInterruptionList} />
    </StyledDrawer>
  );
}

const StatusStoreSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[500],
  },
  '& .MuiSwitch-switchBase.Mui-checked:hover': {
    backgroundColor: 'rgba(78, 181, 82, 0.15)',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[500],
  },
}))