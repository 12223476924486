export const toogleTaxaEntregaList = (currentState, action) => {
    let { taxaEntregaList } = action
  
    return {
      ...currentState,
      taxaEntregaList: { ...taxaEntregaList, taxaEntrega: taxaEntregaList.taxaEntrega, open: taxaEntregaList.open }
    }
  }
  
  export const initialTaxaEntregaList = () => {
    return {
      open: false,
      taxaEntrega: null,
    }
  }