import React, { useEffect, useState, useRef } from 'react';
import { Map, HeatMap, GoogleApiWrapper } from "google-maps-react";
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// 3000 metros (3 km)
const DEFAULT_RADIUS = 3000

const containerStyle = {
  height: '60vh',
  position: 'relative',
  width: '100%'
}

const MapStyled = styled(Box)(() => ({

  '&': {
    position: 'relative'
  },

  [`& .gm-style-iw-d`]: {
    overflow: 'hidden !important',
    paddingRight: 10,
    paddingBottom: 10,
    paddingTop: 5,
    color: '#000'
  },

}))

const MapContainer = props => {
  const mapRef = useRef(null)
  const infoWindowRef = useRef(new props.google.maps.InfoWindow())
  const [showHeatMap, setShowHeatMap] = useState(true)
  const circleRef = useRef(null)
  const [filteredOrders, setFilteredOrders] = useState([]) // Estado para os pedidos filtrados
  const [valorTotalPedidos, setValorTotalPedidos] = useState(0)
  const [quantidadeTotalPedidos, setQuantidadeTotalPedidos] = useState(0)
  const [ticketMedioGeral, setTicketMedioGeral] = useState(0)
  const [circleRadius, setCircleRadius] = useState(3) // Inicializar com o valor inicial do raio em km

  useEffect(() => {
    const map = mapRef.current.map
    const google = props.google
    const center = props.initialCenter; // Usando o centro como local do restaurante 

    if (props.showMarkers) {
      setShowHeatMap(false)

      if (window.markerClusterer) {
        window.markerClusterer.clearMarkers()
      }

      // Cria marcadores e MarkerClusterer
      window.markers = props.markers.map((marker) => {

        const mapMarker = new google.maps.Marker({
          map,
          position: { ...marker },
        })

        mapMarker.addListener('click', () => {
          let contentString

          switch (props.typeWeight) {
            case 'QUANTITY':
              contentString = `<div><strong>Qtd. de pedidos: ${marker.quantidadePedido}</strong></div>`
              break

            case 'CURRENCY':
              contentString = `<div><strong>Valor total: ${marker.valorPedido}</strong></div>`
              break

            case 'AVG_TICKET':
              contentString = `<div><strong>Ticket médio: ${marker.ticketMedio}</strong></div>`
              break

            case 'TODOS':
              contentString = `<div><strong>Qtd. de pedidos: ${marker.quantidadePedido}</strong></div><div><strong>Valor total: ${marker.valorPedido}</strong></div><div><strong>Ticket Médio: ${marker.ticketMedio}</strong></div>`
              break

            default:
              contentString = `<div><strong>Qtd. de pedidos: ${marker.quantidadePedido}</strong></div><div><strong>Valor total: ${marker.valorPedido}</strong></div>`
          }
          infoWindowRef.current.setContent(contentString)
          infoWindowRef.current.open(map, mapMarker)
        });
        return mapMarker
      })

      window.markerClusterer = new MarkerClusterer({ map, markers: window.markers, minimumClusterSize: 1 })

    } else {
      setShowHeatMap(true)

      // Esconde marcadores
      if (window.markers) {
        window.markers.forEach(marker => marker.setMap(null))
      }

      if (window.markerClusterer) {
        window.markerClusterer.clearMarkers()
      }
    }

    if (!props.showCircle) {
      circleRef.current = null
      return
    }

    if (props.showCircle) {
      setShowHeatMap(false)

      if (!circleRef.current) {
        circleRef.current = new props.google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.1,
          map: map,
          center: center,
          radius: DEFAULT_RADIUS,
          editable: true,
          draggable: true
        })
        updateFilteredOrders(circleRef.current.getRadius(), circleRef.current.getCenter())
      } else {
        // Se já existir, atualize centro e raio
        circleRef.current.setCenter(center)
        circleRef.current.setRadius(DEFAULT_RADIUS)
        updateFilteredOrders(circleRef.current.getRadius(), circleRef.current.getCenter())
      }
    }

    // Eventos para atualização do painel ao modificar o círculo
    circleRef.current.addListener('bounds_changed', () => {
      const newRadius = circleRef.current.getRadius(); updateFilteredOrders(newRadius, circleRef.current.getCenter()); setCircleRadius(newRadius / 1000)
      circleRef.current.addListener('dragend', () => updateFilteredOrders(circleRef.current.getRadius(), circleRef.current.getCenter()))
    })

    // Atualização automática quando os pedidos filtrados mudam
    if (filteredOrders.length > 0) {
      updateFilteredOrders(circleRef.current.getRadius(), circleRef.current.getCenter())
    }

    // Limpeza ao desmontar o componente
    return () => {
      if (circleRef.current) {
        circleRef.current.setMap(null)
        circleRef.current = null
      }
    }
  }, [props.showMarkers, props.markers, props.google, DEFAULT_RADIUS, props.initialCenter, props.showCircle])

  // Função para atualizar os pedidos filtrados
  const updateFilteredOrders = async (radius, center) => {
    const { spherical } = await props.google.maps.importLibrary("geometry")
    const filtered = props.markers.filter(customer => {
      const customerLocation = new props.google.maps.LatLng({ lat: customer.lat, lng: customer.lng })
      const centerLocation = new props.google.maps.LatLng(center)
      const distance = spherical.computeDistanceBetween(customerLocation, centerLocation)

      return distance <= radius
    })
    setFilteredOrders(filtered)
  }

  // Função para calcular e atualizar o valor total, quantidade de pedidos e ticket médio
  const calculateOrderMetrics = (orders) => {
    let valorTotal = 0;
    let quantidadeTotalPedidos = 0.0

    orders.forEach(order => {

      quantidadeTotalPedidos += order.quantidadePedido
      // Limpar o valor para remover o símbolo de moeda e vírgulas, e convertê-lo para float
      let valorLimpo = order.valorPedido.replace('R$', '').replace(',', '.').trim();
      let valorNum = parseFloat(valorLimpo);
      valorTotal += valorNum;
    });

    const avgTicket = valorTotal / quantidadeTotalPedidos
    const avgTicketFormatted = avgTicket.toFixed(2)

    valorTotal = parseFloat(valorTotal.toFixed(2));

    setValorTotalPedidos(valorTotal)
    setQuantidadeTotalPedidos(quantidadeTotalPedidos)
    setTicketMedioGeral(avgTicketFormatted)
  };

  // Atualizar métricas sempre que os pedidos filtrados mudarem
  useEffect(() => {
    if (filteredOrders.length > 0) {
      calculateOrderMetrics(filteredOrders)
    } else {
      setValorTotalPedidos(0)
      setQuantidadeTotalPedidos(0)
      setTicketMedioGeral(0)
    }
  }, [filteredOrders])

  const FloatingPanel = () => {

    return (
      <div style={{
        position: 'absolute',
        width: 'auto',
        bottom: '30px',
        left: '10px',
        height: 'auto',
        backgroundColor: 'white',
        padding: '10px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
        zIndex: 1000,
        overflowY: 'auto',
        maxHeight: '90vh',
        color: 'black',
        borderRadius: '4px',
      }}>

        <div style={{ color: 'black' }}>
          <p><strong>Raio do Círculo:</strong> {circleRadius.toFixed(2)} km</p>
          <p><strong>Qtd. Total de Pedidos:</strong> {quantidadeTotalPedidos}</p>
          <p><strong>Valor Total dos Pedidos:</strong> R$ {valorTotalPedidos}</p>
          <p><strong>Ticket Médio Geral:</strong> R$ {ticketMedioGeral}</p>
        </div>
      </div>

    )
  }

  const gradient = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 0, 223, 1)',
    'rgba(0, 0, 191, 1)',
    'rgba(0, 0, 159, 1)',
    'rgba(0, 0, 127, 1)',
    'rgba(63, 0, 91, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)'
  ]

  return (
    <>
      <MapStyled>
        <Map
          google={props.google}
          className="mapStyled"
          initialCenter={props.initialCenter}
          containerStyle={containerStyle}
          zoom={13}
          ref={mapRef}
        >
          {showHeatMap && props.positions && props.positions.length > 0 &&
            <HeatMap
              gradient={gradient}
              opacity={0.5}
              positions={props.positions}
              radius={20}
            />
          }
          {props.showCircle && <FloatingPanel />}
        </Map>
      </MapStyled>
    </>
  )
}

const Loading = () => <div>Fancy loading container</div>

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: ['visualization', 'places'],
  LoadingContainer: Loading
})(MapContainer)
