import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import SelectDeliveryman from "./index"

export default function SelectDeliverymanBoard() {

    const dispatch = useDispatch()
    const order = useSelector(state => state.detailDialogDeliveryman.order)
    const open  = useSelector(state => state.detailDialogDeliveryman.open)


    const onConfirmSelectDialog = (pedidoImportacao) => {
        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoImportacao })
        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoImportacao } })
    }

    const hadleClose = () => {
        dispatch({type: 'TOOGLE_DIALOG_DELIVERYMAN', detailDialogDeliveryman: { open: false }}) 
    }

    return (
        order ? (
            <SelectDeliveryman 
                handleClose={hadleClose}
                open={open}
                pedidoIntegracao={ order }
                onConfirm={onConfirmSelectDialog}
            />
        ) : null 
    )
}