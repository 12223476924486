import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Dialog,
} from '@mui/material'
import { DialogTitle, DialogContent } from "../../pages/layout-components/CustomDialog/dialogComponents"
import LogisticList from './logisticList';

export default function DialogLogistic() {
  const dispatch = useDispatch()
 
  const open = useSelector(state => state.dialogLogistics.open)
  const order = useSelector(state => state.dialogLogistics.order)

  const handleClose = () => {
    dispatch({ type: 'TOOGLE_DIALOG_LOGISTICS', dialogLogistics: { open: false, order } })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle onClose={handleClose}>
        </DialogTitle>

        <DialogContent>
          <LogisticList order = {order} />  
        </DialogContent>
      </Dialog>
    </div>
  )
}
