import {
    Box,
    Button, Dialog, DialogActions, Grid, IconButton, InputAdornment,
    Radio, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Tabs,
    TextField
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParamByKey } from '../../../../component/parameter';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import AuthService from '../../../../service/AuthService';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '../../../../util/http/HttpUtil';
import { totalOrder, totalItems, totalDeliveryFee, totalPayments } from '../../../../util/order/orderUtil';
import { currencyFormat } from '../../../../util/formatter';
import { Alert } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Fragment } from 'react';
import TransferItem from './TransferItem';
import { createCardFromPedido } from '../../../../util/board/BoardUtil'
import { dateToStringISO } from '../../../../util/formatter'
import { usePdvContext } from '../../../../component/generics/PdvProvider';

export default function JoinOrder() {
    let service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const { confirmDialog } = usePdvContext()
    const identificadorPrincipalRef = useRef(null)
    const generalParameters = useSelector(state => state.generalParameters)
    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString
    const LABEL_IDENTIFICADOR_PRINCIPAL = MODELO_IDENTIFICADOR_PRINCIPAL === 'MESA' ? 'Mesa' : 'Comanda'
    const LABEL_IDENTIFICADOR_SECUNDARIO = MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' ? 'Mesa' : '--'
    const [msgErroBusca, setMsgErroBusca] = useState("")
    const [orderList, setOrderList] = useState()
    const [origemOrder, setOrigemOrder] = useState()
    const [buscando, setBuscando] = useState(false)
    const [saving, setSaving] = useState(false)
    const dispatch = useDispatch()
    const [selectedOrder, setSelectedOrder] = React.useState();
    const open = useSelector(state => state.dialogJoinOrders.open)
    const orderListFromParam = useSelector(state => state.dialogJoinOrders.data?.orderList)
    const openCloseOrder = useSelector(state => state.dialogJoinOrders.openCloseOrder)
    const identificadorCard = useSelector(state => state.dialogJoinOrders.identificadorCard)
    const [selectedTab, setSelectedTab] = useState(0)


    useEffect(() => {
        setOrderList(orderListFromParam)
        if (orderListFromParam && orderListFromParam.length > 0) {
            setSelectedOrder(orderListFromParam[0].id)
        }
    }, [orderListFromParam])



    const handleClose = () => {
        onConstructor()
        dispatch({ type: 'TOOGLE_DIALOG_JOIN_ORDERS', dialogJoinOrders: { open: false } })
    };

    const onClickCancel = () => {
        handleClose()
    }

    const buscarPedidoPeloIdentificadorPrincipal = (identificadorPrincipal, tipos) => {

        setBuscando(true)
        service
            .get(`/api-v2/pedidoIntegracao/listByIdentificadorPrincipal?identificadorPrincipal=${identificadorPrincipal}&tipos=${tipos}&importado=false&pago=NAO&cancelado=false`)
            .then(pedidos => {
                setBuscando(false)
                let orderListTemp = Object.assign([], orderList)

                pedidos = pedidos.filter((pedido) => pedido.motivoCancelamento === undefined) //remove os pedidos cancelados da lista para agrupar pedidos

                if (pedidos && pedidos.length > 0) {

                    /* SO ADICIONA O PEDIDO CASO O MESMO NAO EXISTA NA LISTA  */
                    pedidos.map(pedido => {
                        let pedidoJaAdicionado = orderListTemp.find(pedidoAdicionado => pedidoAdicionado.id === pedido.id)
                        if (!pedidoJaAdicionado) {
                            orderListTemp.push(pedido)
                        }
                        return null;
                    })

                    setOrderList(orderListTemp)
                    setSelectedOrder(pedidos[0].id)

                    identificadorPrincipalRef.current.value = null


                    setTimeout(() => {
                        identificadorPrincipalRef.current.focus()
                    }, 150);

                    setMsgErroBusca("")

                } else {
                    setMsgErroBusca("Nenhum pedido encontrado.")
                    setTimeout(() => {
                        identificadorPrincipalRef.current.select();
                        //identificadorPrincipalRef.current.focus();
                    }, 150);
                }

            }).catch(err => {
                console.error(err)

                extractErrorMessage(err, "Não foi possível buscar pedidos pelo identificador principal.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })

                setBuscando(false)
            })
    }

    const handleEnterCampoIdentificadorPrincipal = event => {
        let identificadorPrincipalValue = identificadorPrincipalRef.current.value
        if (identificadorPrincipalValue.length === 0) {
            enqueueSnackbar(`É necessário informar um valor para buscar a ${LABEL_IDENTIFICADOR_PRINCIPAL}`, { variant: "error" });
        } else {
            buscarPedidoPeloIdentificadorPrincipal(identificadorPrincipalValue, MODELO_IDENTIFICADOR_PRINCIPAL)
        }
    }

    const onClickAdd = () => {
        handleEnterCampoIdentificadorPrincipal()
    }

    const handleKeyDownIdentificadorPrincipal = event => {
        if (13 === event.keyCode) {
            handleEnterCampoIdentificadorPrincipal(event)
        }
    }

    const handleChangeIdentificadorPrincipal = event => {
        setMsgErroBusca("")
    }

    function onConstructor() {
        setSelectedOrder()
        setOrigemOrder()
        setOrderList()
    }

    const onClickFinalizar = () => {

        if (selectedTab === 0) {
            if (orderList && orderList.length > 1) {

                setSaving(true)

                const lastOrder = orderList.find((e) => parseInt(e.id) === parseInt(selectedOrder))

                if (lastOrder) {
                    let idsPedidoSepVirgula = ''

                    orderList.map((order) => {
                        idsPedidoSepVirgula += order.id + ','
                        return null;
                    })

                    service
                        .get(`/api-v2/pedidoIntegracao/juntarPedidoIntegracao?idsPedidoSepVirgula=${idsPedidoSepVirgula}&idPedidoDestino=${lastOrder.id}`)
                        .then(orderResp => {

                            let identificadores

                            orderList.map((order) => {
                                if (order.id !== orderResp.id) {

                                    if (!identificadores) {
                                        identificadores = order.identificadorPrincipal
                                    } else {
                                        identificadores += `, ${order.identificadorPrincipal}`
                                    }

                                    dispatch({ type: 'REMOVE_ORDER_GENERIC', order: order })
                                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'REMOVE_ORDER_GENERIC', order: order } })
                                }
                                return null;
                            })

                            dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderResp })
                            dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: orderResp } })


                            enqueueSnackbar(`${MODELO_IDENTIFICADOR_PRINCIPAL} ${identificadores} foi agrupada na  ${MODELO_IDENTIFICADOR_PRINCIPAL} ${lastOrder.identificadorPrincipal}`, { variant: "success" });
                            handleClose()
                            setSaving(false)

                            let updatedCard = createCardFromPedido(orderResp)

                            if (identificadorCard && identificadorCard.orderList.length > 1) {
                                dispatch({
                                    type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: {
                                        open: true,
                                        data: { identificadorCard }
                                    }
                                })
                            } else if (openCloseOrder) {//define se vai abrir a tela de detalhe do pedido ou a tela de fechamento do pedido
                                dispatch({ type: 'OPEN_DIALOG_CLOSE_ORDER', closeOrder: { open: true, order: orderResp } })
                            } else {
                                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })
                            }

                        }).catch(err => {
                            console.error(err)
                            setSaving(false)

                            extractErrorMessage(err, "Erro juntar os pedidos.")
                                .then(msg => {
                                    enqueueSnackbar(msg, { variant: "error" });
                                })
                        })
                }
            } else {
                enqueueSnackbar(`Adicione outra ${MODELO_IDENTIFICADOR_PRINCIPAL} para poder juntar.`, { variant: "warning" });
            }
        } else {
            if (orderList && orderList.length > 1) {
                setSaving(true)
                let orderOrigem = orderList[0]
                let orderDestino = orderList[1]

                const valueTotalOrder = totalOrder(orderOrigem.iFoodOrder)
                const valueTotalPayment = totalPayments(orderOrigem.iFoodOrder)

                if (valueTotalPayment > valueTotalOrder) {
                    const valueTotalOrderOriginal = totalOrder(origemOrder.iFoodOrder)

                    confirmDialog({
                        confirmLabel: 'Ok',
                        showCancelButton: false,
                        contentAlignment: 'left',
                        content: `
                            Não é permitido transferir os itens, o pedido já foi parcialmente pago.<br>
                            <br>
                            Total Recebido: <strong>${currencyFormat(valueTotalPayment)}</strong><br>
                            Valor total do pedido: <strong>${currencyFormat(valueTotalOrderOriginal)}</strong><br>
                            <br>
                            SUGESTÃO: Estorne os pagamentos deste pedido.`,
                    }).catch(() => { })

                    setSaving(false)

                    return
                }

                orderDestino.logs = !!orderDestino.logs ? orderDestino.logs + " \n" : ""
                orderDestino.logs = orderDestino.logs + "Este Pedido recebeu itens do pedido: " + orderOrigem.id

                if (orderOrigem.iFoodOrder.items.length <= 0) {
                    orderOrigem.ativo = false
                    orderOrigem.situacao = 'CANCELADO'
                    orderOrigem.dataCancelamento = dateToStringISO(new Date())
                    orderOrigem.motivoCancelamento = `Este pedido transferiu todos os itens para o pedido #${orderDestino.id || ' UM PEDIDO NOVO'}. Por isso foi cancelado`;
                    orderOrigem.iFoodOrder.observations += `Este pedido transferiu todos os itens para o pedido #${orderDestino.id || ' UM PEDIDO NOVO'}. Por isso foi inativado`
                    orderOrigem.justificativaExclusao = `Este pedido transferiu todos os itens para o pedido #${orderDestino.id || ' UM PEDIDO NOVO'}. Por isso foi inativado`
                    orderDestino.quantidadePessoas = (orderDestino.quantidadePessoas || 0) + (orderOrigem.quantidadePessoas || 0)
                    let adidtionalLog = !!orderOrigem.logs ? " \n Logs do pedido: " + orderOrigem.id + " ---" + orderOrigem.logs + "---" : ""
                    orderDestino.logs = orderDestino.logs + adidtionalLog
                }

                orderOrigem.logs = !!orderOrigem.logs ? orderOrigem.logs + " \n" : ""
                orderOrigem.logs = orderOrigem.logs + "Este Pedido: " + orderOrigem.id + " teve items transferidos"

                /* ATUALIZA TOTAIS ORIGEM */
                orderOrigem.iFoodOrder.subTotal = totalItems(orderOrigem.iFoodOrder)
                orderOrigem.iFoodOrder.deliveryFee = totalDeliveryFee(orderOrigem.iFoodOrder)
                orderOrigem.iFoodOrder.totalPrice = totalOrder(orderOrigem.iFoodOrder)
                console.log('orderOrigem ->', orderOrigem.iFoodOrder)


                /* ATUALIZA TOTAIS DESTINO */
                orderDestino.iFoodOrder.subTotal = totalItems(orderDestino.iFoodOrder)
                orderDestino.iFoodOrder.deliveryFee = totalDeliveryFee(orderDestino.iFoodOrder)
                orderDestino.iFoodOrder.totalPrice = totalOrder(orderDestino.iFoodOrder)
                console.log('orderDestino ->', orderDestino.iFoodOrder)

                //SE O PEDIDO DESTINO AINDA NAO POSSUI COMPLEMENTO, PEGA DA ORIGEM SE ELE POSSUIR
                if (!orderDestino.complementoIdentificador && orderOrigem.complementoIdentificador) {
                    orderDestino.complementoIdentificador = orderOrigem.complementoIdentificador
                }

                //transfere a pessoa para a nova comanda caso não tenha pessoa associada a comanda de destino
                if (!!!orderDestino.pessoaCliente) {
                    orderDestino.pessoaCliente = orderOrigem.pessoaCliente
                    orderDestino.iFoodOrder.customer = orderOrigem.iFoodOrder.customer
                }

                //SE O PEDIDO DESTINO AINDA NAO EXISTE, COPIA A MESA TBM (SE HOUVER)
                if (!orderDestino.id) {
                    orderDestino.identificadorSecundario = orderOrigem.identificadorSecundario
                }

                console.log('Transferir item desta lista ->', orderList)

                service.post('/api-v2/pedidoIntegracao/saveAll', orderList)
                    .then(respOrderList => {

                        respOrderList.map((savedOrder) => {
                            if (savedOrder.ativo) {
                                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder })
                                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: savedOrder } })
                            } else {
                                dispatch({ type: 'REMOVE_ORDER_GENERIC', order: savedOrder })
                                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'REMOVE_ORDER_GENERIC', order: savedOrder } })

                            }
                            return null;
                        })

                        enqueueSnackbar(`Itens transferidos`, { variant: "success" });
                        handleClose()
                        setSaving(false)
                    }).catch(err => {
                        console.error(err)
                        setSaving(false)

                        extractErrorMessage(err, "Erro ao juntar os pedidos.")
                            .then(msg => {
                                enqueueSnackbar(msg, { variant: "error" });
                            })
                    })
            } else {
                enqueueSnackbar(`Nenhum item transferido`, { variant: "warning" });
            }
        }
    }

    const handleChangeRadio = (event) => {
        setSelectedOrder(event.target.value);
    };

    const getTotalOrderList = (orderList) => {
        let total = 0.0;
        if (orderList && orderList.length > 0) {
            orderList.map(order => {
                total += totalOrder(order.iFoodOrder)
                return null;
            })
        }
        return total;
    }


    const removeOrder = (order) => {
        let orderListCopy = Object.assign([], orderList)
        orderListCopy = orderListCopy.filter(orderAdded => orderAdded.id !== order.id)
        setOrderList(orderListCopy)
        if (selectedOrder === order.id && orderListCopy.length > 0) {
            setSelectedOrder(orderListCopy[0].id)
        }
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function handleSentDestino(newOrderList, origemOrderOriginal) {
        setOrderList(newOrderList)
        setOrigemOrder(origemOrderOriginal)
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth='md'
                fullWidth
                open={open}
                onClose={onClickCancel}>
                <DialogTitle onClose={onClickCancel}>
                    Agrupar os pedidos em uma mesma {LABEL_IDENTIFICADOR_PRINCIPAL}
                </DialogTitle>


                <DialogContent>
                    <Fragment>
                        <Tabs

                            value={selectedTab}
                            onChange={handleChange}
                            indicatorColor='primary'
                            textColor='primary'
                        >
                            <Tab label={`Agrupar ${LABEL_IDENTIFICADOR_PRINCIPAL}`} tabIndex={0} />
                            <Tab label="Transferir itens" tabIndex={1} />
                        </Tabs>

                        <Box pt={2}>
                            {
                                selectedTab === 0 ?

                                    <Grid

                                        container
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <TextField
                                                label={`Informe número da ${LABEL_IDENTIFICADOR_PRINCIPAL}`}
                                                placeholder="'Enter' para adicionar"
                                                variant="filled"
                                                autoFocus
                                                error={!!msgErroBusca}
                                                helperText={msgErroBusca}
                                                fullWidth
                                                inputRef={identificadorPrincipalRef}
                                                onKeyDown={handleKeyDownIdentificadorPrincipal}
                                                onChange={handleChangeIdentificadorPrincipal}
                                                disabled={buscando}
                                                InputProps={{
                                                    endAdornment: (
                                                        buscando ? (
                                                            <InputAdornment position="end">Buscando...</InputAdornment>
                                                        ) :
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" onClick={onClickAdd} size="large">
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                    ),
                                                    disableUnderline: true
                                                }}
                                            />

                                        </Grid>

                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                Os pedidos serão agrupados na <strong>{LABEL_IDENTIFICADOR_PRINCIPAL}</strong> selecionada
                                            </Alert>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TableContainer>

                                                <Table aria-label="Juntar pedidos" size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell align="center" >{LABEL_IDENTIFICADOR_PRINCIPAL}</TableCell>
                                                            <TableCell align="center" >{LABEL_IDENTIFICADOR_SECUNDARIO}</TableCell>
                                                            <TableCell align="right">Valor</TableCell>
                                                            <TableCell align="center"></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            orderList?.map((order, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <Radio
                                                                            checked={// eslint-disable-next-line
                                                                                selectedOrder == order.id}
                                                                            onChange={handleChangeRadio}
                                                                            value={order.id}
                                                                            name="ckeck_order_id"
                                                                        />

                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" align="center">
                                                                        <strong>{order.identificadorPrincipal}</strong>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row" align="center">
                                                                        {order.identificadorSecundario}
                                                                    </TableCell>
                                                                    <TableCell align="right">{currencyFormat(totalOrder(order?.iFoodOrder))}</TableCell>
                                                                    <TableCell align="center">
                                                                        <IconButton onClick={() => removeOrder(order)} size="large">
                                                                            <DeleteOutlineIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TableCell colSpan={3} component="th" scope="row" align="right">
                                                                Total
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>{currencyFormat(getTotalOrderList(orderList))}</strong>
                                                            </TableCell>
                                                            <TableCell ></TableCell>
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                    :
                                    <TransferItem onSentDestino={handleSentDestino} />
                            }
                        </Box>
                    </Fragment>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={onClickFinalizar}
                        variant='contained'
                        color='primary'
                        disabled={saving}
                        endIcon={<DoneIcon />}>
                        {saving ? 'Salvando...' : selectedTab === 0 ? 'Agrupar' : 'Transferir'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}