import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { getSettings, saveSettings } from "./settings"
import {
    Grid,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
    Button,
    Chip,
    useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PrintLogDialog from '../print-log';
import { ifAnyGrantedUnidade } from '../../util/auth/authUtil';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckIcon from '@mui/icons-material/Check';
import { isAuthorized } from '../../routes';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getParamByKey } from '../../component/parameter';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import KdsLog from '../print-log/KdsLog';
import { styled } from '@mui/material/styles';
import SequencePedidoIntegracaoSettings from './SequencePedidoIntegracaoSettings';

const PREFIX = 'LocalSettings'

const classes = {
    chip: `${PREFIX}-chip`,
    configAlert: `${PREFIX}-configAlert`,
    configRadio: `${PREFIX}-configRadio`,
}

const StyledBox = styled(Box)(({ theme }) => ({

    [`& .${classes.chip}`]: {
        margin: theme.spacing(0.5),
    },

    [`& .${classes.configAlert}`]: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiAlert-icon': {
                display: 'none',
            },

            '& ul': {
                paddingLeft: '15px',
            },
        },
    },

    [`& .${classes.configRadio}`]: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
        },
    }
}))

export default function Load() {
    const [settings, setSettings] = useState(getSettings())
    const { enqueueSnackbar } = useSnackbar()
    const [openPrintDialog, setOpenPrintDialog] = useState(false)
    const [openKDSLogDialog, setOpenKDSLogDialog] = useState(false)
    const [generateInvoice, setGenerateInvoice] = useState(localStorage.getItem('GENERATE_INVOICE_FINISH_IMPORT') === 'true' ? 'true' : 'false')
    const URL_BASE = process.env.REACT_APP_API_URL
    const ROLE_CONFIGURAR_PARAMETROS = isAuthorized('ROLE_CONFIGURAR_PARAMETROS')
    const generalParameters = useSelector(state => state.generalParameters)
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const handleSaveSettings = () => {
        localStorage.setItem('GENERATE_INVOICE_FINISH_IMPORT', generateInvoice)
        dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { productMenu: null } })
        saveSettings(settings)
        enqueueSnackbar("Configurações salvas com sucesso", { variant: 'success', })
    }

    const handleChange = (event) => {
        setSettings({ ...settings, [event.target.name]: event.target.value })
    };

    const ROLE_UTILIZA_NOTIFICACAO = ifAnyGrantedUnidade('ROLE_UTILIZA_NOTIFICACAO,ROLE_UTILIZA_KDS')
    const ROLE_UTILIZA_NOTIFICACAO_KDS = ifAnyGrantedUnidade('ROLE_UTILIZA_KDS')
    const TIPO_PEDIDO_QUE_ACEITAM_NOTIFICACAO_AREA_PRODUCAO = getParamByKey(generalParameters, 'TIPO_PEDIDO_QUE_ACEITAM_NOTIFICACAO_AREA_PRODUCAO')?.valorString
    const LOCAL_NOTIFICACAO_ITEM = getParamByKey(generalParameters, 'LOCAL_NOTIFICACAO_ITEM')?.valorString
    const TIPO_PEDIDO_QUE_ACEITAM_NOTIFICACAO_AREA_PRODUCAO_ARRAY = TIPO_PEDIDO_QUE_ACEITAM_NOTIFICACAO_AREA_PRODUCAO ? TIPO_PEDIDO_QUE_ACEITAM_NOTIFICACAO_AREA_PRODUCAO.split("|") : ["-- nenhum configurado --"]
    const IMPRIMIR_AUTOMATICAMENTE_AO_CONFIRMAR = getParamByKey(generalParameters, 'IMPRIMIR_AUTOMATICAMENTE_AO_CONFIRMAR')?.valorString
    const CONFIRMAR_PEDIDOS_AUTOMATICAMENTE = getParamByKey(generalParameters, 'CONFIRMAR_PEDIDOS_AUTOMATICAMENTE')?.valorString
    const TIPO_SEQUENCIAL_IDENTIFICADOR = getParamByKey(generalParameters, 'TIPO_SEQUENCIAL_IDENTIFICADOR')?.valorString

    return (
        <StyledBox mt={3}>
            <SettingOption
                lableContent={
                    <SequencePedidoIntegracaoSettings />
                }
                detail={
                    settings.printApplication === 'APP' ? (
                        <Box p={1}>
                            <Alert className={classes.configAlert}>
                                <AlertTitle>Configuração do sequencial</AlertTitle>
                                <ul>
                                    <li>
                                        <Typography variant="subtitle2">
                                            Modelo ativo
                                            {TIPO_SEQUENCIAL_IDENTIFICADOR === "S" && (<Chip size="small" label="Sequencial" color='primary' className={classes.chip} />)}
                                            {TIPO_SEQUENCIAL_IDENTIFICADOR === "A999" && (<Chip size="small" label="Aleatório de 1 até 999" color='primary' className={classes.chip} />)}
                                            {TIPO_SEQUENCIAL_IDENTIFICADOR === "A9999" && (<Chip size="small" label="Aleatório de 1 até 9999" color='primary' className={classes.chip} />)}
                                        </Typography>
                                    </li>
                                </ul>

                                {ROLE_CONFIGURAR_PARAMETROS && (
                                    <Button
                                        size="small"
                                        color="grey"
                                        href={`${URL_BASE}/admin/parametro-config/SISTEMA_SISCHEF/`}
                                        target="_blank" endIcon={<OpenInNewIcon />}>
                                        Alterar
                                    </Button>
                                )}
                            </Alert>
                        </Box>
                    ) : (null)
                } />

            <SettingOption
                lableContent={
                    <Typography variant="subtitle1">
                        Notificações nas áreas de produção (impressoras e KDS)
                    </Typography>
                }
                detail={
                    <Box p={1}>
                        <Alert className={classes.configAlert}>
                            <AlertTitle>Configuração atual das notificações</AlertTitle>

                            <ul>
                                <li>
                                    <Typography variant="subtitle2">
                                        Módulos ativos
                                        {ROLE_UTILIZA_NOTIFICACAO ? <Chip size="small" icon={<NotificationsIcon />} label="'Notificação impressa' ativo" color='primary' className={classes.chip} /> : <Chip size="small" icon={<NotificationsOffIcon />} label="'Notificação impressa' inativo" className={classes.chip} />}
                                        {ROLE_UTILIZA_NOTIFICACAO_KDS ? <Chip size="small" icon={<NotificationsIcon />} label="'KDS' ativo" color='primary' className={classes.chip} /> : <Chip size="small" icon={<NotificationsOffIcon />} label="'KDS' inativo" className={classes.chip} />}
                                    </Typography>
                                </li>

                                {ROLE_UTILIZA_NOTIFICACAO || ROLE_UTILIZA_NOTIFICACAO_KDS ? (
                                    <>
                                        <li>
                                            <Typography variant="subtitle2">Tipos de pedido a serem notificados:
                                                {TIPO_PEDIDO_QUE_ACEITAM_NOTIFICACAO_AREA_PRODUCAO_ARRAY.map(tipoPedido => (
                                                    <Chip size="small" label={tipoPedido} color='primary' key={tipoPedido} className={classes.chip} />
                                                ))}
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="subtitle2">
                                                Tipo de notificação dos itens:

                                                {// eslint-disable-next-line
                                                    LOCAL_NOTIFICACAO_ITEM == "AMBOS" || LOCAL_NOTIFICACAO_ITEM == "IMPRESSO" ?
                                                        <Chip size="small" label={"'Notificação impressa' ativo"} color='primary' className={classes.chip} />
                                                        :
                                                        <Chip size="small" label={"'Notificação impressa' inativo"} className={classes.chip} />
                                                }

                                                {// eslint-disable-next-line
                                                    LOCAL_NOTIFICACAO_ITEM == "AMBOS" || LOCAL_NOTIFICACAO_ITEM == "KDS" ?
                                                        <Chip size="small" label={"'KDS' ativo"} color='primary' className={classes.chip} />
                                                        :
                                                        <Chip size="small" label={"'KDS' inativo"} className={classes.chip} />
                                                }

                                            </Typography>
                                        </li>

                                        {ROLE_CONFIGURAR_PARAMETROS && (
                                            <Button
                                                size="small"
                                                color="grey"
                                                href={`${URL_BASE}/admin/parametro-config/SISTEMA_SISCHEF_PDV_ONLINE/`}
                                                target="_blank" endIcon={<OpenInNewIcon />}>
                                                Alterar
                                            </Button>
                                        )}

                                        {ROLE_CONFIGURAR_PARAMETROS && ROLE_UTILIZA_NOTIFICACAO_KDS && '|'}

                                        {ROLE_UTILIZA_NOTIFICACAO_KDS && (
                                            <Button
                                                size="small"
                                                onClick={() => setOpenKDSLogDialog(true)}
                                                color="grey">
                                                Log dos itens do KDS
                                            </Button>
                                        )}
                                    </>
                                ) : null}

                            </ul>
                        </Alert>
                    </Box>
                } />

            <SettingOption
                lableContent={
                    <Typography variant="subtitle1">
                        Ao confirmar os pedidos integrados (tela de delivery)
                    </Typography>
                }
                detail={
                    <Box p={1}>
                        <Alert className={classes.configAlert}>
                            <AlertTitle>Configuração atual</AlertTitle>

                            <ul>
                                <li>
                                    <Typography variant="subtitle2">
                                        Confirmar pedidos automaticamente:
                                        {CONFIRMAR_PEDIDOS_AUTOMATICAMENTE === 'S' ? <Chip size="small" label="Ativo" color='primary' className={classes.chip} /> : <Chip size="small" label="Inativo" className={classes.chip} />}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        Imprimir automaticamente ao confirmar:
                                        {IMPRIMIR_AUTOMATICAMENTE_AO_CONFIRMAR === 'S' ? <Chip size="small" label="Ativo" color='primary' className={classes.chip} /> : <Chip size="small" label="Inativo" className={classes.chip} />}
                                    </Typography>
                                </li>
                            </ul>

                            <Button
                                size="small"
                                color="grey"
                                href={`${URL_BASE}/admin/parametro-config/SISTEMA_SISCHEF_PDV_ONLINE/`}
                                target="_blank" endIcon={<OpenInNewIcon />}>
                                Alterar
                            </Button>

                        </Alert>
                    </Box>
                } />

            <SettingOption
                lableContent={
                    <Typography variant="subtitle1">
                        Imprimir automaticamente ao criar pedido
                    </Typography>
                }
                inputContent={
                    <RadioGroup row aria-label="position"
                        name="printAutomaticallyOnCreate"
                        className={classes.configRadio}
                        defaultValue={settings.printAutomaticallyOnCreate}
                        value={settings.printAutomaticallyOnCreate} onChange={handleChange}>
                        <FormControlLabel
                            value="ENABLED"
                            control={<Radio color="primary" />}
                            label="Ativo"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value="DISABLED"
                            control={<Radio color="primary" />}
                            label="Inativo"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                } />

            <SettingOption
                lableContent={
                    <Typography variant="subtitle1">
                        Ativar notificação sonora para pedidos 'Pendentes'
                    </Typography>
                }
                inputContent={
                    <RadioGroup row aria-label="position" name="enableRingSongNewOrder"
                        className={classes.configRadio}
                        value={settings.enableRingSongNewOrder}
                        defaultValue={settings.enableRingSongNewOrder}
                        onChange={handleChange}>
                        <FormControlLabel
                            value="ENABLED"
                            control={<Radio color="primary" />}
                            label="Ativo"
                            labelPlacement="start" />
                        <FormControlLabel
                            value="DISABLED"
                            control={<Radio color="primary" />}
                            label="Inativo"
                            labelPlacement="start" />
                    </RadioGroup>
                } />

            <SettingOption
                lableContent={
                    <>
                        <Typography variant="subtitle1"> Forma de impressão</Typography>
                        <Typography variant="subtitle2">
                            Para utilizar o app de impressão é necessário que o mesmo já esteja instalado
                        </Typography>
                    </>
                }
                detail={
                    settings.printApplication === 'APP' ? (
                        <Box p={1}>
                            <Alert>
                                <AlertTitle>Sobre as impressões</AlertTitle>
                                <span>
                                    <Button
                                        size="small"
                                        color="grey"
                                        target="_blank"
                                        href="https://ajuda.sischef.com/article/app-impressao/">
                                        Como configurar o app?
                                    </Button>
                                    <Button
                                        color="grey"
                                        size="small"
                                        onClick={() => setOpenPrintDialog(true)}>
                                        Últimas impressões
                                    </Button>
                                </span>
                            </Alert>
                        </Box>
                    ) : (null)
                }

                inputContent={
                    <RadioGroup row aria-label="position"
                        name="printApplication"
                        className={classes.configRadio}
                        defaultValue={settings.printApplication}
                        value={settings.printApplication} onChange={handleChange}>
                        <FormControlLabel value="WEB" control={<Radio color="primary" />}
                            label="Navegador web"
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            value="APP"
                            control={<Radio color="primary" />}
                            label="App de impressão"
                            labelPlacement="start"
                        />
                    </RadioGroup>
                } />

            <SettingOption
                lableContent={
                    <Typography variant="subtitle1">
                        Abrir pop-up de emissão fiscal após importação
                    </Typography>
                }
                inputContent={
                    <RadioGroup row aria-label="position"
                        name="generateInvoice"
                        defaultValue={generateInvoice}
                        className={classes.configRadio}
                        value={generateInvoice}
                        onChange={event => setGenerateInvoice(event.target.value)}>
                        <FormControlLabel
                            value="true"
                            label="Sempre abrir"
                            labelPlacement="start"
                            control={<Radio color="primary" />} />
                        <FormControlLabel
                            value="false"
                            label="Nunca abrir"
                            labelPlacement="start"
                            control={<Radio color="primary" />} />
                    </RadioGroup>
                } />

            <SettingOption
                lableContent={
                    <>
                        <Typography variant="subtitle1">
                            Modo de visualização
                        </Typography>
                        <Typography variant="subtitle2">
                            Utilizado para filtrar os produtos de acordo com o modo configurado no grupo do produto
                        </Typography>
                    </>
                }
                inputContent={
                    <RadioGroup
                        row
                        aria-label="position"
                        name="enableMobilePDV"
                        value={settings.enableMobilePDV ?? "false"}
                        className={classes.configRadio}
                        onChange={handleChange}>
                        <FormControlLabel
                            value="true"
                            label="Mobile"
                            labelPlacement="start"
                            control={<Radio color="primary" />} />
                        <FormControlLabel
                            value="false"
                            label="Terminal (desktop)"
                            labelPlacement="start"
                            control={<Radio color="primary" />} />
                    </RadioGroup>
                } />

            <Box pt={2} display="flex" justifyContent="flex-end">
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm>
                        {ROLE_CONFIGURAR_PARAMETROS && (
                            <Box pr={1}>
                                <Button
                                    fullWidth={isMobile}
                                    color="grey"
                                    variant="text"
                                    href={`${URL_BASE}/admin/parametro-config/SISTEMA_SISCHEF_PDV_ONLINE/`}
                                    target="_blank" endIcon={<OpenInNewIcon />}>
                                    Configurações do retaguarda
                                </Button>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} sm="auto">
                        <Button
                            fullWidth={isMobile}
                            variant="contained"
                            onClick={handleSaveSettings}
                            endIcon={<CheckIcon />}>
                            Salvar configurações
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <PrintLogDialog open={openPrintDialog} setOpen={setOpenPrintDialog} />
            <KdsLog open={openKDSLogDialog} setOpen={setOpenKDSLogDialog} />
        </StyledBox>
    )
}


export function SettingOption({ lableContent, inputContent, detail }) {
    return (
        <Box pt={2}>
            <Paper elevation={0}>
                <Box p={2}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm>
                            {lableContent}
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Box display="flex" justifyContent="flex-end">
                                {inputContent}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {detail}
            </Paper>
        </Box>
    )
}