import React, { useState, useEffect, memo } from 'react'
import styled from '@emotion/styled'
import { IconButton, InputAdornment, TextField } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { grey } from '@mui/material/colors'

const StyledTextField = styled(TextField)(({ theme }) => ({
    '&': {
        width: '110px',
    },

    '& .MuiInputBase-root': {
        padding: 0,
    },

    '& .MuiIconButton-root': {
        margin: 0,
        padding: 0,
        borderRadius: theme.shape.borderRadius,
        border: '1px solid ' + theme.palette.primary.main,

        '&.Mui-disabled': {
            borderColor: grey[600],
        }
    },

    '& .MuiInputBase-input::-webkit-outer-spin-button,& .MuiInputBase-input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },

    '& .MuiInputBase-input': {
        MozAppearance: 'textfield',
    },

    '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
    },
}))

export const InputQuantity = memo(function InputQuantity({ autoFocus = false, value = 1, max = 99999, disabled, onChangeOnce, onChange, onKeyUp }) {
    const [number, setNumber] = useState(value)

    function handleRemove() {
        if (number === 0) return

        if (onChangeOnce) {
            onChange(0)
            return
        }

        const newValue = number - 1

        setNumber(newValue)

        const oldNumber = onChange(newValue)
        if (oldNumber) {
            setNumber(oldNumber)
        }
    }

    function handleAdd() {
        if (number === max) return

        if (onChangeOnce) {
            onChange(1)
            return
        }

        const newValue = number + 1

        setNumber(newValue)
        onChange(newValue)
    }

    function handleChange(event) {
        let inputValue = event.target.value

        if (!inputValue) {
            setNumber('')
            return
        }

        inputValue = Number(inputValue.replace(/[A-z]/g, ''))

        if (inputValue > max)
            inputValue = max

        setNumber(inputValue)
        onChange(inputValue)
    }

    function handleBlur() {
        if (!number) {
            onChange(0)
        }
    }

    useEffect(() => setNumber(Number(value)), [value])

    return (
        <StyledTextField
            size="small"
            variant="outlined"
            value={number}
            autoFocus={autoFocus}
            disabled={disabled || onChangeOnce}
            onKeyUp={onKeyUp}
            onBlur={handleBlur}
            type="number"

            onChange={handleChange}
            inputProps={{
                pattern: "[0-9]*",
                style: {
                    padding: '5px 0',
                    textAlign: 'center',
                }
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" >
                        <IconButton
                            size="small"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            color="primary"
                            aria-label="adicionar +"
                            disabled={disabled}
                            onClick={handleAdd}
                            edge="start">
                            <AddIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            color="primary"
                            aria-label="remover -"
                            disabled={disabled}
                            onClick={handleRemove}
                            edge="end">
                            <RemoveIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }} />
    )
})