
export const toogleDialogDeliveryman = (currentState, action) =>  {
    let { detailDialogDeliveryman } = action
    return {
        ...currentState, 
        detailDialogDeliveryman: { ...detailDialogDeliveryman, open: detailDialogDeliveryman.open } 
    }
}

export const initialStateDialogDeliveryman = () =>  {

    return {
        open: false,
        order: null
    }
}