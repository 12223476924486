import {
    Badge,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip,
    Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dateFormat, dateFormatDistanceToNow, dateParseISO } from '../../../util/formatter';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const PREFIX = 'MinimizedOrders'

const classes = {
    fab: `${PREFIX}-fab`,
    badge: `${PREFIX}-badge`,
    minimizedList: `${PREFIX}-minimizedList`,
    listItem: `${PREFIX}-listItem`
}

const StyledZoom = styled(Zoom)(({ theme }) => ({
    [`&.${classes.fab}`]: {
        position: 'absolute',
        bottom: '50px',
        right: '100px',
        zIndex: "1000",
        boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",

        [theme.breakpoints.down('sm')]: {
            right: theme.spacing(2),
        }
    },
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
    [`&.${classes.badge}`]: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

const StyledList = styled(List)(({ theme }) => ({
    [`&.${classes.minimizedList}`]: {
        position: 'absolute',
        right: '100px',
        bottom: '100px',
        width: '400px',
        maxWidth: '80%',
        zIndex: 12001,

        [theme.breakpoints.down('sm')]: {
            right: theme.spacing(2),
        }
    },
    [`& .${classes.listItem}`]: {
        backgroundColor: theme.palette.background.paper
    },
}))

export default function MinimizedOrders() {
    const minimizedOrders = useSelector(state => state.minimizedOptions.minimizedOrders)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener('visibilitychange', visibilitychangeEventMinimized)

        return () => {
            document.removeEventListener('visibilitychange', visibilitychangeEventMinimized)
        }
        // eslint-disable-next-line
    }, [])

    const visibilitychangeEventMinimized = (ev) => {
        if ("visible" === document.visibilityState) {
            dispatch({ type: 'RELOAD_MINIMIZED_OPTIONS_FROM_LOCAL_STORAGE' })
        }
    }

    const toggleList = () => {
        setOpen(value => !value)
    }

    const deleteMinimizedOrder = (order) => {
        let minimizedOrdersCopy = Object.assign([], minimizedOrders)
        let minimizedOrdersFiltered = minimizedOrdersCopy.filter(elem => elem.codigoIntegracao !== order.codigoIntegracao)
        dispatch({ type: 'UPDATE_MINIMIZED_OPTIONS', minimizedOptions: { minimizedOrders: minimizedOrdersFiltered } })

        if (minimizedOrdersFiltered.length === 0) {
            toggleList()
        }
    }

    const handleSelect = (order) => {
        let minimizedOrdersCopy = Object.assign([], minimizedOrders)
        let minimizedOrdersFiltered = minimizedOrdersCopy.filter(elem => elem.codigoIntegracao !== order.codigoIntegracao)
        dispatch({ type: 'UPDATE_MINIMIZED_OPTIONS', minimizedOptions: { minimizedOrders: minimizedOrdersFiltered } })
        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: order } })
        toggleList()
    }

    return (
        <React.Fragment>
            {open && minimizedOrders.length > 0 &&
                <StyledList
                    className={classes.minimizedList}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Pedidos iniciados e não confirmados
                        </ListSubheader>
                    }>
                    {minimizedOrders.map(order => (
                        <ListItem
                            disablePadding
                            key={order.codigoIntegracao}
                            className={classes.listItem}
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    size="large"
                                    aria-label="comments"
                                    onClick={() => deleteMinimizedOrder(order)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            }>
                            <ListItemButton onClick={() => handleSelect(order)}>
                                <ListItemIcon>
                                    <StyledBadge
                                        overlap='rectangular'
                                        badgeContent={order.iFoodOrder?.items?.length}
                                        color="primary"
                                        classes={{ badge: classes.badge }}>
                                        <ShoppingCartIcon color={order.id ? 'action' : 'disabled'} />
                                    </StyledBadge>
                                </ListItemIcon>
                                <ListItemText
                                    primary={order.iFoodOrder?.customer?.name || "cliente não informado"}
                                    secondary={`${dateFormat(order.dataPedido)} | ${dateFormatDistanceToNow(dateParseISO(order.dataPedido))}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </StyledList>}

            <StyledZoom in={minimizedOrders.length > 0}>
                <Tooltip title="Pedidos iniciados e não confirmados">
                    <Fab
                        onClick={toggleList}
                        className={classes.fab}
                        color="secondary"
                        size='medium'>
                
                        <Badge overlap='rectangular' badgeContent={minimizedOrders.length} color="primary" >
                            <DynamicFeedIcon />
                        </Badge>
                    </Fab>
                </Tooltip>
            </StyledZoom>
        </React.Fragment>
    )
}