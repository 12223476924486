import { setKey } from "../util/indexedDB/IdbUtils"

export const initialCards = () => {
    return ([])
}

export const setCards = (currentState, action) => {

    return {
        ...currentState,
        cards: action.cards
    }
}

export const setCardsOposto = (currentState, action) => {

    return {
        ...currentState,
        cardsOposto: action.cardsOposto
    }
}

const _addOrUpdateOrderOnCardSecundary = (currentCards, newOrder) => {
    let currentCardsCopy = Object.assign([], currentCards)

    /** SE O ORDER ESTA PAGO (OU IMPORTADO) */
    if (newOrder.pago === true 
        || newOrder.dataImportacao 
        || newOrder.situacao === "CANCELADO"
        || !newOrder.ativo ){ 
        const updatedCardSecundary = _removeOrderOnCardById(currentCardsCopy, newOrder)
        return updatedCardSecundary;
    }


    //atualiza a listagem de mesas
    const filteredCard = currentCardsCopy.filter((card) => card.identificador.tipo === 'MESA')
    
    /* REMOVE O PEDIDO (CASO EXISTA) PARA SER ADICIONADO POSTERIORMENTE */
    filteredCard.map(card => {
        if (card.orderList && card.orderList.length > 0){
            const indexOfOrder = card.orderList.findIndex((orderElem) => orderElem.id === newOrder.id)
            //remover o pedido do card
            if (indexOfOrder >= 0) {
                card.orderList.splice(indexOfOrder, 1)
            }
        }
        return null;
    })

    const foundCard = filteredCard.find((card) => (card.identificador.identificador === newOrder.identificadorSecundario))

    if (foundCard) {
        if (foundCard.orderList && foundCard.orderList.length > 0) {
            const indexOfOrder = foundCard.orderList?.findIndex((order) => order.id === newOrder.id)
   
            if (indexOfOrder >= 0) {
                foundCard.orderList[indexOfOrder] = newOrder
            } else {
                foundCard.orderList.push(newOrder)    
            }

        } else {
            foundCard.orderList.push(newOrder)
        }

        // //encontra o card na lista de cards 
        // const indexOfCard = currentCardsCopy.findIndex((card) => card.identificador.tipo === 'MESA' && card.identificador.identificador === foundCard.identificador.identificador)
        
        // //seta o card na posição da lista de cards
        // if (indexOfCard >= 0) {
        //     currentCardsCopy[indexOfCard] = foundCard
        // }
    }

    return currentCardsCopy
}

const _addOrUpdateOrderOnCard = (currentCards, newOrder) => {
    let currentCardsCopy = Object.assign([], currentCards)

    /** SE O ORDER ESTA PAGO (OU IMPORTADO) */
    if (newOrder.pago === true 
            || newOrder.dataImportacao 
            || newOrder.situacao === "CANCELADO"
            || !newOrder.ativo){
        //const updatedcard = _removeOrderOnCard(currentCardsCopy, newOrder)
        //const updatedCardSecundary = _removeOrderOnCardSecundary(updatedcard, newOrder)
        const updatedcard = _removeOrderOnCardById(currentCardsCopy, newOrder)
        return updatedcard;
    }


    //filtra os cards pelo tipo do pedido
    const filteredCard = currentCardsCopy.filter((card) => card.identificador.tipo === newOrder.tipo)

    /* REMOVE O PEDIDO (CASO EXISTA) PARA SER ADICIONADO POSTERIORMENTE */
    filteredCard.map(card => {
        if (card.orderList && card.orderList.length > 0){
            const indexOfOrder = card.orderList.findIndex((orderElem) => orderElem.id === newOrder.id)
            //remover o pedido do card
            if (indexOfOrder >= 0) {
                card.orderList.splice(indexOfOrder, 1)
            }
        }
        return null;
    })

    //Localiza o card que tem o pedido pelo identificador
    //Converte o identificador para JSON antes de comparar por causa do bug de identificador vindo em array de string
    const foundCard = filteredCard.find((card) => (JSON.stringify(card.identificador.identificador) === JSON.stringify(!!newOrder.identificadorPrincipal ? newOrder.identificadorPrincipal : 'undefined')))

    if (foundCard) {

        if (foundCard.orderList && foundCard.orderList.length > 0) {
            const indexOfOrder = foundCard.orderList?.findIndex((order) => order.id === newOrder.id)
            
            if (indexOfOrder >= 0) {
                foundCard.orderList[indexOfOrder] = newOrder
            }else{
                foundCard.orderList.push(newOrder)
            }
        } else {
            foundCard.orderList.push(newOrder)
        }
    }

    return currentCardsCopy
}

export const addOrUpdateOrderOnCard = (currentState, action) => {
    const currentCards = currentState.cards
    const newOrder = action.newOrder;
    const currentCardsOposite = currentState.cardsOposto

    let updatedCard = _addOrUpdateOrderOnCard(currentCards, newOrder)
    let updatedCardOposto = _addOrUpdateOrderOnCard(currentCardsOposite, newOrder)?.filter((card) => (card.orderList?.length > 0))

    if (newOrder.tipo === 'COMANDA') {
        updatedCard = _addOrUpdateOrderOnCardSecundary(updatedCard, newOrder)
    }

    setKey("Cards", updatedCard.filter((e) => (e.identificador.tipo === newOrder.tipo && e.identificador.ativo)))
    return {
        ...currentState,
        cards: updatedCard,
        cardsOposto: updatedCardOposto
    }
}

const _removeOrderOnCardById = (currentCards, order) => {
    let currentCardsCopy = Object.assign([], currentCards)

    currentCardsCopy.map(cardElement => {
        
        if (cardElement.orderList && cardElement.orderList.length > 0) {
            const indexOfOrder = cardElement.orderList.findIndex((orderElem) => orderElem.id === order.id)

            //remover o pedido do card
            if (indexOfOrder >= 0) {
                cardElement.orderList.splice(indexOfOrder, 1)
            }
        } 
        return null;

    })

    return currentCardsCopy;
}

/**
 * @deprecated - Utilize o método _removeOrderOnCardById
 */
/*
const _removeOrderOnCardSecundary = (currentCards, order) => {
    let currentCardsCopy = Object.assign([], currentCards)

    //atualiza a listagem de mesas
    const filteredCard = currentCardsCopy.filter((card) => card.identificador.tipo === 'MESA')
   

    filteredCard.map(cardSecondary => {
        
        if (cardSecondary.orderList && cardSecondary.orderList.length > 0) {
            const indexOfOrder = cardSecondary.orderList?.findIndex((orderElem) => orderElem.id === order.id)

            //remover o pedido do card
            if (indexOfOrder >= 0) {
                cardSecondary.orderList.splice(indexOfOrder, 1)
            }
        } 
        return null;
    })

    return currentCardsCopy;
}*/

/**
 * @deprecated - Utilize o método _removeOrderOnCardById
 *//*
const _removeOrderOnCard = (currentCards, orderDel) => {

    let currentCardsCopy = Object.assign([], currentCards)

    //filtra os cards pelo tipo do pedido
    const filteredCard = currentCards.filter((card) => card.identificador.tipo === orderDel.tipo)

    //Localiza o card que tem o pedido pelo identificador
    const foundCard = filteredCard.find((card) => (card.identificador.identificador === orderDel.identificadorPrincipal))

    if (foundCard) {
        if (foundCard.orderList && foundCard.orderList.length > 0) {
            const indexOfOrder = foundCard.orderList?.findIndex((order) => order.id === orderDel.id)

            //remover o pedido do card
            if (indexOfOrder >= 0) {
                foundCard.orderList.splice(indexOfOrder, 1)
            }
        } 

        //encontra o card na lista de cards 
        const indexOfCard = filteredCard.findIndex((card) => card.identificador.identificador === foundCard.identificador.identificador)

        //seta o card na posição da lista de cards
        if (indexOfCard >= 0) {
            currentCardsCopy[indexOfCard] = foundCard
        }
    }

    return currentCardsCopy
}*/


export const removeOrderOnCard = (currentState, action) => {
    const currentCards = currentState.cards
    const order = action.order;

    let updatedcard = _removeOrderOnCardById(currentCards, order)
    // let updatedcard = _removeOrderOnCard(currentCards, order)
    // if (order.tipo === 'COMANDA') {
    //     updatedcard = _removeOrderOnCardSecundary(updatedcard, order)
    // }

    return {
        ...currentState,
        cards: updatedcard
    }
}

export const convertCardsToOrderList = (cardList, tipoIdentificadorPrincipal) => {

    const orderList = [] 
    const filteredCard = cardList.filter((card) => card.identificador.tipo === tipoIdentificadorPrincipal)
    
     /* REMOVE O PEDIDO (CASO EXISTA) PARA SER ADICIONADO POSTERIORMENTE */
     filteredCard.map(card => {
         if (card.orderList && card.orderList.length > 0){
            orderList.push(...card.orderList)
         }
         return null;
     })

     //console.log("convertCardsToOrderList", orderList)
     return orderList;
}
 