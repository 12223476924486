import { useDispatch } from "react-redux"
import AuthService from "../service/AuthService"
import { extractErrorMessage } from "../util/http/HttpUtil"
import { enqueueSnackbar } from "notistack"

export const initialPaymentOptions = () => {
    return ([])
}

export const setPaymentOptions = (currentState, action) => {

    return {
        ...currentState,
        paymentOptions: action.paymentOptions
    }
}

const useCarregarFormasPagamento = () => {
    const dispatch = useDispatch();
    const service = new AuthService();

    const carregarFormasPagamento = () => {
        service.get('/service?action=list&entity=formaPagamento').then(formaDePagamentoList => {
            const formOfPaymentActive = formaDePagamentoList.filter(element => element.ativo);
            dispatch({ type: 'SEND_PAYMENT_OPTIONS', paymentOptions: formOfPaymentActive });
        }).catch(err => {
            extractErrorMessage(err, "Erro ao buscar as formas de pagamento").then(msg => {
                enqueueSnackbar(`Erro ao buscar as formas de pagamento - ${msg}`, { variant: "error" });
            });
            console.log('ERRO_FORMA_PAGAMENTO: ' + err);
        });
    };

    return carregarFormasPagamento;
};

export default useCarregarFormasPagamento;