import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Grid,
} from '@mui/material';
import AuthService from '../../service/AuthService';
import { dateFormatedToNewFormat } from '../../util/formatter';
import { getGenericCodeByKey } from '../../component/genericCode';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const PREFIX = 'KdsLog'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar
}))


export default function KdsLog({ open, setOpen }) {

    const [itemKDSList, setItemKDSList] = useState(null)
    const [finding, setFinding] = useState(false)
    const [inputTextFilterValue, setInputTextFilterValue] = useState('')
    const { enqueueSnackbar } = useSnackbar();

    //const URL_SERVER_IMPRESSAO = process.env.REACT_APP_URL_SERVIDOR_IMPRESSAO
    const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'URL_SERVIDOR_IMPRESSAO')
    const URL_SERVER_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com'

    useEffect(() => {
        searchKDSById()
        // eslint-disable-next-line
    }, [open])


    const searchKDSById = () => {
        let service = new AuthService(URL_SERVER_IMPRESSAO)
        const ID_PED_FORMATADO = inputTextFilterValue;
        const profile = service.getProfile();
        const uuid = profile.unidade.uuid;
        const url = ID_PED_FORMATADO ?
            `/api/kds/listByIdPedidoIntegracao?unidadeUUID=${uuid}&idPedidoIntegracao=${ID_PED_FORMATADO}`
            : `/api/kds/listByIdPedidoIntegracao?unidadeUUID=${uuid}`;


        setFinding(true)

        service.get(url)
            .then(resultList => {
                setItemKDSList(resultList)
            }).catch(err => {
                enqueueSnackbar(`Erro ao buscar os itens do KDS`, { variant: "error" });
                console.error(err)
            }).finally(() => {
                setFinding(false)
            })
    }

    const onClickBuscar = () => {
        searchKDSById();
    }

    const handleChangeInputTextFilter = (event) => {
        setInputTextFilterValue(event.target.value)
    }

    const handleClose = () => {
        setOpen(false)
    }


    return (
        <Root>

            <Dialog open={open}
                onClose={handleClose}
                fullWidth maxWidth="sm">

                <DialogTitle onClose={handleClose} >
                    Últimos itens KDS
                </DialogTitle>

                <DialogContent className={classes.scrollBar}>
                    <Grid container spacing={2}>
                        <Grid xs={9} item>
                            <TextField
                                size="small"
                                label={`ID do pedido`}
                                placeholder="ID do pedido no Sischef"
                                variant="outlined"
                                autoFocus
                                fullWidth
                                onChange={handleChangeInputTextFilter}
                            />
                        </Grid>
                        <Grid xs={3} item mb={1}>
                            <Button
                                fullWidth
                                color='primary'
                                variant='outlined'
                                disabled={finding}
                                onClick={onClickBuscar}>
                                {finding ? 'Buscando...' : 'Buscar'}
                            </Button>
                        </Grid>
                    </Grid>
                    <TableContainer >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Data</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell>Descrição</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {itemKDSList && itemKDSList.docs.map((itemKDS) => (
                                    <TableRow key={itemKDS._id}>
                                        <TableCell align="center">{itemKDS.pedidoKds.id}</TableCell>
                                        <TableCell align="right">{dateFormatedToNewFormat(itemKDS.createdAt, 'ISO', 'dd MMM HH:mm:ss')}</TableCell>
                                        <TableCell align="center">{itemKDS.item.status}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {itemKDS.item.descricao}
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent>

            </Dialog>

        </Root>
    );
}