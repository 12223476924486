import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const PREFIX = 'CircularProgressWithLabel';

const classes = {
  circle: `${PREFIX}-circle`
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.circle}`]: {
    stroke: "url(#linearColors)",
  }
}));


export default function CircularProgressWithLabel(props) {

  return (
    <StyledBox position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption"
          component="div"
          color="textSecondary">
          {props.label}
        </Typography>
      </Box>
    </StyledBox>
  );
}

export function CircularProgressWithLabelLinearGradiente(props) {


  return (
    <Box position="relative" display="inline-flex">
      <svg width="0" height="0">
        <linearGradient id="linearColors" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#fc0000" />
          <stop offset="100%" stopColor="#59b43a" />

        </linearGradient>
      </svg>

      <CircularProgress classes={{ circle: classes.circle }} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption"
          component="div"
          color="textSecondary">
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
}

export function CircularProgressWithLabelAndBackground(props) {

  return (
    <StyledBox position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.divider,
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          position: 'absolute',
          left: 0,
        }}
        {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption"
          component="div"
          color="textSecondary">
          {props.label}
        </Typography>
      </Box>
    </StyledBox>
  );
}


CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
