import { Dialog, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DialogTitle, DialogContent } from '../../layout-components/CustomDialog/dialogComponents';
import { formatFullAddressDelivery, isAddressValid } from '../../../util/customer/helper';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { FixedSizeList as List } from 'react-window';
import { makeStyles } from '@mui/styles';
import { currencyFormat } from '../../../util/formatter';
import { totalOrder } from '../../../util/order/orderUtil';
import DialogAddress from '../components/DialogAddress';

const useStyles = makeStyles((theme) => ({
    list: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
    },

    typography: {
        textAlign: 'right',
    },

    border: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: '8px'
    }

}))

export default function ResumeListDispatch({ open, setOpen, pedidos, onUpdate }) {
    const classes = useStyles()
    const totalHeight = pedidos.length * 60; // 60 é a altura de cada item
    const [openDialogCustomer, setOpenDialogCustomer] = useState(false)
    const [selectedPedido, setSelectedPedido] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (event) => {
        const attr = event.target.name
        const value = event.target.value
        setSelectedPedido({ ...pedidos, [attr]: value })
    }

    const handleEditCustomer = (pedido) => {
        setSelectedPedido(pedido)
        setOpenDialogCustomer(true)
    }

    function handleUpdate() {
        setOpen(false)
        onUpdate()
    }

    const Cell = ({ index, style }) => {
        const pedido = pedidos[index];
        const addressValid = isAddressValid(pedido.iFoodOrder?.deliveryAddress);

        return (
            <>
                <Grid container style={{ ...style }} className={classes.border} alignItems="center">
                    <Grid item xs={6}>
                        <Typography>{pedido.iFoodOrder?.customer?.name}</Typography>
                        <Typography variant="caption">{formatFullAddressDelivery(pedido.iFoodOrder?.deliveryAddress).address}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.list}>
                        <Typography>{currencyFormat(totalOrder(pedido.iFoodOrder))}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.list}>
                        <Typography>{pedido.pessoaEntregador.nome}</Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.list}>
                        <Tooltip title={addressValid ? 'Endereço completo.' : 'Endereço incompleto. Clique para editar'}>
                            {addressValid ? (
                                <CheckIcon />
                            ) : (
                                <ErrorIcon color="error" onClick={() => { handleEditCustomer(pedido); }} style={{ cursor: 'pointer' }} />
                            )}
                        </Tooltip>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={handleClose}>
                Listagem de clientes e vendas
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontWeight: 'bold' }}>Nome</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.typography}>
                        <Typography sx={{ fontWeight: 'bold' }}>Total</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.typography}>
                        <Typography sx={{ fontWeight: 'bold' }}>Entregador</Typography>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                {pedidos.length > 0 && (
                    <List
                        height={Math.min(totalHeight, 700)} // Limita a altura a 700 ou menos se a lista for menor
                        width="100%" // A largura do conteúdo do modal
                        itemSize={60} // Altura de cada linha
                        itemCount={pedidos.length} // Total de itens
                        itemData={pedidos} // Dados da lista
                    >
                        {Cell}
                    </List>
                )}
                <Typography variant="caption">{pedidos.length} pedidos</Typography>
            </DialogContent>

            <DialogAddress
                open={openDialogCustomer}
                setOpen={setOpenDialogCustomer}
                pedido={selectedPedido}
                setPedido={setSelectedPedido}
                handleChange={handleChange}
                setSelectedPedido={setSelectedPedido}
                onUpdate={handleUpdate}
            />
        </Dialog>
    )
}