import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';

const PREFIX = 'DialogTitle'

const classes = {
  root: `${PREFIX}-root`,
  closeButton: `${PREFIX}-closeButton`
}

const StyledMuiDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(2),
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: 8
  }
}))

export const DialogTitle = ((props) => {
  const { children,  onClose, ...other } = props;
  return (
    <StyledMuiDialogTitle className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
});
  
export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);