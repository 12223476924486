import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState }  from 'react';
import { currencyFormat, quantityFormat } from '../../../util/formatter';
import { ItemRow } from '../DetailOrder/detailOrderElements';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const PREFIX = 'GroupedItemRow';

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    useRowStyles: `${PREFIX}-useRowStyles`
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    [`&.${classes.scrollBar}`]: theme.scrollBar,
}))

const StyledTableRow = styled(TableRow)(() => ({
    [`&.${classes.useRowStyles}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
    }
}))

function GroupedItemRow({ groupedItem, opened }) {
    const [collapse, setCollapse] = useState(opened ? opened : false);

    return (
        <>
            <StyledTableRow className={classes.useRowStyles}>

                <TableCell component='th' scope='row' padding="none">
                    <Typography>{groupedItem.name}</Typography>
                </TableCell>

                <TableCell align='right'>
                    {quantityFormat(groupedItem.quantity)}
                </TableCell>

                <TableCell align='right'>
                    {`${currencyFormat(groupedItem.totalPrice)}`}
                </TableCell>

                <TableCell style={{ padding: "0px" }} align='center'>
                    <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
                        {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

            </StyledTableRow>

            <StyledTableRow>
                <TableCell colSpan={4}>
                    <Collapse in={collapse} timeout="auto" unmountOnExit>

                        <Table size="small">

                            <TableHead>
                                <StyledTableRow className={classes.useRowStyles}>
                                    <TableCell padding="none" />
                                    <TableCell align="right">Qtde</TableCell>
                                    <TableCell align="right">Un</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell align='right' />
                                </StyledTableRow>
                            </TableHead>

                            <TableBody>
                                {groupedItem.items.map((item, index) => (
                                    <ItemRow item={item} key={index} opened={opened} />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </StyledTableRow>

        </>
    )
}


export default function ListItemsGrouped({ groupedItems, opened }) {

    return (
        <StyledTableContainer className={classes.scrollBar} style={{ width: '100%' }} >
            <Table size='small' stickyHeader>
                <TableHead>
                    <TableRow >
                        <TableCell >Itens/Produtos</TableCell>
                        <TableCell align="right">Qtde</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="center" />
                    </TableRow>
                </TableHead>


                <TableBody>
                    {groupedItems?.map((groupedItem, index) => (
                        <GroupedItemRow key={index} groupedItem={groupedItem} opened={opened} />
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
}


