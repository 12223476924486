import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Box, Tooltip, Hidden } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useDispatch } from 'react-redux'
import MainTopContent from "../main/MainTopContent"
import { useLocation } from 'react-router-dom'
import MoneyBoxStatus from "./MoneyBoxStatus"
import { ifNotGranted } from "../../util/auth/authUtil"
import OrderIdentificadorTopBar from './ListOrderIdentificador/CardIdentificador/Principal/OrderIdentificadorTopBar';
import KdsTopBar from '../kds/components/topBar';
import HelpButton from './HelpButton';
import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { WhatsappStatus } from './WhatsappStatus';

const PREFIX = 'TopBar'

const classes = {
    content: `${PREFIX}-content`
}

const TopContent = styled(Box)(({ theme }) => ({
    [`&.${classes.content}`]: {
        flexGrow: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}))

export default function TopBar() {

    const dispatch = useDispatch()

    let location = useLocation()

    const toogleThemeMode = () => {
        dispatch({ type: 'TOOGLE_THEME_MODE' })
    }

    let showMainTopContent = ((location.pathname === "/" && ifNotGranted('ROLE_ESCONDER_SOMATORIOS_PDV_SISCHEF')) || (location.pathname === "/delivery" && ifNotGranted('ROLE_ESCONDER_SOMATORIOS_PDV_SISCHEF')))

    let showOrderIdentificadorTopContent = location.pathname === "/pedidosIdentificador" && ifNotGranted('ROLE_ESCONDER_SOMATORIOS_PDV_SISCHEF')

    let showKdsTopContent = location.pathname === "/kds" && ifNotGranted('ROLE_ESCONDER_SOMATORIOS_PDV_SISCHEF')

    return (
        <>
            <TopContent className={classes.content}>
                {showMainTopContent && <MainTopContent />}

                {showOrderIdentificadorTopContent && <OrderIdentificadorTopBar />}

                {showKdsTopContent && <KdsTopBar />}
            </TopContent>
            <Box mr={2}>
                <MoneyBoxStatus />
            </Box>

            <Hidden mdDown>
                <Tooltip title="Programa de benefícios">
                    <IconButton href="https://sischef.com/programa-de-beneficios/" target="_blank" size="large">
                        <EmojiEventsIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Conheça nosso EAD">
                    <IconButton href="https://www.sympla.com.br/play/ensina-sischef/1365178" target="_blank" size="large">
                        <SchoolIcon />
                    </IconButton>
                </Tooltip>
            </Hidden>

            <HelpButton />

            <WhatsappStatus />

            <IconButton color="inherit" onClick={() => toogleThemeMode()} size="large">
                <Brightness4Icon />
            </IconButton>
        </>
    )
}
