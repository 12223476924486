import React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';

const PREFIX = 'InputSearch'

const classes = {
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.search}`]: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.action.active, 0.1),
      '&:hover': {
        backgroundColor: alpha(theme.palette.action.hover, 0.15),
      },
      '&:focus': {
          backgroundColor: alpha(theme.palette.action.active, 0.15),
      },
      marginLeft: 0,
      width: '100%',

      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
       
    },

  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  }
}))

export default function InputSearch(props) {

  return (
    <Root className={classes.search}>
        <div className={classes.searchIcon}>
            <SearchIcon />
        </div>
        <InputBase
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search'}}
            {...props}
      />        
    </Root>
  )
}