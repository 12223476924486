import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Card, CardActionArea, CardContent, CardMedia, Container } from '@mui/material';

const md5 = require('md5');

const PREFIX = 'Login'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  loginBox: `${PREFIX}-loginBox`,
  promoText: `${PREFIX}-promoText`,
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  frasePrincipal: `${PREFIX}-frasePrincipal`
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
    //backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/layout/login-bg.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },


}))

export default function Helpdesk() {
  const scriptRef = useRef(null)

  useEffect(() => {

    mountChat();

    return () => {
      unmountChat()
    }
  }, []);

  const mountChat = () => {
    scriptRef.current = document.createElement('script');
    scriptRef.current.src = "https://kong.tallos.com.br:18000/megasac-api/widget/65c27b9cd142585857989238-65cd048770ef7053bc4ee1af-1.min.js";
    scriptRef.current.async = true;
    document.body.appendChild(scriptRef.current);
    //document.body.appendChild(scriptRef.current);
  }

  const unmountChat = () => {

  }

  const openChatSuport = () => {
    const element = document.getElementById('megasac-button');
    if (element) {
      element.click()
    }
  }


  function ActionAreaCard() {
    return (
      <Card >
        <CardActionArea onClick={openChatSuport}>
          <CardMedia
            component="img"
            height="240"
            image="/static/images/layout/helpdesk.svg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Iniciar uma conversa
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Clique aqui para falar diretamente com um atendente
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            paddingTop: 8
          }}
        >

          <Grid container>
            <Grid item sm={12} xs={12}  >
              <ActionAreaCard />
            </Grid>
          </Grid>

        </Box>

      </Container>
    </>
  );
}


