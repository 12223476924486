import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { DialogTitle } from '../CustomDialog/dialogComponents'

const PREFIX = 'WaiterSearch'

const classes = {
    paper: `${PREFIX}-paper`,
    boxViewWaiters: `${PREFIX}-boxViewWaiters`
}

const Root = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogPaper}`]: {
        minHeight: '30vh',
        maxHeight: '30vh',
    },

    [`& .${classes.boxViewWaiters}`]: {
        maxHeight: 100,
    },

    [`& .${classes.dialogPadding}`]: {
        padding: "10px 10px"
    }
}))

export const ItemAutoObservation = ({ open, setOpen, item, onFinalizar, size }) => {
    const editObsItem = useRef()
    const btnConfirmRef = useRef();

    const [obsDefault, setObsDefault] = useState()
    const [itemEdit, setItemEdit] = useState({ observations: '' })

    useEffect(() => {
        //pega as observações salvas no sischef e mostra como botões para o cliente clicar e adicionar
        const obs = item?.product?.observacoesMobile?.split(';')?.filter((e) => e !== '')
        setObsDefault(obs)

        let itemCopy = JSON.parse(JSON.stringify(item ? item : {}))

        setItemEdit(itemCopy)
        // eslint-disable-next-line
    }, [open])

    useHotkeys('f10', (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    })

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickCancel = () => {
        handleClose()
    }

    const onClickAddObs = (obs) => {

        const itemTemp = Object.assign({}, itemEdit)

        if (itemTemp?.observations) {
            itemTemp.observations = itemTemp?.observations + ', ' + obs
        } else {
            itemTemp.observations = obs
        }

        setItemEdit(itemTemp)
    }

    const handleChange = (event) => {
        const itemTemp = Object.assign({}, itemEdit)
        setItemEdit({ ...itemTemp, observations: event.target.value })
    }

    const handleFinalizar = () => {
        setOpen(false)
        if (onFinalizar) {
            onFinalizar(itemEdit)
        }
    }

    return (
        <Root
            maxWidth="sm"
            fullWidth
            open={open}
            scroll='paper'
            fullScreen={false}
            onClose={handleClickCancel}
        >
            <DialogTitle onClose={handleClickCancel} sx={{ display: 'grid' }}>
                <Typography sx={{ fontSize: '14px', mb: 0 }} gutterBottom> Observação </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    {obsDefault?.map((obs, index) => (
                        <Grid item key={index} >
                            <Button variant='outlined' size='small' onClick={() => onClickAddObs(obs)} focusRipple={false}>
                                {obs}
                            </Button>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                        inputRef={editObsItem}
                        autoFocus={true}
                        id="observacoes"
                        label="Observação do item"
                        multiline
                        fullWidth
                        maxRows={4}
                        minRows={2}
                        name='observacoes'
                        value={itemEdit?.observations || ''}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                    />

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button sx={{ mr: 2, mb: 1 }}
                    ref={btnConfirmRef}
                    onClick={handleFinalizar}
                    variant="contained"
                    color="primary" >
                    Confirmar
                </Button>
            </DialogActions>

        </Root>
    )
}