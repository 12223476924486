import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react'
import { getPesoBalanca, isModoPesoFakeAtivo, openSischefLepeso, verificaSeEstaRodandoNoElectron } from '../../helpers/balancaHelper';
import { useSnackbar } from 'notistack';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddCardIcon from '@mui/icons-material/AddCard';
import ScaleIcon from '@mui/icons-material/Scale';
import UsbIcon from '@mui/icons-material/Usb';
import PrintIcon from '@mui/icons-material/Print'
import AutoatendimentoBalanca from './autoatendimentoBalanca.js';
import AuthService from '../../service/AuthService';
import { getStadoIncialTela } from './autoatendimentoHelper';
import CheckIcon from '@mui/icons-material/Check';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import ProductSearch from '../layout-components/InputSearch/ProductSearch.js';
import { usePdvContext } from '../../component/generics/PdvProvider.js';

// caso queira inserir mais teclas é só colocalas aqui
// tem que ser o mesmo nome da key que aparece no event do keyUp
//
// fique atento que, ao alterar ou adicionar uma nova key,
// será necessário limpar as informações dos produtos selecionados no localstorage
// para que essa tecla seja inserida ou removida

const F_KEYS = {
    f1: '',
    f2: '',
    f3: '',
    f4: '',
    f5: '',
    f6: '',
    f7: '',
    f8: '',
    f9: '',
    f10: '',
    f11: '',
}

const Circle = styled(Box)(({ theme }) => ({
    '&': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '23px',
        height: '23px',
        fontSize: '11px',
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: '15px',
    },
}))

function OtherProductsShortcut({ configuracoes, setConfiguracoes }) {
    const filteredProdutosSelecionados = Object.keys(configuracoes.produtosKGSelecionados).filter(key => configuracoes.produtosKGSelecionados[key])

    function handleChange(item, newValue) {
        setConfiguracoes({
            ...configuracoes,
            produtosKGSelecionados: {
                ...configuracoes.produtosKGSelecionados,
                [item]: newValue
            }
        })
    }

    function getAlreadySelectedProducts() {
        const arr = Object.keys(configuracoes.produtosKGSelecionados)
            .map(key => configuracoes.produtosKGSelecionados[key])
            .filter(item => item)

        arr.push(configuracoes.produtoKGSelecionado)

        return arr
    }

    return (
        <Box border="1px solid" borderColor="rgba(255, 255, 255, 0.23)" margin="16px" marginTop="0" paddingBottom="1px">
            <Accordion>
                <AccordionSummary
                    id="produtos-kg-header"
                    aria-controls="produtos-kg-content"
                    expandIcon={<ExpandMoreIcon />}>
                    <Box display="flex" alignItems="center">
                        <Typography>Configure os produtos de acesso rápido</Typography>
                        {filteredProdutosSelecionados.length ? (
                            <Circle marginLeft="8px">{filteredProdutosSelecionados.length}</Circle>
                        ) : null}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {Object.keys(configuracoes.produtosKGSelecionados).map(item => (
                            <ListItem key={item}>
                                <ProductSearch
                                    listUrl="listWithoutStep"
                                    label={item.toUpperCase()}
                                    alreadySelected={getAlreadySelectedProducts()}
                                    value={configuracoes.produtosKGSelecionados[item]}
                                    onConfirm={(produto) => handleChange(item, produto)} />
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default function ConfiguracoesBalanca() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const locaisDeImpressao = useSelector(state => state.printerLocations) || [];
    const [produtosKG, setProdutosKG] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(false)
    const [telaAutoatendimento, setTelaAutoatendimento] = React.useState({})
    const inputSequenceRef = useRef()
    const [balancaFake, setBalancaFake] = React.useState(isModoPesoFakeAtivo());
    const { promptDialog } = usePdvContext()

    const [configuracoes, setConfiguracoes] = useState({
        solicitarComanda: "N",
        produtoKGSelecionado: null,
        localDeImpressao: "CAIXA",
        produtosKGSelecionados: F_KEYS
    })

    useEffect(() => {
        loadProdutosKG();

        let solicitarComanda = localStorage.getItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_SOLICITAR_COMANDA_")
        let imprimirComanda = localStorage.getItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_IMPRIMIR_COMANDA_")
        let produtoSelecionado = JSON.parse(localStorage.getItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_PRODUTO_SELECIONADO_"));
        let localDeImpressao = localStorage.getItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_LOCAL_IMPRESSAO_");
        let produtosSelecionados = JSON.parse(localStorage.getItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_PRODUTOS_SELECIONADOS_"));

        setConfiguracoes({
            imprimirComanda,
            solicitarComanda: solicitarComanda || 'N',
            produtoKGSelecionado: produtoSelecionado,
            localDeImpressao: localDeImpressao,
            produtosKGSelecionados: produtosSelecionados || F_KEYS,
        })
    }, [])

    const salvarConfiguracoes = () => {
        localStorage.setItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_SOLICITAR_COMANDA_", configuracoes.solicitarComanda)
        localStorage.setItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_IMPRIMIR_COMANDA_", configuracoes.imprimirComanda)
        localStorage.setItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_PRODUTO_SELECIONADO_", JSON.stringify(configuracoes.produtoKGSelecionado))
        localStorage.setItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_PRODUTOS_SELECIONADOS_", JSON.stringify(configuracoes.produtosKGSelecionados))
        localStorage.setItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_LOCAL_IMPRESSAO_", configuracoes.localDeImpressao)
    }

    const loadProdutosKG = () => {
        console.log("[CONFIGURACAO BALANCA] Iniciando busca de produtos por KG")
        setLoadingProducts(true)
        service.get('/api-v2/produtoCardapio/listByUnidadeMedida?siglaUnidadeMedida=KG')
            .then(products => {

                const produtoSelecionado = JSON.parse(localStorage.getItem("_CONFIGURACAO_AUTOATENDIMENTO_BALANCA_PRODUTO_SELECIONADO_"));
                if (produtoSelecionado) {
                    const sameProduct = products.find(product => product.id === produtoSelecionado.id)

                    if (sameProduct)
                        setConfiguracoes(state => ({ ...state, produtoKGSelecionado: sameProduct }))
                }

                //let productsWithProdutoLivreAssociado = products.filter(product => !!product.produtoLivreAssociado)
                //setProdutosKG(productsWithProdutoLivreAssociado)
                setProdutosKG(products)
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                setLoadingProducts(false)
            })
    }


    const handleClose = () => {
        setOpen(false);
        //atualiza o valor gerado
        if (inputSequenceRef.current) {
            inputSequenceRef.current.value = localStorage.getItem('_SEQ_IDENTIFICADOR_BALANCA_')
        }
    };

    const lerPeso = () => {

        getPesoBalanca()
            .then(peso => {
                console.log("[CONFIGURACAO BALANCA] PESO RETORNADO", peso)
                enqueueSnackbar(`Peso lido com sucesso: ${peso} kg`, { variant: "success" });
            }).catch(error => {
                console.error("[CONFIGURACAO BALANCA] ERRO AO LER PESO", error.code, error.message)
                enqueueSnackbar(`Erro ao buscar peso da balança - Detalhes: ${error.message}`, { variant: "error" });

            });
    }

    const abrirLepeso = () => {
        openSischefLepeso()
    }

    const abrirTelaAutoatendimento = () => {
        if (!verificaSeEstaRodandoNoElectron() && !isModoPesoFakeAtivo()) {
            enqueueSnackbar(`Para utilizar o autoatendimento na balança você precisa ter o PDV Sischef instalado na sua máquina. Entre em contato com o suporte`, { variant: "error" });
            return;
        }

        let produtoConfigurado = configuracoes.produtoKGSelecionado && configuracoes.produtoKGSelecionado.idProduto > 0;
        if (!produtoConfigurado) {
            enqueueSnackbar(`Para utilizar o autoatendimento na balança você precisa selecionar o 'Produto KG (padrão)'`, { variant: "error" });
            return;
        }

        salvarConfiguracoes()
        setTelaAutoatendimento(getStadoIncialTela(configuracoes.produtoKGSelecionado, configuracoes.solicitarComanda))
        setOpen(true);
    }

    const alterarSequence = () => {
        const newSeq = inputSequenceRef.current.value;
        localStorage.setItem("_SEQ_IDENTIFICADOR_BALANCA_", newSeq)
        enqueueSnackbar(`Sequência alterada para ${newSeq} com sucesso`, { variant: "success" });
    }

    const ativarInativarModoBalancaFake = async () => {

        if (verificaSeEstaRodandoNoElectron()) {
            enqueueSnackbar(`Não é possível ativar o modo emulador usando o PDV Desktop Sischef`, { variant: "error" });
            return;
        }

        let senha = await promptDialog({
            inputLabel: 'Senha',
            content: 'Informe a senha:'
        });

        if ("4321" !== senha) {
            enqueueSnackbar(`Senha incorreta`, { variant: "error" });
            return;
        }

        if (isModoPesoFakeAtivo()) {
            localStorage.removeItem("_PESO_FAKE_")
            setBalancaFake(false)
        } else {
            localStorage.setItem("_PESO_FAKE_", true)
            setBalancaFake(true)
        }
    }

    const handleChangePrinterLocation = (event) => {
        const configuracoesCopy = Object.assign({}, configuracoes)
        configuracoesCopy.localDeImpressao = event.target.value
        setConfiguracoes(configuracoesCopy)
    }

    return (
        <div>
            <Box alignContent={'center'} sx={{ mt: 5, width: '100%', maxWidth: 800 }}>
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <Paper variant='outlined'>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={lerPeso}>
                                    <ListItemIcon>
                                        <UsbIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Teste de leitura de peso" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton onClick={abrirLepeso}>
                                    <ListItemIcon>
                                        <OpenInNewIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Abrir aplicativo 'Le peso'" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <AddCardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Solicitar leitura de comanda?" />
                                <Switch
                                    edge="end"
                                    checked={configuracoes.solicitarComanda === "S"}
                                    onChange={event => setConfiguracoes({ ...configuracoes, solicitarComanda: (event.target.checked ? "S" : "N") })}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-bluetooth',
                                    }}
                                />
                            </ListItem>

                            {configuracoes.solicitarComanda === "N" ? (
                                <ListItem>
                                    <ListItemIcon>
                                        <RepeatOneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`Último identificador gerado`} />
                                    <TextField edge="end" size='small' type="number"
                                        defaultValue={localStorage.getItem('_SEQ_IDENTIFICADOR_BALANCA_')}
                                        inputRef={inputSequenceRef} />
                                    <IconButton onClick={alterarSequence}>
                                        <CheckIcon />
                                    </IconButton>
                                </ListItem>
                            ) : (
                                <ListItem>
                                    <ListItemIcon>
                                        <PrintIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Imprimir comanda?" />
                                    <Switch
                                        edge="end"
                                        checked={configuracoes.imprimirComanda === 'S'}
                                        onChange={event => setConfiguracoes({ ...configuracoes, imprimirComanda: (event.target.checked ? 'S' : 'N') })}
                                        inputProps={{
                                            'aria-labelledby': 'switch-list-label-print-order',
                                        }}
                                    />
                                </ListItem>
                            )}

                            <ListItem disablePadding>
                                <ListItemButton onClick={ativarInativarModoBalancaFake}>
                                    <ListItemIcon>
                                        <PublishedWithChangesIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ativar/inativar modo emulador de balança"
                                        secondary={balancaFake ? 'Ativo' : 'Inativo'} />
                                </ListItemButton>
                            </ListItem>

                            <Divider />

                            <ListItem>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Local de impressão</InputLabel>
                                    <Select
                                        value={configuracoes.localDeImpressao}
                                        onChange={handleChangePrinterLocation}
                                        label="Local de impressão"
                                        name='localDeImpressao'
                                        id='localDeImpressao'
                                    >
                                        {
                                            locaisDeImpressao.map(option => (
                                                <MenuItem key={option._id} value={option.chave}>{option.descricao}</MenuItem>
                                            ))
                                        }

                                        {locaisDeImpressao && locaisDeImpressao.length === 0 &&
                                            <MenuItem
                                                disabled>Nenhuma impressora encontrada. Acesse o monitor de impressão e configure
                                            </MenuItem>
                                        }

                                    </Select>
                                </FormControl>
                            </ListItem>


                            <ListItem >
                                <Autocomplete
                                    loading={loadingProducts}
                                    isOptionEqualToValue={(option, value) => (option.idProduto === value.idProduto)}
                                    value={configuracoes.produtoKGSelecionado}
                                    onChange={(event, newInputValue) => { setConfiguracoes({ ...configuracoes, produtoKGSelecionado: newInputValue }) }}
                                    autoHighlight
                                    fullWidth
                                    options={produtosKG || []}
                                    getOptionLabel={(option) => option ? `${option.descricao}` : ''}
                                    renderOption={(props, option) => <li {...props} key={option.idProduto}>{option.descricao}</li>}
                                    renderInput={(params) => <TextField {...params} label="Selecione o produto KG (padrão)" />}
                                    variant="outlined" />

                            </ListItem>
                        </List>

                        <OtherProductsShortcut
                            configuracoes={configuracoes}
                            setConfiguracoes={setConfiguracoes}
                        />
                    </Paper>
                </Box>

                <Box textAlign={'center'} sx={{ p: 2 }}>
                    <Button variant="contained" size="large" color='primary'
                        onClick={abrirTelaAutoatendimento} startIcon={<ScaleIcon />}>
                        Iniciar atendimento
                    </Button>
                </Box>
            </Box>

            <AutoatendimentoBalanca
                open={open} handleClose={handleClose}
                configuracoes={configuracoes}
                setTelaAutoatendimento={setTelaAutoatendimento}
                telaAutoatendimento={telaAutoatendimento} />

        </div>
    );
}