import { isSameDay, subHours } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

const roundTo = require('round-to');

export const hashCode = (str) => {
    return str.split('').reduce((prevHash, currVal) =>
        (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}

export const roundUp = (value, casas = 2) => {
    let valueRoundUp = roundTo.up(value, casas + 1)
    return parseFloat(valueRoundUp.toFixed(casas))
}

export const roundDown = (value, casas = 2) => {
    let valueRoundUp = roundTo.down(value, casas + 1)
    return parseFloat(valueRoundUp.toFixed(casas))
}

export const hasModalOpen = () => {
    const modals = [...document.querySelectorAll('.MuiBackdrop-root')]

    return modals.some(item => item.style.visibility !== 'hidden')
}

export const useHotkeysCustom = (key, callback) => {
    const handleKeyDown = event => {

        if (key.toLowerCase() !== event.key?.toLowerCase()) return

        event.preventDefault()
        event.stopPropagation()

        callback()
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)

        return () => document.removeEventListener('keydown', handleKeyDown)
    })
}

export const useMultipleHotkeysCustom = (keys, callback) => {
    const handleKeyDown = event => {
        keys.forEach(key => {
            if (key.toLowerCase() !== event.key?.toLowerCase()) return

            event.preventDefault()
            event.stopPropagation()

            callback(key)
        })
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)

        return () => document.removeEventListener('keydown', handleKeyDown)
    })
}

export const useIsFirstRender = () => {
    const isFirst = useRef(true)

    if (isFirst.current) {
        isFirst.current = false
        return true
    }

    return isFirst.current
}

export const useIntersectionObserver = ({ keepMounted = false, rootMargin = '0px', updateOn = '' }) => {
    const ref = useRef(null)
    const [inView, setInView] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersect, {
            rootMargin,
            root: null,
            threshold: 0.1,
        })

        function handleIntersect([{ target, isIntersecting }]) {
            setInView(isIntersecting)

            if (keepMounted && isIntersecting) {
                observer.unobserve(target)
            }
        }

        observer.observe(ref.current)

        return () => observer.disconnect()
    }, [ref, updateOn, keepMounted, rootMargin])

    return [ref, inView]
}

export function porcentage(value, total) {
    return Math.round((value / total) * 100)
}

// verifica se as datas passadas são o mesmo dia de acordo com a regra do sischef
// onde o dia começa e termina as 4 horas da manhã
export function isSameDaySischef(dateLeft, dateRight) {
    const dateLeftCorrect = subHours(dateLeft, 4)
    const dateRightCorrect = subHours(dateRight, 4)

    return isSameDay(dateLeftCorrect, dateRightCorrect)
}