import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AuthService from "./service/AuthService"
import Main from './pages/main'
import Login from './pages/login'
import Load from './pages/load'
import Deliveryman from './pages/deliveryman'
import Settings from './pages/settings'
import RouteWithLayout from "./pages/layout-components/RouteWithLayout"
import Layout from "./pages/layout-components/Layout"
import PedidosAgendados from "./pages/pedidosAgendados";
import SalesHeatMap from "./pages/report/salesHeatMap";
import ListOrderIdentificador from "./pages/layout-components/ListOrderIdentificador/index"
import Unauthorized from "./pages/unauthorized";
import { ifAnyGranted } from "./util/auth/authUtil";
import BoardKds from "./pages/kds";
import AtendimentoBalanca from "./pages/atendimento-balanca";
import DeliveryManOrder from "./pages/deliveryman/components/DeliveryManOrder";
import Helpdesk from "./pages/helpdesk";
import { FastSale } from "./pages/fastSale";
import JobAssincrono from "./pages/sischefGerencial/jobAssincrono";

export const isAuthorized = (role) => {
  if (role){
    return ifAnyGranted(role)
  }
  return true;
};

const isAuthenticated = (role) => (
  AuthService.loggedIn() 
);


const PrivateRoute = ({ component: Component, unauthorizedUrl, role, ...rest }) => (
    <Route {...rest} render={props => (
      isAuthenticated() ? (
        isAuthorized(role) ? (
          <RouteWithLayout layout={Layout} {...props} component={Component}  />
        ) : (
          <Redirect to={{ pathname: `/unauthorized/${role}`, state: { from: props.location } }} /> 
        )
      ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
    )} />
  )

const Routes = () => (
    <BrowserRouter>
      <Switch>
      <PrivateRoute exact path="/" component={Main} role="ROLE_PDV_DELIVERY" />
      <PrivateRoute exact path="/delivery" component={Main} role="ROLE_PDV_DELIVERY" />
      <Route exact path="/login" component={Login} />
      <Route exact path="/helpdesk" component={Helpdesk} />
      <Route exact path="/load/:jwt" component={Load} />
      <Route exact path="/gerencial/jobAssincrono/:nome/:jwt" component={JobAssincrono} />
      <PrivateRoute exact path="/deliveryman" component={Deliveryman} role="ROLE_PDV_GESTAO_ENTREGADORES" />
      <PrivateRoute exact path="/dispatchDeliveryman" component={DeliveryManOrder} role="ROLE_PDV_DESPACHO_ENTREGADORES" />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/agendados" component={PedidosAgendados} role="ROLE_PDV_PEDIDOS_AGENDADOS" />
      <PrivateRoute exact path="/pedidosIdentificador" component={ListOrderIdentificador} role="ROLE_PDV_COMANDA_MESA" />
      <PrivateRoute exact path="/salesHeatMap" component={SalesHeatMap} role="ROLE_MAPA_DE_CALOR" />
      <PrivateRoute exact path="/fastSale" component={FastSale} role="ROLE_VENDA_RAPIDA_PDV_ONLINE" />
      <PrivateRoute path="/unauthorized/:role" component={Unauthorized} />

        {/* Alterar o Role para ROLE_UTILIZA_KDS */}
        <PrivateRoute exact path="/kds" component={BoardKds} role="ROLE_UTILIZA_KDS"/>
      <PrivateRoute exact path="/atendimento-balanca" component={AtendimentoBalanca} role="ROLE_AUTOATENDIMENTO_BALANCA_PDV_ONLINE" />
      </Switch>
    </BrowserRouter>
  );
  
  export default Routes;