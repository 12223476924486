import React, { useEffect, useState } from 'react'

import { Box, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material'
import DefaultDialogLayout from './DefaultDialogLayout'
import AuthService from '../../../service/AuthService'

export default function PromptDialog({
    open,
    content,
    footerContent,
    maxWidth,
    inputLabel = 'Justificativa',
    inputType = 'text',
    cancelLabel,
    confirmLabel,
    onClose,
    order,
    disableClose,
    setCancellationCode
}) {
    const [isOpen, setIsOpen] = useState(open)
    const [confirmed, setConfirmed] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [error, setError] = useState(false)

    function cancel() {
        setIsOpen(false)
    }

    function confirm() {
        if (!inputValue) {
            setError(true)
        } else {
            setIsOpen(false)
            setConfirmed(true)
            setError(false)
            onClose(inputValue)
        }
    }

    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            confirm()
        }
    }

    function change(event) {
        const { value } = event.target
        setInputValue(value)
        if (error) setError(false)
    }

    function close() {
        onClose(confirmed ? inputValue : false)
    }

    return (
        <DefaultDialogLayout
            open={isOpen}
            maxWidth={maxWidth}
            onClose={close}
            onCancel={cancel}
            onConfirm={confirm}
            cancelLabel={cancelLabel}
            disableClose={disableClose}
            confirmLabel={confirmLabel}>

            <Typography align="center">{content}</Typography>

            <Box pt={2}>
                <TextField
                    fullWidth
                    autoFocus
                    label={inputLabel}
                    value={inputValue}
                    type={inputType}
                    onKeyDown={handleKeyDown}
                    onChange={change}
                    helperText={error ? "É necessário preencher o campo." : ""}
                    error={error}
                />
            </Box>

            <Box marginTop={'15px'}>
                <SelectCancellationReason order={order} setCancellationCode={setCancellationCode} />
            </Box>

            {footerContent && (
                <Typography
                    mt={2}
                    align="center"
                    color="error.main">
                    {footerContent}
                </Typography>
            )}

        </DefaultDialogLayout>
    )
}

function SelectCancellationReason({ order, setCancellationCode }) {
    const [selectedIndex, setSelectedIndex] = useState('')
    const [cancellationReasonsArray, setCancellationReasonsArray] = useState([])

    const handleMenuItemClick = (event) => {
        setSelectedIndex(event.target.value);
        setCancellationCode(cancellationReasonsArray[event.target.value].cancelCodeId)
    };

    useEffect(() => {
        console.log("order", order)
        if (!!order && order.tipoImportacao === "IF") {
            const service = new AuthService()
            service.get(`/api-v2/pedidoIntegracao/getCancellationReasons/${order.id}`)
                .then((resp) => {
                    console.log("resp", resp.message)
                    setCancellationReasonsArray(JSON.parse(resp.message))
                    console.log("parse", JSON.parse(resp.message))
                }).catch((err) => {
                    console.error(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {(!!cancellationReasonsArray && cancellationReasonsArray.length > 0) && (
                <>
                    <FormControl
                        size="small"
                        variant='outlined'
                        fullWidth >
                        <Select
                            value={selectedIndex}
                            onChange={(event) => handleMenuItemClick(event)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}>

                            <MenuItem value=''>
                                <em>Código de cancelamento</em>
                            </MenuItem>
                            {cancellationReasonsArray?.map((option, indexBrand) => {
                                return (
                                    <MenuItem key={indexBrand} value={indexBrand}>{option.description}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </>
            )}
        </>
    )
}