import React, { useRef } from 'react'
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  DialogActions,
  Button,
  TableContainer,
  Grid,
  Box,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { PAGE_STYLE_PRINT } from '../../../util/printUtils';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';
import { dateFormatedToNewFormat } from '../../../util/formatter';

const PREFIX = 'ListaDeItens';

const classes = {
  scrollBar: `${PREFIX}-scrollBar`,
  headerStyle: `${PREFIX}-headerStyle`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.scrollBar}`]: theme.scrollBar,

  [`& .${classes.headerStyle}`]: {
    backgroundColor: theme.palette.personal.boardCard
  }
}));

export default function ListaDeItens({ open, setOpenItens, groupedDate }) {
  const [displayMode, setDisplayMode] = useState('ITEM');
  const tableRef = useRef()


  const pedidos = groupedDate ? groupedDate.hourList.flatMap(hour => hour.orderList) : []

  const groupedItems = pedidos.length > 0 ? displayMode === 'ITEM' ? agruparItens() : agrupaItensPorProduto() : []


  function agrupaItensPorProduto() {
    let groupedItemsTemp = []

    pedidos.filter(pedido => {

      pedido.iFoodOrder.items.map(item => {
        const copyItem = Object.assign({}, item)

        var foundItem = null

        if (!item.externalCode || item.externalCode === "") {
          foundItem = groupedItemsTemp.find(element => element.name === copyItem.name)
        } else {
          foundItem = groupedItemsTemp.find(element => element.externalCode === copyItem.externalCode)
        }

        if (foundItem === undefined || foundItem === null) {
          copyItem.isMain = true
          copyItem.subItems = null
          groupedItemsTemp.push(copyItem)
        } else {
          foundItem.quantity += copyItem.quantity
          foundItem.totalPrice += copyItem.totalPrice
        }

        if (item.subItems) {
          item.subItems.map(subitem => {
            const copySubitem = Object.assign({}, subitem)

            var foundSubItem = null

            if (!subitem.externalCode || subitem.externalCode === "") {
              foundSubItem = groupedItemsTemp.find(element => element.name === copySubitem.name)
            } else {
              foundSubItem = groupedItemsTemp.find(element => element.externalCode === copySubitem.externalCode)
            }

            if (foundSubItem === undefined || foundSubItem === null) {
              copySubitem.isMain = false
              groupedItemsTemp.push(copySubitem)
            } else {
              foundSubItem.quantity += copySubitem.quantity
              foundSubItem.totalPrice += copySubitem.totalPrice
            }
            return null;
          })
        }
        return null;
      })
      return null;
    })

    return groupedItemsTemp.sort((a, b) => b.isMain - a.isMain)
  }

  function agruparItens() {

    let groupedItemsTemp = []

    pedidos.map(pedido => {
      pedido.iFoodOrder.items.map(item => {

        const copyItem = Object.assign({}, item)

        /* SE TEM SUBITEM, NAO ARUPA */
        if (copyItem.subItems && copyItem.subItems.length > 0) {
          groupedItemsTemp.push(copyItem)

        } else {
          //SE NAO TIVER SUBITEM SOMA NOS QUE JA EXISTEM

          let itemEncontrado = groupedItemsTemp.find(itemExistente => itemExistente.externalCode === copyItem.externalCode)

          if (itemEncontrado) {
            itemEncontrado.quantity += copyItem.quantity;
            itemEncontrado.totalPrice += copyItem.totalPrice
          } else {
            groupedItemsTemp.push(copyItem)
          }
        }
        return null;
      })
      return null;
    })

    return groupedItemsTemp
  }

  const handleChange = (event) => {
    setDisplayMode(event.target.value);
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    pageStyle: () => PAGE_STYLE_PRINT
  });

  function onClickResume(e) {
    e.stopPropagation();
    handlePrint()
  }


  return (
    <StyledDialog
      open={open}
      onClose={() => setOpenItens(false)}
      fullWidth maxWidth="sm">

      <DialogTitle id="title-resumo" >
        {`Resumo dos itens agendados de ${dateFormatedToNewFormat(groupedDate.date, 'yyyy-MM-dd', 'dd MMMM yyyy')}`}
      </DialogTitle>

      <DialogContent>

        {groupedItems.length <= 0 && 'Carregando as itens, aguarde...'}

        <Grid container spacing={2}>
          <Grid item xs>
            <FormControl component="fieldset" fullWidth={true}>
              <FormLabel component="legend">Modo de exibição</FormLabel>
              <RadioGroup row aria-label="displayMode" name="displayMode" value={displayMode} onChange={handleChange}>
                <FormControlLabel value="ITEM" control={<Radio />} label="Item" />
                <FormControlLabel value="PRODUTO" control={<Radio />} label="Produto" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <div ref={tableRef}>
          <Box display="none" displayPrint="block">
            {`Itens agendados ${dateFormatedToNewFormat(groupedDate.date, 'yyyy-MM-dd', 'dd MMMM yyyy')}`}
          </Box>

          <TableContainer>

            <Table size="small" >
              <TableHead>
                <TableRow className={classes.headerStyle}>
                  <TableCell className={classes.headerStyle}>Descrição</TableCell>
                  <TableCell align="right" className={classes.headerStyle}>Quantidade</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.headerStyle}>
                {groupedItems ? (
                  groupedItems.map((item) => (
                    <React.Fragment key={item.referenceCode}>
                      <TableRow className={classes.headerStyle}>
                        <TableCell component="th" className={classes.headerStyle}>
                          {item.name}
                        </TableCell>

                        <TableCell align="right" className={classes.headerStyle}>
                          {item.quantity}
                        </TableCell>
                      </TableRow>

                      {item.subItems ? (
                        item.subItems.map((subItem, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell
                                style={{ paddingLeft: '60px' }}
                                component="th"
                                size='small'
                                className={classes.headerStyle}>
                                {subItem.name}
                              </TableCell>

                              <TableCell
                                align="right"
                                size='small'
                                className={classes.headerStyle}>
                                {subItem.quantity}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      ) : null}
                    </React.Fragment>
                  ))
                ) : null}

              </TableBody>

            </Table>

          </TableContainer>
        </div>

      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<PrintIcon />}
          color="grey"
          size="small"
          onClick={onClickResume}>
          Imprimir
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
