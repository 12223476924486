import AuthService from "../../service/AuthService"



export const ifAnyGrantedFromProfile = (roles, profile) =>{

    let authrized = false;
    
    if (profile){

        if (profile.permissoes && profile.permissoes.length > 0){

            profile.permissoes.map(permissao => {
                let roleArray = roles.split(",");
                if (roleArray.includes(permissao)){
                    authrized = true;
                }
                return null;
            })

        }

    }
    return authrized;
}

export const ifAnyGranted = (roles) =>{
    const profile = AuthService.profile();
    return ifAnyGrantedFromProfile(roles, profile);
}


export const ifAnyGrantedUnidadeFromProfile = (roles, profile) =>{

    let authrized = false;
    
    if (profile){
        const rolesOfUnidade = profile.unidade.roles;

        if (rolesOfUnidade && rolesOfUnidade.length > 0) {
            rolesOfUnidade.map(roleOfUnidade => {
                let roleArray = roles.split(",");
                if (roleArray.includes( roleOfUnidade )) {
                    authrized = true;
                }
                return null;
            })

        }
    }

    return authrized;
}


export const ifAnyGrantedUnidade = (roles) =>{
    const profile = AuthService.profile();
    return ifAnyGrantedUnidadeFromProfile(roles, profile);
}


export const ifNotGranted = (roles) =>{
    return ! ifAnyGranted(roles);
}

export const ifNotGrantedUnidade = (roles) =>{
    return ! ifAnyGrantedUnidade(roles);
}
