export const toogleMunicipioList = (currentState, action) => {
    let { municipioList } = action
  
    return {
      ...currentState,
      municipioList: { ...municipioList, municipio: municipioList.municipio, open: municipioList.open }
    }
  }
  
  export const initialMunicipioList = () => {
    return {
      open: false,
      municipio: null,
    }
  }