import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';

export default function SimpleBackdrop() {

  const dispatch = useDispatch()
  const open = useSelector(state => state.simpleBackdrop.open)

  const setOpen = (openValue) => {
    dispatch({ type: 'TOOGLE_SIMPLE_DROPBOX', simpleBackdrop: { open: openValue } })
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}