export const saveSettings = (settings) => {
    localStorage.setItem('_settings_', JSON.stringify(settings));
};

export const getSettings = () => {
    let stringSettings = localStorage.getItem('_settings_');
    let settings = JSON.parse(stringSettings);

    if (!settings) {
        //default value
        settings = {
            enableRingSongNewOrder: 'ENABLED', //ENABLED, DISABLED
            printApplication: 'WEB', //WEB ou APP (no app de impressao da parseint)
            automaticallyConfirmOrder: 'DISABLED', //ENABLED, DISABLED
            printAutomaticallyOnConfirm: 'DISABLED', //ENABLED, DISABLED
            printAutomaticallyOnCreate: 'ENABLED', //ENABLED, DISABLED
            importOrderCheckout: 'ENABLED', //ENABLED, DISABLED
            typeNotificationOrderIntegrated: 'SEND_ONLY_TO_DELIVERY_PRINT', //DEFAULT
            enableMobilePDV: 'false', //false, true
            printWhenConfirmingPayment: 'false', //false, true
            tefIntegrationId: null,
        };
    }
    settings.importOrderCheckout = 'ENABLED';
    return settings;
};
