import {
    Box,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    Popover,
    IconButton,
    Tabs,
    Tab,
    Hidden,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { memo, useEffect, useMemo, useState } from 'react';
import AuthService from '../../../service/AuthService';
import CardIdentificadorPrincipal from './CardIdentificador/Principal';
import CardIdentificadorSecundario from './CardIdentificador/Secundario';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { createCardList } from './CardIdentificador/Principal/Helper';
import Order from '../NewOrder/Order';
import FindMenuIdentificador from './FindMenuIdentificador';
import PaymentDialog from '../Payment';
import DetailOrder from '../DetailOrder';
import { SkeletonProducts } from '../elements/Skeletons';
import SocketContext from '../../../util/socket';
import ImportOrder from "../ImportOrder"
import FiscalDocument from '../fiscalDocument';
import OrderListByIdentificador from './OrderListByIdentificador';
import JoinOrder from './JoinOrder';
import AdditionalInformation from '../AdditionalInformation';
import CloseOrder from '../CloseOrder';
import FilterListIcon from '@mui/icons-material/FilterList';
import { setKey } from '../../../util/indexedDB/IdbUtils';
import { Typography } from '@mui/material';
import ReactGA from "react-ga4";
import DetailOrderOffline from '../DetailOrderOffline';
import { AutoGridBox } from '../../../component/generics/AutoGridBox';
import { TefDialog } from '../Payment/Tef';
import { SaleInfoDialog } from '../Payment/Tef/SaleInfoDialog';
import { IntegracoesButton } from '../Board/Board';
import AcoesIntegracao from '../Integracoes/AcoesIntegracoes';

const PREFIX = 'ListOrderIdentificador';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`
}

const Root = styled('div')(() => ({
    [`& .${classes.root}`]: {
        flexGrow: 1,
    },

    [`& .${classes.paper}`]: {
        paddingBottom: '12px',
        width: '100%',
        height: '70%',
        textAlign: 'center',
        marginTop: 20,
        backgroundColor: 'transparent'

    }
}))

function ListOrderIdentificador() {
    const { enqueueSnackbar } = useSnackbar();
    const service = new AuthService()
    const dispatch = useDispatch()

    const infoIdentificador = useSelector(state => state.globalCache.infoIdentificador)
    const { MODELO_IDENTIFICADOR_PRINCIPAL } = infoIdentificador;
    const { LABEL_IDENTIFICADOR_PRINCIPAL } = infoIdentificador;
    const MODELO_IDENTIFICADOR_OPOSTO = MODELO_IDENTIFICADOR_PRINCIPAL === "COMANDA" ? "MESA" : "COMANDA";
    const [selectedTab, setSelectedTab] = useState(0)
    const cardList = useSelector(state => state.cards)
    const [loadingCards, setLoadingCards] = useState(false)
    const [finalList, setFinalList] = useState(cardList)
    const [anchorEl, setAnchorEl] = useState(null);

    const savedOrderBy = useSelector(state => state?.orderBy?.orderBy)
    const [orderBy, setOrderBy] = useState(savedOrderBy)

    const cardListOposto = useSelector(state => state.cardsOposto)

    const filteredFinalList = useMemo(
        () => (finalList || []).filter(({ identificador }) => (identificador.tipo === MODELO_IDENTIFICADOR_PRINCIPAL && identificador.ativo)),
        [MODELO_IDENTIFICADOR_PRINCIPAL, finalList])

    const filteredFinalListMesa = useMemo(() => (finalList || []).filter(({ identificador }) => identificador.tipo === 'MESA'), [finalList])

    useEffect(() => {//informa ao analytics que o usuário caiu na pagina de pedidosIdentificador
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "pedidosIdentificador" });
    }, [])

    useEffect(() => {
        async function fetch() {
            try {
                setLoadingCards(true)

                const tiposPedido = MODELO_IDENTIFICADOR_PRINCIPAL + ',' + MODELO_IDENTIFICADOR_OPOSTO;
                const [respIdentificadorList, respPedidoIntegracaoList] = await Promise.all([
                    service.get('/api-v2/identificador/tipo/COMANDA,MESA'),
                    service.get(`/api-v2/pedidoIntegracao/listByTipo?pago=NAO&cancelado=false&importados=false&tipo=${tiposPedido}`),
                ])

                const cards = createCardList(respIdentificadorList, respPedidoIntegracaoList, MODELO_IDENTIFICADOR_PRINCIPAL)
                //Filtragem de pedidos do modelo não principal.
                let respPedidoIntegracaoListFiltrado = respPedidoIntegracaoList.filter((identificador) => (identificador.tipo === MODELO_IDENTIFICADOR_OPOSTO))

                const cardsOposto = createCardList(respIdentificadorList, respPedidoIntegracaoListFiltrado, MODELO_IDENTIFICADOR_OPOSTO)

                //Filtra pelos card com pedidos.
                const cardsOpostoFiltered = cardsOposto.filter((card) => (card.identificador?.tipo === MODELO_IDENTIFICADOR_OPOSTO && card.identificador.ativo && card.orderList?.length > 0))

                dispatch({ type: 'LOAD_CARDS_FROM_DATA', cards: cards })
                dispatch({ type: 'LOAD_OPPOSITE_CARDS_FROM_DATA', cardsOposto: cardsOpostoFiltered })

                setLoadingCards(false)

                setKey('Cards', cards.filter(({ identificador }) => (identificador.tipo === MODELO_IDENTIFICADOR_PRINCIPAL && identificador.ativo)))
            } catch (err) {
                console.error(err)
                enqueueSnackbar(`Erro ao buscar os identificadores.`, { variant: "error" });
                setLoadingCards(false)
            }
        }

        if (MODELO_IDENTIFICADOR_PRINCIPAL) {
            fetch()
        }
        // eslint-disable-next-line
    }, [MODELO_IDENTIFICADOR_PRINCIPAL])

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    useEffect(() => {

        if (savedOrderBy === 'opens') {
            let firstList = []
            let secondList = []
            // eslint-disable-next-line no-unused-expressions
            cardList?.map((card) => {

                if (card.orderList.length > 0) {
                    firstList.push(card)//Pega os cards com pedidos
                } else {
                    secondList.push(card)//Pega os cards sem pedidos
                }
                return null;
            })

            const resultList = firstList?.concat(secondList) //Junta os dois arrays, porém agora com os cards com pedidos em primeiro
            setFinalList(resultList);
        } else {
            setFinalList(cardList)
        }
    }, [cardList, savedOrderBy])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleChangeOrderByMesa = (event) => {
        setOrderBy(event.target.value); //Trigger quando muda-se ordem de exibição das comandas/mesa
    }

    useEffect(() => {
        dispatch({ type: 'TOGGLE_ORDER_BY_MODE', orderBy: orderBy }) //Atualiza estado do redux espelho do localStorage
    }, [orderBy])

    return (
        <Root>
            <Box className={classes.root} paddingTop={0}>
                <Grid container direction='row'>
                    <Grid container item xs={12} justifyContent="center" alignItems="center" >
                        <Grid item xs={8} >
                            <Tabs
                                value={selectedTab}
                                onChange={handleChange}
                                indicatorColor='primary'
                                textColor='primary'
                            >
                                <Tab label={`${LABEL_IDENTIFICADOR_PRINCIPAL}s`} tabIndex={0} />

                                {MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' &&
                                    <Tab label="Mesas" tabIndex={1} />
                                }

                            </Tabs>
                        </Grid>
                        <Grid item xs={4} >
                            <Hidden smDown>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box >
                                        <IntegracoesButton goTo="/pedidosIdentificador" />
                                    </Box>
                                </Box>
                            </Hidden>
                        </Grid>
                        <Grid item xs >
                            {(MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' && selectedTab !== 0) &&

                                <Grid container alignItems="flex-end" justifyContent="flex-end">
                                    <Box display="flex" flexDirection="row-reverse" >
                                        <IconButton onClick={handleClick} size="large">
                                            <FilterListIcon />
                                        </IconButton >
                                        <Popover
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Box p={1} textAlign="center">
                                                <FormControl component="fieldset">
                                                    <RadioGroup name="OrderBy" onChange={handleChangeOrderByMesa} value={orderBy}>
                                                        <FormControlLabel value={"opens"} control={<Radio />} label="Ordenar por mesas abertas" />
                                                        <FormControlLabel value={"ids"} control={<Radio />} label="Ordenar pelo número da mesa" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Popover>

                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>

                        {
                            selectedTab === 0 ?
                                <Box paddingTop={1}>

                                    <FindMenuIdentificador />

                                    {loadingCards ? (
                                        <Box pt={1}>
                                            <SkeletonProducts
                                                width={160}
                                                height={110}
                                                length={30} />
                                        </Box>
                                    ) : (
                                        <Box pt={1}>
                                                <AutoGridBox>
                                                    <ListComandasMemonized list={filteredFinalList} />
                                                </AutoGridBox>

                                            {cardListOposto?.length > 0 && (
                                                <Box className={classes.paper} spacing={1}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        alignItems="flex-start"  >
                                                        <Grid item xs={12}>
                                                            <Box pb={2} pt={2}>
                                                                <Typography variant="h4" color="primary"> Outros pedidos abertos</Typography>
                                                            </Box>
                                                        </Grid>

                                                        </Grid>

                                                        <AutoGridBox>
                                                            {cardList && cardListOposto.map(item => (
                                                                <CardIdentificadorPrincipal key={item.identificador.identificador} identificadorCard={item} tipo={MODELO_IDENTIFICADOR_OPOSTO} />
                                                            ))}
                                                        </AutoGridBox>
                                                </Box>
                                            )}

                                        </Box>
                                    )}

                                </Box>
                                :
                                <Box paddingTop={1}>

                                    {loadingCards ? (
                                        <SkeletonProducts
                                            width={160}
                                            height={90}
                                            length={30} />
                                    ) : (
                                            <AutoGridBox>
                                                {MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' && filteredFinalListMesa.map((identificador, index) => (
                                                    <CardIdentificadorSecundario key={index} identificadorCard={identificador} />
                                                ))}
                                            </AutoGridBox>
                                    )}
                                </Box>

                        }
                    </Grid>
                </Grid>
            </Box>

            <Order />
            <DetailOrder />
            <DetailOrderOffline />
            <PaymentDialog />
            <SocketContext />
            <ImportOrder />
            <CloseOrder />
            <AcoesIntegracao />
            <FiscalDocument />
            <OrderListByIdentificador />
            <JoinOrder />
            <AdditionalInformation />
            <TefDialog />
            <SaleInfoDialog />

        </Root>
    )
}

const ListComandasMemonized = memo(function ListTest({ list }) {

    return list.map(item => (
        <CardIdentificadorPrincipal key={item.identificador.identificador} identificadorCard={item} />
    ))
})

export default ListOrderIdentificador;