
export const toogleDialogEditItem = (currentState, action) =>  {
    let { detailDialogEditItem } = action
    return {
        ...currentState, 
        detailDialogEditItem: { ...detailDialogEditItem, open: detailDialogEditItem.open } 
    }
}

export const initialStateDialogEditItem = () =>  {

    return {
        open: false,
        item: {}
    }
}