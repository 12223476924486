import {
    Box, Checkbox, Typography, FormControl, FormControlLabel, FormGroup, FormLabel,
    IconButton, Menu, MenuItem, Radio, RadioGroup, Button, Select, InputLabel, Grid, Dialog, DialogContentText, DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGenericCodeByKey } from '../../../../component/genericCode';
import SettingsIcon from '@mui/icons-material/Settings';
import { DialogTitle, DialogContent } from "./../../../layout-components/CustomDialog/dialogComponents"
import { SITUACOES_KDS } from '../../utils';
import { TIPOS_PEDIDOS } from '../../../../util/board/BoardUtil';
 
export default function FilterKds({ atualizarTela }) {
    const locaisDeNotificacao = getGenericCodeByKey(useSelector(state => state.genericCodes), 'TIPO_NOTIFICACAO_PRODUTO') || []
    const locaisDeImpressao = useSelector(state => state.printerLocations) || []
    const [anchorElFiltros, setAnchorElFiltros] = useState(null);
    const SAVED_SETTINGS_KDS = "_saved_settings_kds_"
    const savedFilters = JSON.parse(localStorage.getItem(SAVED_SETTINGS_KDS))
    const [settings, setSettings] = useState();
    const [openConfigHelper, setOpenConfigHelper] = useState(localStorage.getItem(SAVED_SETTINGS_KDS) == null);
    

    useEffect(() => {
        let settingsCopy = Object.assign({}, settings)

        settingsCopy.agrupamento = 'INDIVIDUAL'
        settingsCopy.locais = []
        settingsCopy.exibir = []
        settingsCopy.tiposPedido = []
        settingsCopy.localDeImpressao = '-'
        settingsCopy.horasAnterior = 24
        settingsCopy.horasPosterior = 24
        settingsCopy.avisoSonoro = true

        if (savedFilters) {
            settingsCopy.agrupamento = savedFilters.agrupamento
            settingsCopy.localDeImpressao = savedFilters.localDeImpressao
            settingsCopy.horasAnterior = savedFilters.horasAnterior
            settingsCopy.horasPosterior = savedFilters.horasPosterior
            settingsCopy.avisoSonoro = savedFilters.avisoSonoro
        }

        if (locaisDeNotificacao) {

            locaisDeNotificacao.map((local) => {
                let check = false

                if (savedFilters) {
                    const localSaved = savedFilters.locais.filter((e) => e.codigo === local.codigo)

                    if (localSaved && localSaved.length > 0) {
                        check = localSaved[0].checked
                    }
                }

                settingsCopy.locais.push({ ...local, checked: check })
                return null;
            })
        }

        SITUACOES_KDS.map((situacao) => {
            let check = false

            if (savedFilters) {
                const situacaoSaved = savedFilters.exibir?.filter((e) => e.key === situacao.key)

                if (situacaoSaved.length > 0) {
                    check = situacaoSaved[0].checked
                }
            }

            settingsCopy.exibir.push({ ...situacao, checked: check })
            return null;
        })

        TIPOS_PEDIDOS.map((tipoPedido) => {
            let check = false

            if (savedFilters) {
                const tipoSaved = savedFilters.tiposPedido?.filter((e) => e.key === tipoPedido.key)

                if (tipoSaved && tipoSaved.length > 0) {
                    check = tipoSaved[0].checked
                }
            }

            settingsCopy.tiposPedido.push({ ...tipoPedido, checked: check })
            return null;
        })

        setSettings(settingsCopy)
        // eslint-disable-next-line
    }, [])

    const handleChangeAgrupamento = (event) => {

        const settingsCopy = Object.assign({}, settings)

        settingsCopy.agrupamento = event.target.value

        setSettings(settingsCopy)
    }

    const handleChangeType = (event, local) => {
        const settingsCopy = Object.assign({}, settings)

        let index = settingsCopy.locais.findIndex((localFilter) => localFilter.codigo === local.codigo);
        local.checked = event.target.checked
        settingsCopy.locais[index] = local

        setSettings(settingsCopy)
    }

    const handleChangeExibir = (event, situacao) => {
        const settingsCopy = Object.assign({}, settings)

        let index = settingsCopy.exibir.findIndex((e) => e.key === situacao.key);

        situacao.checked = event.target.checked
        settingsCopy.exibir[index] = situacao

        setSettings(settingsCopy)
    }

    const handleChangePrinterLocation = (event) => {
        const settingsCopy = Object.assign({}, settings)

        settingsCopy.localDeImpressao = event.target.value

        setSettings(settingsCopy)
    }

    const handleChangeAvisoSonoro = (event) => {
        const settingsCopy = Object.assign({}, settings)

        settingsCopy.avisoSonoro = event.target.checked

        setSettings(settingsCopy)
    }

    const handleChangePeriodoAnterior = (event) => {
        const settingsCopy = Object.assign({}, settings)

        settingsCopy.horasAnterior = event.target.value

        setSettings(settingsCopy)
    };

    
    const handleChangePeriodoPosterior = (event) => {
        const settingsCopy = Object.assign({}, settings)

        settingsCopy.horasPosterior = event.target.value

        setSettings(settingsCopy)
    };

    const onClickAplicar = () => {

        localStorage.setItem(SAVED_SETTINGS_KDS, JSON.stringify(settings))

        atualizarTela()

        setAnchorElFiltros(null);
    }

    const handleClose = () => {
        setAnchorElFiltros(null);
    };

    return (
        <React.Fragment>
        <Box >
            <Grid container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center">

              <Grid item>
                <Typography variant="h5">
                    KDS (Kitchen Display System)
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                    onClick={event => setAnchorElFiltros(event.currentTarget)}
                    size="medium"
                    edge="end"
                    color="inherit"
                >
                    <SettingsIcon />
                 </IconButton>

          <Menu
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
              }}
              anchorEl={anchorElFiltros}
              open={Boolean(anchorElFiltros)}
              onClose={event => setAnchorElFiltros(null)}
          >

              <Box>
                  <DialogTitle onClose={handleClose} />
              </Box>

              <Box p={1} pb={2} pl={2} pr={2}>
                  <FormControl key='tiposPedido' component="fieldset" margin="dense">
                      <FormLabel component="legend">Tipo pedido</FormLabel>
                      <FormGroup>
                          {settings?.tiposPedido?.map((tipo) => (
                              <MenuItem disableRipple key={tipo.key} >
                                  <FormControlLabel
                                      control={<Checkbox
                                          name={tipo.key}
                                          checked={tipo.checked}
                                          onChange={(event) => { handleChangeType(event, tipo) }}
                                          size='small' />}

                                      label={<Typography variant='button'>{tipo.descricao}</Typography>}
                                  />
                              </MenuItem>
                          ))}
                      </FormGroup>
                  </FormControl>

                  <FormControl key='locaisDeNotificacao' component="fieldset" margin="dense">
                      <FormLabel component="legend">Locais</FormLabel>
                      <FormGroup>
                                        {// eslint-disable-next-line
                                            settings?.locais?.filter((e) => e.codigo != 'NN').map((local) => (
                              <MenuItem disableRipple key={local.codigo} >
                                  <FormControlLabel
                                      control={<Checkbox
                                          name={local.codigo}
                                          checked={local.checked}
                                          onChange={(event) => { handleChangeType(event, local) }}
                                          size='small' />}

                                      label={<Typography variant='button'>{local.descricao}</Typography>}
                                  />
                              </MenuItem>
                          ))}
                      </FormGroup>
                  </FormControl>

                  <FormControl key='agrupar' component="fieldset" margin="dense">
                      <FormLabel component="legend">Agrupamento</FormLabel>

                      <RadioGroup name="agrupamento" value={settings?.agrupamento} onChange={handleChangeAgrupamento}>
                          <MenuItem disableRipple>
                              <FormControlLabel value='INDIVIDUAL' control={<Radio />} label={<Typography variant='button'>Itens individuais</Typography>} />
                          </MenuItem>

                          <MenuItem disableRipple>
                              <FormControlLabel value="AGRUPADO" control={<Radio />} label={<Typography variant='button'>Agrupar por lançamento</Typography>} />
                          </MenuItem>
                      </RadioGroup>
                  </FormControl>

                  <FormControl key='situacao' component="fieldset" margin="dense">
                      <FormLabel component="legend">Exibir</FormLabel>
                      <FormGroup>
                          {settings?.exibir?.map((situacao) => (
                              <MenuItem disableRipple key={situacao.key} >
                                  <FormControlLabel
                                      control={<Checkbox
                                          name={situacao.key}
                                          checked={situacao.checked}
                                          onChange={(event) => { handleChangeExibir(event, situacao) }}
                                          size='small' />}

                                      label={<Typography variant='button'>{situacao.singularName}</Typography>}
                                  />
                              </MenuItem>
                          ))}
                      </FormGroup>
                  </FormControl>
              </Box>

              <Box p={1} pb={2} pl={2} pr={2}>

                  <FormControl variant="outlined" fullWidth>
                      <InputLabel>Local de impressão</InputLabel>
                      <Select
                          value={settings?.localDeImpressao}
                          onChange={handleChangePrinterLocation}
                          label="Local de impressão"
                          name='localDeImpressao'
                          id='localDeImpressao'
                      >
                          {
                              locaisDeImpressao.map(option => (
                                  <MenuItem key={option._id} value={option.chave}>{option.descricao}</MenuItem>
                              ))
                          }

                          { locaisDeImpressao && locaisDeImpressao.length === 0 && 
                            <MenuItem 
                                disabled>Nenhuma impressora encontrada. Acesse o monitor de impressão e configure
                            </MenuItem>
                          }

                      </Select>
                  </FormControl>
              </Box>
              <Box p={1} pb={2} pl={2} pr={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Listar apartir das últimas</InputLabel>
                            <Select
                                value={settings?.horasAnterior}
                                onChange={handleChangePeriodoAnterior}
                                label="Listar apartir das últimas"
                                name='horasAnterior'
                                id='horasAnterior'
                            >
                                <MenuItem key={2} value={2}>2hs</MenuItem>
                                <MenuItem key={6} value={6}>6hs</MenuItem>
                                <MenuItem key={12} value={12}>12hs</MenuItem>
                                <MenuItem key={24} value={24}>24hs</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>    
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>até às próximas</InputLabel>
                            <Select
                                value={settings?.horasPosterior}
                                onChange={handleChangePeriodoPosterior}
                                label="Listar apartir das próximas"
                                name='horasPosterior'
                                id='horasPosterior'
                            >
                                <MenuItem key={2} value={2}>2hs</MenuItem>
                                <MenuItem key={6} value={6}>6hs</MenuItem>
                                <MenuItem key={12} value={12}>12hs</MenuItem>
                                <MenuItem key={24} value={24}>24hs</MenuItem>
                                <MenuItem key={168} value={168}>1sem</MenuItem>
                                <MenuItem key={720} value={720}>1mês</MenuItem>
                                <MenuItem key={9999} value={9999}>todos</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>     
                </Grid>       
              </Box>        
              <Box p={1} pb={2} pl={2} pr={2}>
                  <FormControlLabel
                      control={<Checkbox
                          name='avisoSonoro'
                          checked={settings?.avisoSonoro}
                          onChange={(event) => { handleChangeAvisoSonoro(event) }}
                          size='small' />}

                      label={<Typography variant='button'>Aviso sonoro</Typography>}
                  />

              </Box>

              <Box p={1} pb={2} pl={2} pr={2}>
                  <Button 
                                    color="grey"
                    variant='outlined'
                    onClick={onClickAplicar}
                    fullWidth>Aplicar
                  </Button>
              </Box>
          </Menu>
              </Grid>

            </Grid>
          </Box>

          <Dialog 
            onClose={ () => setOpenConfigHelper(false) } 
            open={openConfigHelper}
            maxWidth="xs">

            <DialogContent>
                <DialogContentText>
                    Nenhum filtro foi configurado ainda. 
                </DialogContentText>
            </DialogContent>
 
          <DialogActions>
         
            <Button color="primary"
                startIcon={<SettingsIcon />}
                onClick={event => { setOpenConfigHelper(false); setAnchorElFiltros(event.currentTarget) }} >
                Configurar agora
            </Button>

            <Button 
                color="secondary"
                onClick={ () => setOpenConfigHelper(false) }>
                Fechar
            </Button>
          
        </DialogActions>
             
        </Dialog>
              
        </React.Fragment>

    );
}