import { Box, Button, Dialog, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DialogTitle, DialogContent } from "../../../../../src/pages/layout-components/CustomDialog/dialogComponents"
import { formatFullAddressCustomer } from '../../../../util/customer/helper';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from 'notistack';
import AuthService from '../../../../service/AuthService';
import RefreshIcon from '@mui/icons-material//Refresh';
import { FixedSizeList as List } from 'react-window';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    cell: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: '10px',
        display: 'flex',
        alignItems: 'flex-start',
    },

    box: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    }
}));

export default function ResumeHeatMap({ open, setOpen, customerList, setCustomerList, getLatLng }) {
    const { enqueueSnackbar } = useSnackbar();
    const service = new AuthService()
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const totalHeight = customerList.length * 60; // 60 é a altura de cada item

    const handleClose = () => {
        setOpen(false)
    }

    const onClickRefreshLatLng = async () => {
        setLoading(true)

        let customerListCopy = Object.assign([], customerList.filter((customer) => customer.isValidLatLng === false))
        if (!customerListCopy || customerListCopy.length <= 0) {
            setLoading(false)
            enqueueSnackbar('Todas as pessoas estão com suas coordenadas geográficas (latitude e longitude) informadas', { variant: 'success' })
            return
        }

        const newCustomerList = await Promise.all(customerListCopy.map(customer => getLatLng(customer)))
        const newCustomerListWithoutError = newCustomerList.filter(customer => customer)

        service.post('/api-v2/pessoa/saveList', newCustomerListWithoutError).then(() => {
            enqueueSnackbar('As coordenadas geográficas (latitude e longitude) foram atualizadas', { variant: 'success' })

            let mergelistCustomer = customerList.map(cl => newCustomerListWithoutError.find(clc => clc.id === cl.id) || cl)

            setCustomerList(mergelistCustomer)

            setLoading(false)
        }).catch((e) => {
            console.log('ERRO_SAVE_PESSOA_COORDENADAS', e)
            enqueueSnackbar('Erro ao salvar as coordenadas geográficas (latitude e longitude) nas pessoas', { variant: 'error' })
        })
    }

    const Cell = ({ index, style }) => {
        const customer = customerList[index]
        return (
            <div style={{ ...style }} className={classes.cell}>
                <div style={{ flex: 4 }}>
                    <Typography>{customer.nome}</Typography>
                    <Typography variant="caption">{formatFullAddressCustomer(customer).address}</Typography>
                </div>
                <div style={{ flex: 1, textAlign: 'right', justifyContent: 'flex-end', height: '100%', alignItems: 'center', display: 'flex' }}>
                    <Typography>{customer.quantidadePedido}</Typography>
                </div>
                <div style={{ flex: 1, textAlign: 'right', justifyContent: 'flex-end', height: '100%', alignItems: 'center', display: 'flex' }}>
                    <Typography>R$ {customer.valorPedido.toFixed(2)}</Typography>
                </div>
                <div style={{ flex: 1, textAlign: 'right', justifyContent: 'flex-end', height: '100%', alignItems: 'center', display: 'flex' }}>
                    <Typography>R$ {(customer.valorPedido / customer.quantidadePedido).toFixed(2)}</Typography>
                </div>
                <div style={{ flex: 1, textAlign: 'right', justifyContent: 'flex-end', height: '100%', alignItems: 'center', display: 'flex' }}>
                    <Tooltip title={customer.isValidLatLng ? ('lat: ' + customer.latitude + ' | Lng: ' + customer.longitude) : 'Coordenadas geográficas ( Latitude e longitude ) não informadas.'}>
                        {customer.isValidLatLng ? <CheckIcon /> : <ErrorIcon color="error" />}
                    </Tooltip>

                </div>

            </div>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle onClose={handleClose}>
                Listagem de clientes e vendas
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Grid style={{ width: '50%' }}>
                        <Button
                            startIcon={<RefreshIcon />}
                            disabled={loading}
                            variant="outlined"
                            color="primary"
                            size='medium'
                            fullWidth
                            onClick={() => onClickRefreshLatLng()}>{loading ? 'Atualizando...' : 'Atualizar as coordenadas geográficas'}
                        </Button>
                    </Grid>
                    {loading && <LinearProgress />}
                    <Box style={{ display: 'flex', padding: '10px' }} className={classes.box}>
                        <Typography style={{ flex: 4, fontWeight: 'bold' }}>Nome</Typography>
                        <Typography style={{ flex: 2, textAlign: 'right', fontWeight: 'bold' }}>Quantidade</Typography>
                        <Typography style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>Total</Typography>
                        <Typography style={{ textAlign: 'right', fontWeight: 'bold' }}>Ticket Médio</Typography>
                        <Typography style={{ flex: 1 }}></Typography>
                    </Box>
                    {customerList.length > 0 && (
                        <List
                            height={Math.min(totalHeight, 700)} // Limita a altura a 700 ou menos se a lista for menor
                            width='100%' // A largura do conteúdo do modal
                            itemSize={60} // Altura de cada linha
                            itemCount={customerList.length} // Total de itens
                            itemData={customerList} // Dados da lista
                        >
                            {Cell}
                        </List>
                    )}
                    <Typography variant="caption">{customerList.length} pessoas</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}