import React from 'react';
import { Grid, Hidden, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux'
import InfoCard from '../../../InfoCard';
import { convertCardsToOrderList } from '../../../../../store/identificadorStore';
import { totalOrder } from '../../../../../util/order/orderUtil';
import LinearProgressWithLabel from '../../../elements/LinearProgressWithLabel';

function OrderIdentificadorTopBar() {
    const cardList = useSelector(state => state.cards)
    const infoIdentificador = useSelector(state => state.globalCache.infoIdentificador)
    const [stateValues, setStateValues] = React.useState({})

    React.useEffect(() => {

        let numberOfOrders = 0
        let totalOpened = 0.0
        let numberOfOrdersClosed = 0
        let numberOfTableOpened = 0
        let qtdePessoas = 0
        let percentualOcupacao = 0.0;

        if (cardList) {
            const orders = convertCardsToOrderList(cardList, infoIdentificador.MODELO_IDENTIFICADOR_PRINCIPAL)
            
            orders.map(order => {
                if (!order.solicitadoFechamento) {
                    numberOfOrders += 1;
                } else {
                    numberOfOrdersClosed += 1;
                }

                let totalThisOrder = totalOrder(order?.iFoodOrder)
                totalOpened += !totalThisOrder.isNaN ? totalThisOrder : 0.0;
                qtdePessoas += (order.quantidadePessoas || 1)
                return null;
            })

            cardList.filter((e) => e.identificador.tipo === 'MESA').map((card) => {
                if (card.orderList && card.orderList.length > 0) {
                    numberOfTableOpened++
                }
                return null;
            })

            let mesasCard = cardList.filter(e => e.identificador.tipo === 'MESA');

            percentualOcupacao = mesasCard.length ? (numberOfTableOpened / mesasCard.length) * 100.0 : 0.0;

        }

        setStateValues({
            ...stateValues,
            numberOfOrders,
            numberOfOrdersClosed,
            numberOfTableOpened,
            totalOpened,
            qtdePessoas,
            percentualOcupacao
        })
        // eslint-disable-next-line
    }, [cardList])

    return (
        <Hidden mdDown>
            <Grid container spacing={2}>
                <Grid item>
                    <InfoCard caption={`${infoIdentificador.LABEL_IDENTIFICADOR_PRINCIPAL}s abertas`} value={stateValues.numberOfOrders} valueColor="primary" />
                </Grid>

                {infoIdentificador.MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' &&

                    <Grid item>
                        <InfoCard caption={`Mesas ocupadas`} value={stateValues.numberOfTableOpened} valueColor="info.main" />
                    </Grid>
                }

                <Grid item>
                    <InfoCard caption="Qtde de Pessoas" value={stateValues.qtdePessoas} valueColor="warning.main" />
                </Grid>

                <Grid item>
                  
                             
                    <InfoCard caption="Ocupação" value={ 
                        <Tooltip title="Percentual de mesas abertas (ocupadas) no momento">
                        <div style={{
                            width: '100px',
                            height: '24px'
                        }}>
                            <LinearProgressWithLabel
                                value={stateValues.percentualOcupacao || 0}
                                label={`${Math.round(stateValues.percentualOcupacao)}%`} /> 
                            
                        </div>
                        </Tooltip>
                    } />
                   
                   
                </Grid>


            </Grid>
        </Hidden>
    );
}

export default OrderIdentificadorTopBar;
