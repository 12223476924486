import { dateFormatedToNewFormat } from "../../../util/formatter"
import { totalOrder } from "../../../util/order/orderUtil"

export function createHourlyOrder(orderlist, locations, statusList) {
    // let orderScheduledlist = [{"date": "2022-09-19", "hourList": [{"hour": "16","orderList": []}]}]

    const orderListCopy = JSON.parse(JSON.stringify(orderlist))

    /*Filtrar os pedidos pelo status */
    const filteredOrder = filterScheduledItemsByStatus(orderListCopy, statusList)

    const filteredOrderByLocations = []

    /*Aqui filtra os pedidos que contém algum item referente ao local de notificação selecionado  */
    filteredOrder.map(order => {


        const filtedItems = filterScheduledItemsByNotificationLocations(order.iFoodOrder.items, locations)

        if (filtedItems.length > 0) {

            order.iFoodOrder.items = filtedItems

            filteredOrderByLocations.push(order)
        }
        return null;
    })

    /*aqui começa a mostar o objeto que vai ser mostrado na tela 
     ex: [{key: '2022091115', data: '2022-09-11', hora: '15', orderList: [] }]
    */

    let orderScheduledlist = []

    filteredOrderByLocations.map(order => {

        const date = dateFormatedToNewFormat(order.dataPedido, 'ISO', 'yyyy-MM-dd')
        const hour = dateFormatedToNewFormat(order.dataPedido, 'ISO', 'HH')

        let groupedDate = orderScheduledlist.find(e => e.date === date)

        if (!groupedDate) {
            groupedDate = {}
            groupedDate.date = date
            groupedDate.orderLength = 0
            groupedDate.orderTotalValue = 0
            groupedDate.hourList = []

            orderScheduledlist.push(groupedDate)
        }

        let groupedHour = groupedDate.hourList.find(e => e.hour === hour)

        if (!groupedHour) {
            groupedHour = {}
            groupedHour.hour = hour
            groupedHour.orderList = []

            groupedDate.hourList.push(groupedHour)
        }

        groupedDate.orderLength++
        groupedDate.orderTotalValue += totalOrder(order.iFoodOrder)
        groupedHour.orderList.push(order)
        return null;
    })

    return orderScheduledlist
}

export function filterScheduledItemsByNotificationLocations(items, locations) {

    if (locations && locations.length > 0) {
        return items.filter(item => locations.includes(item.localNotification))
    }

    return items
}

export function filterScheduledItemsByStatus(orderList, statusList) {

    if (statusList && statusList.length > 0) {
        return orderList.filter(order => statusList.includes(order.situacao))
    }

    return orderList
}