import React from 'react';
import { Box, Typography } from '@mui/material';

export default function SideBarTop() {
    return (
       <Box display="flex" flexGrow={1} alignItems="center">
        <Box p={1} >
            <img src={process.env.PUBLIC_URL + "/logo-sischef.svg"} style={{width: '42px'}} alt="Logo do Sischef"></img>
        </Box>
        <Box p={1} alignSelf="" flexGrow={1}  justifyContent="center">
            <Typography style={{ fontSize: "20px",   fontWeight: "bold", letterSpacing: "0px" }}>Sischef</Typography>
        </Box>

       </Box>
    )
}