import { roundUp } from "../../util/genericUtil";
import { createUUID } from "../../util/order/orderUtil";
import { ItemObj, OrderObj } from "../layout-components/NewOrder/helper/OrderHelper";


export const INTERVALO_CAPTURA_PESO_MS = 500;
export const INTERVALO_TEMPO_PARA_NOVA_PESAGEM = 500;
export const DIFERENCA_VARIACAO_PESO = 0.004;
export const TENTATIVAS_MAX_DE_LEITURAS_PARA_NOVA_PESAGEM = 1;
export const STATUS_DISPONIVEIS = ["AGUARDANDO_PESAGEM", "PESO_CAPTURADO", "INFORMAR_COMANDA", "RETIRAR_PRATO"]


export const getStadoIncialTela = (produtoKg, solicitarComanda) => {

    return {
        status: STATUS_DISPONIVEIS[0],
        solicitarComanda,
        produtoKg,
        retornoPesoAtual: { peso: 0.0 },
        retornoPesoAnterior: { peso: 0.0 }
    }
}

export const isNovaPesagem = (pesoAtual, pesoAnterior, tentativasComFalha = 0) => {

    let novaPesagem = false;
    let diferenca = parseFloat(Math.abs(pesoAnterior - pesoAtual).toFixed(3));

    if (pesoAtual > 0 && pesoAnterior > 0) {
        if (diferenca > DIFERENCA_VARIACAO_PESO) {
            novaPesagem = true;
        }
    } else if (/*pesoAtual <= 0 && pesoAnterior <= 0 && */ tentativasComFalha >= TENTATIVAS_MAX_DE_LEITURAS_PARA_NOVA_PESAGEM) {
        novaPesagem = true;
    }

    console.log("[AUTOATENDIMENTO HELPER] isNovaPesagem: " + novaPesagem
        + " Peso atual: " + pesoAtual
        + " peso ante: " + pesoAnterior
        + " tentativas: " + tentativasComFalha
        + " diferenca: " + diferenca)

    return novaPesagem;
}

export const criarItemPedidoAutoatendimento = (produto, peso) => {
    const price = produto.valorUnitarioVenda
    const totalPrice = roundUp(peso * produto.valorUnitarioVenda)
    const notification = produto.tipoNotificacao ? produto.tipoNotificacao : produto.grupoProduto?.tipoNotificacao

    const item = new ItemObj()
    item.referenceCode = createUUID()
    item.externalCode = produto.idProduto
    item.product = produto
    item.name = produto.descricao
    item.quantity = peso
    item.price = price
    item.totalPrice = totalPrice
    item.localNotification = notification || 'NN'
    item.ProductChargeServiceFee = (produto.cobrarTaxaServico ?? true)

    if (notification === 'NN') {
        item.status = 'NAO_NOTIFICAR'
        item.statusKds = 'NAO_NOTIFICAR'
    }

    return item
}

export const criaPedidoAutoatendimento = (identificadorPrincipal, produto, peso) => {
    const order = new OrderObj()
    order.tipo = "COMANDA"
    order.identificadorPrincipal = identificadorPrincipal
    order.complementoIdentificador = "BALANÇA"
    order.printOrder = true;

    const produtoLivreAssociado = produto.produtoLivreAssociado

    let produtoUtilizado = produto;
    let quantidade = peso;
    let price = produto.valorUnitarioVenda;
    let totalPrice = roundUp(peso * produto.valorUnitarioVenda);
    order.iFoodOrder.subTotal = totalPrice

    if (produtoLivreAssociado
        && produtoLivreAssociado.valorUnitarioVenda < totalPrice) {
        produtoUtilizado = produtoLivreAssociado;
        quantidade = 1.0;
        price = produtoLivreAssociado.valorUnitarioVenda;
        totalPrice = price;
    }

    const notification = produtoUtilizado.tipoNotificacao ? produtoUtilizado.tipoNotificacao : produtoUtilizado.grupoProduto?.tipoNotificacao

    const item = new ItemObj()
    item.referenceCode = createUUID()
    item.externalCode = produtoUtilizado.idProduto
    item.product = produtoUtilizado
    item.name = produtoUtilizado.descricao
    item.quantity = quantidade
    item.price = price
    item.totalPrice = totalPrice
    item.localNotification = notification || 'NN'
    item.ProductChargeServiceFee = (produtoUtilizado.cobrarTaxaServico ?? true)

    if (notification === 'NN') {
        item.status = 'NAO_NOTIFICAR'
        item.statusKds = 'NAO_NOTIFICAR'
    }

    order.iFoodOrder.items.push(item)

    return order;
}


export const gerarIdentificador = (TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA) => {

    let tipoGeracao = TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA.split("|")[0]
    let identificadorInicial = parseInt(TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA.split("|")[1])
    let identificadorFinal = parseInt(TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA.split("|")[2])

    let identificadorGerado = 0;

    if (tipoGeracao === "S") {

        //SEQUENCIAL
        let ultimoSequencial = localStorage.getItem("_SEQ_IDENTIFICADOR_BALANCA_")
        identificadorGerado = ultimoSequencial ? parseInt(ultimoSequencial) + 1 : identificadorInicial;

        if (identificadorGerado < identificadorInicial) {
            identificadorGerado = identificadorInicial
        }

        if (identificadorGerado > identificadorFinal) {
            identificadorGerado = identificadorInicial
        }
    } else if (tipoGeracao === "A") {
        //ALEATORIO (dentro do intervalo)
        identificadorGerado = Math.floor(Math.random() * (identificadorFinal - identificadorInicial + 1)) + identificadorInicial;
    }

    console.log("[IDENTIFICADOR GERADO]", identificadorGerado)
    localStorage.setItem("_SEQ_IDENTIFICADOR_BALANCA_", identificadorGerado)

    return identificadorGerado;
}