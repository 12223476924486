import React from 'react';
import { Dialog, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { dateFormat } from '../formatter';
import { DialogContent, DialogTitle } from '../../pages/layout-components/CustomDialog/dialogComponents';
import { getDescricaoLocalNotificacaoBySigla } from '../board/BoardUtil';



/*Função para mostrar as informações do item em um dialog na tela*/
function ItemInfo({ item, open, setOpen }) {

  const handleClose = () => {//fecha o dialog
    setOpen(false)
  }

  /*Gera uma tabela dentro de um dialog com as informações do item  */
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      scroll='paper'
      open={open}
      onClose={handleClose}>

      <DialogTitle onClose={handleClose}>
        <Typography>Informações do Item</Typography>
      </DialogTitle>
      
      <DialogContent>
        <Table size='small' aria-label='resumo do pedido' stickyHeader >
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography color="primary" variant="h5">{item?.name}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>Vendedor</Typography>
              </TableCell>
              <TableCell>
                <Typography>{item?.pessoaVendedor?.nome ? item.pessoaVendedor.nome : "Não informado"}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>Adicionado em</Typography>
              </TableCell>
              <TableCell>
                <Typography>{item?.createdAt ? dateFormat(item.createdAt) : "Sem informação"}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>Notificado em</Typography>
              </TableCell>
              <TableCell>
                <Typography>{getDescricaoLocalNotificacaoBySigla(item?.localNotification)}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>Impressão</Typography>
              </TableCell>
              <TableCell>
                <Typography>{`${item?.status} ${item?.notificationDate ? ' em ' + dateFormat(item.notificationDate) : ''}`}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>Notificação KDS</Typography>
              </TableCell>
              <TableCell>
                <Typography>{`${item?.statusKds} ${item?.notificationDateKds ? ' em ' + dateFormat(item.notificationDateKds) : ''}`}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography>ID externo (cód PDV)</Typography>
              </TableCell>
              <TableCell>
                <Typography>{item?.externalCode || item?.externalId}</Typography>
              </TableCell>
            </TableRow>

            {item?.logs && (
              <TableRow>
                <TableCell>
                  <Typography>Logs</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{item?.logs}</Typography>
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

export default ItemInfo