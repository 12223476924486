import React from 'react';
import { useDispatch } from "react-redux"
import { useCardPrincipal } from '../Helper';
import { useConnectionStatus } from '../../../../../../util/http/HttpUtil';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { IconButton, Tooltip } from '@mui/material';

export default function FecharPedidoButton({ identificadorCard, onLoading, disabled = false }) {
    const dispatch = useDispatch()
    const isConnected = useConnectionStatus()
    const [onClickOpenOrderAsync] = useCardPrincipal(identificadorCard)

    const onClickCloseOrder = event => {

        //Quando nao é possível identificar qual pedido abrir
        const onOpenManyOrders = identificadorCard => {
            //SE TIVER MAIS DE UM PEDIDO PARA A MESMA COMANDA
            dispatch({
                type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR',
                dialogOrderListByIdentificador: {
                    open: true,
                    data: {
                        identificadorCard,
                        openCloseOrder: true,
                    }
                }
            })
        }

        //abre de edição de produtos
        const onOpenOneOrder = (orderCopy, initActiveStep) => {
            dispatch({
                type: 'OPEN_DIALOG_CLOSE_ORDER',
                closeOrder: {
                    open: true,
                    order: orderCopy
                }
            })
        }

        const onFinally = () => onLoading ? onLoading(false) : null

        if (onLoading) onLoading(true)

        onClickOpenOrderAsync(onOpenManyOrders, onOpenOneOrder, onFinally)

        event.stopPropagation()
    }

    return (
        <Tooltip disableInteractive title="Fechar pedido">
            <span>
                <IconButton size="medium" onClick={onClickCloseOrder} disabled={disabled || !isConnected}>
                    <ReceiptIcon fontSize="small" />
                </IconButton>
            </span>
        </Tooltip>
    )
}