import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import RedeemIcon from '@mui/icons-material/Redeem';
import { Box, Button, Paper } from '@mui/material';
import ReactGA from "react-ga4";
import AuthService from '../../service/AuthService';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '../../util/http/HttpUtil';

const PREFIX = 'Unauthorized'

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  iconSize: `${PREFIX}-iconSize`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.iconSize}`]: {
    fontSize: '5em'
  }
}))

export default function Unauthorized(props) {
  const { match: { params } } = props;
  const role = params.role
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false)
  const [solicitado, setSolicitado] = useState(false)
  const service = new AuthService()
  const profile = service.getProfile()
  const isGarcom = profile?.perfilUsuario?.tipo === 'garcom'


  const requestRole = (role) => {

    setSending(true)

    service.post(`/api-v2/sistema/solicitarFuncionalidade/${role}`)
      .then(() => {
        enqueueSnackbar(`Solicitação realizada com sucesso. Logo entraremos em contato.`, { variant: "success" });
        setSolicitado(true)
      }).catch(err => {
        extractErrorMessage(err, "Erro ao solicitar funcionalidade")
          .then(msg => {
            enqueueSnackbar(`Erro ao solicitar funcionalidade- ${msg}`, { variant: "error" });
          })
        console.log('ERRO SOLICITAR FUNCIONALIDADE: ' + err)
      }).finally(() => {
        setSending(false)
      })
  }

  useEffect(() => {//informa ao analytics que o usuário caiu na pagina de unauthorized
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "unauthorized" });
  }, [])

  return (
    <Root className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">

        <Paper> 

        <Box p={3}>

            <Typography variant="h2" component="h1" gutterBottom align="center">
            Funcionalidade indisponível
            </Typography>
            <Box p={4} textAlign="center">
              <RedeemIcon className={classes.iconSize} />
            </Box>
            <Typography variant="h5" component="h2" gutterBottom align="center">
              Esta funcionalidade não está habilitada em sua conta. {isGarcom && 'Verifique com o seu gestor para ter acesso a está funcionalidade'}
            </Typography>

            <Box p={2} textAlign="center">
              {solicitado ? (
                <Typography variant="subtitle1" gutterBottom align="center">
                  Agradecemos sua solicitação. Em breve entraremos em contato 🤝
                </Typography>
              ) : (
                  role && !isGarcom &&
                <Button variant="outlined" onClick={() => requestRole(role)} disabled={sending}>
                  {sending ? "Solicitando..." : "Solicitar esta funcionalidade"}
                </Button>
              )}
            </Box>
            
        </Box>

        </Paper>
      </Container>
       
    </Root>
  );
}