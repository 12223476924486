import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Menu,
    MenuItem,
    IconButton,
    Chip,
    ListItemIcon,
    TextField,
    InputAdornment,
    Typography,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import EditSubItemOrder from '../EditSubItemOrder';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { roundUp } from '../../../../../util/genericUtil';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StepModal from './StepModal';

const PREFIX = 'StepResume';

const classes = {
    removeBottomLine: `${PREFIX}-removeBottomLine`
};

const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${classes.removeBottomLine}`]: {
        borderBottom: "none"
    }
}));

function StepResume({ stepsItems, setStepsItems, activeStep, activeStepIndex, addSubItem, productMenu, stepNivel }) {
    const { enqueueSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openEditItem, setOpenEditItem] = React.useState(false)
    const [subItemSelected, setSubItemSelected] = React.useState({})
    const [stepSelected, setStepSelected] = React.useState({})

    const [openSubItemStep, setOpenSubItemStep] = React.useState(false)
    const [productMenuSubItemStep, setProductMenuSubItemStep] = React.useState({})
    const subItemRef = React.useRef(null)


    const deleteItem = () => {
        const stepsCopy = Object.assign([], stepsItems)
        const stepTemp = stepsCopy.find(element => element.id === stepSelected.id)
        const indexSubItem = stepTemp.items.indexOf(subItemSelected)

        if (stepTemp.id === activeStep.id) {
            stepTemp.items.splice(indexSubItem, 1)

            setStepsItems(stepsCopy)
            handleClose()
        } else {
            enqueueSnackbar(`Volte ao passo ${activeStepIndex} - ${stepTemp.descricao} para remover o item ${subItemSelected.name}.`, { variant: 'error', });
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickEdit = () => {
        setOpenEditItem(true)

        handleClose()
    }

    const handleClick = (event, step, sub) => {

        setStepSelected(step)
        setSubItemSelected(sub)

        setAnchorEl(event.currentTarget);
    };


    const handleClickAddAdicionais = () => {
        console.log("stepSelected>>", stepSelected)
        console.log("subItemSelected>>", subItemSelected)

        handleClose()

        if (!subItemSelected.productMenu?.complementos?.length > 0) {
            enqueueSnackbar(`Não existe passos do nível 3 para o produto ${subItemSelected.productMenu?.produto?.descricao}`, { variant: 'error' });
            return;
        }

        // SAFE COPY
        subItemRef.current = JSON.parse(JSON.stringify(subItemSelected));

        // SAFE COPY
        const productMenuNivel3 = JSON.parse(JSON.stringify(subItemSelected.productMenu))
        setProductMenuSubItemStep(productMenuNivel3)
        setOpenSubItemStep(true)
    }

    const onFinishStepNivel = (item) => {

        const stepsCopy = Object.assign([], stepsItems)

        // //const itemFounded = stepsCopy.find(elem => elem === item)

        console.log("ITEM ADICIONADO NIVEL 3", item)
        console.log("stepsCopy", stepsCopy)

        stepsCopy.map(stepNivel3 => {

            let subItemFounded = stepNivel3.items.find(subItemNivel2 => (subItemNivel2.referenceCode === item.referenceCode))

            console.log("subItemFounded", subItemFounded)
            if (subItemFounded) {
                subItemFounded.subItems = item.subItems
            }
            return null;
        })



        setStepsItems(stepsCopy)
        console.log("stepsCopy NIVEL 2", stepsCopy)

        // const stepTemp = stepsCopy.find(element => element.id === item.id)

        //


    }

    const handleAdd = (step, subItem) => {
        const complementos = productMenu.complementos.find((e) => (e.id === step.id))

        if (complementos) {
            const product = complementos.itens.find((e) => (e.produto.idProduto === subItem.product.idProduto))

            if (product) {
                console.log(step, product)
                addSubItem(product, step, true)
            }
        }
    }

    const onCompleteStepModal = (produtctIncludeOnOrder) => {
        //NAO FAZ NADA NO NIVEL 3
    }

    /**
     * Remove um item por vez (decrementando a quantidade do item)
     */
    const removeItem = (step, subItem) => {
        const stepsCopy = Object.assign([], stepsItems)
        const stepTemp = stepsCopy.find(element => element.id === step.id)
        const itemSelecionado = stepTemp.items.find((e) => e.product.idProduto === subItem.product.idProduto)

        if (itemSelecionado) {
            if (activeStep.id === step.id) {
                if (itemSelecionado.quantity > 1) {
                    itemSelecionado.quantity--
                    itemSelecionado.totalPrice = roundUp(itemSelecionado.quantity * itemSelecionado.price, 2)
                } else {
                    const item = (subItemSelected && Object.keys(subItemSelected).length !== 0) ? subItemSelected : itemSelecionado
                    const indexSubItem = stepTemp.items.indexOf(item)

                    stepTemp.items.splice(indexSubItem, 1)
                }

                setStepsItems(stepsCopy)
            } else {
                enqueueSnackbar(`Volte ao passo ${activeStepIndex} - ${stepTemp.descricao} para remover o item ${itemSelecionado.name}.`, { variant: 'error', });
            }
        }
    };

    const onFocus = (event) => {
        event.target.select()
    }

    const alteraQuantidadeItem = (step, subItem, event) => {//Altera a quantidade do item de acordo com o que for editado
        const stepsCopy = Object.assign([], stepsItems)
        const stepTemp = stepsCopy.find(element => element.id === step.id)
        const itemSelecionado = stepTemp.items.find((e) => e.product.idProduto === subItem.product.idProduto)
        const quantidade = parseInt(event.currentTarget.value)//valor digitado pelo usuário

        if (itemSelecionado) {
            if (activeStep.id === step.id) {

                const max = stepTemp.maximo //quantidade máxima de itens permitida
                const totalQuantity = stepTemp?.items ? (stepTemp?.items.reduce((acumulador, item) => acumulador + item.quantity, 0, 0) || 0) : 0; //calcula o total de itens já inseridos
                const newQuantity = totalQuantity + quantidade - itemSelecionado.quantity //pega o total de itens inseridos + o valor a ser inserido - a quantidade deste item que está sendo alterado

                if (quantidade >= 1) {

                    if (newQuantity > max) { //verifica se o total não ultrapassa o valor máximo de itens, se ultrapassar gera uma snackbar e não altera a quantidade
                        enqueueSnackbar(`Foi adicionado ${newQuantity}. Máximo: ${max}, remova alguns itens.`, {
                            variant: 'warning', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                        });
                    } else {// se tudo correr bem altera a quantidade e calcula um novo total
                        itemSelecionado.quantity = quantidade
                        itemSelecionado.totalPrice = roundUp(itemSelecionado.quantity * itemSelecionado.price, 2)
                    }

                } else {//se a quantidade a ser inserida for menor que 1 remove o item
                    const indexSubItem = stepTemp.items.indexOf(subItemSelected)
                    stepTemp.items.splice(indexSubItem, 1)
                }

                setStepsItems(stepsCopy)
            } else {
                enqueueSnackbar(`Volte ao passo ${activeStepIndex} - ${stepTemp.descricao} para remover o item ${itemSelecionado.name}.`, { variant: 'error', });
            }
        }
    }

    function getQuantityProductStep(items) {
        var total = items.reduce(getQuantity, 0);

        function getQuantity(total, item) {
            return total + item.quantity
        }

        return total
    }

    const deleteSubItemNivel3 = (subItemNivel3) => {
        //console.log("ITEM NIVEL 3 EXCLUÍDO: ", subItemNivel3)

        const stepsCopy = Object.assign([], stepsItems)


        //console.log("STEP COPY: ", stepsCopy)

        stepsCopy.map(step => {
            step.items.map(itemOfStep => {

                let indexOffoundedItem = itemOfStep?.subItems?.findIndex(elemSubItemN3 => elemSubItemN3.referenceCode === subItemNivel3.referenceCode)

                //console.log("INDEX: ", indexOffoundedItem, itemOfStep.subItems )

                if (indexOffoundedItem >= 0) {
                    //console.log("POSICAO ITEM A SER EXCLUIDO - NIVEL 3: ", indexOffoundedItem)
                    itemOfStep.subItems.splice(indexOffoundedItem, 1)
                    calculateQuantitySubItemNivel3(itemOfStep.subItems);
                }
                return null;
            })
            return null;
        })

        setStepsItems(stepsCopy)

    }

    const calculateQuantitySubItemNivel3 = (subItensNivel3) => {

        const divisor = subItensNivel3.length ? subItensNivel3.length : 1;
        const quantidade = 1 / divisor;

        subItensNivel3.map(subItemNivel3 => {
            subItemNivel3.quantity = quantidade;
            return null;
        })
    }

    return (
        <>
            <StyledTable size='small' aria-label='resumo do sub item' stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align='center' padding='none'>Qtde</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell align='center'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stepsItems.map((step, index) => (

                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell colSpan={4} scope='row'>
                                    <span style={{ paddingRight: '5px' }}>{step.descricao}</span>
                                    <Chip label={getQuantityProductStep(step.items)} size="small" variant="outlined" />
                                </TableCell>
                            </TableRow>

                            {step.items?.map((sub, index) => (
                                <React.Fragment key={sub.referenceCode}>
                                    <TableRow >

                                        <TableCell
                                            className={classes.removeBottomLine}
                                            align='right' padding='none' size='small'
                                            style={{
                                                width: '110px',
                                                paddingTop: "10px"
                                            }}>
                                            <TextField
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                size="small"
                                                id="quantity"
                                                variant='outlined'
                                                onChange={(event) => alteraQuantidadeItem(step, sub, event)}
                                                value={sub.quantity}
                                                onFocus={onFocus}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    inputMode: 'numeric',
                                                    min: "1"
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start" >
                                                            <IconButton
                                                                size="small"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                color="primary"
                                                                aria-label='adicionar +'
                                                                onClick={() => handleAdd(step, sub)}
                                                                edge="start"
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                size="small"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                color="primary"
                                                                aria-label='remover -'
                                                                onClick={() => removeItem(step, sub)}
                                                                edge="end"
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                        </TableCell>

                                        <TableCell component='th'
                                            scope='row'
                                            padding="checkbox" className={classes.removeBottomLine}>

                                            <Typography variant='subtitle2'>
                                                {sub.name}
                                            </Typography>

                                            {sub.observations &&
                                                <Typography color='textSecondary' variant='subtitle2'>
                                                    {'* '.concat(sub.observations)}
                                                </Typography>
                                            }

                                        </TableCell>

                                        <TableCell align='center' padding="checkbox" className={classes.removeBottomLine}>
                                            <IconButton
                                                size="small"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                color="secondary"
                                                aria-label='opções'
                                                onClick={(event) => handleClick(event, step, sub)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={3} >
                                            {
                                                sub?.subItems?.map(subItemNivel3 => (
                                                    <Chip
                                                        key={subItemNivel3.referenceCode}
                                                        size="small"
                                                        label={subItemNivel3.name}
                                                        onDelete={() => deleteSubItemNivel3(subItemNivel3)}
                                                    />

                                                ))
                                            }
                                        </TableCell>
                                    </TableRow>

                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </StyledTable>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}>

                <MenuItem key={1} onClick={handleClickAddAdicionais} disabled={stepNivel === 3}>
                    <ListItemIcon>
                        <ListAltIcon fontSize="small" />
                    </ListItemIcon>
                    Adicionais
                </MenuItem>

                <MenuItem key={2} onClick={handleClickEdit}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    Observação
                </MenuItem>

                <MenuItem key={3} onClick={deleteItem}>
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    Remover
                </MenuItem>
            </Menu>


            <EditSubItemOrder open={openEditItem} setOpen={setOpenEditItem} subItem={subItemSelected} />


            <StepModal open={openSubItemStep}
                setOpenStep={setOpenSubItemStep}
                productMenu={productMenuSubItemStep}
                clearProductMenu={onCompleteStepModal}
                itemRef={subItemRef}
                stepNivel={3}
                onFinishStepNivel={onFinishStepNivel}
            />
        </>
    );
}

export default StepResume;