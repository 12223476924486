import { grey } from '@mui/material/colors';

function getButtonOverrides() {

  return {
    root: {
      boxShadow: 'none',
    },
    contained: {
      boxShadow: 'none',
    },
    outlined: {
      boxShadow: 'none',
    },
    grouped: {
      boxShadow: 'none',
    }
  }
}

export default {
  MuiButton: {
    styleOverrides: getButtonOverrides(),
    variants: [
      {
        props: { variant: "contained", color: "grey" },
        style: {
          backgroundColor: grey[300],
        }
      }
    ]
  },
  MuiButtonBase: {
    styleOverrides: getButtonOverrides(),
  },
  MuiButtonGroup: {
    styleOverrides: getButtonOverrides(),
  },
  MuiFab: {
    styleOverrides: { root: { boxShadow: 'none' } }
  },
  MuiPaper: {
    styleOverrides: { root: { backgroundImage: 'unset' } },
  },
}