import React, { useState, useEffect } from 'react';
import { Dialog, Button, Grid, TextField, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DialogTitle, DialogContent, DialogActions } from "../../CustomDialog/dialogComponents"
import AuthService from '../../../../service/AuthService';
import { onlyDigits } from "../../../../util/formatter"
import AddressForm from "./AddressForm"
import { usePdvContext } from '../../../../component/generics/PdvProvider';


export default function SecondaryAddress({ open, setOpen, selectedAddress, returnAddress, productsDeliveryFee = [] }) {
    const { enqueueSnackbar } = useSnackbar();
    const [address, setAddress] = useState(null)
    const { confirmDialog } = usePdvContext()

    //já recebe as taxas de entrega ordenadas

    useEffect(() => {
        setAddress(selectedAddress)
    }, [selectedAddress]);

    function handleDelete() {
        confirmDialog({
            showCancelButton: true,
            confirmLabel: 'Confirmar',
            content: 'Deseja realmente excluir esse endereço?',
        }).then(() => {
            let service = new AuthService()
            service.delete("/api-v2/endereco/" + address.id)
                .then(response => {
                    setAddress(address)
                    setOpen(false)
                    enqueueSnackbar("Endereço secundário excluído com sucesso", { variant: 'success' });
                    returnAddress(address, 'DELETED')
                }).catch(err => {
                    console.error(err)
                    enqueueSnackbar("Erro ao excluir endereço secundário", { variant: 'error' });
                });

        }).catch(() => { })
    }

    function handleSubmit() {
        console.log("Salvando endereco secundario")

        let addressCopy = Object.assign({}, address)
        addressCopy.cep = onlyDigits(address.cep)

        let service = new AuthService()
        service.post("/api-v2/endereco", addressCopy)
            .then(addressSaved => {
                setAddress(addressSaved)
                setOpen(false)
                enqueueSnackbar("Endereço secundário salvo com sucesso", { variant: 'success' });
                returnAddress(addressSaved, 'SAVED')
            }).catch(err => {
                console.log(err)
                enqueueSnackbar("Erro ao salvar endereço secundário", { variant: 'error' });
            });

    }

    const handleChange = (event) => {
        const attr = event.target.name
        const value = event.target.value
        setAddress({ ...address, [attr]: value })
    }

    const handleChangeCity = (municipio) => {
        if (!municipio?.nome) return
        municipio.nome = municipio.nome.replaceAll('\'', '')
        setAddress({ ...address, municipio })
    }

    const handleClickCancel = () => {
        confirmDialog({
            confirmLabel: 'Ok',
            showCancelButton: true,
            content: 'Deseja realmente cancelar a ação?',
        })
            .then(() => {
                setOpen(false)

            }).catch(() => { })

        return
    }

    return (
        <div>

            <Dialog open={open} onClose={handleClickCancel}
                fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClickCancel} >
                    Endereço secundário
                </DialogTitle>

                <DialogContent>

                    <AddressForm
                        autoFocus={true}
                        address={address}
                        setAddress={setAddress}
                        handleChangeCity={handleChangeCity}
                        handleChange={handleChange} />

                    <Grid container spacing={1} style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Taxa de entrega"
                                value={address ? (address.idProdutoTaxaEntrega || '') : ''}
                                onChange={handleChange}
                                name="idProdutoTaxaEntrega"
                                helperText="Selecione a taxa mais adequada"
                            >
                                <MenuItem value={-1}>
                                    --
                                </MenuItem>
                                {productsDeliveryFee.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.descricao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>


                </DialogContent>

                <DialogActions>
                    <Button onClick={handleDelete} >Excluir</Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">Salvar</Button>
                </DialogActions>

            </Dialog>

        </div>
    );
}