import {useEffect } from 'react';



export default function BrowserNotification() {

    useEffect(()=>{

        if (!("Notification" in window)) {
            console.log("Este browser não suporta notificações de Desktop")
        }
        else if (Notification.permission === "granted") {
            
            console.log("Notificações liberadas")
        }
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) { 

              if (permission === "granted") {
                console.log("Notificações liberadas")
              }

            });
          }

    }, [])


    return null;
}


export function spawnNotification(titile, body, icon, onClick) {

    if (("Notification" in window) && Notification.permission === "granted"){
        var opcoes = {
            body: body,
            icon: icon
        }
        var n = new Notification(titile, opcoes);
        n.onclick = onClick
    }

   
  }