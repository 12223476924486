import { openDB } from 'idb';

const dbPromise = openDB('orders-store', 1, {
    upgrade(db) {
        db.createObjectStore('orders');
    },
});

export async function getKey(key) {
    return (await dbPromise).get('orders', key);
}
export async function setKey(key, val) {
    return (await dbPromise).put('orders', val, key);
}
export async function deleteKey(key) {
    return (await dbPromise).delete('orders', key);
}
export async function cleanDB() {
    return (await dbPromise).clear('orders');
}
export async function getAllKeys() {
    return (await dbPromise).getAllKeys('orders');
}

//https://www.npmjs.com/package/idb?activeTab=readme#examples

/*
Exemplo de utilização do banco de dados indexado

const onClickOpenSavedOrders = () => {

    //salva a string "cardList" em uma variável de chave Cards
    setKey("Cards", "cardList")

    //busca o conteúdo da chave Cards. É utilizado o then para adquirir o resultado no qual só pode ser manipudado dentro do mesmo
    getKey("Cards").then(result => { console.log(result) });
}

*/

const dbPromiseDespacho = openDB('despacho-store', 1, {
    upgrade(db) {
        db.createObjectStore('despacho');
    },
});

export async function getKeyDespacho(key) {
    return (await dbPromiseDespacho).get('despacho', key);
}
export async function setKeyDespacho(key, val) {
    return (await dbPromiseDespacho).put('despacho', val, key);
}