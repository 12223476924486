export const updateConnection = (currentState, action) =>  {
    
    return {
        ...currentState,
        connection: {
            ...currentState.connection,
            ...action.data
        },
    }
}

export const initialStateConnection = () => {

    return {
        isOnline: navigator.onLine,
        isConnectedToServer: true,
    }
}