export const toogleDialogDialogListAllOrder = (currentState, action) => {
  const { dialogListAllOrder } = action

  return {
    ...currentState,
    dialogListAllOrder: { ...dialogListAllOrder, open: dialogListAllOrder.open }
  }
}

export const initialDialogDialogListAllOrder = () => {
  return {
    open: false,
  }
}