import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';


const PREFIX = 'InfoCard';

const classes = {
   root: `${PREFIX}-root`,
   caption: `${PREFIX}-caption`,
   value: `${PREFIX}-value`
};

const StyledPaper = styled(Paper)((
   {
      theme
   }
) => ({
   [`&.${classes.root}`]: {
      minWidth: theme.spacing(8),
      minHeight: theme.spacing(7),
   },

   [`& .${classes.caption}`]: {
      opacity: "0.5",
   },

   [`& .${classes.value}`]: {
      fontWeight: "500",
   }
}));


const InfoCard = React.forwardRef(function InfoCard(props, ref) {

    const { caption, value, valueColor, variant, className } = props;
   
    return (
       <StyledPaper elevation={0} className={classes.root} ref={ref} >
          <Box pl={1} pt={0.5} pr={1} pb={0.5}>
                <Typography variant="caption" display="block" className={classes.caption} >{caption}</Typography>
                <Box color={valueColor} className={className} >
                <Typography variant={variant || "h4"} className={classes.value}>{value}</Typography>
                {props.children}
                </Box>
          </Box>
       </StyledPaper>
    );
});

export default InfoCard;
