import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, Button } from '@mui/material';
import Customer from './index'
import { useSnackbar } from 'notistack';
import {DialogTitle, DialogContent, DialogActions} from "../../CustomDialog/dialogComponents"
import AuthService from '../../../../service/AuthService';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { onlyDigits } from "../../../../util/formatter"

const PREFIX = 'DialogCustomer'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`&.${classes.scrollBar} .MuiDialog-paperScrollPaper`]: theme.scrollBar
}))

export default function DialogCustomer({ open, setOpen, selectedCustomer, confirmCustomer }) {
    const { enqueueSnackbar } = useSnackbar()
    const [customer, setCustomer] = useState({})

    useEffect(() => {
        setCustomer(selectedCustomer)
        console.log("selectedCustomer", selectedCustomer)
    }, [selectedCustomer])

    function handleSubmit() {
        console.log("Salvando pessoa", customer)

        let customerCopy = Object.assign({}, customer)
        customerCopy.cpfCnpj = onlyDigits(customer.cpfCnpj)
        customerCopy.cep = onlyDigits(customer.cep)
        customerCopy.fone = onlyDigits(customer.fone)
        customerCopy.tipoCliente = true

        let service = new AuthService()
        service.post("/api-v2/pessoa", customerCopy)
        .then(pessoaSalva => {
            setCustomer(pessoaSalva)
            setOpen(false)
            enqueueSnackbar("Salvo com sucesso", {variant: 'success'});
            confirmCustomer(pessoaSalva)

        }).catch(err => {
            console.log(err)
            enqueueSnackbar("Erro ao salvar cliente", {variant: 'error'});
        })
    }

    const handleClose = (event, reason) =>{
        if (reason !== 'backdropClick') {
            setOpen(false)
        }
    }

    return (
        <div>

            <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="md" className={classes.scrollBar}>

                <DialogTitle onClose={handleClose} >
                    Cadastro do cliente
                </DialogTitle>

                <ValidatorForm
                    onSubmit={handleSubmit}
                    instantValidate={false}
                    onError={errors => console.log(errors)}>

                    <DialogContent>
                        <Customer customer={customer} setCustomer={setCustomer} />
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary">Salvar</Button>
                    </DialogActions>

                </ValidatorForm>

            </StyledDialog>

        </div>
    )
}