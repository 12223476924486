import React, { useState, createContext, useContext } from 'react'
import ConfirmDialog from './dialogs/ConfirmDialog'
import PromptDialog from './dialogs/PromptDialog'
import { TefElginDialog } from '../../pages/layout-components/Payment/Tef'

const PdvContext = createContext()

export default function PdvProvider({ children }) {
    const [dialog, setDialog] = useState()

    /**
     * Function to create a Function with multiple prompt messages
     * @function
     * @param {Number} count - number of times to prompt
     */
    function confirmDialog(options) {
        return new Promise((resolve, reject) => {

            function close(value) {
                value ? resolve() : reject()
                setDialog(null)
            }

            setDialog(<ConfirmDialog {...options} open={true} onClose={close} />)
        })
    }

    function promptDialog(options) {
        return new Promise((resolve, reject) => {

            function close(value) {
                value ? resolve(value) : reject()
                setDialog(null)
            }

            setDialog(<PromptDialog {...options} open={true} onClose={close} />)
        })
    }

    function tefElginDialog(options) {
        return new Promise((resolve, reject) => {
            function close() {
                resolve()
                setDialog(null)
            }

            setDialog(<TefElginDialog {...options} open={true} onClose={close} />)
        })
    }

    return (
        <PdvContext.Provider value={{ confirmDialog, promptDialog, tefElginDialog }} >
            {children}
            {dialog}
        </PdvContext.Provider>
    )
}

/**
 * @description
 * Provider do PDV On
 *
 * @returns {{
 *     confirmDialog: function({
 *         content: string,
 *         maxWidth: string,
 *         cancelLabel: string,
 *         confirmLabel: string,
 *     }): Promise,
 *     promptDialog: function({
 *         content: string,
 *         footerContent: string,
 *         maxWidth: string,
 *         inputLabel: string,
 *         cancelLabel: string,
 *         confirmLabel: string,
 *     }): Promise,
 *     tefElginDialog: function({
 *         content: string,
 *         maxWidth: string,
 *         cancelLabel: string,
 *         confirmLabel: string,
 *     }): Promise
 * }} provider
 */
export const usePdvContext = () => useContext(PdvContext)