import React from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import InfoCard from '../../layout-components/InfoCard'
import IntegrationAvatar from '../../layout-components/Board/IntegrationAvatar';
import { currencyFormat, dateFormatedToNewFormat } from '../../../util/formatter';
import { groupItemsByName, totalDeliveryFee, totalOrder } from '../../../util/order/orderUtil';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DetalhePedido from '../../deliveryman/components/DetalhePedido';
import ListItemsGrouped from '../../layout-components/ListItemsGrouped';
import { Fragment } from 'react';

export default function ListaPedidosPorHorario(props) {
  const { classes, groupedHour, openAccordion } = props
  const [openResumeOrder, setOpenResumeOrder] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState({});
  const [openAccordionNext, setOpenAccordionNext] = React.useState(openAccordion); //Recebe o estado do accordion pai(accordion).
  const [openAccordionLast, setOpenAccordionLast] = React.useState(openAccordion); //Recebe o estado do accordion pai(accordionNext).

  const showResumeOrder = (order) => {
    setOpenResumeOrder(true)
    setSelectedOrder(order)
  }

  const calculaValorTotal = (pedidoIntegracao) => {
    var valorTotal = 0;
    pedidoIntegracao.map(pedido => {
      if (pedido.iFoodOrder) {
        valorTotal += totalOrder(pedido.iFoodOrder);
      }
      return null;
    })
    return valorTotal;
  }
  const toggleAccordionNext = () => {
    setOpenAccordionNext((prev) => !prev) //Altera o estado do accordionNext
  }
  const toggleAccordionLast = () => {
    setOpenAccordionLast((prev) => !prev) //Altera o estado do accordionLast
  }

  return (
    <React.Fragment>
      <DetalhePedido open={openResumeOrder} setOpen={setOpenResumeOrder} order={selectedOrder} />

      <Accordion expanded={openAccordionNext}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAccordionNext}>
          <TableContainer style={{ width: '100%' }} className={classes.scrollBar} >
            <Table size='small'>
              <TableBody>
                <TableRow className={classes.useRowStyles}>
                  <TableCell align='left'>
                    <Typography variant="h5" display="block">
                      {groupedHour.hour}h
                    </Typography>
                  </TableCell>

                  <TableCell align='right'>
                    <InfoCard variant='h6'
                      value={`${groupedHour.orderList ? groupedHour.orderList.length : '0'} pedido(s)`}
                      valueColor="primary" />
                  </TableCell>

                  <TableCell align='right'>
                    <InfoCard variant='h6'
                      value={`Total ${currencyFormat(calculaValorTotal(groupedHour.orderList))}`}
                      valueColor="primary" />
                  </TableCell>
                </TableRow>
              </TableBody>

            </Table>
          </TableContainer>
        </AccordionSummary>

        <AccordionDetails>
          <Box style={{ width: '100%' }}>
            {groupedHour.orderList.map((order, index) => (
              <Accordion key={index} expanded={openAccordionLast}>

                <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAccordionLast}>
                  <TableContainer style={{ width: '100%' }} className={classes.scrollBar} >
                    <Table size='small' stickyHeader>
                      <TableBody>
                        <Row classes={classes} order={order} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionSummary>

                <AccordionDetails>
                  <ListItemsGrouped groupedItems={groupItemsByName(order)} opened={true} />
                </AccordionDetails>

              </Accordion>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )

  function Row({ order, classes }) {
    return (
      <Fragment>
        <TableRow className={classes.useRowStyles}>

          <TableCell align='left'>
            <Typography variant="h6" display="block">
              {`${dateFormatedToNewFormat(order.dataPedido, 'ISO', 'HH:mm')}h`}
            </Typography>
          </TableCell>

          <TableCell colSpan={4} align='left' component='th' scope='row'>
            <Typography variant="h6" display="block">
              {order.iFoodOrder?.customer?.name}
            </Typography>
          </TableCell>

        </TableRow>

        <TableRow className={classes.useRowStyles}>
          <TableCell align='left'>
            <IntegrationAvatar pedido={order} />
          </TableCell>

          <TableCell align='left'>
            {`#${order.id}`}
            <br />
            <Chip variant="outlined" size="small" label={order.sequencial} />
          </TableCell>

          <TableCell align='right'>
            <InfoCard variant='h6'
              caption='Tx. Entrega'
              value={currencyFormat(totalDeliveryFee(order.iFoodOrder))}
              valueColor="primary" />

          </TableCell>

          <TableCell align='right'>
            <InfoCard variant='h6'
              caption='Total'
              value={currencyFormat(totalOrder(order.iFoodOrder))}
              valueColor="primary" />
          </TableCell>

          <TableCell align='center'>
            <Box displayPrint="none">
              <IconButton size='small' onClick={() => showResumeOrder(order)} >
                <VisibilityIcon />
              </IconButton>
            </Box>
          </TableCell>
        </TableRow>
      </Fragment >
    )
  }
}
