import React from 'react';
import Board from "../layout-components/Board/Board"
import { useEffect } from 'react';
import ReactGA from "react-ga4";

function Main() {
  useEffect(() => {//informa ao analytics que o usuário caiu na pagina de delivery
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "delivery" });
  }, [])
  return (
    <React.Fragment >
      <Board key='Board' /> 
    </React.Fragment>
  );
}

export default Main;
