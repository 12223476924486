export const updateGlocalCache = (currentState, action) => {
  const globalCacheAction = action.globalCache
  const { globalCache } = currentState

  return {
    ...currentState,
    globalCache: { ...globalCache, ...globalCacheAction }
  }
}

export const initialGlobalCache = () => {
  return {
    productMenu: null,
    groups: null,
    listDeliveryFee: null,
    listDeliveryMan: null,
    infoIdentificador: {
      MODELO_IDENTIFICADOR_PRINCIPAL: "",
      MODELO_IDENTIFICADOR_SECUNDARIO: "",
      LABEL_IDENTIFICADOR_PRINCIPAL: "",
      LABEL_IDENTIFICADOR_SECUNDARIO: ""
    },
    focusOnInputIdentificadorPrincipal: false
  }
}