
export const closeOrderDialog = (currentState, action) =>  {
    let { closeOrder } = action
    return {
        ...currentState, 
        closeOrder: { ...closeOrder, open: closeOrder.open } 
    }
}

export const initialStateCloseOrderDialog = () =>  {

    return {
        open: false,
        order: null
    }
}