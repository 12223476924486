import React, { Fragment, useState } from 'react';
import { ListItemText, List, ListItem, Dialog, Button, Accordion, AccordionSummary, AccordionDetails, TableBody, TableCell, TableRow } from '@mui/material';
import { DialogContent, DialogTitle } from '../../CustomDialog/dialogComponents';
import AuthService from '../../../../service/AuthService';
import { currencyFormat } from '../../../../util/formatter';
import { useSnackbar } from 'notistack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DialogCustomerValues({ selectedCustomer }) {
    const { enqueueSnackbar } = useSnackbar()
    const [contasPendentes, setContasPendentes] = useState([])
    const [openContasPendentes, setOpenContasPendentes] = useState(false)
    const [totalPendente, setTotalPendente] = useState(0)
    const [expanded, setExpanded] = useState(false)

    const buscarContasPendentes = async () => {
        const service = new AuthService()
        await service
            .get(`/api-v2/pedidoIntegracao/contasPendentes/${selectedCustomer.id}`)
            .then(resp => {

                if (resp && resp.length === 0) {
                    setContasPendentes([])
                    setTotalPendente(0)
                } else {
                    setContasPendentes(resp)
                    const total = resp.reduce((acc, pedido) => acc + pedido.valor, 0)
                    setTotalPendente(total)
                }
            })
            .catch(err => {
                console.error(err)
                enqueueSnackbar("Erro ao buscar contas pendentes", { variant: 'error' })
            })
    }

    const handleOpenContasPendentes = async () => {
        setOpenContasPendentes(!openContasPendentes)
        if (!openContasPendentes) {
            await buscarContasPendentes()
        } else {
            setExpanded(false)
        }
    }

    const handleChangeExpansion = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <Button color="inherit" onClick={handleOpenContasPendentes} startIcon={<AttachMoneyIcon />}>
                Valores pendentes
            </Button>

            <Dialog
                open={openContasPendentes}
                onClose={handleOpenContasPendentes}
                fullWidth maxWidth="sm"
            >
                <DialogTitle onClose={handleOpenContasPendentes}>
                    Valores pendentes de {selectedCustomer.nome}
                </DialogTitle>

                <DialogContent>
                    {contasPendentes.length > 0 ? (
                        <Accordion expanded={expanded} onChange={handleChangeExpansion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <ListItemText primary={`Total pendente: ${currencyFormat(totalPendente)}`} />
                            </AccordionSummary>

                            <AccordionDetails>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" align="left"><strong>Descrição</strong></TableCell>
                                        <TableCell component="th" scope="row" align="center"><strong>Valor</strong></TableCell>
                                        <TableCell component="th" scope="row" align="right"><strong>Vencimento</strong></TableCell>
                                    </TableRow>

                                    {contasPendentes.map((pedido, index) => (
                                        <Fragment key={index}>
                                            <TableRow>
                                                <TableCell align="left">{pedido.descricao}</TableCell>
                                                <TableCell align="right" sx={{ width: '100px' }}>{currencyFormat(pedido.valor)}</TableCell>
                                                <TableCell align="center">{pedido.dataVencimento}</TableCell>
                                            </TableRow>
                                        </Fragment>
                                    ))}
                                </TableBody>
                            </AccordionDetails>

                        </Accordion>
                    ) : (
                            <List>
                                <ListItem>
                                    <ListItemText primary="Nenhuma conta pendente encontrada para este cliente" />
                                </ListItem>
                            </List>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}