import React from 'react';
import InfoCard from "../layout-components/InfoCard"
import { Box, Grid, Hidden, Rating, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { convertBoarToPedidoList } from "../../util/board/BoardUtil"
import { currencyFormat } from "../../util/formatter"
import { totalOrder } from '../../util/order/orderUtil';
import { AGENDADO_COLOR } from '../../theme/palette';
import StarIcon from '@mui/icons-material/Star';

function MainTopContent() {

  const boardData = useSelector(state => state.boardState.board)
  const [stateValues, setStateValues] = React.useState({ })

  React.useEffect(() => {
    const orders = boardData && convertBoarToPedidoList(boardData)
    let numberOfOrders = 0
    let numberOfOrdersScheduled = 0
    let numberOfOrdersCanceled = 0
    let totalAmout = 0.0
      let totalPreparationTime = 0.0;
      let countPreparationTime = 0;



    orders.map(order => {
        if (!order.dataCancelamento){
            numberOfOrders += 1;

            if (order.pedidoAgendado){
                numberOfOrdersScheduled  += 1;
            }

            let totalThisOrder = totalOrder(order?.iFoodOrder)
            totalAmout += !totalThisOrder.isNaN ? totalThisOrder : 0.0;

            try {
                if (["ENTREGUE", "ENVIADO", "AGUARDANDO_RETIRADA"].indexOf(order.situacao) >= 0) {
                    //se tiver essas 2 datas
                    if (order.dataPedido && (order.dataEnvio || order.dataEntrega)
                        && !order.iFoodOrder.scheduled) {

                        var data01 = new Date(order.dataPedido);
                        // testa 2 datas pois ao mover pra aguardnado retirada o sistema alimentava a data incorreta (ou seja a data de entrega)
                        var data02 = new Date(order.dataEnvio || order.dataEntrega);

                        // Calcula a diferença em milissegundos entre as duas datas
                        var diferencaEmMilissegundos = Math.abs(data02 - data01);

                        // Converte a diferença para minutos
                        var diferencaEmMinutos = Math.floor(diferencaEmMilissegundos / (1000 * 60));

                        //console.log('Diferença em minutos:', diferencaEmMinutos);
                        totalPreparationTime += diferencaEmMinutos;

                        countPreparationTime += 1;
                    }
                }
            } catch (err) {
                console.error("Erro ao calcular tempo de preparo", err)
            }


        } else {
            numberOfOrdersCanceled += 1;
        }
        return null;
    })

      let totalAmountPreparationTime = countPreparationTime > 0 ? totalPreparationTime / countPreparationTime : 0.0;

      // Calcula as horas e minutos
      let horas = Math.floor(totalAmountPreparationTime / 60);
      let minutos = Math.floor(totalAmountPreparationTime % 60);
      let labelTotalAmountPreparationTime = (horas > 0 ? `${horas}h` : "") + (minutos > 0 ? ` ${minutos}m` : "")
      let starAmountPreparation = totalAmountPreparationTime == 0 ? 0 : (5 - ((totalAmountPreparationTime - 30) / 12));

    let ticketAvg = numberOfOrders === 0.0 ? 0.0 : (totalAmout / numberOfOrders)

    setStateValues({...stateValues, 
        numberOfOrders,
        numberOfOrdersScheduled,
        numberOfOrdersCanceled,
        totalAmout, 
        ticketAvg,
        labelTotalAmountPreparationTime,
        starAmountPreparation
    })
    // eslint-disable-next-line
  }, [boardData])

  return (
      <Hidden mdDown>
       <Grid container spacing={1}>
          <Grid item>
              <InfoCard caption="Pedidos" value={stateValues.numberOfOrders} valueColor="primary"/>
          </Grid>

          <Grid item>
              <InfoCard caption="Agendados" value={stateValues.numberOfOrdersScheduled} valueColor={AGENDADO_COLOR}/>
          </Grid>

          <Grid item>
              <InfoCard caption="Cancelados" value={stateValues.numberOfOrdersCanceled} valueColor="error.main"/>
          </Grid>

          <Grid item>
              <InfoCard caption="Total" value={currencyFormat(stateValues.totalAmout)}   />
          </Grid>

          <Grid item>
              <InfoCard caption="Ticket médio" value={currencyFormat(stateValues.ticketAvg)} valueColor="info.main"/>
          </Grid>

              <Grid item>
                  <InfoCard caption="Tempo méd. preparo" valueColor="primary" >
                      <Tooltip title={
                          <React.Fragment>
                              <Typography color="inherit" variant="caption" display="block">Legenda:</Typography>
                              <Typography color="inherit" variant="caption" display="block">5 ⭐ até 30 min</Typography>
                              <Typography color="inherit" variant="caption" display="block">3 ⭐ até 60 min</Typography>
                              <Typography color="inherit" variant="caption" display="block">0 ⭐ mais de 90 min</Typography>
                              <Typography color="inherit" variant="caption" display="block"><strong>👉 Tente manter entre 30 e 60 min 👈</strong></Typography>
                              <Typography color="inherit" variant="caption" display="block">❓ O tempo médio é obtido pela diferença entre a data do pedido e a mudança para 'Aguardando retirada' ou 'Enviados'</Typography>
                          </React.Fragment>
                      }>
                          <Box component="div" sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-start' }}>
                              <Rating name="read-only" value={stateValues.starAmountPreparation}
                                  readOnly size="small" precision={0.1} emptyIcon={<StarIcon style={{ opacity: 0.55 }}
                                      fontSize="inherit" />} />
                              <Box sx={{ ml: 1 }} component="span">{stateValues.labelTotalAmountPreparationTime}</Box>
                          </Box>
                      </Tooltip>
                  </InfoCard>
              </Grid>

      </Grid>
      </Hidden>
  );
}

export default MainTopContent;
