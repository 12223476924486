/*
Função utilizada no Redux para atualizar o status global do caixa

o valor inicial é dado como não definido e pode variar entre 

OPEN, CLOSE e DISABLED

*/

//Recebe o estado atual do caixa e o estado futuro 
export const updateMoneyBoxStatus = (currentState, action) => {
  let { statusMoneyBox } = action

  //retorna atualizado o estado do caixa para o valor recebido em action.status
  return {
    ...currentState,
    statusMoneyBox: { ...statusMoneyBox, status: statusMoneyBox.status }
  }
}

//Valor com que a definição do caixa é iniciada
export const initialMoneyBoxStatus = () => {
  return {
    status: 'NÃO DEFINIDO',
  }
}