
export const tooglePaymentDialog = (currentState, action) =>  {
    let { paymentOrder } = action

    return {
        ...currentState, 
        paymentOrder: { ...paymentOrder, open: paymentOrder.open } 
    }
}

export const initialPaymentDialog = () =>  {

    return {
        open: false,
        card: null
    }
}