import { groupItemsByName } from "../util/order/orderUtil"

export const listItemGroupedDialog = (currentState, action) => {

    let { listItemsGrouped } = action

    const order = listItemsGrouped.order
    const groupedItems = groupItemsByName(order)

    return {
        ...currentState,
        listItemsGrouped: { ...listItemsGrouped, open: listItemsGrouped.open, groupedItems }
    }
}

export const initialStateListItemGrouped = () => {

    return {
        open: false,
        order: null,
        groupedItems: null,
    }
}