export const toogleDetailOrderDialogOffline = (currentState, action) =>  {
    let { detailOrderOffline } = action
  
    return {
        ...currentState, 
        detailOrderOffline: { ...detailOrderOffline } 
    }
}

export const initialStateDialogDetailOffline = () => {

    return {
        open: false,
        order: null,
        groupedItems: null,
    }
}