import { useSelector } from 'react-redux'
import AuthService from '../../service/AuthService'

export const extractErrorMessage = function (error, defaultErrorMessage) {
    return new Promise(function (resolve, reject) {

        if (error.response === undefined) {
            resolve(defaultErrorMessage)
        } else {
            error.response.json().then(elem => {
                resolve(elem.errorMessage)
            });
        }

    })
}

/**
 * Hook para verificar conexão
 * 
 * @return {boolean} Retorna `true` se estiver conectado na internet e também no servidor
 */
export const useConnectionStatus = () => {
    const { isOnline, isConnectedToServer } = useSelector(state => state.connection)

    return isOnline && isConnectedToServer
}

export const autoPaginationRequest = ({
    max = 100,
    startDate = '',
    endDate = '',
    endpoint = '',
    totalCount = 0,
    interval = 500,
}) => new Promise((resolve, reject) => {
    try {
        let promiseArray = []
        const service = new AuthService()

        function* loopGenerator() {
            let count = 0

            if (!totalCount) {
                return
            }

            while (count < totalCount) {
                // esse count e firstCount é para conseguir sempre da onde parou a pesquisa para a paginação
                const firstCount = count

                if (count < totalCount) {
                    count += max
                }

                if (count > totalCount) {
                    count = totalCount
                }

                yield service.get(endpoint + `?dataInicial=${startDate}&dataFinal=${endDate}&first=${firstCount}&max=${max}&totalCount=${totalCount}`)
            }
        }

        async function handleResult(arr) {
            const { done, value } = arr.next()

            if (done) {
                resolve(promiseArray)
                return
            }

            const result = await value

            promiseArray.push(result)
            setTimeout(() => handleResult(arr), interval)
        }

        const generatedArray = loopGenerator()

        handleResult(generatedArray)
    } catch (err) {
        reject(err)
    }
})