import { Button, Dialog, DialogActions } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../../service/AuthService';
import { extractErrorMessage } from '../../../util/http/HttpUtil';
import { DialogTitle, DialogContent } from "../CustomDialog/dialogComponents"
import ComponentAdditionalInformation from './Component';
import DoneIcon from '@mui/icons-material/Done';

export default function AdditionalInformation() {
    const open = useSelector(state => state.additionalInformation.open)
    const order = useSelector(state => state.additionalInformation.order)
    const dispatch = useDispatch()
    const [info, setInfo] = useState({})
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar()
    const [saving, setSaving] = useState(false)

    const onClickClose = () => {
        onConstructor()

        dispatch({ type: 'ADD_UPDATE_ADDITIONAL_INFORMATION_ON_ORDER', additionalInformation: { open: false, order: undefined } })
    };

    function onConstructor() {
        setInfo({})
    }

    const onClickFinalizar = () => {
        setSaving(true)

        let orderCopy = Object.assign({}, order)

        orderCopy.iFoodOrder.observations = info.observations
        orderCopy.complementoIdentificador = info.complementoIdentificador
        orderCopy.quantidadePessoas = info.quantidadePessoas

        service.post('/api-v2/pedidoIntegracao', orderCopy)
            .then((respOrder) => {
                enqueueSnackbar('Informações adicionais salvas.', { variant: 'success' })

                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder } })

                onClickClose()

                setSaving(false)

            }).catch(err => {
                setSaving(false)

                extractErrorMessage(err, "Erro ao salvar o pedido")
                    .then(msg => {
                        enqueueSnackbar('Não foi possível salvar o pedido - Detalhes: ' + msg, { variant: "error" });
                    })
                console.error('ERRO_IMPRIMIR_PEDIDO: ', err)
            })
    }

    useEffect(() => {
        if (open === true) {
            setInfo({ observations: order.iFoodOrder.observations, complementoIdentificador: order.complementoIdentificador, quantidadePessoas: order.quantidadePessoas })
        }
        // eslint-disable-next-line
    }, [open])

    return (
        <Dialog
            maxWidth='xs'
            fullWidth
            open={open}
            onClose={onClickClose}>

            <DialogTitle onClose={onClickClose}>
                {`Informações adicionais`}
            </DialogTitle>

            <DialogContent>
                <ComponentAdditionalInformation info={info} setInfo={setInfo} />

            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClickFinalizar}
                    variant='contained'
                    disabled={saving}
                    color='primary'
                    endIcon={<DoneIcon />}>
                    {saving ? 'Salvando...' : 'Finalizar'}
                </Button>
            </DialogActions>
        </Dialog >
    );
}