export const updatePaymentScreens = (currentState, action) => {
    const { paymentScreensStatus } = action

    return {
        ...currentState,
        paymentScreensStatus: {
            ...currentState.paymentScreensStatus,
            ...paymentScreensStatus,
        },
    }
}

export const initialStatePaymentScreens = () => {

    return {
        payment: 'CLOSED',
        import: 'CLOSED',
        fiscal: 'CLOSED',
        tef: 'CLOSED',
    }
}