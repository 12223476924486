export const toogleDialogJoinOrders = (currentState, action) => {
  const { dialogJoinOrders } = action

  return {
    ...currentState,
    dialogJoinOrders: { ...dialogJoinOrders, open: dialogJoinOrders.open }
  }
}

export const initialDialogJoinOrders = () => {
  return {
    open: false,
    data: null,
    origin: null,
    destiny: null
  }
}