
export const toogleAdditionalInformation = (currentState, action) =>  {
    let { additionalInformation } = action

    return {
        ...currentState, 
        additionalInformation: { ...additionalInformation, open: additionalInformation.open , order: additionalInformation.order} 
    }
}

export const initialAdditionaInformation = () =>  {

    return {
        open: false,
    }
}