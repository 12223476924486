import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { DialogTitle } from "./../../layout-components/CustomDialog/dialogComponents"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currencyFormat, dateFormat, formatPhoneBR } from '../../../util/formatter';
import DetalhePedido from './DetalhePedido';
import AuthService from '../../../service/AuthService';
import SelectDeliveryman from '../../layout-components/NewOrder/SelectDeliveryman';
import { useSnackbar } from 'notistack';
import { totalOrder, totalDeliveryFee, isPrepaidOrder, isTakeout } from '../../../../src/util/order/orderUtil';
import InfoCard from '../../layout-components/InfoCard';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IntegrationAvatar from '../../layout-components/Board/IntegrationAvatar';
import { useReactToPrint } from 'react-to-print';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CustomDateFilter from '../../../component/generics/customDateFilter';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search'
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { TAKEOUT_COLOR } from '../../../theme/palette';
import TypeOrderAvatar from '../../layout-components/Board/typeOrderAvatar';
import { PAGE_STYLE_PRINT } from '../../../util/printUtils';
import SettingsIcon from '@mui/icons-material/Settings';
import { getDefaultPeriodFormated } from '../../../helpers/dateHelper';
import { formatFullAddressDelivery } from '../../../util/customer/helper';
import { SITUACOES } from '../../../util/board/BoardUtil';
import { lime, red } from '@mui/material/colors';

const PREFIX = 'DeliveryManList'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    headerStyle: `${PREFIX}-headerStyle`,
    deliveryFee: `${PREFIX}-deliveryFee`,
    neighborhoodStyle: `${PREFIX}-neighborhoodStyle`,
    idStyle: `${PREFIX}-idStyle`,
    cell: `${PREFIX}-cell`
}

const AccordionStyled = styled(Accordion)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.headerStyle}`]: {
        backgroundColor: theme.palette.personal.boardCard
    },

    [`& .${classes.deliveryFee}`]: {
        color: theme.palette.mode === 'dark' ? lime[700] : lime[700],
    },

    [`& .${classes.neighborhoodStyle}`]: {
        color: theme.palette.mode === 'dark' ? red[200] : red[800],
    },

    [`& .${classes.idStyle}`]: {
        fontSize: "0.9em",
        color: theme.palette.mode === 'dark' ? "#f5f5f5" : "#9e9e9e",
    },
}))

const StyledTableCell = styled(TableCell)(() => ({
    padding: '5px'
}))

const SAVED_SETTINGS_DELIVERYMAN = "_saved_settings_deliveryman_"
const COLUMNS_HEADER = [
    { nameAttribute: 'tipoImportacao', description: 'Origem', render: true },
    { nameAttribute: 'id', description: '#', render: true },
    { nameAttribute: 'shortReference', description: 'Cód. Plat.', render: false },
    { nameAttribute: 'tipo', description: 'Tipo', render: true },
    { nameAttribute: 'sequencial', description: 'Seq.', render: true },
    { nameAttribute: 'dataPedido', description: 'Data', render: true },
    { nameAttribute: 'name', description: 'Cliente', render: true },
    { nameAttribute: 'phone', description: 'Telefone', render: false },
    { nameAttribute: 'neighborhood', description: 'Bairro', render: true },
    { nameAttribute: 'deliveryFee', description: 'Tx. Entr.', render: true },
    { nameAttribute: 'prepaid', description: 'Pagto online', render: true },
    { nameAttribute: 'total', description: 'Total', render: true },
    { nameAttribute: 'endereco', description: 'Endereço', render: false },
    { nameAttribute: 'observacoes', description: 'Observações', render: false },
    { nameAttribute: 'situacao', description: 'Status Pedido', render: false },
];

function loadColumns() {
    const localStorageColumns = localStorage.getItem(SAVED_SETTINGS_DELIVERYMAN)

    if (!localStorageColumns) {
        return { columnsHeader: COLUMNS_HEADER }
    }

    return JSON.parse(localStorageColumns)
}

export default function DeliveryManList() {
    const service = new AuthService();
    const { enqueueSnackbar } = useSnackbar();
    const [orderList, setOrderList] = React.useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(getDefaultPeriodFormated())
    const [finding, setFinding] = React.useState(false)
    const [showTakeout, setShowTakeout] = useState(true)
    const [anchorElFiltros, setAnchorElFiltros] = useState(null);
    const savedFilters = JSON.parse(localStorage.getItem(SAVED_SETTINGS_DELIVERYMAN))
    const [settings, setSettings] = useState();

    useEffect(() => {
        let settingsCopy = Object.assign({}, settings)
        settingsCopy.columnsHeader = []

        // if (savedFilters) {
        //     settingsCopy.columnsHeader = savedFilters.columnsHeader
        // }

        COLUMNS_HEADER.map((columnHeader) => {
            let render = columnHeader.render

            if (savedFilters) {
                const columnsSaved = savedFilters.columnsHeader?.filter((e) => e.nameAttribute === columnHeader.nameAttribute)

                if (columnsSaved.length > 0) {
                    render = columnsSaved[0].render
                }
            }

            settingsCopy.columnsHeader.push({ ...columnHeader, render })
            return null;
        })

        setSettings(settingsCopy)

        if (selectedPeriod) {
            laodOrderByDate();
        }
        // eslint-disable-next-line
    }, [])

    const handleChangePeriodo = (period) => {
        setSelectedPeriod(period)
    };

    const onClickSearch = () => {
        if (!selectedPeriod) {
            enqueueSnackbar(`Nenhum período informado.`, { variant: "error" });
            return
        }

        laodOrderByDate()
    }

    const handleClickTakeout = () => {
        setShowTakeout(!showTakeout)
    }

    function laodOrderByDate() {

        if (!selectedPeriod) {
            return
        }

        setFinding(true)

        service.get(`/api-v2/pedidoIntegracao/listPorEntregador?dtInicio=${selectedPeriod?.startDate}&dtFinal=${selectedPeriod?.endDate}&showTakeout=${showTakeout}`)
            .then(resp => {
                setOrderList(resp);
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                setFinding(false)
            })
    }

    const updatePedidoOnList = (pedidoIntegracaoAlterado) => {

        const orderListCopy = Object.assign([], orderList)

        let indexNovogrupo = -1;

        //remover elemento antigo
        orderListCopy.map((group, index) => {
            if (group.pessoa.id === pedidoIntegracaoAlterado.pessoaEntregador.id) {
                indexNovogrupo = index;
            }

            let indexPedido = group.pedidos.findIndex(pedido => pedido.id === pedidoIntegracaoAlterado.id)
            if (indexPedido < 0)
                return null

            group.pedidos.splice(indexPedido, 1);

            return null;
        })

        //adiciona no grupo correto
        if (indexNovogrupo >= 0) {
            orderListCopy[indexNovogrupo].pedidos.push(pedidoIntegracaoAlterado)
        } else {
            orderListCopy.push({
                pessoa: pedidoIntegracaoAlterado.pessoaEntregador,
                pedidos: [pedidoIntegracaoAlterado]
            })
        }

        //console.log("orderListCopy", orderListCopy)
        //console.log("indexNovogrupo", indexNovogrupo)
        setOrderList(orderListCopy)

        //searchOrder()
    }

    const onClickAplicar = () => {

        console.log('onClickAplicar', settings)
        localStorage.setItem(SAVED_SETTINGS_DELIVERYMAN, JSON.stringify(settings))

        // atualizarTela()

        setAnchorElFiltros(null);
    }

    const handleClose = () => {
        setAnchorElFiltros(null);
    };

    const handleChangeRenderColumn = (event, column) => {
        const settingsCopy = Object.assign({}, settings)

        let index = settingsCopy.columnsHeader.findIndex((e) => e.nameAttribute === column.nameAttribute);

        column.render = event.target.checked
        settingsCopy.columnsHeader[index] = column

        setSettings(settingsCopy)
    }

    return (
        <>
            <Box paddingBottom={2}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <Grid item xs={12} md={4}>
                        <CustomDateFilter onChange={handleChangePeriodo} key={10} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Checkbox onClick={handleClickTakeout} checked={showTakeout} />
                        Mostrar pedidos para retirar
                    </Grid>


                    <Grid item xs={0} md={2} />

                    <Grid item xs={12} md={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Button
                                fullWidth
                                size="small"
                                color="primary"
                                disabled={finding}
                                startIcon={<SearchIcon />}
                                onClick={() => onClickSearch()}
                                variant='outlined'> {finding ? 'Buscando...' : 'Buscar'}
                            </Button>

                            <IconButton
                                onClick={event => setAnchorElFiltros(event.currentTarget)}
                                size="medium"
                                edge="end"
                                color="inherit">
                                <SettingsIcon />
                            </IconButton>

                            <Menu
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                anchorEl={anchorElFiltros}
                                open={Boolean(anchorElFiltros)}
                                onClose={event => setAnchorElFiltros(null)}
                            >

                                <Box>
                                    <DialogTitle onClose={handleClose} />
                                </Box>

                                <Box p={1} pb={2} pl={2} pr={2}>

                                    <FormControl key='mostrarColunas' component="fieldset" margin="dense">
                                        <FormLabel component="legend">Mostrar as colunas</FormLabel>
                                        <FormGroup>
                                            {settings?.columnsHeader?.map((column, index) => (
                                                <MenuItem disableRipple key={index} >
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            name={column.nameAttribute}
                                                            checked={column.render}
                                                            onChange={(event) => { handleChangeRenderColumn(event, column) }}
                                                            size='small' />}

                                                        label={<Typography variant='button'>{column.description}</Typography>}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                </Box>

                                <Box p={1} pb={2} pl={2} pr={2}>
                                    <Button
                                        color="grey"
                                        variant="outlined"
                                        onClick={onClickAplicar}
                                        fullWidth>Aplicar
                                    </Button>
                                </Box>
                            </Menu>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={"10px"}>
                {orderList.map((pedidoIntegracaoPorEntregador, index) => (
                    <React.Fragment key={index}>
                        <DeliveryManExpandablePanel searchOrder={onClickSearch} updatePedidoOnList={updatePedidoOnList} pedidoIntegracao={pedidoIntegracaoPorEntregador} />
                    </React.Fragment>
                ))}
            </Box>


        </>
    );
}

function DeliveryManExpandablePanel({ pedidoIntegracao, searchOrder, updatePedidoOnList }) {
    const [selectedOrder, setSelectedOrder] = React.useState({});
    const [openResumeOrder, setOpenResumeOrder] = React.useState(false);
    const [openDialogEntregador, setOpenDialogEntregador] = React.useState(false);

    const savedFilters = loadColumns()

    const [gridPedidos, setGridPedidos] = React.useState({
        pedidos: pedidoIntegracao.pedidos || [],
        sortBy: null,
        sortDirection: null
    });

    useEffect(() => {
        setGridPedidos({
            pedidos: pedidoIntegracao.pedidos || [],
            sortBy: null,
            sortDirection: null
        })
    }, [pedidoIntegracao])

    const onConfirmSelectDialog = (pedidoIntegracaoAlterado) => {

        updatePedidoOnList(pedidoIntegracaoAlterado)

        //searchOrder()
    }

    const calculaValorTotal = (pedidoIntegracao) => {
        var valorTotal = 0;
        pedidoIntegracao.pedidos.map(pedido => {
            if (pedido.iFoodOrder) {
                valorTotal += totalOrder(pedido.iFoodOrder);
            }
            return null;
        })

        return valorTotal;
    }

    const calculaValorTotalEntrega = (pedidoIntegracao) => {
        var valorTotal = 0.0;
        pedidoIntegracao.pedidos.map(pedido => {
            if (pedido.iFoodOrder) {
                valorTotal += totalDeliveryFee(pedido.iFoodOrder);
            }
            return null;
        })

        return valorTotal;
    }

    const checktTotalPaymentOnline = (pedidoIntegracao) => {
        let valorTotal = 0.0

        pedidoIntegracao.pedidos.map(pedidos => {
            if (pedidos.iFoodOrder && pedidos.iFoodOrder.payments) {
                pedidos.iFoodOrder.payments.map(payment => {
                    if (payment.prepaid) {
                        valorTotal += payment.value
                    }
                    return null;
                })
            }
            return null;
        })

        return valorTotal
    }

    const checkTotalDescontoOnline = () => { //Checa os valores dos descontos gerados pela plataforma online para mostrar na tela
        let valorTotal = 0.0

        pedidoIntegracao.pedidos.map(pedidos => {
            if (pedidos.iFoodOrder?.benefits) {
                pedidos.iFoodOrder.benefits.map(benefits => {
                    if (benefits.sponsorshipValues.IFOOD > 0) {
                        valorTotal += benefits.sponsorshipValues.IFOOD
                    }
                    return null;
                })
            }
            return null;
        })

        return valorTotal
    }

    function checkPaymentPrepaid(prepaid) {
        return (
            <div>
                {prepaid && <CheckIcon />}
            </div>
        )
    }


    const showResumeOrder = (order) => {
        setOpenResumeOrder(true)
        setSelectedOrder(order)
    }

    const tableRef = useRef()


    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: () => PAGE_STYLE_PRINT
    });

    function onClickResume(e) {
        e.stopPropagation();

        handlePrint()
    }

    const sortPedidosBy = (sortBy, direction) => {
        let copiaPedidos = Object.assign([], gridPedidos.pedidos)

        let ordered = orderBy(copiaPedidos, [function (o) {
            return get(o, sortBy, "")
        }], [direction])

        setGridPedidos({ ...gridPedidos, sortBy, direction, pedidos: ordered })
    }

    const MyCellRender = (props) => {
        // console.log('props.nameAttribute', props.hasOwnProperty('nameAttribute'))
        let render = true

        if (savedFilters && props.hasOwnProperty('nameAttribute')) {
            render = savedFilters.columnsHeader.find(cell => cell.nameAttribute === props.nameAttribute)?.render
        }

        return (
            render ? <StyledTableCell props={props} padding="none">{props.children}</StyledTableCell > : null
        )
    }

    return (
        <>
            <DetalhePedido open={openResumeOrder} setOpen={setOpenResumeOrder} order={selectedOrder} />

            <SelectDeliveryman
                handleClose={() => setOpenDialogEntregador(false)}
                open={openDialogEntregador}
                pedidoIntegracao={selectedOrder}
                onConfirm={onConfirmSelectDialog} />

            <AccordionStyled>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />} >

                    <Grid container alignItems='center' justifyContent="space-evenly">
                        <Grid item xs={4} sm={2}>
                            <Typography variant="h6" display="block">{pedidoIntegracao.pessoa.nome.toUpperCase()}</Typography>
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <InfoCard variant='h6' caption="Pedidos" value={pedidoIntegracao.pedidos.length} valueColor="primary" />
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <InfoCard variant='h6' caption="Tx. entrega" value={currencyFormat(calculaValorTotalEntrega(pedidoIntegracao))} className={classes.deliveryFee} />
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <InfoCard variant='h6' caption="Pgto online" value={currencyFormat(checktTotalPaymentOnline(pedidoIntegracao))} valueColor="info.main" />
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <InfoCard variant='h6' caption="Desc online" value={currencyFormat(checkTotalDescontoOnline(pedidoIntegracao))} valueColor="info.main" />
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <InfoCard variant='h6' caption="Total" value={currencyFormat(calculaValorTotal(pedidoIntegracao))} valueColor="primary" />
                        </Grid>

                        <Grid item xs={4} sm={2}>
                            <Button
                                color="grey"
                                startIcon={<ListAltIcon />}
                                size='small'
                                onClick={onClickResume}>
                                <Typography variant='caption'>Extrato entregador</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <TableContainer style={{ maxHeight: 300, width: '100%' }} className={classes.scrollBar} >

                        <Table size='small' stickyHeader ref={tableRef}>
                            <TableHead >
                                <TableRow>
                                    <TableCell padding="checkbox" colSpan={16}>
                                        <Typography variant="h6" display="block">{pedidoIntegracao.pessoa.nome.toUpperCase()}</Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <MyCellRender padding="checkbox" nameAttribute='tipoImportacao'>
                                        <TableSortLabel
                                            active={gridPedidos.sortBy === "tipoImportacao"}
                                            direction={gridPedidos.direction}
                                            onClick={() => sortPedidosBy("tipoImportacao", gridPedidos.direction === 'desc' ? 'asc' : 'desc')}
                                        >
                                            Origem
                                        </TableSortLabel>

                                    </MyCellRender>

                                    <MyCellRender padding="checkbox" nameAttribute='id'>
                                        <TableSortLabel
                                            active={gridPedidos.sortBy === "id"}
                                            direction={gridPedidos.direction}
                                            onClick={() => sortPedidosBy("id", gridPedidos.direction === 'desc' ? 'asc' : 'desc')}
                                        >
                                            #
                                        </TableSortLabel>
                                    </MyCellRender>

                                    <MyCellRender padding="checkbox" nameAttribute='shortReference'>
                                        <TableSortLabel
                                            active={gridPedidos.sortBy === "iFoodOrder.shortReference"}
                                            direction={gridPedidos.direction}
                                            onClick={() => sortPedidosBy("iFoodOrder.shortReference", gridPedidos.direction === 'desc' ? 'asc' : 'desc')}
                                        >
                                            Cód. Plat.
                                        </TableSortLabel>
                                    </MyCellRender>

                                    <MyCellRender padding="checkbox" nameAttribute='tipo'>
                                        Tipo
                                    </MyCellRender>

                                    <MyCellRender padding="checkbox" nameAttribute='sequencial'>
                                        <TableSortLabel
                                            active={gridPedidos.sortBy === "sequencial"}
                                            direction={gridPedidos.direction}
                                            onClick={() => sortPedidosBy("sequencial", gridPedidos.direction === 'desc' ? 'asc' : 'desc')}
                                        >
                                            Seq
                                        </TableSortLabel>
                                    </MyCellRender>

                                    <MyCellRender key='dataPedido' nameAttribute='dataPedido'>
                                        <TableSortLabel
                                            active={gridPedidos.sortBy === "dataPedido"}
                                            direction={gridPedidos.direction}
                                            onClick={() => sortPedidosBy("dataPedido", gridPedidos.direction === 'desc' ? 'asc' : 'desc')}
                                        >
                                            Data
                                        </TableSortLabel>
                                    </MyCellRender>

                                    <MyCellRender component='th' scope='row' nameAttribute='name'>
                                        Cliente
                                    </MyCellRender>

                                    <MyCellRender component='th' scope='row' nameAttribute='phone'>
                                        Telefone
                                    </MyCellRender>

                                    <MyCellRender scope='row' nameAttribute='neighborhood'>
                                        <TableSortLabel
                                            active={gridPedidos.sortBy === "iFoodOrder.deliveryAddress.neighborhood"}
                                            direction={gridPedidos.direction}
                                            onClick={() => sortPedidosBy("iFoodOrder.deliveryAddress.neighborhood", gridPedidos.direction === 'desc' ? 'asc' : 'desc')}
                                        >
                                            Bairro
                                        </TableSortLabel>
                                    </MyCellRender>

                                    <MyCellRender align='right' nameAttribute='deliveryFee'>
                                        Taxa entrega
                                    </MyCellRender>

                                    <MyCellRender padding="checkbox" nameAttribute='prepaid'>
                                        Pgto online
                                    </MyCellRender>

                                    <MyCellRender align='right' nameAttribute='total' >
                                        Total
                                    </MyCellRender>

                                    <MyCellRender align='right' nameAttribute='endereco' >
                                        Endereço
                                    </MyCellRender>

                                    <MyCellRender align='right' nameAttribute='observacoes' >
                                        Observações
                                    </MyCellRender>

                                    <MyCellRender align='right' nameAttribute='situacao' >
                                        Status Pedido
                                    </MyCellRender>

                                    <MyCellRender padding="checkbox" />
                                    <MyCellRender padding="checkbox" />
                                </TableRow>
                            </TableHead>


                            <TableBody className={classes.headerStyle}>
                                {gridPedidos.pedidos.map(pedido => {
                                    const situacao = SITUACOES.find(item => item.key === pedido.situacao)

                                    return (
                                        <TableRow key={pedido.id}>

                                            <MyCellRender padding="checkbox" nameAttribute='tipoImportacao'>
                                                <IntegrationAvatar pedido={pedido} />
                                            </MyCellRender>

                                            <MyCellRender padding="checkbox" nameAttribute='id'>
                                                <Box className={classes.idStyle}>
                                                    #{pedido.id}
                                                </Box>
                                            </MyCellRender>

                                            <MyCellRender padding="checkbox" nameAttribute='shortReference'>
                                                <strong>{pedido.iFoodOrder?.shortReference}</strong>
                                            </MyCellRender>

                                            <MyCellRender padding="checkbox" nameAttribute='tipo'>
                                                <TypeOrderAvatar order={pedido} />
                                            </MyCellRender>

                                            <MyCellRender padding="checkbox" nameAttribute='sequencial'>
                                                {pedido.sequencial}
                                            </MyCellRender>

                                            <MyCellRender nameAttribute='dataPedido'>
                                                {dateFormat(pedido.dataPedido)}
                                            </MyCellRender>

                                            <MyCellRender component='th' scope='row' nameAttribute='name'>
                                                {pedido.iFoodOrder?.customer?.name}
                                            </MyCellRender>

                                            <MyCellRender component='th' scope='row' nameAttribute='phone'>
                                                {formatPhoneBR(pedido.iFoodOrder?.customer?.phone)}
                                            </MyCellRender>

                                            <MyCellRender scope='row' nameAttribute='neighborhood'>
                                                <Box className={classes.neighborhoodStyle}>
                                                    {pedido.iFoodOrder?.deliveryAddress?.neighborhood}
                                                </Box>

                                                {isTakeout(pedido.iFoodOrder) ? <Box style={{ color: TAKEOUT_COLOR }}>Para retirar</Box> : null}

                                            </MyCellRender>

                                            <MyCellRender align='right' nameAttribute='deliveryFee'>
                                                <Box className={classes.deliveryFee}>
                                                    {currencyFormat(totalDeliveryFee(pedido.iFoodOrder))}
                                                </Box>
                                            </MyCellRender>

                                            <MyCellRender padding="checkbox" nameAttribute='prepaid'>
                                                {checkPaymentPrepaid(isPrepaidOrder(pedido.iFoodOrder))}
                                            </MyCellRender>

                                            <MyCellRender align='right' nameAttribute='total'>
                                                {currencyFormat(totalOrder(pedido.iFoodOrder))}
                                            </MyCellRender>

                                            <MyCellRender align='right' nameAttribute='endereco'>
                                                {formatFullAddressDelivery(pedido.iFoodOrder?.deliveryAddress)?.onlyAddress}
                                                {formatFullAddressDelivery(pedido.iFoodOrder?.deliveryAddress)?.complement ? (" - " + formatFullAddressDelivery(pedido.iFoodOrder?.deliveryAddress)?.complement) : null}
                                            </MyCellRender>

                                            <MyCellRender align='right' nameAttribute='observacoes'>
                                                {pedido.iFoodOrder?.observations}
                                            </MyCellRender>

                                            <MyCellRender align='right' nameAttribute='situacao'>
                                                <Typography style={{ color: situacao.color }}>
                                                    {situacao.singularName}
                                                </Typography>
                                            </MyCellRender>

                                            <MyCellRender padding="checkbox">

                                                <Box displayPrint="none">
                                                    <Tooltip title="Alterar entregador">
                                                        <IconButton
                                                            onClick={() => { setOpenDialogEntregador(true); setSelectedOrder(pedido) }}
                                                            size="large">
                                                            <SportsMotorsportsIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>

                                            </MyCellRender>

                                            <MyCellRender padding="checkbox">
                                                <Box displayPrint="none">
                                                    <IconButton onClick={() => showResumeOrder(pedido)} size="large">
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Box>
                                            </MyCellRender>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>

                    </TableContainer>

                </AccordionDetails>
            </AccordionStyled>
        </>
    )
}

