import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import { LoadingButton } from '@mui/lab'
import { Button, Chip, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, useMediaQuery } from '@mui/material'
import { extractErrorMessage } from '../../util/http/HttpUtil'
import AuthServiceTransient from '../../service/AuthServiceTransient'
import { ThemeProvider } from '@emotion/react'
import { createThemeApp } from '../../theme'
import { dateFormat, dateFormatDistanceToNow, dateParseISO } from '../../util/formatter'
import CloseIcon from '@mui/icons-material/Close'

const LIMIT_MOBILE = 3
const ROWS_PER_PAGE = 6
const PREFIX = 'JobAssincrono'

const classes = {
    ready: `${PREFIX}-ready`,
    table: `${PREFIX}-table`,
    hideMobile: `${PREFIX}-hideMobile`,
}

const StyledBox = styled(Box)(() => ({
    [`& .${classes.ready}`]: {
        backgroundColor: '#66bb6a1c',
    },

    [`& .${classes.hideMobile}`]: {
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },

    [`& .${classes.table}`]: {
        minHeight: 498,

        '@media (max-width: 600px)': {
            minHeight: 'auto',

            ['td, th']: {
                padding: 8
            }
        },
    },
}))


function useSocket() {
    const [data, setData] = useState()
    const [room, setRoom] = useState('')
    const [socket, setSocket] = useState(null)

    useEffect(() => {
        function initSocket() {
            if (socket || !room) return

            const room_socket = room + process.env.REACT_APP_SOCKET_ROOM
            const query = 'room=' + room_socket + '&auth=' + process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN
            const webSocket = io(process.env.REACT_APP_SOCKET_URL, {
                query,
                transports: ['websocket'],
            })

            console.log('Socket instanciado', webSocket)

            webSocket.on("connect", () => {
                console.log('connect SocketIO', webSocket.id)
            })

            webSocket.on("disconnect", (msg) => {
                console.log('disconnect SocketIO', webSocket.id)
                console.log("Disconnection message", msg)
            })

            setSocket(webSocket)
        }

        initSocket()

        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
    }, [room])

    useEffect(() => {
        if (!socket) return

        function onMessageReceive(event) {
            if (event.type === 'UPDATE_STATUS_JOB') {
                setData(event.data)
            }
        }

        socket.room = room

        // Recebe um update de socket de outros usuários socket
        socket.on('Update', (event) => onMessageReceive(event))

        // Recebe um update do socket do servidor
        socket.on('reciveSocketMessage', (event) => onMessageReceive(event))
    }, [socket])

    return [data, setRoom]
}

function BodyWrapper({ children }) {
    const theme = createThemeApp('light')

    return (
        <ThemeProvider theme={theme}>
            <style jsx="true" global="true">
                {`body {
                    margin: 0;
                    overflow: hidden;
                }`}
            </style>
            {children}
        </ThemeProvider>
    )
}

const StatusIcon = memo(function StatusIcon({ status }) {
    if (status === 'PENDENTE') {
        return <CircleIcon fontSize="small" color="warning" />
    }

    if (status === 'INICIADO') {
        return <CircleIcon fontSize="small" color="info" />
    }

    if (status === 'FINALIZADO') {
        return <CircleIcon fontSize="small" color="success" />
    }

    if (status === 'ERRO') {
        return <CircleIcon fontSize="small" color="error" />
    }

    return <CircleIcon fontSize="small" color="default" />
})

const StatusChip = memo(function StatusChip({ status }) {
    if (status === 'PENDENTE') {
        return <Chip variant="outlined" label={status} color="warning" />
    }

    if (status === 'INICIADO') {
        return <Chip variant="outlined" label={status} color="info" />
    }

    if (status === 'FINALIZADO') {
        return <Chip variant="outlined" label={status} color="success" />
    }

    if (status === 'ERRO') {
        return <Chip variant="outlined" label={status} color="error" />
    }

    return <Chip variant="outlined" label={status} color="default" />
})

function JobAssincronoAction({ job, size = 'medium', onAfterUpdate }) {
    const { enqueueSnackbar } = useSnackbar()
    const [isOpening, setIsOpening] = useState(false)

    function abrir(job) {
        setIsOpening(true)

        const copyJob = Object.assign({}, job)

        copyJob.visualizado = true

        AuthServiceTransient.post('/api-v2/jobAssincrono', copyJob).then(rest => {
            onAfterUpdate(rest)

            AuthServiceTransient.get('/api-v2/jobAssincrono/open/' + rest.id)
                .then(rest => window.open(rest.url, '_blank', 'noreferrer'))
                .finally(() => setIsOpening(false))
        }).catch(err => {
            console.log(err)
            setIsOpening(false)
            extractErrorMessage(err, 'Erro ao abrir relatório').then(msg => {
                enqueueSnackbar('Falha ao abrir: ' + msg, { variant: 'error' })
            })
        })
    }

    if (job.status === 'ERRO') {
        return (
            <Tooltip title={job.dados} disableInteractive arrow>
                <Chip
                    size={size}
                    color="error"
                    variant="outlined"
                    label="O que houve?"
                    icon={<InfoOutlinedIcon color="error" />} />
            </Tooltip>
        )
    }

    if (job.tipo === 'INFO') {
        return (
            <Tooltip title={job.dados} disableInteractive arrow>
                <Chip
                    size={size}
                    color="info"
                    variant="outlined"
                    label="INFO"
                    icon={<InfoOutlinedIcon color="info" />} />
            </Tooltip>
        )
    }

    return (
        <LoadingButton
            size={size}
            variant="outlined"
            loadingPosition="start"
            loading={isOpening || job.status === 'INICIADO'}
            disabled={job.status !== 'FINALIZADO'}
            startIcon={<OpenInNewIcon />}
            onClick={() => abrir(job)}>
            {job.status !== 'FINALIZADO' ? 'Processando' : 'Abrir'}
        </LoadingButton>
    )
}

function JobAssincronoRow({ row, onAfterUpdate }) {

    return (
        <TableRow
            key={row.uuid}
            className={(!row.visualizado && row.status === 'FINALIZADO') ? classes.ready : ''}>
            <TableCell align="center" className={classes.hideMobile}>
                <StatusIcon status={row.status} />
            </TableCell>
            <TableCell sx={{ width: 300 }}>
                <Typography>
                    {row.descricao}
                </Typography>
            </TableCell>
            <TableCell align="center" className={classes.hideMobile}>
                <Typography>
                    {dateFormat(row.createdAt, 'dd/MM/yyyy HH:mm')}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <StatusChip status={row.status} />
            </TableCell>
            <TableCell align="right" sx={{ width: 210 }}>
                <JobAssincronoAction
                    job={row}
                    onAfterUpdate={onAfterUpdate} />
            </TableCell>
        </TableRow>
    )
}

function JobAssincronoMobile({ data, mobileList, onAfterUpdate }) {
    const isMounted = useRef(false)
    const [showTable, setShowTable] = useState(false)

    // mostra a tabela de processos quando um item é adicionado/atualizado
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true
            return
        }

        setShowTable(true)
    }, [data])

    // exibe a tabela de processos ao clicar no botão lá do gerencial
    // no caso ele recebe as mensagens a partir do postMessage do click do botão
    useEffect(() => {
        function receiveMessage(event) {
            const open = event.data?.open || false
            setShowTable(open)
        }

        window.addEventListener('message', receiveMessage)

        return () => window.removeEventListener('message', receiveMessage)
    }, [])

    // é feito isso para ajustar o tamanho (e o style) do iframe lá na página do gerencial
    useEffect(() => {
        function updateIframeStyle(height) {
            let iframeStyle = `
                position: fixed;
                right: 16px;
                bottom: 16px;
                background: #fff;
                width: 500px;
                max-width: calc(100% - 32px);
                height: ${height}px;
                border: 0;
                opacity: 0;
                transition: 0.4s;
                overflow: hidden;`

            if (height) {
                iframeStyle += `
                    opacity: 1;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    box-shadow: 0 0 18px -10px #bbb;`
            }

            window.parent.postMessage({ iframeStyle }, '*')
        }

        const heightNow = document.body.scrollHeight
        updateIframeStyle(heightNow)

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { height } = entry.contentRect
                updateIframeStyle(height)
            }
        })

        resizeObserver.observe(document.body)

        return () => resizeObserver.unobserve(document.body)
    }, [mobileList])

    function openAll() {
        window.parent.postMessage({ seeAllLink: '/admin/relatorio/jobs-RELATORIO.jsf' }, '*')
    }

    if (!showTable) {
        return null
    }

    return (
        <StyledBox>
            <Box sx={{ p: 2, borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6">
                    Relatórios em andamento
                </Typography>
                <IconButton size="small" onClick={() => setShowTable(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            {mobileList.length ? (
                mobileList.map(job => (
                    <Box key={job.uuid}
                        className={(!job.visualizado && job.status === 'FINALIZADO') ? classes.ready : ''}
                        sx={{ p: 2, gap: 1, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                        <StatusIcon status={job.status} />
                        <Box sx={{ flex: 1 }}>
                            <Typography>
                                {job.descricao}
                            </Typography>
                            <Typography variant="caption">
                                há {dateFormatDistanceToNow(dateParseISO(job.createdAt))}
                            </Typography>
                        </Box>
                        <JobAssincronoAction
                            job={job}
                            size="small"
                            onAfterUpdate={onAfterUpdate} />
                    </Box>
                ))
            ) : (
                <Typography variant="body1" sx={{ p: 2, textAlign: 'center' }}>
                    Nenhum relatório em andamento encontrado
                </Typography>
            )}
            <Box sx={{ p: 2, borderTop: '1px solid #ccc' }}>
                <Button size="small" onClick={openAll}>
                    Ver todos
                </Button>
            </Box>
        </StyledBox>
    )
}

export default function JobAssincrono(props) {
    const { match: { params } } = props
    const { nome, jwt } = params
    const query = new URLSearchParams(props.location?.search)
    const [jobs, setJobs] = useState()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(true)
    const [data, setRoom] = useSocket()
    const [page, setPage] = useState(0)
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const mobileList = useMemo(() => {
        if (jobs && isMobile) {
            return jobs.slice(0, LIMIT_MOBILE)
        }

        return []
    }, [jobs, isMobile])

    useEffect(() => {
        AuthServiceTransient.load(jwt).then((res) => {
            console.log('Dados do usuário/empresa carregados com sucesso.')
            setRoom(res.unidade.uuid)
            loadJobs(res)
        }).catch(err => {
            console.log(err)
            extractErrorMessage(err, 'Erro ao carregar dados do usuário').then(msg => {
                enqueueSnackbar('Falha ao carregar dados do usuário/empresa carregados: ' + msg, { variant: 'error' })
            })
        })
    }, [])

    useEffect(() => {
        if (!data) return

        let copyJobs = [...jobs]
        if (!copyJobs.some(item => item.id === data.id)) {
            copyJobs.unshift(data)
            setJobs(copyJobs)
            return
        }

        copyJobs = [...jobs].map(item => item.id === data.id ? data : item)

        setJobs(copyJobs)
    }, [data])

    // ajusta o tamanho do iframe la na página do gerencial de acordo com o tamanho da tabela
    function updateIframeHeight(delay = 400) {
        setTimeout(() => {
            window.parent.postMessage({
                iframeStyle: `border: 0; height: ${document.body.scrollHeight}px;`
            }, '*')
        }, delay)
    }

    function handleAfterUpdate(rest) {
        const copyJobs = [...jobs].map(item => item.id === rest.id ? rest : item)

        setJobs(copyJobs)
    }

    function loadJobs(user) {
        setLoading(true)
        AuthServiceTransient.get(`/api-v2/jobAssincrono/listByNomeAndIdUsuario/${nome}/${user.id}`).then(rest => {
            setJobs(rest)
            updateIframeHeight()
        }).catch(err => {
            console.log(err)
            extractErrorMessage(err, 'Erro ao carregar dados dos relatórios').then(msg => {
                enqueueSnackbar('Falha ao carregar dados dos relatórios: ' + msg, { variant: 'error' })
            })
        }).finally(() => setLoading(false))
    }

    function handleChangePage(event, newPage) {
        setPage(newPage)
        updateIframeHeight(0)
    }

    if (loading) {
        return (
            <BodyWrapper>
                <Box sx={{ p: 3 }}>
                    <LinearProgress />
                </Box>
            </BodyWrapper>
        )
    }

    if (!jobs || !jobs.length) {
        return (
            <BodyWrapper>
                <Box sx={{ p: 3, textAlign: 'center' }} className={classes.hideMobile}>
                    <Typography>
                        Nenhum dado encontrado
                    </Typography>
                </Box>
            </BodyWrapper>
        )
    }

    if (isMobile && query.get('showMobileScreen') === 'true') {
        return (
            <BodyWrapper>
                <JobAssincronoMobile
                    data={data}
                    mobileList={mobileList}
                    onAfterUpdate={handleAfterUpdate} />
            </BodyWrapper>
        )
    }

    const jobsByPage = jobs.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
    return (
        <BodyWrapper>
            <StyledBox>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer className={classes.table}>
                            <Table>
                                <TableHead sx={{ bgcolor: 'grey.100' }}>
                                    <TableRow>
                                        <TableCell align="center" className={classes.hideMobile}></TableCell>
                                        <TableCell>
                                            <Typography>
                                                Descrição
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" className={classes.hideMobile}>
                                            <Typography>
                                                Criado em
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                Status
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobsByPage.map((row) => (
                                        <JobAssincronoRow
                                            key={row.uuid}
                                            row={row}
                                            onAfterUpdate={handleAfterUpdate} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            page={page}
                            component="div"
                            count={jobs.length}
                            rowsPerPage={ROWS_PER_PAGE}
                            rowsPerPageOptions={[ROWS_PER_PAGE]}
                            onPageChange={handleChangePage} />
                    </Grid>
                </Grid>
            </StyledBox>
        </BodyWrapper>
    )
}