import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ReactGA from "react-ga4";
import ConfiguracoesBalanca from './configuracoesBalanca.js';

const PREFIX = 'atendimento-balanca'

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(5),
    //marginBottom: theme.spacing(2),
  }
}))

export default function AtendimentoBalanca() {
  useEffect(() => {
    //informa ao analytics que o usuário caiu na pagina de settings
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: PREFIX });

  }, [])

  return (
    <Root className={classes.root}>
      <Container component="main" className={classes.main} maxWidth={false}>
        <Typography variant="h2" component="h1">
          Autoatendimento de balança
        </Typography>

        <Typography variant="h5" component="h2">
          Configurações
        </Typography>


        <ConfiguracoesBalanca></ConfiguracoesBalanca>

      </Container>
    </Root>
  )
}