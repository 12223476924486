import React from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { getDescricaoLocalNotificacaoBySigla } from '../../../../util/board/BoardUtil';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';

const PREFIX = 'StatusItemIcon'

const classes = {
    printDisabledIcon: `${PREFIX}-printDisabledIcon`,
    printIconImpresso: `${PREFIX}-printIconImpresso`,
    printIconPendente: `${PREFIX}-printIconPendente`,
    printIconOutros: `${PREFIX}-printIconOutros`
}

const StyledIconWrapper = styled('div')(({ theme }) => ({
    [`& .${classes.printDisabledIcon}`]: {
        color: '#f10b5c',
    },

    [`& .${classes.printIconImpresso}`]: {
        color: '#4caf50',
    },

    [`& .${classes.printIconPendente}`]: {
        color: '#ffc107',
    },

    [`& .${classes.printIconOutros}`]: {
        color: '#9e9e9e',
    }
}))

export function StatusItemIcon({ item }) {
    return (
        <StyledIconWrapper>
            {item.status === 'NAO_NOTIFICAR' ?
                <Tooltip title='Item configurado para não imprimir nas área de produção'>
                    <NotificationsOffIcon fontSize='small' className={classes.printDisabledIcon} />
                </Tooltip>
                :
                item.status === 'NOTIFICADO' ?
                    <Tooltip title={`Impresso em: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`}>
                        <NotificationsActiveIcon fontSize='small' className={classes.printIconImpresso} />
                    </Tooltip>
                    :
                    <Tooltip title={`Item pendente de impressão: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`}>
                        <NotificationsNoneIcon fontSize='small' className={classes.printIconPendente} />
                    </Tooltip>}
        </StyledIconWrapper>
    )
}

export function StatusKdsItemIcon({ item }) {
    return (
        <StyledIconWrapper>
            {item.statusKds === 'NAO_NOTIFICAR' ?
                <Tooltip title='Item configurado para não notificar o KDS'>
                    <NotificationsOffIcon fontSize='small' className={classes.printDisabledIcon} />
                </Tooltip>
                :
                item.statusKds === 'NOTIFICADO' ?
                    <Tooltip title={`Notificado KDS em: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`}>
                        <NotificationsActiveIcon fontSize='small' className={classes.printIconImpresso} />
                    </Tooltip>
                    :
                    <Tooltip title={`Item pendente de notificação no KDS: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`}>
                        <NotificationsNoneIcon fontSize='small' className={classes.printIconPendente} />
                    </Tooltip>}
        </StyledIconWrapper>
    )
}

export function StatusNotificationItemIcon({ item, impressaoAtivo, kdsAtivo }) {
    const statusKds = item.statusKds;
    const status = item.status;

    // eslint-disable-next-line
    const notificados = statusKds == 'NOTIFICADO' && status === 'NOTIFICADO';
    const pendentes = (statusKds === 'PENDENTE' || statusKds === undefined) && status === 'PENDENTE';
    const naoNotificados = statusKds === 'NAO_NOTIFICAR' && status === 'NAO_NOTIFICAR';
    const outros = !notificados && !pendentes && !naoNotificados;

    let messagePrint = ""

    if (impressaoAtivo) {
        /* MSG REF IMPRESSAO */
        if (status === 'NAO_NOTIFICAR') {
            messagePrint += "Não imprimir nas área de produção"
        } else if (status === 'NOTIFICADO') {
            messagePrint += `Impresso em: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`
        } else {
            messagePrint += `Pendente de impressão: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`
        }
    }

    let messageKDS = ""

    if (kdsAtivo) {
        /* MSG REF KDS */
        if (statusKds === 'NAO_NOTIFICAR') {
            messageKDS += "Não notificar o KDS"
        } else if (statusKds === 'NOTIFICADO') {
            messageKDS += `Notificado KDS em: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`
        } else {
            messageKDS += `Pendente de notificação no KDS: ${getDescricaoLocalNotificacaoBySigla(item.localNotification)}`
        }
    }

    return (
        <Tooltip disableInteractive title={
            <>
                {messagePrint} <br /> {messageKDS}
            </>
        }>
            <StyledIconWrapper>
                {naoNotificados && <DoneAllIcon fontSize='small' className={classes.printDisabledIcon} />}
                {pendentes && <DoneAllIcon fontSize='small' className={classes.printIconPendente} />}
                {notificados && <DoneAllIcon fontSize='small' className={classes.printIconImpresso} />}
                {outros && <DoneIcon fontSize='small' className={classes.printIconOutros} />}
            </StyledIconWrapper>
        </Tooltip>
    )
}
