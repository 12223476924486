import React, { useState, useEffect } from 'react';
import { Dialog, Button, Grid, TextField, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DialogTitle, DialogContent, DialogActions } from "../../CustomDialog/dialogComponents"
import AuthService from '../../../../service/AuthService';
import { onlyDigits } from "../../../../util/formatter"
import { TextMaskPhone } from "../../../../util/mask"
import { usePdvContext } from '../../../../component/generics/PdvProvider';

const TIPOS_CONTATOS = [
    {
        value: 'FONE',
        label: 'Fone',
    },
    {
        value: 'EMAIL',
        label: 'E-mail',
    },

];

export default function SecondaryContact({ open, setOpen, selectedContact, returnContact }) {
    const { enqueueSnackbar } = useSnackbar();
    const [contact, setContact] = useState(selectedContact || {})
    const { confirmDialog } = usePdvContext()

    useEffect(() => {
        setContact(selectedContact)
    }, [selectedContact]);
    function handleDelete() {
        confirmDialog({
            showCancelButton: true,
            confirmLabel: 'Confirmar',
            content: 'Deseja realmente excluir esse contato?',
        }).then(() => {

            let service = new AuthService()
            service.delete("/api-v2/contatoPessoa/" + contact.id)
                .then(response => {
                    setContact(contact)
                    setOpen(false)
                    enqueueSnackbar("Contato secundário excluído com sucesso", { variant: 'success' });
                    returnContact(contact, 'DELETED')
                }).catch(err => {
                    console.error(err)
                    enqueueSnackbar("Erro ao excluir contato secundário", { variant: 'error' });
                });

        }).catch(() => { })
    }

    function handleSubmit() {
        console.log('salvar contato', contact)

        if (!contact.valor || contact.valor === '') {
            enqueueSnackbar(`Informe um ${contact.tipo}.`, { variant: 'warning' });
            return
        }

        let contactCopy = Object.assign({}, contact)
        if (contact.tipo === 'FONE') {
            contactCopy.valor = onlyDigits(contact.valor)
        }

        let service = new AuthService()
        service.post("/api-v2/contatoPessoa", contactCopy)
            .then(contactSaved => {
                setContact(contactSaved)
                setOpen(false)
                enqueueSnackbar("Contato secundário salvo com sucesso", { variant: 'success' });
                returnContact(contactSaved, 'SAVED')
            }).catch(err => {
                console.log(err)
                enqueueSnackbar("Erro ao salvar contato secundário", { variant: 'error' });
            });

    }

    const handleChange = (event) => {
        const attr = event.target.name
        const value = event.target.value
        setContact({ ...contact, [attr]: value })
    }

    const handleClickCancel = () => {
        confirmDialog({
            confirmLabel: 'Ok',
            showCancelButton: true,
            content: 'Deseja realmente cancelar a ação?',
        })
            .then(() => {
                setOpen(false)

            }).catch(() => { })

        return
    }

    return (
        <div>

            <Dialog open={open} onClose={handleClickCancel}
                fullWidth maxWidth="sm">
                <DialogTitle onClose={handleClickCancel} >
                    Contato secundário
                </DialogTitle>

                <DialogContent>

                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField
                                label="Tipo" name="tipo" variant="outlined" size="small"
                                fullWidth
                                autoFocus
                                select
                                value={(contact && contact.tipo) || ''}
                                onChange={handleChange}
                            >
                                {TIPOS_CONTATOS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={8}>

                            {contact && contact.tipo === 'FONE' ? (
                                <TextField label="Valor"
                                    name="valor" variant="outlined" size="small"
                                    fullWidth
                                    value={(contact && contact.valor) || ''}
                                    InputProps={{
                                        inputComponent: TextMaskPhone,
                                    }}
                                    onChange={handleChange}
                                />
                            ) : (
                                <TextField label='Valor' name="valor" variant="outlined" size="small"
                                    fullWidth
                                    value={(contact && contact.valor) || ''}
                                    onChange={handleChange}
                                />
                            )}

                        </Grid>
                    </Grid>




                </DialogContent>

                <DialogActions>
                    <Button onClick={handleDelete} >Excluir</Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">Salvar</Button>
                </DialogActions>


            </Dialog>

        </div>
    );
}