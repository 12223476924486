import {
    Button,
    Dialog,
    DialogActions,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef } from 'react';
import { currencyFormat, dateFormat } from '../../../../util/formatter';
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { ifAnyGranted, ifAnyGrantedUnidade } from '../../../../util/auth/authUtil';
import { useHotkeys } from 'react-hotkeys-hook';
import { roundUp } from '../../../../util/genericUtil';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import { removeDiscountValueOnItems } from "../../../../util/order/orderUtil"
import { usePdvContext } from '../../../../component/generics/PdvProvider';

const PREFIX = 'EditItemOrder';

const classes = {
    dialogPaper: `${PREFIX}-dialogPaper`,
    backgroundColumn1: `${PREFIX}-backgroundColumn1`,
    backgroundColumn2: `${PREFIX}-backgroundColumn2`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogPaper}`]: {
        // minHeight: '40vh',
        // maxHeight: '60vh',
    },

    [`& .${classes.backgroundColumn1}`]: {
        background: theme.palette.personal.boardCard
    },

    [`& .${classes.backgroundColumn2}`]: {
        background: theme.palette.personal.boardList
    }
}));

function EditItemOrder({ open, setOpen, item }) {

    const order = useSelector(state => state.dialogOrder.order)
    const dispatch = useDispatch()
    let [itemEdit, setItemEdit] = React.useState({ observations: '' })
    const [obsDefault, setObsDefault] = React.useState()
    const editObsItem = useRef()
    const { enqueueSnackbar } = useSnackbar();
    const btnConfirmRef = useRef();
    let tempItemTotalPrice = item.price
    let tempItemQuantity = item.quantity
    const hasPermissionToNotEdit = useRef(ifAnyGranted("ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_DESCONTO_PEDIDO"));
    const statusInicial = item.status;
    const { confirmDialog } = usePdvContext()


    useHotkeys('f10', (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    })


    const handleClose = () => {
        setOpen(false)
    }

    const onClickAddObs = (obs) => {

        const itemTemp = Object.assign({}, itemEdit)

        if (itemTemp?.observations) {
            itemTemp.observations = itemTemp?.observations + ' ' + obs
        } else {
            itemTemp.observations = obs
        }

        setItemEdit(itemTemp)
    }

    const handleChangeSubItem = (event, sub) => {
        sub[event.target.name] = event.target.value
    }

    const handleChange = (event) => {

        const itemTemp = Object.assign({}, itemEdit)

        /*VERIFICA SE ESTÁ EDITANDO A QUANTIDADE PARA TRATAR VALOR MENOR QUE UM*/
        if(event.target.name === 'quantity'){
            itemTemp.quantity = event.target.value >= 0 && event.target.value !== '' ? event.target.value : 0 //Evita que seja admitido valor menor que 0
        }
        /*/*VERIFICA SE ESTÁ EDITANDO A QUANTIDADE PARA TRATAR VALOR MENOR QUE ZERO*/
        else if(event.target.name === 'price'){
            itemTemp.price = event.target.value >= 0.0 && event.target.value !== '' ? event.target.value : itemTemp.price;
        }else{
            itemTemp[event.target.name] = event.target.value
        }

        let discount = itemTemp.discount || 0.0
        itemTemp.totalPrice = roundUp((itemTemp.quantity * itemTemp.price) - discount, 2)

        if (event.target.name === 'status') {
            itemTemp.statusKds = event.target.value
        }

        setItemEdit(itemTemp)
    }

    const handleClickCancel = () => {

        confirmDialog({
            showCancelButton: true,
            confirmLabel: 'Confirmar',
            content: 'Deseja realmente cancelar? Todas as alterações serão perdidas.',
        }).then(( )=> { 
            handleClose()

        }).catch(() => { })
    }

    useEffect(() => {
        //pega as observações salvas no sischef e mostra como botões para o cliente clicar e adicionar
        const obs = item?.product?.observacoesMobile?.split(';')?.filter((e) => e !== '')
        setObsDefault(obs)

        const itemCopy = JSON.parse(JSON.stringify(item))

        setItemEdit(itemCopy)
        // eslint-disable-next-line
    }, [open])


    const hasAdditional = () => {
        return itemEdit.subItems?.length > 0
    }

    const handleFinalizar = () => {
        if (itemEdit.status === 'NOTIFICADO') {
            enqueueSnackbar(`O item não pode ser editado pois já foi enviado para produção.`, { variant: 'warning', });
            return
        } else {
            const orderCopy = Object.assign({}, order)

            let itemTemp = orderCopy.iFoodOrder.items?.find(element => element.referenceCode === itemEdit.referenceCode)

            if (itemEdit.status === 'PENDENTE' && statusInicial !== itemEdit.status && !itemEdit.notSaved) {
                const data = new Date();
                orderCopy.logs = orderCopy.logs ? orderCopy.logs += `Item do pedido (${item.name}) renotificado manualmente (${dateFormat(data)});` : `Item do pedido (${item.name}) renotificado manualmente (${dateFormat(data)})`
            }

            for (const property in itemEdit) {
                itemTemp[property] = itemEdit[property];
            }
            if ((tempItemTotalPrice != item.price) || (tempItemQuantity != item.quantity)) { //Verifica se houve alteração na qunatidade ou valor do item
                removeDiscountValueOnItems(orderCopy.iFoodOrder) //Se houve alteração, remove o desconto do pedido para recalculá-lo
                enqueueSnackbar(`Por favor note que o desconto do pedido foi zerado`, { variant: 'warning', });
            }
            dispatch({ type: 'UPDATE_ORDER_EDIT', dialogOrder: { order: orderCopy, open: true } })

            setOpen(false)
        }
    }

    return (
        <StyledDialog
            maxWidth="sm"
            fullWidth
            open={open}
            scroll='paper'
            onClose={handleClickCancel}>

            <DialogTitle onClose={handleClickCancel}>
                {item.name}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2} direction='row' alignItems='center' className={classes.dialogPaper} >
                    {
                        ifAnyGrantedUnidade('ROLE_UTILIZA_NOTIFICACAO', 'ROLE_UTILIZA_KDS') ?
                            <Grid item xs={12}>
                                <FormControl component="fieldset" size='small' fullWidth >
                                    <FormLabel component="legend">Notificação do item</FormLabel>
                                    <RadioGroup aria-label="status" name="status" value={itemEdit.status} onChange={handleChange} row>
                                        <FormControlLabel value="PENDENTE" control={<Radio />} label="Pendente" />
                                        <FormControlLabel disabled={item.status === 'PENDENTE'} value="NOTIFICADO" control={<Radio />} label="Notificado" />
                                        <FormControlLabel disabled={item.status === 'NOTIFICADO'} value="NAO_NOTIFICAR" control={<Radio />} label="Não notificar" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            :
                            ''
                    }
                    <Grid item xs={6} sm={3}>
                        <TextField
                            disabled={hasAdditional()}
                            type="number"
                            id="quantity"
                            fullWidth
                            variant='outlined'
                            label='Quantidade'
                            defaultValue={item.quantity}
                            onChange={handleChange}
                            name='quantity'
                            size='small'
                            InputProps={{
                                inputProps: {
                                    min: 0,
                            }}}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            type="number"
                            disabled={hasAdditional() || hasPermissionToNotEdit.current}
                            id="price"
                            fullWidth
                            variant="outlined"
                            label='Valor unitário'
                            defaultValue={item.price}
                            onChange={handleChange}
                            name='price'
                            size='small'
                            InputProps={{
                                inputProps: {
                                    min: 0,
                            }}}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            id="discount"
                            disabled={true}
                            fullWidth
                            variant="outlined"
                            label='Desconto'
                            defaultValue={currencyFormat(item.discount, { simbol: '' })}
                            name='discount'
                            size='small'
                        />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <TextField
                            id="totalPrice"
                            disabled={true}
                            fullWidth
                            variant='filled'
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            label='Valor Total'
                            value={currencyFormat(itemEdit.totalPrice)}
                            name='totalPrice'
                            size='small'
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1} direction="row" justifyContent="flex-start">

                            {obsDefault?.map((obs, index) => (
                                <Grid item key={index}>
                                    <Button variant='outlined' size='small' onClick={() => onClickAddObs(obs)} focusRipple={false}>
                                        {obs}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            inputRef={editObsItem}
                            autoFocus={true}
                            id="observations"
                            label="Observação do item"
                            multiline
                            fullWidth
                            maxRows={4}
                            minRows={2}
                            name='observations'
                            value={itemEdit.observations || ''}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Table size='small' aria-label='resumo do pedido' stickyHeader>
                            <TableBody>

                                {itemEdit.subItems?.map((sub, index) => (
                                    <TableRow key={index}>
                                        <TableCell component='th' scope='row'>{'+ '.concat(sub.name)}</TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Observação"
                                                fullWidth
                                                name='observations'
                                                defaultValue={sub.observations}
                                                onChange={(event) => handleChangeSubItem(event, sub)}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>

                <Button
                    ref={btnConfirmRef}
                    onClick={handleFinalizar}
                    variant="contained"
                    color="primary" >
                    Confirmar
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default EditItemOrder;