export const toogleTefSaleInfoDialog = (currentState, action) => {
    const { dialogTefSaleInfo } = action
    const { open, sale } = dialogTefSaleInfo

    return {
        ...currentState,
        dialogTefSaleInfo: { ...dialogTefSaleInfo, open, sale }
    }
}

export const initialTefSaleInfoDialog = () => {

    return {
        sale: null,
        open: false,
    }
}