import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { getTipoImportacaoBySigla } from '../../../util/board/BoardUtil';
import { currencyFormat } from '../../../util/formatter';
import { includeTaxFeeOnInport, isDeliveryOrder } from '../../../util/order/orderUtil';

const PREFIX = 'OrderImportTaxFee'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    productFounded: `${PREFIX}-productFounded`,
    productNotFound: `${PREFIX}-productNotFound`,
    rootTableRow: `${PREFIX}-rootTableRow`
}

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.productFounded}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.productNotFound}`]: {
        color: theme.palette.error.main,
    },

    [`& .${classes.rootTableRow}`]: {
        '& > *': {
            borderBottom: 'unset',
        },
    }
}))

export default function OrderImportTaxFee({ order, setOrder }) {

    const mustIncludeTaxFee = includeTaxFeeOnInport(order.iFoodOrder)
    const iFoodOrder = order.iFoodOrder
    const deliveryFee = iFoodOrder.deliveryFee

    const integracao = getTipoImportacaoBySigla(order.tipoImportacao).descricao //recebe o tipo de importação onde foi efetuada a taxa de entrega

    const handleChangeIncluirTaxaEntregaImportacaoSelecionado = (event) => {
        let copyOrder = Object.assign({}, order)
        copyOrder.iFoodOrder.incluirTaxaEntregaImportacaoSelecionado = event.target.value;
        setOrder(copyOrder)
    }

    return (
        <StyledBox mt={2}>
            {isDeliveryOrder(order) && (
                <Box>
                    {/** <Typography variant="h6" component="div">Taxa de Entrega</Typography> */}
                    <TableContainer >
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell  >Taxa de Entrega</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right"  >Valor</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ width: "60%" }}>
                                        <Typography className={classes.productFounded}>Taxa de entrega vinda do {integracao}</Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right" >
                                        {currencyFormat(deliveryFee)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>
            )}
            <Box py={1}>
            <Paper variant='outlined'>
                
                <Box p={2}>

                    {mustIncludeTaxFee ? null : (
                        <Alert severity="warning">
                            <AlertTitle>Entrega realizada por terceiros</AlertTitle>
                            Verifique a necessidade de incluir a taxa de entrega nos pedidos
                        </Alert>
                    )}


                    <FormControl component="fieldset">
                        <FormLabel component="legend">Incluir taxa de entrega na importação?</FormLabel>
                        <RadioGroup row aria-label="position" name="position"
                            value={order.iFoodOrder.incluirTaxaEntregaImportacaoSelecionado || 'S'}
                            onChange={handleChangeIncluirTaxaEntregaImportacaoSelecionado}>
                            <FormControlLabel
                                value="S"
                                control={<Radio color="primary" />}
                                label="Sim"
                            />
                            <FormControlLabel
                                value="N"
                                control={<Radio color="primary" />}
                                label="Não"
                            />
                        </RadioGroup>
                    </FormControl>

              </Box>

            </Paper>
            </Box>

        </StyledBox>
    )
}