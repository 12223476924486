
export const toogleDiscountDialog = (currentState, action) =>  {
    let { discountOrder } = action
    return {
        ...currentState, 
        discountOrder: { ...discountOrder, open: discountOrder.open } 
    }
}

export const initialDiscountDialog = () =>  {

    return {
        open: false,
    }
}