
export const toogleSimpleBackdrop = (currentState, action) => {
    let { simpleBackdrop } = action
    return {
        ...currentState,
        simpleBackdrop: { ...simpleBackdrop, open: simpleBackdrop.open }
    }
}

export const initialSimpleBackdrop = () => {

    return {
        open: false
    }
}