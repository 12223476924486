import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, Grid, Paper, Typography } from '@mui/material';
import { FixedSizeGrid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { currencyFormat } from '../../../../util/formatter';
import Tooltip from "@mui/material/Tooltip";
import { SkeletonProducts } from '../../elements/Skeletons';
import { memo } from 'react';

const PREFIX = 'Product';

const classes = {
    paper: `${PREFIX}-paper`,
    productPrice: `${PREFIX}-productPrice`,
};

const StyledAutoSizer = styled(AutoSizer)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        paddingTop: '4px',
        overflow: "hidden",
        height: "100%",
        width: '100%',
        verticalAlign: 'center'
    },

    [`& .${classes.productPrice}`]: {
        position: 'absolute',
        bottom: theme.spacing(),
        right: theme.spacing(),
    }
}));

const cellRowHeight = 60;
const cellRowWidth = 158;


const Product = memo(function Product({ handleClickAddProduct, activeGroup, products, productsRef, loading }) {

    console.log("RERENDER PRODUCT")

    const theme = useTheme();
    const backgroundColor = '#cfd8dc'
    const refColoumnCount = useRef(4);

    const GUTTER_SIZE = 0;

    //Utilizado para aguardar o retorno de adicionar produto
    const addProduct = (product) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(handleClickAddProduct(product));
            }, 100);
        })
    }

    //utilizado para navegação entre os itens e também para adicionar o item ao order
    const handleKeyDown = (event, index, columnRows) => {
        if (event.key === 'Enter') {
            if (productsRef.current && productsRef.current[index] && products[index]) {
                event.preventDefault()
                addProduct(products[index]).then(() => {
                    if (!!!products[index].abrirPassos === 'S') {
                        productsRef.current[index].focus()
                    }
                })
            }
        } else if (event.key === 'ArrowRight') {
            if (productsRef.current) {
                if (productsRef.current[index + 1]) {
                    event.preventDefault()
                    productsRef.current[index + 1].focus()
                }
            }
        } else if (event.key === 'ArrowLeft') {
            if (productsRef.current) {
                if (productsRef.current[index - 1]) {
                    event.preventDefault()
                    productsRef.current[index - 1].focus()
                }
            }
        } else if (event.key === 'ArrowUp') {
            if (productsRef.current) {
                if (productsRef.current[index - columnRows]) {
                    event.preventDefault()
                    productsRef.current[index - columnRows].focus()
                }
            }
        } else if (event.key === 'ArrowDown') {
            if (productsRef.current) {
                if (productsRef.current[index + columnRows]) {
                    event.preventDefault()
                    productsRef.current[index + columnRows].focus()
                }
            }
        }
    }

    const Cell = ({ columnIndex, rowIndex, style }) => {

        const columnCount = refColoumnCount.current;
        const index = (rowIndex * columnCount) + columnIndex;
        const itemCardapio = products[index]
        const bgColor = activeGroup.cor || backgroundColor;
        const fontColor = theme.palette.getContrastText(bgColor)
        const productPrice = itemCardapio ? currencyFormat(itemCardapio.produto.valorUnitarioVenda) : ""

        const tipDescription = itemCardapio ?
            `${itemCardapio.produto.descricao} - ${productPrice}` : productPrice;

        return (
            <div style={{
                ...style,
                left: style.left + GUTTER_SIZE,
                top: style.top + GUTTER_SIZE,
                width: style.width - GUTTER_SIZE,
                height: style.height - GUTTER_SIZE,
                display: 'flex',
                overflow: 'hidden'
            }} >
                {itemCardapio ? (
                    <Box flexGrow={1} p={0.2}>
                        <ButtonBase
                            ref={el => productsRef.current[index] = el}
                            onClick={() => handleClickAddProduct(itemCardapio)}
                            onKeyDown={(event) => handleKeyDown(event, index, columnCount)}
                            focusRipple={true}
                            focusVisibleClassName={classes.button}
                            style={{
                                height: "100%",
                                width: '100%'
                            }}>
                            <Tooltip title={`${tipDescription}`} disableInteractive>
                                <Paper
                                    elevation={0}
                                    className={classes.paper}
                                    style={{
                                        background: itemCardapio.produto.grupoProduto.cor || bgColor,
                                        color: fontColor,
                                        padding: '2px',
                                        position: 'relative',
                                    }}>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            background: itemCardapio.produto.grupoProduto.cor || bgColor,
                                            color: fontColor,
                                        }}
                                    >
                                        {itemCardapio.produto.descricao.substring(0, 25)}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        className={classes.productPrice}
                                        style={{
                                            background: itemCardapio.produto.grupoProduto.cor || bgColor,
                                            color: fontColor,
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        {productPrice}
                                    </Typography>
                                </Paper>
                            </Tooltip>

                        </ButtonBase>
                    </Box>
                ) : (null)}
            </div>
        );

    };

    if (!products?.length && !loading) {
        return (
            <Typography textAlign="center" p={2}>
                Nenhum produto encontrado para o grupo selecionado
            </Typography>
        )
    }

    return (
        <StyledAutoSizer>
            {({ height, width }) => {
                const columnWidth = cellRowWidth + GUTTER_SIZE;
                const dinamicColumnCount = parseInt(width / (columnWidth));
                refColoumnCount.current = dinamicColumnCount;

                const dinamicColumnWidth = parseInt((width - 10) / dinamicColumnCount)

                const dinamicRows = Math.ceil(products.length / dinamicColumnCount)

                if (loading) {
                    return (
                        <Box style={{ width, height, }}>
                            <SkeletonProducts />
                        </Box>
                    )
                }

                return (
                    <FixedSizeGrid
                        width={width}
                        height={height}
                        columnCount={dinamicColumnCount}
                        columnWidth={dinamicColumnWidth}
                        rowCount={dinamicRows}
                        rowHeight={cellRowHeight + 5} // Aumenta a altura da linha
                        style={{ height: height + 5 }} // Aumenta a altura do Grid
                    >
                        {Cell}
                    </FixedSizeGrid>
                )
            }}
        </StyledAutoSizer>
    );
})

export default Product