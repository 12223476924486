import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'

export function AutoGridBox({ minWidth = 160, gap = 1, children }) {
    const theme = useTheme()
    const gridTemplateColumns = `repeat(auto-fill, minmax(${minWidth}px, 1fr))`

    return (
        <Box
            display="grid"
            gap={theme.spacing(gap)}
            gridTemplateColumns={gridTemplateColumns}>
            {children}
        </Box>
    )
}