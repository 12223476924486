import { Box, Button, Dialog, DialogActions, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import { useHotkeys } from 'react-hotkeys-hook';
import { pendingAmount } from '../../../../util/order/orderUtil';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';

function DeliveryFee({ open, setOpen, order }) {
    const btnConfirmRef = useRef();
    const listDeliveryFee = useSelector(state => state.globalCache.listDeliveryFee)
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState({deliveryFee: 0.0, productDeliveryFee: null})

    useHotkeys('f10', (event) => {

        event.preventDefault()
        event.stopPropagation()

        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
            onClickConfirm()
        }
    })

    useEffect(() => {

        setValue({ deliveryFee: order?.iFoodOrder?.deliveryFee, productDeliveryFee: order?.iFoodOrder?.productDeliveryFee })

    }, [order, open])

    function handleClose() {
        setOpen(false)
    }

    const onClickConfirm = () => {

        let pending = pendingAmount(order.iFoodOrder)

        if (value.deliveryFee > pending){
            enqueueSnackbar('Não é possivel remover a taxa de entrega, pois o total pendente é menor que a taxa.', { variant: 'error', });
            return
        }

        // if (total < 0) {
        //     enqueueSnackbar('Não é possivel remover a taxa de entrega, pois o total pendente .', { variant: 'error', });
        //     return
        // }

        order.iFoodOrder.deliveryFee = value.deliveryFee
        order.iFoodOrder.productDeliveryFee = value.productDeliveryFee

        handleClose()
    }

    function handleSelectDeliveryFee(value) {
        if (value) {
            setValue({ deliveryFee: value.valorUnitarioVenda, productDeliveryFee: value })
        } else {
            setValue({ deliveryFee: 0.0, productDeliveryFee: null })
        }
    }

    return (
        <Dialog open={open}
            maxWidth={"xs"}
            fullWidth
            onClose={handleClose}>

            <DialogTitle onClose={handleClose}>
               Selecione a taxa de entrega mais adequada
            </DialogTitle>

            <DialogContent>

                <Box pt={1}>
                    <Autocomplete
                        size='small'
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        value={value.productDeliveryFee || null}
                        onChange={(event, newInputValue) => { handleSelectDeliveryFee(newInputValue) }}
                        autoHighlight
                        id="deliveryFee"
                        options={listDeliveryFee || []}
                        getOptionLabel={(option) => option ? `${option.descricao} | R$ ${option.valorUnitarioVenda?.toFixed(2)}` : ''}
                        renderInput={(params) => <TextField {...params} label="Informe a taxa de entrega" variant="outlined" />}
                    />
                </Box>

            </DialogContent>

            <DialogActions>
                <Tooltip title="Atalho F10" aria-label="Finalizar">
                    <Button
                        ref={btnConfirmRef}
                        variant="contained"
                        color="primary"
                        onClick={onClickConfirm}
                        endIcon={<DoneIcon />}>Confirmar</Button>
                </Tooltip>
            </DialogActions>

        </Dialog>
    )
}

export default DeliveryFee;